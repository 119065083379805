import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import FilterIcon from '@mui/icons-material/Filter';
import ConfirmDialog from 'views/Hoc/Dialog/GlobalDialog';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Modal } from "reactstrap";
import { Radio } from '@material-ui/core';
import MultiUpload from '../../Hoc/MultiUpload';
import ModalImageCropper from '../../Hoc/ModalImageCropper';
import { commonService } from '_services/common.service';
import { UncontrolledTooltip } from "reactstrap";

import { Card, CardBody, Row, Col, CardHeader,  ModalFooter, ModalHeader, ModalBody,TabContent, TabPane, Nav, NavItem, NavLink, } from "reactstrap";
import ReactQuill from "react-quill";
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { FormButton, FormInput, FormAutocomplete } from "_components/FormElements/FormInput";
import Avatar from "@material-ui/core/Avatar";
import axios from 'axios';
import { GoogleAddress } from './googleAddress';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Spinner } from '../../Hoc/Spinner';
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';

import Checkbox from '@mui/material/Checkbox';
import AnnualTurnOver from "views/Hoc/annualTurnOver";
import { Spin } from 'antd';
import AddBrand from "views/Hoc/Dialog/AddBrand";
import { countryIsoCodeList } from './CountryIsoCode';
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
    GridRowModes,
    DataGrid,
    GridToolbar,
    GridToolbarQuickFilter,
    GridToolbarContainer,
    GridActionsCellItem,
    GridLinkOperator

} from '@mui/x-data-grid';
import { ColorLensOutlined } from '@material-ui/icons';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { MenuItem, Select, FormControlLabel } from '@material-ui/core';
import { ModalDialog } from 'react-bootstrap';

var _ = require('lodash');

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
            display: "none"
        },
        "& .MuiAvatar-img":{
            height:"60%",
            objectFit:"contain"
        }
    }
}));


const businessTypes = [{ id: '2', name: 'B2B' }, { id: '1', name: 'B2C' }, { id: '3', name: 'Both' }];

const revenueMaster = [{ id: 1, name: '<1M€' }, { id: 2, name: '1-10M€' }, { id: 3, name: '10-100M€' }, { id: 4, name: '>100M€' }]

function EditToolbar(props) {
    const { colFilter, isLoaded, setDeleted, isDeleted, setChannel, isTestChannel,setTestChannel, setRowModesModel, channel, handleSave, handleSaveToDB, setFilter,filterFlag, setGrouping, setBulkEdit, channelSelectionModel, filterChannel,setFilterChannel,filterValue,setIsOpen,setButtons } = props;
    const [updatedChannels, setUpdatedChannel] = useState([]);   
    const [checkGridChild, setCheckGridChild] = useState(null);  

    const handleClick = (filterValue) => {

        const maxchannelId =Math.max(...channel.map((x)=>+x.channelId));//randomId();
        const id=maxchannelId+1;
        if(checkGridChild === null) {
            setCheckGridChild(id)
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [id]: { mode: GridRowModes.View, fieldToFocus: 'companyName' },
            }));

            if(filterFlag){
                setChannel((prev)=>[{id, channelId: null, companyName: '', channelType: filterValue.channelType?.name, profileType: filterValue.profile.length>0?filterValue.profile[0].name:'', isOEM:filterValue.isOEM?.name, channelGroup: filterValue.grouping?.name,
                deletedAt: false, isActive: false, isPublic: true, startup: false, isTestAccount: false, estYear: null, empCnt: null, storeCount: null, webSiteUrl: '',no_of_resellers:'',no_of_retailers:'',no_of_distributors:'',
                country: filterValue.country.length?filterValue.country[0].name:'', channelLocation: { sellingLocation: [], targetLocation: [] }, channelLogo: null,
                isNew: true},...prev]);
                setFilterChannel((prev)=>[{id, channelId: null, companyName: '', channelType: filterValue.channelType?.name, profileType: filterValue.profile.length>0?filterValue.profile[0].name:'', isOEM:filterValue.isOEM?.name, channelGroup: filterValue.grouping?.name,
                deletedAt: false, isActive: true, isPublic: true, startup: false, isTestAccount: false, estYear: null, empCnt: null, storeCount: null, webSiteUrl: '',no_of_resellers:'',no_of_retailers:'',no_of_distributors:'',
                country: filterValue.country.length?filterValue.country[0].name:'', channelLocation: { sellingLocation: [], targetLocation: [] }, channelLogo: null,
                isNew: true},...prev]);
            } else {
                setChannel((oldRows) => [{
                    id, channelId: null, companyName: '', channelType: '', profileType: '', oem: '', channelGroup: '',
                    deletedAt: false, isActive: true, isPublic: true, startup: false, isTestAccount: false, estYear: null, empCnt: null, storeCount: null, phone: null, webSiteUrl: '',no_of_resellers:'',no_of_retailers:'',no_of_distributors:'',
                    country: '', channelLocation: { sellingLocation: [], targetLocation: [] }, channelLogo: null,
                    isNew: true
                }, ...oldRows]);
            }
        } else {
            ToastsStore.warning('Please add data to already added row')
        }
        
      
    };

    return (
        <>
            <div>
            <FormButton onClick={handleSave}
                className="btn-sin btn btn-primary"
                id="loginbutton"
                name="loginbutton"
                value="Save Changes"
                // style={{float:"right"}}
                style={{color:"white",backgroundColor: channelSelectionModel.length > 0 ?  "red" : "#2c9db7"}}

            />

<FormButton onClick={handleSaveToDB}
                className="btn-sin btn btn-primary"
                id="loginbutton1"
                name="loginbutton1"
                value="Save Changes1"
                style={{ display: 'none', color:"white",backgroundColor: channelSelectionModel.length > 0 ?  "red" : "#2c9db7"}}
            />

            <FormButton onClick={() => setGrouping(true)}
                className="btn-sin btn btn-primary"
                id="addchannelgroup"
                name="addchannelgroup"
                value="Channel Group"
            />

            <FormButton onClick={() => channelSelectionModel.length > 0 && setBulkEdit(true)}
                className="btn-sin btn btn-primary"
                id="addchannelgroup"
                name="addchannelgroup"
                value="Bulk Edit"
            />

            <FormButton onClick={() => { if (channelSelectionModel.length > 0) { setIsOpen(true); setButtons('channels');}}}
                className="btn-sin btn btn-primary"
                id="addchannelgroup"
                name="addchannelgroup"
                value="Bulk Delete"
            />
           <Spin size="medium" className="fp-loader" spinning={isLoaded ? true : false} />{(isLoaded && !isDeleted) ? 'Data Loading Please Wait...' : (isLoaded && isDeleted) ? 'Loading Deleted Data Please Wait...' : ''}

                </div>

            <GridToolbarContainer>

                <Button color="primary" disabled={(colFilter || filterChannel?.length || channel.filter(x => x.channelId === null).length) ? false : false} startIcon={<AddIcon />} onClick={()=>handleClick(filterValue)}>
                    Add record
                </Button>

                <GridToolbarQuickFilter />
                {<>
                Load Deleted Channel
                <label className="custom-toggle custom-toggle-success mx-4">
                    <input type="checkbox"  name="deletedData" onChange={() => setDeleted(!isDeleted)} />
                    <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                </label></>}
                {<>
                Load Test Channel
                <label className="custom-toggle custom-toggle-success">
                    <input type="checkbox"  name="testData" onChange={() => setTestChannel(!isTestChannel)} />
                    <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                </label></>}
            </GridToolbarContainer>


        </>
    );
}

function brandToolbar(props) {
    const { setBrandMaster, brandMaster, setAddBrand } = props;

    const handleClick = () => {
        // const id = brandMaster.length + 9999;//randomId();
        // setBrandMaster((oldRows) => [{ id: id, isNew: true, brandName: '', document: { documentUrl: null } }, ...oldRows]);
        setAddBrand(true);
    };

    return (
        <>

            <GridToolbarContainer>

                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    Add Brand
                </Button>

                <GridToolbarQuickFilter />
            </GridToolbarContainer>
         
        </>
    );
}


function groupToolbar(props) {
    const { setGroupMaster, groupMaster } = props;

    const handleClick = () => {
        const id = groupMaster.length + 1;//randomId();
        setGroupMaster((oldRows) => [{ id: id, isNew: true, name: '' }, ...oldRows]);
    };

    return (
        <>

            <GridToolbarContainer>

                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    Add Group
                </Button>

                <GridToolbarQuickFilter />
            </GridToolbarContainer>


        </>
    );
}

EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
    setChannel: PropTypes.func.isRequired,

};

const initialState = [
    { channel: { id: 1, channelId: 1234, companyName: 'Demo', channelType: '', regAddress: null } }
]
export default function Channels() {    

    const classes = useStyles();
    const [firstLoad, setFirstLoad]=useState(true);
    const { control } = useForm({ mode: 'onBlur' });
    const [refreshTime, setRefreshTime]=useState(0);

    const [regAddress, setRegAddress] = useState({
        address: '',
        city: '',
        state: '',
        country: [],
        postalCode: '',
        countryId: '',
        isVat: null,
        regno: '',
        regname: '',
        vatno: '',
        vatname: 'VAT',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        jotTitle: '',
        mobile: '',
    })

    const [socialProfiles,setSocialProfiles]=useState([]);
    
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});

    const [brandFilterModel,setBrandFilterModel]=useState({ items: [], quickFilterLogicOperator: GridLinkOperator.Or });
    const [brandColFilter,setBrandColFilter]=useState(false);

    const [filterModel, setFilterModel] = React.useState({ items: [], quickFilterLogicOperator: GridLinkOperator.Or });

    const [editBrand, setEditBrand] = useState(null);
    const [aws_folderName,]=useState('CompanyLogo')

    const [isBrandEdit, setBrandEdit] = useState(false);

    const [colFilter, setColFilter] = useState(false);

    const [openAddBrand, setAddBrand] = useState(false);

    const [grouping, setGrouping] = useState(false);

    const [bulkEdit, setBulkEdit] = useState(false);

    const [location, setLocation] = useState(false);

    const [isLoaded, setLoaded] = useState(true);

    const [groupExist, setGroupExist] = useState(false);

    const [editItem, setEditItem] = useState();

    const [channelGroup, setChannelGroup] = useState();

    let [groupMaster, setGroupMaster] = useState([]);

    const [groupFilter, setGroupFilter] = useState([]);

    const [state, setState] = useState(initialState);

    const [filterOptionState, setFilterOption] = useState({
        Brand_Option: [],
        Product_Option: []
    })

    const [deletedGroupIds, setDeletedGroupIds] = useState([]);


    let [filter, setFilterData] = useState({
        // channelType: null,
        // isOEM: null,
        // isActive: false,
        // isPublic: false,
        // startup: false,
        // brand:[],
        // product:[]
    });

    const [filterValue, setFilterValues] = useState({
        channelType: null,
        profile:[],
        isOEM: null,
        country: [],
        brand: [],
        product: [],
        speciality: [],
        grouping: null,
        revenue: null,
        deletedAt:null,        

    });

    const [filterFlag, setFilter] = useState(false);
    const [filterParent, setFilterParent] = useState();
    const [filterBase, setFilterBase] = useState([]);

    let [filterChannel, setFilterChannel] = React.useState([]);


    const [updatedChannels, setUpdatedChannel] = useState([]);
    const [channel, setChannel] = React.useState([]);
    let [prevList, setPrevChannel] = React.useState([]);

    const [brandMaster, setBrandMaster] = useState([]);

    let [specialityMaster, setSpecialityMaster] = useState([]);


    const [brandSelectionModel, setBrandSelectionModel] = useState([]);
    const [retailerSelectionModel, setRetailerSelectionModel] = useState([]);
    const [resellerSelectionModel, setRessellerSelectionModel] = useState([]);
    const [retailerList,setRetailerList]=useState([]);
    const [resellerList,setResellerList]=useState([]);
    const [keyRetailers,setKeyRetailers]=useState([]);
    const [keyResellers,setKeyResellers]=useState([]);
    const [channelDeletedBrand, setChannelDeletedBrand] = useState([]);

    const [channelSelectionModel, setChannelSelectionModel] = useState([]);


    const [isAnnualTurn, setAnnualTurn] = useState(false);
    const [revenue, setRevenue] = useState();
    const [contactDetails, setContactDetail] = useState([]);


    const [categoryMaster, setCategoryMaster] = useState([]);
    const [channelProduct, setChannelProduct] = useState([]);
    const [channelSpeciality, setChannelSpeciality] = useState([]);

    const [temp, setTemp] = useState([]);
    const [selectedRows,setSelectedRows]=useState([]);

    const [channelBrand, setChannelBrand] = useState([]);
    const [categorySelectionModel, setCategorySelectionModel] = useState([]);

    const [specialitySelectionModel, setSpecialitySelectionModel] = useState([]);

    let [countryList, setCountryList] = useState([]);

    let [sellingList, setSellingList] = useState([]);
    let [targetList, setTargetList] = useState([]);

    let [profileMaster, setProfileMaster] = useState();

    const [sellingLocation, setSellingLocation] = useState([]);
    const [sellingSelectionModel, setSellingSelectionModel] = useState([]);

    const [targetLocation, setTargetLocation] = useState([]);
    const [targetSelectionModel, setTargetSelectionModel] = useState([]);

    const [openAddress, setAddress] = useState(false);
    const [openSocial,setSocial]=useState(false);
    const [newFbUrl,setNewFbUrl]=useState('');
    const [newInstaUrl,setNewInstaUrl]=useState('');
    const [newTwitterUrl,setNewTwitterUrl]=useState('');
    const [newLinkedUrl,setNewLinkedUrl]=useState('');

    const [openBrand, setBrand] = useState(false);
    const [openYourChannel,setYourChannel]=useState(false);

    const [openProduct, setProduct] = useState(false);
    const [openLogo, setOpenLogo] = useState(false);
    const [selectGroup, setSelectGrouping] = useState(false);
    const [bulkGroupId, setBulkGroup] = useState();

    let [selectData, setSelectData] = useState(null);
    let [selectIndex, setSelectIndex] = useState(null);
    let [list, setList] = useState([]);
    const [open, setOpen] = useState(false);
    const [openDropDown, setOpenDropDown] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    let [isImg, setImgOpen] = useState(false);
    let [imgUrl, setImgUrl] = useState(null);
    const [activeTab, setActiveTab] = useState('1');
    const [logourl, setLogoUrl] = useState(null);

    const [openSpeciality, setSpeciality] = useState(false);

    const [openDesc, setDesc] = useState(false);

    const [description, setDescription] = useState('');

    const [channelTypes, setChannelTypes] = useState([{ id: 2, name: 'Vendor' }, { id: 3, name: 'Distributor' }, { id: 4, name: 'Retailer' }, { id: 5, name: 'Reseller' }]);

    const [channelId, setChannelId] = useState(false);

    const [typeChannelId,setChannelType] = useState('')

    const [groupId, setGroupId] = useState({});

    const [logo, setLogo] = useState({ documentId: null, documentPath: null })

    const [title, setTitle] = useState(null);

    let [count, setCount] = useState(0);

    let [delCount, setDelCount] = useState(0);
    let [testCount,setTestCount]=useState(0);

    const [rowModesModel, setRowModesModel] = React.useState({});

    //const [rowModeMaxId, setRowModeMaxId] = React.useState([]);

    let [loading, setLoading] = useState(true);

    let [isDeleted, setDeleted] = useState(false);
     const [page,setPage]=useState(1);
     const [pageSize,setPageSize]=useState(500);
     const [sort,setSort]=useState({})
     const [channelTypeId,setChannelTypeId]=useState(null)
     const [totalRecord,setTotalRecord]=useState(0);
    //  const [currentPage,setCurrentPage]=useState(page)
    let [isTestChannel,setTestChannel] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDeleteCompanyModal, setIsOpenDeleteCompanyModal] = useState(false);
    const [buttons, setButtons] = useState('');
    const [deletedId,setDeletedId]=useState([]);
    const [mainChannels, setMainChannels]=useState([]);
    const [deletedChannels, setDeletedChannels]=useState([]);
    const [testChannels, setTestChannels]=useState([]);

    const [image, setImage] = useState(null);
    const [uploadedImage, setIUploadedImage] = useState(null);
    const [imagePath,setImagePath]=useState(null);
    const [deleteGroupId, setDeleteGroupId] = useState("");
    const [selectedCompanyName, setSelectedCompanyName] = useState("");
    const [openContactModal,setOpenContactModal] = useState(false)
    const [openUpdateContactModal,setUpdateOpenContactModal] = useState(false)
    const [contactData,setContactData] = useState({})
    const [duplicateEmail,setDuplicateEmail] = useState(false)
    const [deleteCompany, setDeleteCompany] = useState({
         clearAllCompoanies: false,
         clearSelectedCompanies: false
        })
    const [deleteGruopInfo, setDeleteGruopInfo] = useState([]);

    const [closeValidatePopup, setCloseValidatePopup] = useState(false)
    const [validatePop, setValidatePop] = useState(false)    
    let [validateData, setValidateData] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null }); 


    const loadChannelData = (body, isDeleted) => {
      setLoaded(true);
      getChannel(body, isDeleted).then((result) => {
        // console.log("kkkk", result);
        if (result) {
          result = result || [];
          if(isDeleted)
          {
            setDeletedChannels(prevDeletedChannels => [...prevDeletedChannels, ...result]);
          }
            setChannel((previous) => {
            return [...previous, ...result];
          });
        }
        if ((result || []).length < 1000) {
          setLoaded(false);
        } else {
          body.filterData.pageNumber = body.filterData.pageNumber + 1;
          loadChannelData(body, isDeleted);
        }
      });
    };

    useEffect(() => {
        if(!isDeleted)
        {
        CountryList();
        profileMasters();
        groupMasters();
        brandMasters();
        specialityMasters();
        productMaster();
        //countryMaster();
        getResellerRetailer();
        }

      if(isDeleted) {
        if(mainChannels.length===0)
        setMainChannels([...channel]) // step 1 
        loadChannelData({
            filterData: {
              pageSize: 1000,
              pageNumber: 1,
              channelType: channelTypeId,
              sortData: {
                  sortOrder:"DESC"
              },
            },
          },
          isDeleted
        );
      } else {
        if(firstLoad) {
                        loadChannelData({
                filterData: {
                  pageSize: 1000,
                  pageNumber: 1,
                  channelType: channelTypeId,
                  sortData: {
                      sortOrder:"DESC"
                  },
                },
              },
              isDeleted
            );
        } else {
            setDeletedChannels([]);
            setChannel([...mainChannels,...testChannels]);
        }
      }
      setFirstLoad(false);

    }, [isDeleted]);

    useEffect(() => {
    if(isTestChannel){
      if(mainChannels.length===0)
      setMainChannels([...channel]) 
      function getData(body, count, isTestChannel) {
        setLoaded(true);
        getTestChannel(body,isTestChannel).then((result) => {
            let arr2=channel.concat(result)
            setChannel(arr2);
            setTestChannels([...testChannels,...result])
            setLoaded(false);
        });
      }

      getData(
        {
          filterData: {
            pageSize: pageSize,
            pageNumber: page,
            channelType: channelTypeId,
            sortData: {
                sortOrder:"DESC"
            },
          },
        },
        testCount * 500,
        isTestChannel
      );
      testCount = testCount + 1;
      setTestCount(testCount);
    }else{
        setTestChannels([]);
        setChannel([...mainChannels,...deletedChannels]);
    }

    }, [isTestChannel])

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    const handleCropClose = () => {
        setImage('');
      }
      

      const closeValidateError = (e) => {

        setCloseValidatePopup(false);
        setValidatePop(false);
      };
    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
    
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
    
        return new File([u8arr], filename, { type: mime });
      }

      const getSelectedRows=()=>{
        let channels = channel.filter(x => channelSelectionModel.includes(x.id))
           setSelectedRows(channels)
      }
    
    const selectImg = async (data) => {
        if (data.length > 0) {
          loading = true;
    
          setLoading(loading);
          setImage('');
          //converting base64 string to file format.
          var random = Math.random();
          var fileName = random + '.png';
          var file = dataURLtoFile(data, fileName);
          const formData = new FormData();
          formData.append('file', file);

          const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + aws_folderName);
         
        //   if (resImagePath) {
        //     const uploadMediaLibrary = await commonService.postService({
        //       Key: resImagePath.data.url,
        //       user: {
        //         ...(channel?.channelId && { channelId: channel?.channelId }),
        //         ...(channel?.oldChannelId && { oldChannelId: channel?.oldChannelId })
        //       }
        //     }, '/setting/createCompanyLogo');
        //     list.unshift(uploadMediaLibrary.data);
        //     setList(list);
        //     handleChange(0, list[0]);
        //     loading = false;
        //     setLoading(loading);
        //   } else {
        //     loading = false;
        //     setLoading(loading);
        //   }
            setIUploadedImage(data)
            setImagePath(resImagePath.data.url)
            loading = false;
            setLoading(loading);
             }
        }

    const handleUpload = () => {
        setOpen(false);
        getCompanyLogo(selectData);
      }

    const imageModalClose = () => {
        isImg = false;
        setImgOpen(isImg);
      };

    const handleOnDrop = async (data) => {
        if (data.length > 0) {
          console.log('datat', data);
          let file = data[0];
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            console.log('file', file)
            console.log('reader.result', reader.result)
            setIUploadedImage(
              reader.result
            );
            setImage(reader.result)
            console.log('image', image)
            //setOpen(false);          
          };
    
        }
      }

    const handleChangeCountry = (input, value) => {
        if (value) {
            setRegAddress({
                ...regAddress, countryId: value.id, regname: value.registrationcertificate,
                isVat: value.isVat, country: [{ id: value.id, name: value.name }]
            })
        }
    }

    const handleChangeSelect = async (input, value) => {
        if (input == 'address') {
            setState({ ...state, [input]: value });
        }
    }



    // const handleChange = input => e => {
    //     setState({ ...state, [input]: e.target.value });
    // }

    const handleChangeAddress = (input, value, faddress) => {
        // console.log(faddress,value)
        const gAddress = {
            address: faddress,
            city: '',
            state: '',
            country: [],
            postalCode: '',
            countryId: '',
            isVat: '',
        }
        value.map(x => {
            if (x.types.length && (x.types.includes('locality') || x.types.includes('postal_town'))) {
                gAddress.city = x.long_name;
                faddress = faddress.replace(gAddress.city + ', ', '');
            }
            if (x.types.length && x.types.includes("administrative_area_level_1")) {
                gAddress.state = x.long_name;
                faddress = faddress.replace(gAddress.state + ', ', '');
            }
            if (x.types.length && x.types.includes("country")) {
                let id = '';
                let name = ''
                id = countryList.find(a => a.countryCode === x.short_name.substring(0, 2)).id;
                name = countryList.find(a => a.countryCode === x.short_name.substring(0, 2)).name;
                gAddress.isVat = countryList.find(a => a.id === id).isVat;
                gAddress.country = [{ name: name, id: id }]; //
                gAddress.countryId = id;
                faddress = faddress.replace(name, '');
            }
            if (x.types.length && x.types.includes("postal_code"))
                gAddress.postalCode = x.long_name;
        })

        gAddress.address = faddress.substring(faddress.length - 2,) == ', ' ? faddress.slice(0, faddress.length - 2) : faddress;

        setRegAddress({ ...regAddress, ...gAddress });

    }
    const [country, setCountry] = useState(null)
    const[countryPhoneNumber, setCountryNumber] = useState()

    const handleSaveToDB = async ()=>{
        setLoading(true);
          
        let data = [];
        let ctype; let ptype; let oem; let cgroup; let countryId ;let channelProfileData;
        let result = channel.filter(ch => updatedChannels.includes(ch.id));
        let companyExistFlag = false;
        let error = '';
        let err = 0
        
        if(result.length > 0){
            // setChannel(channel.filter(ch => updatedChannels.includes(ch.id)));
            result.map(row => {   
                companyExistFlag = channel.filter(x => x.companyName.toLowerCase().replaceAll(" ","").trim()===row.companyName.toLowerCase().replaceAll(" ","").trim()).length>1?true:false;

                if(row.companyName == '' || companyExistFlag && row.companyName != '' || row.channelType == '' || row.profileType == '' || row.country == ''){
                error += 'Channel Id : '+ row.id+' / Company Name : '+ row.companyName+' \n '; 
                }
                if(row.companyName == ''){
                    error += '* Company Name is required \n ';
                    err = err + 1;
                } else if(companyExistFlag && row.companyName != '') {
                    error += '* Company Name already found! \n ';
                    err = err + 1;
                }
                if(row.channelType == ''){
                    error += '* Channel Type is required \n ';
                    err = err + 1;
                }  
                if(row.profileType == ''){
                    error += '* Profile Type is required \n ';
                    err = err + 1;
                }  if(row.country == ''){
                    error += '* Channel Country is required \n ';
                    err = err + 1;
                } 
                
                if(err > 0){
                   // console.log('err cnt', err )
                    setLoading(false);
                    validateData.message = error
                    validateData.title = row.companyName;
                    validateData.value = 'Confirm';
                    validateData.popUpId = 2;
                  //  console.log('validateData = ', validateData )
                    setValidateData({...validateData});
                    setValidatePop(true)    
                                        
                } else {
                let cgroup=null;
                let certificateDetails = [];
                
                if (row.regAddress && row.regAddress['isVat'] === '1') {
                    if (row.regAddress['regno'])
                        certificateDetails.push({
                            cerificateNumber: row.regAddress['regno'],
                            cerificateName: row.regAddress['regname'] ? row.regAddress['regname'] : 'Registration No',
                            cerificateTypeId: 1
                        })
                    if (row.regAddress['vatno'])
                        certificateDetails.push({
                            cerificateNumber: row.regAddress['vatno'],
                            cerificateName: row.regAddress['vatname'] ? row.regAddress['vatname'] : 'VAT',
                            cerificateTypeId: 2
                        })
                    }
                    else if (row.regAddress && row.regAddress['regno'])
                    certificateDetails.push({
                        cerificateNumber: row.regAddress['regno'],
                        cerificateName: row.regAddress['regname'] ? row.regAddress['regname'] : 'Registration No',
                        cerificateTypeId: 1
                    })              

                    
                    if((country != null && row.country != country) && row.country == ""){
                        row.country = country
                    }
                    if (row.country)
                        countryId = +countryList.filter(x => x.name === row.country)[0].id;
                    
                    if (row.channelType)
                        ctype = +channelTypes.filter(x => x.name === row.channelType)[0].id;
                        if (row.profileType) {
                            let profileData = profileMaster.find(x => x.name === row.profileType);
                        
                            if (profileData) {
                                ptype = +profileData.id;
                                let channelType = profileData.channelTypeId === '2' ? "Vendor" : profileData.channelTypeId === '3' ? "Distributor" : profileData.channelTypeId === '4' ? "Retailer" : "Reseller";
                        
                                channelProfileData = [{
                                    childname: profileData.name,
                                    groupName: channelType,
                                    profileTypeId: +profileData.id,
                                    profileTypeClassificationId: +profileData.profileTypeClassificationId,
                                    status: true
                                }];
                            }
                    }
                    if (row.isOEM)
                        oem = businessTypes.filter(x => x.name === row.isOEM)[0].id;
                    if (row.channelGroup)
                    cgroup = +groupMaster.filter(x => x.name === row.channelGroup)[0].id;
                    

                    data.push({
                    id: row.id,
                    channelId: row.channelId,
                    isNew: row.isNew,
                    channel: {
                        companyName: row.companyName,
                        channelTypeId: ctype,
                        profileTypeId: ptype,
                        isActive: row.isActive ? "1" : "0",
                        deletedAt: row.deletedAt ? new Date() : null,
                        isPublic: row.isPublic ? "1" : "0",
                        isTestAccount: row.isTestAccount ? "1" : "0",
                        isOEM: oem,
                        channelGroup: cgroup ? cgroup: null,
                        countryId: countryId, //row.regAddrObject ? +row.regAddrObject['countryId'] : row.regAddress['countryId']
                    },
                    channelDetail: {
                        startup: row.startup ? "1" : "0",
                        estYear: row.estYear ? row.estYear : null,
                        empCnt: row.empCnt ? row.empCnt : null, //((row.empCnt === '' && row.empCnt !=null) || row.empCnt.toString()?.split('-').length > 1) ? null : row.empCnt,
                        // empCntRange: row.empCnt.toString()?.split('-').length > 1 ? row.empCnt : null,
                        storeCount: row.storeCount === "" ? null : row.storeCount,
                        phone1: row.phone != null ? row.phone:countryPhoneNumber,
                        webSiteUrl: row.webSiteUrl,
                        detailDesc: row.description,
                        channelLogoId: row.channelLogoId, //logo.documentId ? logo.documentId : row.channelLogoId,
                        companyLogoUrl: row.channelLogo, //logo.documentUrl
                        no_of_resellers: row.no_of_resellers ? row.no_of_resellers:0,
                        no_of_retailers: row.no_of_retailers ? row.no_of_retailers:0,
                        no_of_distributors:row.no_of_distributors ? row.no_of_distributors:0,
                    },
                    address: row.regAddress
                        ? {
                            regAddressId: row.regAddressId ? row.regAddressId : null,
                            address: row.regAddress["address"],
                            city: row.regAddress["city"],
                            state: row.regAddress["state"],
                            countryId: +row.regAddress["countryId"],
                            postalCode: row.regAddress["postalCode"],
                        }
                        : null,
                        socialProfiles: row.channelSocial ? row.channelSocial:[],
                        

                    // userObject: row.regAddrObject ? {
                    //     email: row.regAddrObject['email'],
                    //     password: row.regAddrObject['password'],
                    //     verificationCode: 'Admin',
                    //     contact: {
                    //         firstName: row.regAddrObject['firstName'],
                    //         lastName: row.regAddrObject['lastName'],
                    //         email: row.regAddrObject['email'],
                    //         countryId: +row.regAddrObject['countryId'],
                    //         jobTitle: row.regAddrObject['jobTitle'],
                    //         mobileNo: row.regAddrObject['mobile'],
                    //     }
                    // } : null,
                    certificate: certificateDetails.length
                        ? certificateDetails
                        : null,
                    channelBrand: row.channelBrand ? row.channelBrand : [],
                    channelProduct: row.channelProduct ? row.channelProduct : [],
                    // channelSpeciality: row.channelSpeciality ? specialityMaster.filter(x => row.channelSpeciality.includes(x.id)) : [],
                    channelSpeciality: row.channelSpeciality
                        ? row.channelSpeciality
                        : [],
                    revenue: row.revenue ? row.revenue : [],
                    channelProfile:channelProfileData?channelProfileData:[],
                    sellingLocation:
                        row.channelLocation["sellingLocation"]
                        ? sellingList.filter((selling) =>
                            row.channelLocation["sellingLocation"].includes(
                                selling.id
                            )
                            )
                        : [],
                    targetLocation:
                        row.channelLocation["targetLocation"] 
                        ? targetList.filter((target) =>
                            row.channelLocation["targetLocation"].includes(target.id)
                            )
                        : [],
                    });

                }
            })

            if(companyExistFlag)
            {
                ToastsStore.warning('Company Name Duplicated!')
                setLoading(false);
            } else if (companyExistFlag == false && err == 0){
                await axios.post('/channelAdmin/ChannelEdit', data).then(res => {
                    let newChannel = data.filter(x => x.channelId === null);
                    if (newChannel.length) {
                        setChannel((channel) => {
                            return channel.map(x => {
                                if (x.id === newChannel[0].id) {
                                    x.channelId = +res.data['newChannelResult'].channelId;
                                }
                                return x;
                            })
                        })
                    }
                    ToastsStore.success('Saved Successfully!')
                    setLoading(false);
                }).catch(err => {
                    ToastsStore.error('Something Went Wrong!');
                    setLoading(false);
                });        
            }
            setChannel((channel) => {
                return channel.filter(x => x.deletedAt!=true)
            })
            console.log("savechannel:", data)
            

        } else {
            setLoading(false);
            ToastsStore.warning('No changes found')
            return channel.filter(x => x.deletedAt!=true)
        }
    }

    const handleSave = async () => {       
        setTimeout(function() {
           document.getElementById("loginbutton1").click();
        }, 2000);  
    }

    const handleClose = () => {
        setAddress(false);
        setBrand(false);
        setProduct(false);
        setDesc(false);
        setGrouping(false);
        setSpeciality(false);
        setAnnualTurn(false);
        setLocation(false);
        setOpenLogo(false);
        setBulkEdit(false);
        setSelectGrouping(false);
        setYourChannel(false);
        setSocial(false);
    };

    const handleSaveLogo=async ()=>{
        var data = selectedRows.map((ch)=> {
            return {
                key:imagePath,
                channelId: +ch.channelId
            }
        })

        if(imagePath){
            const uploadMediaLibrary = await commonService.postService({
                data
              }, '/setting/createBulkCompanyLogo');
            //   console.log("upload",uploadMediaLibrary);
            //  await commonService.postService({
            //     url:uploadMediaLibrary.data.doc_id.map((x)=>x.document.documentUrl),
            //     channel:selectedRows.map((ch)=>+ch.channelId)
            //  },'channelAdmin/bulkLogoUpdate');
            //  setOpenLogo(false);
            //  setTimeout(updateChannelList,2000)

             await axios.post('channelAdmin/bulkLogoUpdate', { 
                url:uploadMediaLibrary.data.doc_id.map((x)=>x.document.documentUrl),
                channel:selectedRows.map((ch)=>+ch.channelId)
            }).then(res => {
                setLoading(true);
                setBulkEdit(false);
                setOpenLogo(false);
                setTimeout(updateChannelList,2000)
            }).catch(err => {
                setLoading(false);
            });
        }
        setBulkEdit(false);
    }

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });

        setChannelId(id);
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });

    };

    const handleDeleteClick = (id) => () => {
        setButtons("channels")
        setIsOpen(true)
        setDeletedId(id)
    };
    const toggleModal = () => {
      setIsOpen(!isOpen);
    };

    const toggleModalIsCompanyRelate = () => {
        setIsOpenDeleteCompanyModal(false);
    };

    const updateButton = () => {
        const info = {
            prevGroupId: deleteGroupId,
            type: selectedGroupId == null ? "ALLCLEAR":"SELECTEDGROUP",
            currentGroupId: selectedGroupId,
        }
        setDeleteGruopInfo([...deleteGruopInfo, info])
        ToastsStore.success('Saved Successfully!')
        setIsOpenDeleteCompanyModal(false);

        if (selectedGroupId == null) {
            setGroupMaster(groupMaster.map((row)=> {
                if ( row.id == deleteGroupId) {
                    return {  ...row, count: 0 };
                }
                return row;
            }))
        }

        if (selectedGroupId !== null) {
            const prevGroup = groupMaster.find((row) => row.id == deleteGroupId);
            console.log(typeof prevGroup.count)
            setGroupMaster(groupMaster.map((row)=> {
                if ( row.id == deleteGroupId) {
                    return {  ...row, count: 0 };
                }
                if ( row.id == selectedGroupId) {
                    return {  ...row, count: parseInt(prevGroup.count) + parseInt(row.count) };
                }
                return row;
            }))
        }

    }

    const allCompanyDelete = (e) => {
        deleteCompany.clearAllCompoanies = e.target.checked;
        setDeleteCompany({ ...deleteCompany, clearAllCompoanies: deleteCompany.clearAllCompoanies, clearSelectedCompanies:false })
        setSelectedGroupId(null);
    }

    const selectedCompanyDelete = (e) => {
        setOpenDropDown(true)
        deleteCompany.clearSelectedCompanies = e.target.checked;
        setDeleteCompany({ ...deleteCompany, clearSelectedCompanies: deleteCompany.clearSelectedCompanies, clearAllCompoanies:false })
        if (e.target.checked === false) {
            setOpenDropDown(false);
            setSelectedValue('');
        }
        
    }

    const handleDropdownChange = (e) => {
        setSelectedValue(e.target.value)
        const selectedGroupid = groupMaster.find((item) => item.name === e.target.value);
        setSelectedGroupId(selectedGroupid.id);
    }

    const handleDelete = () => {
        if (buttons === "channels") {
            if(channelSelectionModel.length!==0){
                setChannel((channel) => {
                    return channel.map(x => {
                        if (channelSelectionModel.includes(x.id)) {
                            x["deletedAt"] = true;
                        }
                        return x;
                    })})
            //     setChannel(channel.filter((row) =>{
            //         if(channelSelectionModel.includes(row.id))
            //     return false;
            // else{
            //     return true;
            // }
            
            // }));
            }
            else{
            setChannel((channel) => {
                    return channel.map(x => {
                        if (x.id === channelId) {
                            x["deletedAt"] = true;
                        }
                        return x;
                    })})
            // setChannel(channel.filter((row) => row.id !== deletedId));
          console.log("Delete action executed");
        }
    };
    if (buttons === "channelGroup") {
        const deleteItems = [...deletedGroupIds, deleteGroupId];
        setDeletedGroupIds(deleteItems);
        setGroupMaster(groupMaster.filter((row) => row.id !== deleteGroupId));
    }
    handleSave()
    toggleModal();
}
    const handleGroupDelete = (id, count, name) => () => {
        setButtons("channelGroup");
        console.log(count)
        if (count == 0) {
            setIsOpen(true)
            setDeleteGroupId(id)
        }
        if (count > 0) {
            setDeleteCompany({ clearSelectedCompanies:false, clearAllCompoanies:false })
            setIsOpenDeleteCompanyModal(true)

            setDeleteGroupId(id)
            setSelectedCompanyName(name);
        }
    };


    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = channel.find((row) => row.id === id);
        if (editedRow.isNew) {
            setChannel(channel.filter((row) => row.id !== id));
        }

    };

    const processRowUpdate = (newRow) => {

        const updatedRow = { ...newRow, isNew: false };
        setChannel(channel.map((row) => (row.id === newRow.id ? updatedRow : row)));
        setFilterChannel(filterChannel.map((row) => (row.id === newRow.id ? updatedRow : row)))
        return updatedRow;

    };

    const processGroupUpdate = (newRow) => {

        const updatedRow = { ...newRow, isNew: newRow.isNew ? true : false };
        setGroupMaster(groupMaster.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;

    };

    const onCountryChange=(e,param)=>{
        param.row.country = e.target.value 
        const stdCode = countryIsoCodeList.find(({country})=>country === e.target.value)
        param.row.phone = "+"+stdCode?.code+"-"
        if(param.row.phone == '+undefined-') param.row.phone = null
        handleEdit(param)
        setCountry(param.row.country)
        setCountryNumber(param.row.phone)
    }

    const customComparator = (v1, v2) => {
      
      const numericValue1 = Number(v1-v2);
      const numericValue2 = Number(v2-v1);

      if (numericValue1 < numericValue2) {
        return -1;
      } else if (numericValue1 > numericValue2) {
        return 1;
      }
      return 0;
    };

   
    let companyCol = [
        // { field: 'id', headerName: 'S.No', width: 40 },
        { field: 'channelId', headerName: 'chId', width: 60, editable: false, sortComparator: customComparator },
        { field: 'companyName', headerName: 'Company Name', width: 150, editable: true, sortable: true },
        // { field: 'signUpStatusId', headerName: 'SignupStatus', width: 100, editable: false, sortable: true },
        {
            field: 'channelGroup', getApplyQuickFilterFn: undefined,
            headerName: 'Group',
            type: 'singleSelect', headerAlign: 'center',
            valueOptions: groupMaster ? groupMaster.map(x => x.name) : [],
            width: 100,
            editable: true,
            sortable: false
        },
        {
            field: "channelLogo",
            headerName: "Logo",
            width: 120,
            editable: true,
            headerAlign: 'center',
            sortable: false,
            renderCell: (params) => {
                return (
                    <>

                        <ImageLibrary getDocumentId={getCompanyLogo} aws_folderName='CompanyLogo' id={1} size={1}
                            img={params.row.channelLogo ? params.row.channelLogo : process.env.REACT_APP_CDN_URL +
                                "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}
                            channelData={{ channelId: params.row.channelId }} url={params.row.channelLogo} saveLogoUrl={saveLogoUrl} 
                        />

                        {/* <Avatar src={params.row.channelLogo ? params.row.channelLogo : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} /> */}
                    </>
                );
            }
        },
        {
            field: 'webSiteUrl', headerName: 'Website', width: 100, editable: true, getApplyQuickFilterFn: undefined,
            //  sortable: true          
        },
        {
            field: 'regAddress', headerName: 'Address', width: 80,
            renderCell: (params) => {

                return (
                    <>
                        <Button onClick={() => setAddress(true)}><span className="btn-inner--icon"><i class="fas fa-address-card"></i></span></Button>
                    </>
                );
            }
        },
         {
            field: 'country', getApplyQuickFilterFn: undefined,filterable: false,
            headerName: 'Country',
            headerAlign: 'center',
            width: 220,
            sortable: true,
            renderCell: (params) => {
              return (
                <div className="form-group">
                  <Autocomplete
                    sx={{ width: 220}}
                    size="medium"
                    id="country"
                    input='country' 
                    options={countryList || []}
                    getOptionLabel={(option) => option.name}
                    value={countryList.find(option => option.name === params?.row?.country) || null}
                    onSelect={(e) => onCountryChange(e, params)}
                    renderInput={(params) => (
                      <TextField
                        sx={{ border: "none", outline: "none", width: "100%", marginTop: "1.5rem", focus: "none" }} {...params} />
                    )}
                  />
                </div>
              );
            }
          },
        { field: 'phone', headerName: 'Phone', width: 100, editable: true, sortable: true },

        {
            field: 'channelType', getApplyQuickFilterFn: undefined,filterable: false,
            headerName: 'Type',
            type: 'singleSelect', headerAlign: 'center',
            valueOptions: channelTypes.map(x => x.name),
            width: 100,
            editable: true,
            sortable: false
        },
        {
            field: 'profileType', getApplyQuickFilterFn: undefined,filterable: false,
            headerName: 'Profile',
            type: 'singleSelect', headerAlign: 'center',
            valueOptions: ({ row }) => {
                let profile;
                if (profileMaster && row) {
                    if (row.channelType == 'Distributor')
                        profile = profileMaster.filter(x => x.channelTypeId == 3)
                    else if (row.channelType == 'Vendor')
                        profile = profileMaster.filter(x => x.channelTypeId == 2)
                    else if (row.channelType == 'Retailer')
                        profile = profileMaster.filter(x => x.channelTypeId == 4)
                    else if (row.channelType == 'Reseller')
                        profile = profileMaster.filter(x => x.channelTypeId == 5)
                    return profile.map(x => x.name);
                }
                else
                    return [];
            },
            width: 150,
            editable: true,
            sortable: false
        },
        {
            field: 'channelSpeciality', headerName: 'Specialist', width: 80, getApplyQuickFilterFn: undefined,
            renderCell: (params) => {
                return (
                    <>
                        {params.row.channelType == 'Distributor' ?
                            <Button onClick={() => setSpeciality(true)}><span className="btn-inner--icon"><i class="fas fa-cart-arrow-down"></i></span></Button> :
                            <Button><span className="btn-inner--icon">--</span></Button>}

                    </>
                );
            }
        },
        {
            field: 'isOEM',
            headerName: 'B2C/B2B',
            type: 'singleSelect', headerAlign: 'center',
            valueOptions: businessTypes.map(x => x.name),
            width: 110,
            editable: true,
            sortable: false
        },
        { field: 'estYear', headerName: 'Established', width: 100, editable: true, sortable: true },
        { field: 'empCnt', headerName: 'Emp Count', width: 100, type: 'number', editable: true, sortable: true },
        

        {
            field: 'revenue', headerName: 'Revenue', width: 80,
            renderCell: (params) => {
                return (
                    <>
                        <Button><span className="btn-inner--icon"><i class="fas fa-euro-sign"></i></span></Button>
                    </>
                );
            }
        },
        { field: 'channelRevenue', headerName: 'Last Revenue', type: 'number', width: 100, sortable: true,
         renederCell:(params) => {
            return(
                <>
                
                </>
            )
         }

             
        },
        {
            field: 'channelBrand', headerName: 'Brand', width: 80, sortable: false, filterable: false,
            renderCell: (params) => {

                return (
                    <>
                        <Button onClick={() => setBrand(true)}><span className="btn-inner--icon"><i class="fas fa-certificate"></i></span></Button>
                    </>
                );
            }
        },      
        
        
        // { field: 'storeCount', headerName: 'Stores', width: 100, editable: true, sortable: true },        
       
        {
            field: 'channelProduct', headerName: 'Product', width: 80, sortable: false, filterable: false,
            renderCell: (params) => {

                return (
                    <>
                        <Button onClick={() => setProduct(true)}><span className="btn-inner--icon"><i class="fas fa-cart-arrow-down"></i></span></Button>
                    </>
                );
            }
        },
        
        //location
        {
            field: 'channelLocation', headerName: 'Location', width: 80, sortable: false, filterable: false,
            renderCell: (params) => {

                return (
                    <>
                        {
                            <Button onClick={() => setLocation(true)}><span className="btn-inner--icon"><i class="fas fa-clipboard"></i></span></Button>
                            }
                    </>
                );
            }
        },
        // {
        //     field: 'channelDesc', headerName: 'Description', width: 80,
        //     renderCell: (params) => {

        //         return (
        //             <>
        //                 <Button onClick={() => setDesc(true)}><span className="btn-inner--icon"><i class="fas fa-clipboard"></i></span></Button>
        //             </>
        //         );
        //     }
        // },

       
        // {
        //     field: 'isPublic',
        //     headerName: 'Public',
        //     type: 'boolean', headerAlign: 'center',
        //     width: 100,
        //     editable: true,
        //     sortable: false
        // },
        // {
        //     field: 'startup',
        //     headerName: 'Startup',
        //     type: 'boolean', headerAlign: 'center',
        //     width: 100,
        //     editable: true,
        //     sortable: false
        // },
        // {
        //     field: 'isTestAccount',
        //     headerName: 'Test Acc',
        //     type: 'boolean', headerAlign: 'center',
        //     width: 100,
        //     editable: true,
        //     sortable: false
        // },
        {
            field: 'contact', headerName: 'Contact List', width: 80,
            renderCell: (params) => {

                return (
                    <>
                        <Button onClick={()=>setOpenContactModal(true)}><span className="btn-inner--icon"><i class="fas fa-id-badge"></i></span></Button>
                    </>
                );
            }
        },
        // {
        //     field: 'keypartners', headerName: 'Key Partners', width: 80,
        //     renderCell: (params) => {

        //         return (
        //             <>
        //                 <Button><span className="btn-inner--icon"><i class="fas fa-people-carry"></i></span></Button>
        //             </>
        //         );
        //     }
        // },
        {
            field: 'channelProfile', headerName: 'your channels', width: 80, sortable: false, filterable: false,
            renderCell: (params) => {

                return (
                    <>
                        <Button onClick={() => setYourChannel(true)}><span className="btn-inner--icon"><i class="fas fa-certificate"></i></span></Button>
                    </>
                );
            }
        },
        {
            field: 'no_of_retailers', headerName: 'no. of retailers', width: 100, editable: true, sortable: false,          
        },
        {
            field: 'no_of_resellers', headerName: 'no. of resellers', width: 100, editable: true, sortable: false,          
        },
        {
            field: 'no_of_distributors', headerName: 'no. of distributors', width: 100, editable: true, sortable: false, 
            renderCell: (params) => {

                return (
                    <>
                        {params.row.channelType != 'Vendor' ?
                        <span className="btn-inner--icon">--</span>:<span className="btn-inner--icon">{params.row.no_of_distributors}</span>}
                    </>
                );
            }         
        },

       

        // { field: 'signUpStatusId', headerName: 'SignupStatus', width: 100, editable: false, sortable: true },
       
         {
            field: 'channelSocial', headerName: 'social profiles', width: 80,
            renderCell: (params) => {
               
                return (
                    <>
                        <Button onClick={() => setSocial(true)}><span className="btn-inner--icon"><i class="fas fa-address-card"></i></span></Button>
                    </>
                );
            }
        },
        {
            field: 'deletedAt',
            headerName: 'Deleted',
            type: 'boolean', headerAlign: 'center',
            width: 100,
            editable: true,
            sortable: false
        },

        {
            field: 'isActive',
            headerName: 'Active',
            type: 'boolean', headerAlign: 'center',
            width: 100,
            editable: true,
            sortable: false
        },

        // { field: 'id', headerName: 'S.No', width: 40 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                return [<GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={handleDeleteClick(id)}
                    color="inherit"
                />]
                // if (isInEditMode) {
                //     return [
                //         <GridActionsCellItem
                //             icon={<SaveIcon />}
                //             label="Save"
                //             onClick={handleSaveClick(id)}
                //         />,
                //         <GridActionsCellItem
                //             icon={<CancelIcon />}
                //             label="Cancel"
                //             className="textPrimary"
                //             onClick={handleCancelClick(id)}
                //             color="inherit"
                //         />,
                //     ];
                // }

                // return [
                //     <GridActionsCellItem
                //         icon={<EditIcon />}
                //         label="Edit"
                //         className="textPrimary"
                //         onClick={handleEditClick(id)}
                //         color="inherit"
                //     />,
                //     <GridActionsCellItem
                //         icon={<DeleteIcon />}
                //         label="Delete"
                //         onClick={handleDeleteClick(id)}
                //         color="inherit"
                //     />,
                // ];
            },
        },
    ];

    const categoryCol = React.useMemo(
        () => [

            {
                field: 'id',
                headerName: 'Id',
                headerAlign: 'center',
                width: 50,
                editable: false,
                sortable: false
            },
            {
                field: 'group',
                headerName: 'Family',
                headerAlign: 'center',
                width: 150,
                editable: false,
                sortable: true
            },
            {
                field: 'category',
                headerName: 'Product Category',
                headerAlign: 'center',
                width: 300,
                editable: false,
                sortable: true
            }
        ]
    );

    

    const ChannelLogoCol = React.useMemo(
        () => [

            { field: 'channelId', headerName: 'ChannelId', width: 100, editable: false, sortComparator: customComparator },
            { field: 'companyName', headerName: 'Company Name', width: 150, editable: false, sortable: true },
            {
                field: "channelLogo",
                headerName: "Logo",
                width: 120,
                editable: false,
                headerAlign: 'center',
                sortable: false,
                renderCell: (params) => {
                    return (
                        <>
                            <img 
                            height={100}
                            width={100}
                            src={params.row.channelLogo ? params.row.channelLogo : process.env.REACT_APP_CDN_URL +
                                "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} 
                            />
    
                            {/* <Avatar 
                            src={params.row.channelLogo ? params.row.channelLogo : process.env.REACT_APP_CDN_URL +
                                "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}
                                alt='Company Logo'
                                /> */}
                        </>
                    );
                }
            }
        ]
    );

    const locationCol = React.useMemo(
        () => [

            {
                field: 'id',    
                headerName: 'Id',
                headerAlign: 'center',
                width: 50,
                editable: false,
                sortable: false
            },
            {
                field: 'name',
                headerName: 'Location',
                headerAlign: 'center',
                width: 300,
                editable: false,
                sortable: true
            }
        ]
    );

    const retailerCol = React.useMemo(
        () => [

            {
                field: 'childid',
                headerName: 'Id',
                headerAlign: 'center',
                width: 60,
                editable: false,
                sortable: false
            },
            {
                field: 'childname',
                headerName: 'Retailer',
                headerAlign: 'center',
                width: 300,
                editable: false,
                sortable: true
            }
        ]
    );

    const resellerCol = React.useMemo(
        () => [

            {
                field: 'childid',
                headerName: 'Id',
                headerAlign: 'center',
                width: 60,
                editable: false,
                sortable: false
            },
            {
                field: 'childname',
                headerName: 'Reseller',
                headerAlign: 'center',
                width: 300,
                editable: false,
                sortable: true
            }
        ]
    );

    const specialityCol = React.useMemo(
        () => [

            {
                field: 'id',
                headerName: 'Id',
                headerAlign: 'center',
                width: 50,
                editable: false,
                sortable: false
            },
            {
                field: 'name',
                headerName: 'Product Speciality',
                headerAlign: 'center',
                width: 300,
                editable: false,
                sortable: true
            }
        ]
    );



    const brandCol = React.useMemo(
        () => [

            {
                field: 'id',
                headerName: 'id',
                width: 80,
                headerAlign: 'center',
                editable: false,
                sortable: false,
                getApplyQuickFilterFn: undefined
            },
            {
                field: "document",
                headerName: " Brand Image",
                width: 120,
                editable: true,
                headerAlign: 'center',
                sortable: false,
                getApplyQuickFilterFn: undefined,
                renderCell: (params) => {

                    return (
                        <>
                            <Avatar src={params.value?.documentUrl ? params.value.documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
                        </>
                    );
                }
            },
            {
                field: 'brandName',
                headerName: 'Brand Name',
                headerAlign: 'center',
                width: 150,
                editable: true,
                sortable: true,
                // getApplyQuickFilterFn: undefined
            }
        ]
    );

    const groupCol = React.useMemo(
        () => [

            {
                field: 'id',
                headerName: 'id',
                width: 80,
                headerAlign: 'center',
                editable: false,
                sortable: false,
                getApplyQuickFilterFn: undefined
            },
            {
                field: 'name',
                headerName: 'Group Name',
                headerAlign: 'center',
                width: 450,
                editable: true,
                sortable: true,
                // getApplyQuickFilterFn: undefined
            },
            {
                field: 'count',
                headerName: 'Channels Added',
                headerAlign: 'center',
                width: 150,
                editable: false,
                sortable: true,
                // getApplyQuickFilterFn: undefined
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 100,
                cellClassName: 'actions',
                getActions: (id) => {
                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                    return [<GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleGroupDelete(id.row.id, id.row.count, id.row.name)}
                        color="inherit"
                    />]
                },
            },
        ]
    );

    const Address = () => {

        // const { register, handleSubmit, control, watch, errors } = useForm({      
        //     mode:'onBlur',  
        //     resolver: yupResolver()
        // });

        return (
            <div>
                <Modal className="modal-dialog-centered" fade={false} isOpen={openAddress} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        <strong>{'Registered Address'}</strong>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <Box
                            sx={{
                                height: 600,
                                width: '100%',
                                '& .actions': {
                                    color: 'text.secondary',
                                },
                                '& .textPrimary': {
                                    color: 'text.primary',
                                },
                            }}
                        >

                            <div className="container">
                                <form>
                                    <div className="row">

                                        {/* <div className="col-lg-6">
                                            <div className="form-group">
                                                <FormInput
                                                    id="fname"
                                                    type="text"
                                                    name="fname"
                                                    label="First Name"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={regAddress.firstName}
                                                    defaultValue={regAddress.firstName}
                                                    onChange={(e) => setRegAddress({ ...regAddress, ['firstName']: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <FormInput
                                                    id="lname"
                                                    type="text"
                                                    name="lname"
                                                    label="Last Name"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={regAddress.lastName}
                                                    defaultValue={regAddress.lastName}
                                                    onChange={(e) => setRegAddress({ ...regAddress, ['lastName']: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <FormInput
                                                    id="email"
                                                    type="text"
                                                    name="email"
                                                    label="Email"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={regAddress.email}
                                                    defaultValue={regAddress.email}
                                                    onChange={(e) => setRegAddress({ ...regAddress, ['email']: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <FormInput
                                                    id="password"
                                                    type="text"
                                                    name="password"
                                                    label="Password"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={regAddress.password}
                                                    defaultValue={regAddress.password}
                                                    onChange={(e) => setRegAddress({ ...regAddress, ['password']: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <FormInput
                                                    id="jobTitle"
                                                    type="text"
                                                    name="jobTitle"
                                                    label="jobTitle"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={regAddress.jobTitle}
                                                    defaultValue={regAddress.jobTitle}
                                                    onChange={(e) => setRegAddress({ ...regAddress, ['jobTitle']: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <FormInput
                                                    id="mobile"
                                                    type="text"
                                                    name="mobile"
                                                    label="mobile"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={regAddress.mobile}
                                                    defaultValue={regAddress.mobile}
                                                    onChange={(e) => setRegAddress({ ...regAddress, ['mobile']: e.target.value })}
                                                />
                                            </div>
                                        </div> */}

                                        {/* <div className="col-lg-12">
                                            <div className="form-group">
                                                <GoogleAddress id="address" defAddress={regAddress.address} handleChangeAddress={handleChangeAddress} handleChangeSelect={handleChangeSelect} />

                                            </div>
                                        </div> */}

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <TextareaAutosize
                                                    aria-label="empty textarea"
                                                    placeholder="Address"
                                                    defaultValue={regAddress.address}
                                                    style={{ width: 780, height:100 }}
                                                    onChange={(e) => setRegAddress({ ...regAddress, ['address']: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <FormInput
                                                    id="city"
                                                    type="text"
                                                    name="city"
                                                    label="City"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={regAddress.city}
                                                    defaultValue={regAddress.city}
                                                    onChange={(e) => setRegAddress({ ...regAddress, ['city']: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <FormInput
                                                    id="state"
                                                    type="text"
                                                    name="state"
                                                    label="State"
                                                    InputLabelProps={{ shrink: true }}
                                                    //placeholder="Password"
                                                    // register={register}
                                                    // error={errors.state}
                                                    value={regAddress.state}
                                                    defaultValue={regAddress.state}
                                                    onChange={(e) => setRegAddress({ ...regAddress, ['state']: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 thm">
                                            <div className="form-group">

                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    input='country'
                                                    options={countryList || []}
                                                    getOptionLabel={(option) => option.name}
                                                    value={regAddress.country ? regAddress.country[0] : null}
                                                    onChange={handleChangeCountry}

                                                    renderInput={(params) => <TextField {...params} label="Country" InputLabelProps={{ shrink: true }} />}
                                                />
                                            </div>
                                        </div>



                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <FormInput
                                                    id="postalCode"
                                                    type="text"
                                                    name="postalCode"
                                                    label="Postal Code"
                                                    InputLabelProps={{ shrink: true }}
                                                    //placeholder="Password"
                                                    // register={register}
                                                    // error={errors.postalCode}
                                                    value={regAddress.postalCode}
                                                    defaultValue={regAddress.postalCode}
                                                    onChange={(e) => setRegAddress({ ...regAddress, ['postalCode']: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        {/* Certificate */}


                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <FormInput
                                                    id="regno"
                                                    type="text"
                                                    name="regno"
                                                    label="Reg No"
                                                    //placeholder="Password"
                                                    // register={register}
                                                    // error={errors.city}
                                                    defaultValue={regAddress.regno}
                                                    value={regAddress.regno}
                                                    onChange={(e) => setRegAddress({ ...regAddress, ['regno']: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <FormInput
                                                    id="regname"
                                                    type="text"
                                                    name="regname"
                                                    label="Reg Name"
                                                    disabled="true"
                                                    //placeholder="Password"
                                                    // register={register}
                                                    // error={errors.state}
                                                    value={regAddress.regname}
                                                    defaultValue={regAddress.regname}
                                                    onChange={(e) => setRegAddress({ ...regAddress, ['regname']: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        {regAddress.isVat == '1' && <>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <FormInput
                                                        id="vatno"
                                                        type="text"
                                                        name="vatno"
                                                        label="Vat No"
                                                        //placeholder="Password"
                                                        // register={register}
                                                        // error={errors.city}
                                                        defaultValue={regAddress.vatno}
                                                        value={regAddress.vatno}
                                                        onChange={(e) => setRegAddress({ ...regAddress, ['vatno']: e.target.value })}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <FormInput
                                                        id="vatname"
                                                        type="text"
                                                        name="vatname"
                                                        label="Vat Name"
                                                        disabled="true"
                                                        //placeholder="Password"
                                                        // register={register}
                                                        // error={errors.state}
                                                        value={regAddress.vatname}
                                                        defaultValue={regAddress.vatname}
                                                        onChange={(e) => setRegAddress({ ...regAddress, ['vatname']: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                        }



                                    </div>

                                </form>
                            </div>


                        </Box>

                    </div>
                    <div className="modal-footer">
                        <Button color="primary" type="button" onClick={() => saveAddress(regAddress)}>Ok</Button>
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal>
            </div>
        )
    }

    const SocialProfile = () => {
        console.log('firstrrr',socialProfiles)
        // let socialprof;
        let linkedInUrl='https://www.linkedin.com/company/';
        let twitterUrl='https://twitter.com/';
        let instaUrl='https://www.instagram.com/';
        let fbUrl='https://www.facebook.com/';

        let linkked=socialProfiles.find(x=>x.socialSiteId==='1')?.channelSocialLink
        if(linkked && !linkked.startsWith(linkedInUrl)){
            linkked=linkedInUrl+linkked;
        }
        let tweet=socialProfiles.find(x=>x.socialSiteId==='4')?.channelSocialLink
        if(tweet && !tweet.startsWith(twitterUrl)){
            tweet=twitterUrl+tweet;
        }
        let insta= socialProfiles.find(x=>x.socialSiteId==='3')?.channelSocialLink
        if(insta && !insta.startsWith(instaUrl)){
            insta=instaUrl+insta;
        }
        let fb= socialProfiles.find(x=>x.socialSiteId==='5')?.channelSocialLink
        if(fb && !fb.startsWith(fbUrl)){
            fb=fbUrl+fb;
        }
        const handleLinkindChange=(e)=>{
          let  socialprof=socialProfiles.map(x=>{
                if(x.socialSiteId==='1'){
                    return {...x,channelSocialLink:e.target.value}
                }
                else{
                    return x;
                }
            });
            setSocialProfiles(socialprof);
            if(e.target.value!==linkedInUrl && linkked===undefined){

                setNewLinkedUrl({...newLinkedUrl,socialSiteId:'1',channelSocialLink:e.target.value})
            }
        }
       const handleTwitterChange=(e)=>{
       let socialprof=socialProfiles.map(x=>{
            if(x.socialSiteId==='4'){
                return {...x,channelSocialLink:e.target.value}
            }
            else{
                return x;
            }
        });
        setSocialProfiles(socialprof);
        if(e.target.value!==twitterUrl && tweet===undefined){
            setNewTwitterUrl({...newTwitterUrl,socialSiteId:'4',channelSocialLink:e.target.value})
        }
       }

       const handleInstaChange=(e)=>{
       let socialprof=socialProfiles.map(x=>{
            if(x.socialSiteId==='3'){
                return {...x,channelSocialLink:e.target.value}
            }
            else{
                return x;
            }
        });
        setSocialProfiles(socialprof);
        if(e.target.value!==instaUrl && insta===undefined){
            setNewInstaUrl({...newInstaUrl,socialSiteId:'3',channelSocialLink:e.target.value})
        }
       }
     
       const handleFbChange=(e)=>{
        let socialprof=socialProfiles.map(x=>{
            if(x.socialSiteId==='5'){
                return {...x,channelSocialLink:e.target.value}
            }
            else{
                return x;
            }
        });
        setSocialProfiles(socialprof);

        if(e.target.value!==fbUrl && fb===undefined){
                setNewFbUrl({...newFbUrl,socialSiteId:'5',channelSocialLink:e.target.value})
            }
            
       }
       
        return (
            <div>
                <Modal className="modal-dialog-centered" fade={false} isOpen={openSocial} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        <strong>{'Social Profiles'}</strong>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <Box
                            sx={{
                                height: 600,
                                width: '100%',
                                '& .actions': {
                                    color: 'text.secondary',
                                },
                                '& .textPrimary': {
                                    color: 'text.primary',
                                },
                            }}
                        >

                            <div className="container">
                                <form>
                                    <div className="row">

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <FormInput
                                                    id="linkedIn"
                                                    type="text"
                                                    name="linkedIn"
                                                    label="LinkedIn Handle"                                              
                                                    defaultValue={linkked||linkedInUrl}
                                                    onChange={handleLinkindChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <FormInput
                                                    id="twitter"
                                                    type="text"
                                                    name="twitter"
                                                    label="Twitter Handle"                                              
                                                    defaultValue={tweet||twitterUrl}
                                                    onChange={handleTwitterChange}
                                                />
                                            </div>
                                        </div>


                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <FormInput
                                                    id="instagram"
                                                    type="text"
                                                    name="instagram"
                                                    label="Instagram Handle"
                                                    defaultValue={insta||instaUrl}
                                                    onChange={handleInstaChange}
                                                />
                                            </div>
                                        </div>

                
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <FormInput
                                                    id="facebook"
                                                    type="text"
                                                    name="facebook"
                                                    label="Facebook Handle"
                                                    defaultValue={fb||fbUrl}
                                                    onChange={handleFbChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>


                        </Box>

                    </div>
                    <div className="modal-footer">
                        <Button color="primary" type="button" onClick={() => saveSocialProfiles(socialProfiles)}>Ok</Button>
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal>
            </div>
        )
    }

    const contactDetail = () => {
        return (
          <div>
            <Modal
              className="modal-dialog-centered"
              fade={false}
              isOpen={openContactModal}
              size="sm"
              style={{ minWidth: 900, width: "30%" }}
            >
              <div className="modal-header">
                <strong>{"Contact Details"}</strong>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => setOpenContactModal(false)}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <Box
                  sx={{
                    height: 300,
                    width: "100%",
                    "& .actions": {
                      color: "text.secondary",
                    },
                    "& .textPrimary": {
                      color: "text.primary",
                    },
                  }}
                >
                  <div className="container">
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        textAlign: "left",
                      }}
                    >
                      <tr style={{ backgroundColor: "#f2f2f2" }}>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                          Contact Name
                        </th>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                          Email
                        </th>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                          Is Deleted
                        </th>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                          Is Active
                        </th>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                          Action
                        </th>
                      </tr>
                      {contactDetails.length > 0 ? (
                        contactDetails.map((res, index) => (
                          <tr key={index} style={{ backgroundColor: "#f2f2f2" }}>
                            <td
                              style={{ border: "1px solid #ddd", padding: "8px" }}
                            >
                              {res.firstName} {res.lastName}
                            </td>
                            <td
                              style={{ border: "1px solid #ddd", padding: "8px" }}
                            >
                              {res.email}
                            </td>
                            <td
                              style={{ border: "1px solid #ddd", padding: "8px" }}
                            >
                              {res.deletedAt ? "true" : "false"}
                            </td>
                            <td
                              style={{ border: "1px solid #ddd", padding: "8px" }}
                            >
                              {res.isActive == '1' ? "true" : "false"}
                            </td>
                            <td
                              style={{ border: "1px solid #ddd", padding: "8px" }}
                            >
                              <button onClick={()=>toggleUpdate(res)}>Update</button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="5"
                            style={{
                              padding: "8px",
                              backgroundColor: "#f2f2f2",
                              textAlign: "center",
                            }}
                          >
                            No Contact
                          </td>
                        </tr>
                      )}
                    </table>
                  </div>
                </Box>
              </div>
              <div className="modal-footer">
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => setOpenContactModal(false)}
                >
                  Close
                </Button>
              </div>
            </Modal>
          </div>
        );
      };

      const toggleUpdate = (data) =>{
        setContactData(data)
        setDuplicateEmail(false)
        setUpdateOpenContactModal(true)
      }

      const updateContactModal = (data) => {
        return (
          <div>
            <Modal
              className="modal-dialog-centered"
              fade={false}
              isOpen={openUpdateContactModal}
              size="sm"
              style={{ minWidth: 900, width: "30%" }}
            >
              <div className="modal-header">
                <strong>{"Update Contact Details"}</strong>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => setUpdateOpenContactModal(false)}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <Box
                  sx={{
                    height: 300,
                    width: "100%",
                    "& .actions": {
                      color: "text.secondary",
                    },
                    "& .textPrimary": {
                      color: "text.primary",
                    },
                  }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <FormInput
                            id="firstname"
                            type="text"
                            name="firstname"
                            label="First Name"
                            InputLabelProps={{ shrink: true }}
                            value={contactData.firstName}
                            defaultValue={contactData.firstName}
                            onChange={(e) =>
                              setContactData({
                                ...contactData,
                                ["firstName"]: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
      
                      <div className="col-lg-6">
                        <div className="form-group">
                          <FormInput
                            id="lastname"
                            type="text"
                            name="lastname"
                            label="Last Name"
                            InputLabelProps={{ shrink: true }}
                            value={contactData.lastName}
                            defaultValue={contactData.lastName}
                            onChange={(e) =>
                              setContactData({
                                ...contactData,
                                ["lastName"]: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <FormInput
                            id="email"
                            type="text"
                            name="email"
                            label="Contact Email"
                            InputLabelProps={{ shrink: true }}
                            value={contactData.email}
                            error={
                              duplicateEmail
                                ? {
                                    message: "Email Id already taken",
                                    required: true,
                                  }
                                : ""
                            }
                            defaultValue={contactData.email}
                            onChange={(e) =>
                              setContactData({
                                ...contactData,
                                ["email"]: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={contactData.isActive === "1"}
                        onChange={(e) =>
                          setContactData({
                            ...contactData,
                            isActive: e.target.checked ? "1" : "0",
                          })
                        }
                      />{" "}
                      Is Active
                    </div>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={!!contactData.deletedAt} // Use double negation to convert to boolean
                      onChange={(e) =>
                        setContactData({
                          ...contactData,
                          deletedAt: e.target.checked ? new Date() : null,
                        })
                      }
                    />
                    Is Deleted
                  </div>
                </Box>
              </div>
              <div className="modal-footer">
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => updateContactDetails(false)}
                >
                  Update
                </Button>
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => setUpdateOpenContactModal(false)}
                >
                  Close
                </Button>
              </div>
            </Modal>
          </div>
        );
      };

    const handleChannelGroup = (value) => {
        let gname = groupMaster.map(x => x.name);
        setGroupExist(gname.map(group => { return group.toLowerCase().replace(/\s+/g, "") }).includes(value.toLowerCase().replace(/\s+/g, "")));
        setChannelGroup(value)
    }

    const updateContactDetails = async () => {
        setLoading(true);
        let testMail = await axios.post('/channelAdmin/updateContact', contactData)
        if (testMail?.data && testMail?.data?.isavialble) {
            setDuplicateEmail(false)
            ToastsStore.success('Saved Successfully!')
            setLoading(false);
            setUpdateOpenContactModal(false)
            setOpenContactModal(false)
            setTimeout(updateChannelList,2000)
        }else{
            setLoading(false);
            setDuplicateEmail(true)
        }       
    }

    const ChannelGroup = () => {


        return (
            <div>
                <Modal className="modal-dialog-centered" fade={false} isOpen={grouping} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        <strong>Channel Group</strong>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <Box
                            sx={{
                                height: 600,
                                width: '100%',
                                '& .actions': {
                                    color: 'text.secondary',
                                },
                                '& .textPrimary': {
                                    color: 'text.primary',
                                },
                            }}
                        >

                            {/* <div className="container"> */}

                            <div className="row">
                                <Card style={{ height: 600, width: '100%' }}>
                                    <DataGrid className={classes.root}
                                        getRowHeight={() => 'auto'}

                                        // checkboxSelection
                                        // selectionModel={brandSelectionModel}
                                        // onSelectionModelChange={setBrandSelectionModel}
                                        disableSelectionOnClick

                                        disableColumnFilter
                                        disableColumnSelector
                                        disableDensitySelector

                                        components={{ Toolbar: groupToolbar }}

                                        componentsProps={{
                                            toolbar: { groupMaster, setGroupMaster },

                                        }}

                                        // editMode="cell"
                                        onCellClick={handleGroupEdit}

                                        rows={groupMaster||[]}
                                        columns={groupCol}
                                        // onCellEditCommit={handleGroupCommit}
                                        // onRowEditStart={handleRowEditStart}
                                        // onRowEditStop={handleRowEditStop}
                                        processRowUpdate={processGroupUpdate}

                                        experimentalFeatures={{ newEditingApi: true }}

                                  // pageSize={pageSize}
                                    />
                                </Card>

                                {/* <div className="col-lg-6">
                                        <div className="form-group">
                                            <FormInput
                                                id="channelgroup"
                                                type="text"
                                                name="channelgroup"
                                                label="Channel Group Name"
                                                InputLabelProps={{ shrink: true }}
                                                value={channelGroup}
                                                defaultValue={channelGroup}
                                                onChange={(e) => handleChannelGroup(e.target.value)}
                                            />
                                        </div>
                                    </div> */}
                                {groupExist &&
                                    <p>already exist</p>}


                            </div>


                            {/* </div> */}


                        </Box>

                    </div>
                    <div className="modal-footer">
                        {!groupExist &&
                            <Button color="primary" type="button" onClick={() => saveChannelGroup()}>Ok</Button>}
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal>
            </div>
        )
    }

    const SelectGroup = () => {


        return (
            <div>
                <Modal className="modal-dialog-centered" fade={false} isOpen={selectGroup} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        <strong>Channel Group</strong>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <Box
                            sx={{
                                height: 100,
                                width: '100%',
                                '& .actions': {
                                    color: 'text.secondary',
                                },
                                '& .textPrimary': {
                                    color: 'text.primary',
                                },
                            }}
                        >

                            <div className="container">

                                <div className="row">


                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <Autocomplete sx={{ width: 150 }}
                                                size="small"
                                                disableClearable={true}
                                                id="group"
                                                input='group'
                                                options={groupMaster}

                                                getOptionLabel={(option) => option.name}
                                                // value={filterValue.grouping}
                                                onChange={(name, value) => { console.log("group:", value); setBulkGroup(value.id) }}
                                                renderInput={(params) => <TextField {...params} label="Channel Group" />}
                                            />
                                        </div>
                                    </div>


                                </div>


                            </div>


                        </Box>

                    </div>
                    <div className="modal-footer">
                        {!groupExist &&
                            <Button color="primary" type="button" onClick={() => saveBulkGroup()}>Ok</Button>}
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal>
            </div>
        )
    }

    const BulkEdit = () => {


        return (
            <div>
                <Modal className="modal-dialog-centered" fade={false} isOpen={bulkEdit} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        <strong>Bulk Edit</strong>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <Box
                            sx={{
                                height: 100,
                                width: '100%',
                                '& .actions': {
                                    color: 'text.secondary',
                                },
                                '& .textPrimary': {
                                    color: 'text.primary',
                                },
                            }}
                        >

                            <div className="container">

                                <div className="row">


                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <Autocomplete //sx={{ width: 100 }}
                                                disableClearable={true}
                                                id="edit"
                                                input='edit'
                                                options={[{ id: '1', name: 'Product' }, { id: '2', name: 'Brand' }, { id: '3', name: 'Group' },{ id: '4', name: 'Logo' }]}
                                                getOptionLabel={(option) => option.name}
                                                // value={filterValue.channelType}
                                                onChange={handleBulkEditSelection}
                                                renderInput={(params) => <TextField {...params} label="Select ..." />}
                                            />
                                        </div>
                                    </div>



                                </div>


                            </div>


                        </Box>

                    </div>
                    <div className="modal-footer">
                        {!groupExist &&
                            <Button color="primary" type="button" onClick={() => saveChannelGroup()}>Ok</Button>}
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal>
            </div>
        )
    }


    const Brand = () => {

        let arr = brandMaster.filter(x => brandSelectionModel.includes(x.id));

        const deleteBrand = (id) => {
            arr = arr.filter(x => x.id != id)
            // setBrandMaster(brandMaster.filter(x => x.id != id));
            if (brandSelectionModel.length > 0)
                setBrandSelectionModel(brandSelectionModel.filter(x => x != id))
            // setTemp(brandMaster.filter(x => x.id != id))
            setChannelBrand(arr);
        }

        return (
            <div>

                <Modal className="modal-dialog-centered" fade={false} isOpen={openBrand} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        <strong>{'Channel Brand'}</strong>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>

                    </div>
                    <div className="modal-body">

                        <Box
                            sx={{
                                height: 600,
                                width: '100%',
                                '& .actions': {
                                    color: 'text.secondary',
                                },
                                '& .textPrimary': {
                                    color: 'text.primary',
                                },
                            }}
                        >

                            <Row>
                                <Card style={{ height: 600, width: '60%' }}>
                                    <DataGrid className={classes.root}
                                        // getRowHeight={() => 'auto'}

                                        checkboxSelection
                                        selectionModel={brandSelectionModel}
                                        onSelectionModelChange={setBrandSelectionModel}
                                        disableSelectionOnClick

                                        disableColumnFilter
                                        disableColumnSelector
                                        disableDensitySelector

                                        components={{ Toolbar: brandToolbar }}

                                        componentsProps={{
                                            toolbar: { setBrandMaster, brandMaster, setAddBrand,
            //                                
                                                },
                                            

                                        }}

                                        rows={brandMaster}
                                        columns={brandCol}
                                        onCellEditCommit={handleBrandCommit}
                                    />
                                </Card>
                                <Card style={{ height: 600, width: '40%' }}>
                                    <List sx={{
                                        width: '100%',
                                        maxWidth: 360,
                                        bgcolor: 'background.paper',
                                        position: 'relative',
                                        overflow: 'auto',
                                        maxHeight: 600,
                                        '& ul': { padding: 0 },
                                    }}>
                                        {channelBrand.length ? brandMaster.filter(x => brandSelectionModel.includes(x.id)).map((it, index) => {
                                            return (
                                                <ListItem
                                                    secondaryAction={
                                                        <IconButton edge="end" aria-label="delete" onClick={() => deleteBrand(it.id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={it.brandName}

                                                    />
                                                </ListItem>
                                            );
                                        }) :
                                            arr.map((it, index) => {
                                                return (
                                                    <ListItem
                                                        secondaryAction={
                                                            <IconButton edge="end" aria-label="delete" onClick={() => deleteBrand(it.id)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        }
                                                    >
                                                        <ListItemText
                                                            primary={it.brandName}

                                                        />
                                                    </ListItem>


                                                );
                                            })}
                                    </List>

                                </Card>
                            </Row>


                        </Box>

                    </div>
                    <div className="modal-footer">
                        <Button color="primary" type="button" onClick={() => saveBrand(brandSelectionModel)}>Ok</Button>
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal>
            </div>
        )
    }

    const YourChannels = () => {
        console.log('retaiselection',retailerSelectionModel,resellerSelectionModel)
        let retailers = retailerList.filter(x => retailerSelectionModel.includes(x.childid));
        let resellers = resellerList.filter(x => resellerSelectionModel.includes(x.childid));
      
        const deleteRetailers = (id) => {
            retailers = retailers.filter(x => x.childid != id)
            setRetailerSelectionModel(retailerSelectionModel.filter(x => x != id))
            setKeyRetailers(retailers);
        }

        const deleteResellers = (id) => {
            resellers = resellers.filter(x => x.childid != id);
            setRessellerSelectionModel(resellerSelectionModel.filter(x => x != id))
            setKeyResellers(resellers);
        }

        return (
            <div>

                <Modal className="modal-dialog-centered" fade={false} isOpen={openYourChannel} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        <strong>{'Your Channels'}</strong>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>

                    </div>
                    <div className="modal-body">

                        <Box
                            sx={{
                                height: 600,
                                width: '100%',
                                '& .actions': {
                                    color: 'text.secondary',
                                },
                                '& .textPrimary': {
                                    color: 'text.primary',
                                },
                            }}
                        >

                            Retailers
                            <Row>
                                <Card style={{ height: 300, width: '60%' }}>

                                    <DataGrid editMode="row" className={classes.root}
                                        // getRowHeight={() => 'auto'}
                                        getRowId={(row) => row.childid}
                                        checkboxSelection
                                        selectionModel={retailerSelectionModel}
                                        onSelectionModelChange={setRetailerSelectionModel}
                                        disableSelectionOnClick

                                        disableColumnFilter
                                        disableColumnSelector
                                        disableDensitySelector
                                        hideFooter={true}

                                        components={{ Toolbar: GridToolbar }}
                                        componentsProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: { debounceMs: 500 },
                                            },
                                        }}

                                        rows={retailerList}
                                        columns={retailerCol}

                                    />
                                </Card>
                                <Card style={{ height: 300, width: '40%' }}>
                                    <List sx={{
                                        width: '100%',
                                        maxWidth: 360,
                                        bgcolor: 'background.paper',
                                        position: 'relative',
                                        overflow: 'auto',
                                        maxHeight: 600,
                                        '& ul': { padding: 0 },
                                    }}>
                                        {keyRetailers.length ? retailerList.filter(x => retailerSelectionModel.includes(x.childid)).map((it, index) => {
                                            return (
                                                <ListItem
                                                    secondaryAction={
                                                        <IconButton edge="end" aria-label="delete" onClick={() => deleteRetailers(it.childid)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={it.childname}

                                                    />
                                                </ListItem>
                                            );
                                        }) :
                                            retailers.map((it, index) => {
                                                return (
                                                    <ListItem
                                                        secondaryAction={
                                                            <IconButton edge="end" aria-label="delete" onClick={() => deleteRetailers(it.childid)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        }
                                                    >
                                                        <ListItemText
                                                            primary={it.childname}

                                                        />
                                                    </ListItem>


                                                );
                                            })}
                                    </List>

                                </Card>
                            </Row>

                            Resellers
                            <Row>

                                <Card style={{ height: 300, width: '60%' }}>

                                    <DataGrid editMode="row" className={classes.root}
                                        // getRowHeight={() => 'auto'}
                                        getRowId={(row) => row.childid}
                                        checkboxSelection
                                        selectionModel={resellerSelectionModel}
                                        onSelectionModelChange={setRessellerSelectionModel}
                                        disableSelectionOnClick

                                        disableColumnFilter
                                        disableColumnSelector
                                        disableDensitySelector
                                        hideFooter={true}
                                        components={{ Toolbar: GridToolbar }}
                                        componentsProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: { debounceMs: 500 },
                                            },
                                        }}


                                        rows={resellerList}
                                        columns={resellerCol}

                                    />
                                </Card>
                                <Card style={{ height: 300, width: '40%' }}>
                                    <List sx={{
                                        width: '100%',
                                        maxWidth: 360,
                                        bgcolor: 'background.paper',
                                        position: 'relative',
                                        overflow: 'auto',
                                        maxHeight: 600,
                                        '& ul': { padding: 0 },
                                    }}>
                                        {keyResellers.length ? resellerList.filter(x => resellerSelectionModel.includes(x.childid)).map((it, index) => {
                                            return (
                                                <ListItem
                                                    secondaryAction={
                                                        <IconButton edge="end" aria-label="delete" onClick={() => deleteResellers(it.childid)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={it.childname}

                                                    />
                                                </ListItem>
                                            );
                                        }) :
                                            resellers.map((it, index) => {
                                                return (
                                                    <ListItem
                                                        secondaryAction={
                                                            <IconButton edge="end" aria-label="delete" onClick={() => deleteResellers(it.childid)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        }
                                                    >
                                                        <ListItemText
                                                            primary={it.childname}

                                                        />
                                                    </ListItem>


                                                );
                                            })}
                                    </List>

                                </Card>
                            </Row>



                        </Box>

                    </div>
                    <div className="modal-footer">
                        <Button color="primary" type="button" onClick={() => saveYourChannels(retailerSelectionModel,resellerSelectionModel)}>Ok</Button>
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal>
            </div>
        )
    }

    const Product = () => {
        let arr = categoryMaster.filter(x => categorySelectionModel.includes(x.id));

        const deleteCategory = (id) => {
            arr = arr.filter(x => x.id != id)
            // setCategoryMaster(categoryMaster.filter(x => x.id != id));
            setTemp(categoryMaster.filter(x => x.id != id))
            setChannelProduct(arr);
        }

        return (
            <div>
                <Modal className="modal-dialog-centered" fade={false} isOpen={openProduct} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        <strong>{'Channel Product'}</strong>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <Box
                            sx={{
                                height: 600,
                                width: '100%',
                                '& .actions': {
                                    color: 'text.secondary',
                                },
                                '& .textPrimary': {
                                    color: 'text.primary',
                                },
                            }}
                        >

                            <Row>
                                <Card style={{ height: 600, width: '60%' }}>
                                    <DataGrid editMode="row" className={classes.root}
                                        getRowHeight={() => 'auto'}

                                        checkboxSelection
                                        selectionModel={categorySelectionModel}
                                        onSelectionModelChange={setCategorySelectionModel}
                                        disableSelectionOnClick

                                        disableColumnFilter
                                        disableColumnSelector
                                        disableDensitySelector

                                        components={{ Toolbar: GridToolbar }}
                                        componentsProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: { debounceMs: 500 },
                                            },
                                        }}


                                        rows={temp.length ? temp : categoryMaster}
                                        columns={categoryCol}

                                    />
                                </Card>
                                <Card style={{ height: 600, width: '40%' }}>
                                    <List sx={{
                                        width: '100%',
                                        maxWidth: 360,
                                        bgcolor: 'background.paper',
                                        position: 'relative',
                                        overflow: 'auto',
                                        maxHeight: 600,
                                        '& ul': { padding: 0 },
                                    }}>
                                        {channelProduct.length ? temp.filter(x => categorySelectionModel.includes(x.id)).map((it, index) => {
                                            return (
                                                <ListItem
                                                    secondaryAction={
                                                        <IconButton edge="end" aria-label="delete" onClick={() => deleteCategory(it.id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={it.category}

                                                    />
                                                </ListItem>
                                            );
                                        }) :
                                            arr.map((it, index) => {
                                                return (
                                                    <ListItem
                                                        secondaryAction={
                                                            <IconButton edge="end" aria-label="delete" onClick={() => deleteCategory(it.id)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        }
                                                    >
                                                        <ListItemText
                                                            primary={it.category}

                                                        />
                                                    </ListItem>


                                                );
                                            })}
                                    </List>

                                </Card>
                            </Row>


                        </Box>

                    </div>
                    <div className="modal-footer">
                        <Button color="primary" type="button" onClick={() => saveProduct(categorySelectionModel)}>Ok</Button>
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal >
            </div >
        )
    }

    const ChannelLogo = () => {

        return (
            <div>
                <Modal className="modal-dialog-centered" fade={false} isOpen={openLogo} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        <strong>{'Channels'}</strong>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <Box
                            sx={{
                                height: 600,
                                width: '100%',
                                '& .actions': {
                                    color: 'text.secondary',
                                },
                                '& .textPrimary': {
                                    color: 'text.primary',
                                },
                            }}
                        >

                            <Row>
                                <Card style={{ height: 600, width: '50%' }}>
                                    <DataGrid editMode="row" className={classes.root}
                                        getRowHeight={() => 'auto'}
                                        rows={selectedRows}
                                        key={selectedRows.map((_,i)=>i)}
                                        onSelectionModelChange={setCategorySelectionModel}
                                        disableSelectionOnClick

                                        disableColumnFilter
                                        disableColumnSelector
                                        disableDensitySelector

                                        components={{ Toolbar: GridToolbar }}
                                        componentsProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: { debounceMs: 500 },
                                            },
                                        }}
                                        columns={ChannelLogoCol}

                                    />
                                </Card>
                                <Card style={{ height: 600, width: '50%' }}>
                            <>
                                <div className="modal-header">
                                    <Nav tabs>
                                        <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { toggle('1'); }}
                                        >
                                            Upload File
                                        </NavLink>
                                        </NavItem>
                                        <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '3' })}
                                            onClick={() => { toggle('3'); }}
                                        >
                                            Media URL
                                        </NavLink>
                                        </NavItem>

                                    </Nav>

                                    <button
                                        aria-label="Close"
                                        className="close"
                                        data-dismiss="modal"
                                        type="button"
                                        onClick={handleClose}
                                    >
                                        <span aria-hidden={true}>×</span>
                                    </button>

                                    </div>
                                    <div className="modal-body p-0">

                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                        {loading ? <Spinner /> : <Row>
                                            <Col sm="12">
                                            <MultiUpload acceptedFiles={['image/jpeg', 'image/png', 'image/gif']} handleChange={handleOnDrop} />
                                           {uploadedImage ? <><p className='mt-4'>Uploaded Image</p>
                                           <div className='mt-2'><img src={uploadedImage} height={200} width={395}/></div></> :<></>}
                                            </Col>
                                            
                                        </Row>}
                                        </TabPane>

                                        <TabPane tabId="3">
                                        <div className="container">
                                            <Row>
                                            <FormInput
                                                id="logourl"
                                                type="text"
                                                name="logourl"
                                                InputLabelProps={{ shrink: true }}
                                                value={logourl}
                                                onChange={(e) => setLogoUrl(e.target.value)}
                                            />
                                            <br /><br />
                                            <Button onClick={() => { saveBulkLogoUrl(logourl); setOpen(false); }}>Update</Button>
                                            </Row>

                                        </div>
                                        </TabPane>
                                    </TabContent>

                                    </div>
                                    {image ? <ModalImageCropper isOpen={true} src={image} handleChange={selectImg} handleCropClose={handleCropClose} /> : <></>}
                            </>
                            </Card>
                        </Row>
                    </Box>

                    </div>
                    <div className="modal-footer">
                    {uploadedImage ? <Button color="primary" type="button" onClick={() => handleSaveLogo()}>Upload</Button>:<></>}
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal >
            </div >
        )
    }

    const Location = () => {
        console.log('sellinglist',sellingList,sellingSelectionModel)
        let selling = sellingList.filter(x => sellingSelectionModel.includes(x.id));
        let target = targetList.filter(x => targetSelectionModel.includes(x.id));



        const deleteSellingLocation = (id) => {
            selling = selling.filter(x => x.id != id)
            setSellingList(countryList.filter(x => x.id != id));
            setSellingLocation(selling);
        }

        const deleteTargetLocation = (id) => {
            target = target.filter(x => x.id != id);
            setTargetList(countryList.filter(x => x.id != id));
            setTargetLocation(target);
        }



        return (
            <div>
                <Modal className="modal-dialog-centered" fade={false} isOpen={location} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        <strong>{'Geographical Coverage'}</strong>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <Box
                            sx={{
                                height: 600,
                                width: '100%',
                                '& .actions': {
                                    color: 'text.secondary',
                                },
                                '& .textPrimary': {
                                    color: 'text.primary',
                                },
                            }}
                        >
                         {typeChannelId != 'Vendor' ?(
                            <div>
                            Selling Location
                            <Row>
                                <Card style={{ height: 300, width: '60%' }}>

                                    <DataGrid editMode="row" className={classes.root}
                                        getRowHeight={() => 'auto'}

                                        checkboxSelection
                                        selectionModel={sellingSelectionModel}
                                        onSelectionModelChange={setSellingSelectionModel}
                                        disableSelectionOnClick

                                        disableColumnFilter
                                        disableColumnSelector
                                        disableDensitySelector


                                        components={{ Toolbar: GridToolbar }}
                                        componentsProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: { debounceMs: 500 },
                                            },
                                        }}

                                        rows={sellingList}
                                        columns={locationCol}

                                    />
                                </Card>
                                <Card style={{ height: 300, width: '40%' }}>
                                    <List sx={{
                                        width: '100%',
                                        maxWidth: 360,
                                        bgcolor: 'background.paper',
                                        position: 'relative',
                                        overflow: 'auto',
                                        maxHeight: 600,
                                        '& ul': { padding: 0 },
                                    }}>
                                        {sellingLocation.length ? sellingList.filter(x => sellingSelectionModel.includes(x.id)).map((it, index) => {
                                            return (
                                                <ListItem
                                                    secondaryAction={
                                                        <IconButton edge="end" aria-label="delete" onClick={() => deleteSellingLocation(it.id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={it.name}

                                                    />
                                                </ListItem>
                                            );
                                        }) :
                                            selling.map((it, index) => {
                                                return (
                                                    <ListItem
                                                        secondaryAction={
                                                            <IconButton edge="end" aria-label="delete" onClick={() => deleteSellingLocation(it.id)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        }
                                                    >
                                                        <ListItemText
                                                            primary={it.name}

                                                        />
                                                    </ListItem>


                                                );
                                            })}
                                    </List>

                                </Card>
                            </Row></div>
                         ):(
                            <div>
                            Target Location
                            <Row>

                                <Card style={{ height: 300, width: '60%' }}>

                                    <DataGrid editMode="row" className={classes.root}
                                        getRowHeight={() => 'auto'}

                                        checkboxSelection
                                        selectionModel={targetSelectionModel}
                                        onSelectionModelChange={setTargetSelectionModel}
                                        disableSelectionOnClick

                                        disableColumnFilter
                                        disableColumnSelector
                                        disableDensitySelector

                                        components={{ Toolbar: GridToolbar }}
                                        componentsProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: { debounceMs: 500 },
                                            },
                                        }}


                                        rows={targetList}
                                        columns={locationCol}

                                    />
                                </Card>
                                <Card style={{ height: 300, width: '40%' }}>
                                    <List sx={{
                                        width: '100%',
                                        maxWidth: 360,
                                        bgcolor: 'background.paper',
                                        position: 'relative',
                                        overflow: 'auto',
                                        maxHeight: 600,
                                        '& ul': { padding: 0 },
                                    }}>
                                        {targetLocation.length ? targetList.filter(x => targetSelectionModel.includes(x.id)).map((it, index) => {
                                            return (
                                                <ListItem
                                                    secondaryAction={
                                                        <IconButton edge="end" aria-label="delete" onClick={() => deleteTargetLocation(it.id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={it.name}

                                                    />
                                                </ListItem>
                                            );
                                        }) :
                                            target.map((it, index) => {
                                                return (
                                                    <ListItem
                                                        secondaryAction={
                                                            <IconButton edge="end" aria-label="delete" onClick={() => deleteTargetLocation(it.id)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        }
                                                    >
                                                        <ListItemText
                                                            primary={it.name}

                                                        />
                                                    </ListItem>


                                                );
                                            })}
                                    </List>

                                </Card>
                            </Row>
                            </div>)}

                        </Box>

                    </div>
                    <div className="modal-footer">
                        <Button color="primary" type="button" onClick={() => saveLocation(sellingSelectionModel, targetSelectionModel)}>Ok</Button>
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal >
            </div >
        )
    }

    const Speciality = () => {

        if (specialitySelectionModel.length > 3) {
            specialitySelectionModel.pop();
            setSpecialitySelectionModel(specialitySelectionModel)
        }

        let arr = specialityMaster.filter(x => specialitySelectionModel.includes(x.id));

        const deleteSpeciality = (id) => {
            arr = arr.filter(x => x.id != id)
            // setSpecialityMaster(specialityMaster.filter(x => x.id != id));           
            setTemp(specialityMaster.filter(x => x.id != id));
            setChannelSpeciality(arr);
        }

        return (
            <div>
                <Modal className="modal-dialog-centered" fade={false} isOpen={openSpeciality} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        <strong>{'Channel Speciality'}</strong>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <Box
                            sx={{
                                height: 600,
                                width: '100%',
                                '& .actions': {
                                    color: 'text.secondary',
                                },
                                '& .textPrimary': {
                                    color: 'text.primary',
                                },
                            }}
                        >

                            <Row>
                                <Card style={{ height: 600, width: '60%' }}>
                                    <DataGrid editMode="row" className={classes.root}
                                        getRowHeight={() => 'auto'}

                                        checkboxSelection
                                        selectionModel={specialitySelectionModel}
                                        onSelectionModelChange={setSpecialitySelectionModel}
                                        disableSelectionOnClick

                                        disableColumnFilter
                                        disableColumnSelector
                                        disableDensitySelector

                                        components={{ Toolbar: GridToolbar }}
                                        componentsProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: { debounceMs: 500 },
                                            },
                                        }}


                                        rows={temp.length ? temp : specialityMaster}
                                        columns={specialityCol}
                                        initialState={{
                                            sorting: {
                                              sortModel: [{ field: 'name', sort: 'asc' }],
                                            },
                                          }}
                                    />
                                </Card>
                                <Card style={{ height: 600, width: '40%' }}>
                                    <List sx={{
                                        width: '100%',
                                        maxWidth: 360,
                                        bgcolor: 'background.paper',
                                        position: 'relative',
                                        overflow: 'auto',
                                        maxHeight: 600,
                                        '& ul': { padding: 0 },
                                    }}>
                                        {channelSpeciality.length ? temp.filter(x => specialitySelectionModel.includes(x.id)).map((it, index) => {
                                            return (
                                                <ListItem
                                                    secondaryAction={
                                                        <IconButton edge="end" aria-label="delete" onClick={() => deleteSpeciality(it.id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={it.name}

                                                    />
                                                </ListItem>
                                            );
                                        }) :
                                            arr.sort((a, b) =>
                                            a.name.localeCompare(b.name)).map((it, index) => {
                                                return (
                                                    <ListItem
                                                        secondaryAction={
                                                            <IconButton edge="end" aria-label="delete" onClick={() => deleteSpeciality(it.id)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        }
                                                    >
                                                        <ListItemText
                                                            primary={it.name}

                                                        />
                                                    </ListItem>


                                                );
                                            })}
                                    </List>

                                </Card>
                            </Row>


                        </Box>

                    </div>
                    <div className="modal-footer">
                        <Button color="primary" type="button" onClick={() => saveSpeciality(specialitySelectionModel)}>Ok</Button>
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal >
            </div >
        )
    }

    const Description = () => {

        return (
            <div>
                <Modal className="modal-dialog-centered" fade={false} isOpen={openDesc} size="xg" style={{ minWidth: 900, width: '30%' }}>
                    <div className="modal-header">
                        <strong>{'Channel Description'}</strong>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <Box
                            sx={{
                                height: 600,
                                width: '100%',
                                '& .actions': {
                                    color: 'text.secondary',
                                },
                                '& .textPrimary': {
                                    color: 'text.primary',
                                },
                            }}
                        >

                            <ReactQuill
                                theme="snow"
                                autoFocus
                                placeholder="Tell us about your company description and vision -max 2000 characters *"
                                onChange={handleDetailDesc}
                                value={description}
                                // ref={register}
                                id="detailDesc"
                                name="detailDesc"
                                modules={{
                                    toolbar: [
                                        ["bold", "italic", "underline"],
                                        [
                                            {
                                                list: "ordered"
                                            },
                                            {
                                                list: "bullet"
                                            }
                                        ]
                                    ]
                                }}
                            />


                        </Box>

                    </div>
                    <div className="modal-footer">
                        <Button color="primary" type="button" onClick={() => saveDesc(description)}>Ok</Button>
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                    </div>
                </Modal>
            </div>
        )
    }

    const handleBrandCommit = (e) => {
        const array = brandMaster.map(r => {
            if (r.id == e.id) {
                return { ...r, [e.field]: e.value }
            }
            else {
                return { ...r }
            }
        });
        setBrandMaster(array);

    }

    const handleGroupCommit = (e) => {
        console.log("gname:", e.value)
        const array = groupMaster.map(r => {
            if (r.id == e.id) {
                return { ...r, [e.field]: e.value }
            }
            else {
                return { ...r }
            }
        });
        setGroupMaster(array);

    }

    const saveAddress = (regAddress) => {

        setChannel((channel) => {
            return channel.map(x => {
                if (x.id === channelId) {
                    x.regAddress = regAddress;
                }
                return x;
            })
        })
        if(filterFlag){
            setFilterChannel((filterChannel) => {
                return filterChannel.map(x => {
                    if (x.id === channelId) {
                        x.regAddress = regAddress;
                    }
                    return x;
                })
            })
        }
        console.log("saveAddress:", regAddress)
        setAddress(false);
    }

    const saveSocialProfiles=(socialProfiles)=>{
   
        if(newFbUrl){
            socialProfiles.push(newFbUrl)
            setSocialProfiles(socialProfiles)
            setNewFbUrl('');
        }
        if(newInstaUrl){
            socialProfiles.push(newInstaUrl)
            setSocialProfiles(socialProfiles)
            setNewInstaUrl('');
        }
        if(newTwitterUrl){
            socialProfiles.push(newTwitterUrl)
            setSocialProfiles(socialProfiles)
            setNewTwitterUrl('');
        }
        if(newLinkedUrl){
            socialProfiles.push(newLinkedUrl)
            setSocialProfiles(socialProfiles)
            setNewLinkedUrl('');
        }

        setChannel(channel=>{
            return channel.map(x=>{
                if(x.id===channelId){
                    x.channelSocial=socialProfiles;
                }
                return x;
            })
        })
        if(filterFlag){
            setFilterChannel((filterChannel)=>{
                return filterChannel.map(x=>{
                    if(x.id===channelId){
                        x.channelSocial=socialProfiles;
                    }
                    return x;
                })
            })
        }
        console.log('saveSocial',socialProfiles)
        setSocial(false);
    }

    const saveBrand = async (brand) => {

        if (bulkEdit) {
            setLoading(true);
            let channels = channel.filter(x => channelSelectionModel.includes(x.id))
            await axios.post('/channelAdmin/bulkChannelBrand', { channel: channels.map(x => +x.channelId), brand: brand }).then(async(res) => {
                let updatedChannels = channels.map(x => +x.channelId);
                // if (updatedChannels.length) {
                //     setChannel((channel) => {
                //         return channel.map(x => {
                //             if (updatedChannels.includes(+x.channelId)) {
                //                 x.channelBrand = [];
                //             }
                //             return x;
                //         })
                //     })
                // }
                setUpdatedChannel([])
                setChannelSelectionModel([]);
                let updateObj  = {
                    updatedChannels : updatedChannels,
                    brand : brand
                }
                await updateLocalChannel(updateObj)
                // setTimeout(updateChannelList,2000)
                setBulkEdit(false);
                setLoading(false);

            }).catch(err => {
                setLoading(false);
            });

        }
        else {
            setChannel((channel) => {
                return channel.map(x => {
                    if (x.id === channelId) {
                        x.channelBrand = brand;
                    }
                    return x;
                })
            })
           if(filterFlag){
            setFilterChannel((filterChannel)=>{
                return filterChannel.map(x => {
                    if (x.id === channelId) {
                        x.channelBrand = brand;
                    }
                    return x;
                })
            })
           }
        }
        console.log("saveBrand:", channel)
        setBrand(false);
    }

    const updateLocalChannel = async (updateData) => {
        return new Promise(async (resolve, reject) => {
          let { updatedChannels, brand } = updateData;
      
          const promises = [];
      
          for (let i = 0; i < updatedChannels.length; i++) {
            const channelId = updatedChannels[i];
      
            const promise = new Promise((innerResolve, innerReject) => {
              setChannel((channel) => {
                return channel.map((x) => {
                  if (x.id == channelId) {
                    x.channelBrand = [...x.channelBrand, ...brand];
                  }
                  return x;
                });
              });
              innerResolve();
            });
      
            promises.push(promise);
          }
      
          try {
            await Promise.all(promises);
            resolve();
          } catch (error) {
            reject(error);
          }
        });
      };

    const saveProduct = async (product) => {

        if (bulkEdit) {
            let channels = channel.filter(x => channelSelectionModel.includes(x.id))
            await axios.post('/channelAdmin/bulkChannelProduct', { channel: channels.map(x => +x.channelId), product: product }).then(res => {
                setLoading(true);
                let updatedChannels = channels.map(x => +x.channelId);

                if (updatedChannels.length) {
                    setChannel((channel) => {
                        return channel.map(x => {
                            if (updatedChannels.includes(+x.channelId)) {
                                x.channelProduct = [];
                            }
                            return x;
                        })
                    })
                }

                setChannelSelectionModel([]);
                setBulkEdit(false);
                setLoading(false);
            }).catch(err => {
                setLoading(false);
            });

        } else {
            setChannel((channel) => {
                return channel.map(x => {
                    if (x.id === channelId) {
                        x.channelProduct = product;
                    }
                    return x;
                })
            })
           if(filterFlag){
            setFilterChannel((filterChannel)=>{
                return filterChannel.map(x => {
                    if (x.id === channelId) {
                        x.channelProduct = product;
                    }
                    return x;
                })
            })
           }
        }
        console.log("saveProduct:", channel)
        setProduct(false);
    }

    const saveLocation = (sellingLocation, targetLocation) => {

        console.log("loca:", sellingLocation, targetLocation)

        setChannel((channel) => {
            return channel.map(x => {
                if (x.id === channelId) {
                    x.channelLocation['sellingLocation'] = sellingLocation;
                    x.channelLocation['targetLocation'] = targetLocation;
                }
                return x;
            })
        })
        if(filterFlag){
            setFilterChannel((filterChannel)=>{
                return filterChannel.map(x => {
                    if (x.id === channelId) {
                        x.channelLocation['sellingLocation'] = sellingLocation;
                        x.channelLocation['targetLocation'] = targetLocation;
                    }
                    return x;
                })
            })
        }
        console.log("saveLocation:", channel)
        setLocation(false);
    }

    const saveYourChannels=(keyRetailers,keyResellers)=>{
        console.log("chaaa:", keyRetailers, keyResellers)
        let updatedProfile=keyRetailers.concat(keyResellers).map(x=>({profileTypeId:x}))
        setChannel((channel) => {
            return channel.map(x => {
                if (x.id === channelId) {
                    x.channelProfile=updatedProfile;
                }
                return x;
            })
        })
        if(filterFlag){
            setFilterChannel((filterChannel)=>{
                return filterChannel.map(x => {
                    if (x.id === channelId) {
                        x.channelProfile=updatedProfile;
                    }
                    return x;
                })
            })
        }
        console.log("saveYourChannels:", channel)
        setYourChannel(false);
    };

    const saveSpeciality = (speciality) => {

        setChannel((channel) => {
            return channel.map(x => {
                if (x.id === channelId) {
                    x.channelSpeciality = speciality;
                }
                return x;
            })
        })
        if(filterFlag){
            setFilterChannel((filterChannel)=>{
                return filterChannel.map(x => {
                    if (x.id === channelId) {
                        x.channelSpeciality = speciality;
                    }
                    return x;
                })
            })
        }
        console.log("saveSpeciality:", channel)
        setSpeciality(false);
    }

    const handleDetailDesc = (value) => {
        setDescription(value);
    }

    const saveDesc = (description) => {


        setChannel((channel) => {
            return channel.map(x => {
                if (x.id === channelId) {
                    x.description = description;
                }
                return x;
            })
        })
        console.log("saveDesc:", channel)
        setDesc(false);

    }

    const getCompanyLogo = (resImagePath) => {
        setLogo({ ...logo, documentUrl: resImagePath.document.documentUrl, documentId: resImagePath.documentId })

        setChannel((channel) => {
            return channel.map(x => {
                if (x.id === channelId) {
                    x.channelLogo = resImagePath.document.documentUrl;
                    x.channelLogoId = resImagePath.documentId;
                }
                return x;
            })
        })
        if(filterFlag){
            setFilterChannel((filterChannel)=>{
                return filterChannel.map(x => {
                    if (x.id === channelId) {
                        x.channelLogo = resImagePath.document.documentUrl;
                        x.channelLogoId = resImagePath.documentId;
                    }
                    return x;
                })
            })
        }
    };

    const saveLogoUrl = (url) => {
        setLogo({ ...logo, documentUrl: url })

        setChannel((channel) => {
            return channel.map(x => {
                if (x.id === channelId) {
                    x.channelLogo = url;
                }
                return x;
            })
        })
        if(filterFlag){
            setFilterChannel((filterChannel)=>{
                return filterChannel.map(x => {
                    if (x.id === channelId) {
                        x.channelLogo = url;
                    }
                    return x;
                })
            })
        }
    }

    const saveBulkLogoUrl = (url) => {
        setLogo({ ...logo, documentUrl: url })
        setSelectedRows((selectedRows) => {
            return selectedRows.map(x => {
                if (x.channelId) {
                    x.channelLogo = url;
                }
                return x;
            })
        })
       
        if(filterFlag){
            setSelectedRows((selectedRows)=>{
                return selectedRows.map(x => {
                    if (x.channelId) {
                        x.channelLogo = url;
                    }
                    return x;
                })
            })
        }
        setBulkEdit(false)
        setOpenLogo(false);
    }

    const updateBoolean = (field, value) => {
        setChannel((channel) => {
            return channel.map(x => {
                if (x.id === channelId) {
                    x[field] = value;
                }
                return x;
            })
        });
        if(filterFlag){
            setFilterChannel((filterChannel)=>{
                return filterChannel.map(x => {
                    if (x.id === channelId) {
                        x[field] = value;
                    }
                    return x;
                })
            })
        }
    }


    const handleEdit = (e) => {
        // console.log("edit:", e.id, e.field);
        // setChannelSelectionModel([]);

        setChannelId(e.id);

        if ((e.field === 'deletedAt' || e.field === 'isActive' || e.field === 'isPublic' || e.field === 'isTestAccount' || e.field === 'startup') && e.hasFocus) {
            updateBoolean(e.field, e.formattedValue === 'yes' ? false : true);
        }

        if (e.field === 'channelProduct') {
            let channelId = channel.filter(x => x.id == e.id)[0].channelId;
            if (channelId) {
                setChannelProduct([]);
                getProduct(channelId);
            }
            else {
                setChannelProduct([]);
                setCategorySelectionModel([]);
            }
        }
        if (e.field === 'channelSpeciality') {
            let channels = channel.filter(x => x.id == e.id)[0];

            if (channels.channelId && channels.channelType == 'Distributor') {
                setChannelSpeciality([]);
                getSpeciality(channels.channelId);
            }
            else {
                setChannelSpeciality([]);
                setSpecialitySelectionModel([]);
            }
        }
        if (e.field === 'channelBrand') {
            let channelId = channel.filter(x => x.id == e.id)[0].channelId;
            if (channelId) {
                setChannelBrand([]);
                setBrandSelectionModel(e.value||[]);
                // getBrand(channelId);
            }
            else {
                setChannelBrand([]);
                setBrandSelectionModel([]);
            }
        }

        if (e.field === 'channelLocation') {
            let ch = channel.filter(x => x.id == e.id)[0];

            let channelId = +ch.channelId;
            let ctype = ch.channelType;
            setChannelType(ctype)

            let typeId;
            if (ch.channelId != null)
                typeId = +channelTypes.filter(x => x.name === ch.channelType)[0].id;
            // let typeId = +channelTypes.filter(x => x.name === ch.channelType)[0].id;

            if (channelId) {
                setSellingLocation([]);
                setTargetLocation([]);
                getLocation(channelId, typeId);
            }
            else {
                setSellingLocation([]);
                setTargetLocation([]);
                setSellingSelectionModel([]);
                setTargetSelectionModel([]);
            }
        }

        if(e.field === 'channelProfile'){
            console.log('retailer',retailerList,resellerList)
            let channelId = channel.filter(x => x.id == e.id)[0]?.channelId;
            if(channelId){
                let keyRetailers=retailerList.filter(obj1 =>
                    e.value?.some(obj2 => obj1.childid === obj2.profileTypeId)).map(x=>x.childid);
                setRetailerSelectionModel(keyRetailers);
                let keyResellers=resellerList.filter(obj1 =>
                    e.value?.some(obj2 => obj1.childid === obj2.profileTypeId)).map(x=>x.childid);
                setRessellerSelectionModel(keyResellers);
            }
            else{
                setRetailerSelectionModel([]);
                setRessellerSelectionModel([]);
            }
        }

        if (e.field === 'revenue') {
            let channelvalues = channel.filter(x => x.id == e.id)[0];
            if (channelvalues?.channelId) {
                setRevenue(channelvalues.revenue||[])
                setAnnualTurn(true)
                // getRevenue(channelId);
            }
            else {
                setRevenue([]);
                setAnnualTurn(true);
            }
        }

        if (e.id)
            setUpdatedChannel([...updatedChannels, e.id])

        if (e.field === 'regAddress') {
            let addr = channel.filter(x => x.id == e.id)[0];
            setRegAddress(addr.regAddress ? addr.regAddress : { regname: '', vatname: 'VAT' });
        }

        if(e.field==='channelSocial'){
            console.log('channelSocial',e)
            let params=channel.filter(x => x.id==e.id)[0]||{};
            setSocialProfiles(params.channelSocial? params.channelSocial : [])

        }

        if(e.field === 'contact'){
            let params = channel.filter(x=>x.id==e.id)[0]||{}
            setContactDetail(params.contacts?params.contacts:[])
        }

        if (e.field === 'channelDesc') {
            let ch = channel.filter(x => x.id == e.id)[0];
            setDescription(ch.description ? ch.description : '');
        }
    }

    const handleGroupEdit = (e) => {
        console.log("edit:", e.id, e.field);
        let group = groupMaster.find(x => x.id === e.id);

        if (e.field === 'name')
            setGroupId({ ...group, isNew: false });
    }

    const getChannel = async (body, isDeleted) => {
        let url;
        if (isDeleted){
            url = "/channelAdmin/getDeletedData/" 
        }
        else{
            url = '/channelAdmin/getData/'
        }

        
        const getFirstElement = (arr, propName) => {
            return ((arr || []).length >0) ? arr[0][propName] : null;
        }    

        return await axios.post(url,body).then(res => {
            let data = res.data.filteredData.map((row) => {
              if (row.channelType) {
                let ctype = channelTypes?.filter(
                  (x) => x.id == row.channelType
                );
                row.channelType = getFirstElement(ctype, 'name') // ((ctype || []).length >0) ? ctype[0].name : null;
              }
              if (row.profileType) {
                // let profileType = profileMaster?.filter(
                //   (x) => x.id == row.profileType
                // );
                // row.profileTypeId = row.profileType;
                // row.profileType = getFirstElement(profileType, 'name')
                let profileType = profileMaster?.filter((x) => x.id == row.profileType);
                // row.channelGroup = getFirstElement(group, 'name')
                if(getFirstElement(profileType, 'name')){
                    row.profileType = getFirstElement(profileType, 'name'); // group?.length ? group[0].name : null;
                } else {
                    row.profileTypeTempId = JSON.parse(JSON.stringify(row.profileType));
                    row.profileType = null;
                }
              }
              if (row.isOEM) {
                let isOEM = businessTypes.filter((x) => x.id == row.isOEM);
                row.isOEM = getFirstElement(isOEM, 'name') // isOEM?.length ? isOEM[0].name : null;
              }
              if (row.channelGroup) {
                let group = groupMaster?.filter((x) => x.id == row.channelGroup);
                // row.channelGroup = getFirstElement(group, 'name')
                if(getFirstElement(group, 'name')){
                    row.channelGroup = getFirstElement(group, 'name'); // group?.length ? group[0].name : null;
                } else {
                    row.channelGroupTempId = JSON.parse(JSON.stringify(row.channelGroup));
                    row.channelGroup = null;
                }
              }
              if (row.country) {
                // let country = countryList?.filter((x) => x.id == row.country);
                // row.country = getFirstElement(country, 'name') // country?.length ? country[0].name : null;
                let country = countryList?.filter((x) => x.id == row.country);
                // row.channelGroup = getFirstElement(group, 'name')
                if(getFirstElement(country, 'name')){
                    row.country = getFirstElement(country, 'name'); // group?.length ? group[0].name : null;
                } else {
                    row.countryTempId = JSON.parse(JSON.stringify(row.country));
                    row.country = null;
                }
            }
              if (row.webSiteUrl)
                row.webSiteUrl = row.websiteUrl =
                  row.webSiteUrl.substring(0, 2) == "//"
                    ? row.webSiteUrl.substring(2, row.webSiteUrl?.length)
                    : row.webSiteUrl;

              return {...row} ;
            });

            setTotalRecord(res.data.totalRecord);
            // console.log("logcounts:",count)

            prevList =  data
            // setChannel(prevList);
            setPrevChannel(prevList);
            setLoading(false);
            return prevList;

        }).catch(err => {
            console.log("cherr:", err);
            setLoading(false);
        });
    }

    const getTestChannel = async (body, isTest) => {
        let url;
        url = '/channelAdmin/getTestData/'

        
        const getFirstElement = (arr, propName) => {
            return ((arr || []).length >0) ? arr[0][propName] : null;
        }    

        return await axios.post(url,body).then(res => {
            let data = res.data.map((row) => {
              if (row.channelType) {
                let ctype = channelTypes?.filter(
                  (x) => x.id == row.channelType
                );
                row.channelType = getFirstElement(ctype, 'name') // ((ctype || []).length >0) ? ctype[0].name : null;
              }
              if (row.profileType) {
                // let profileType = profileMaster?.filter(
                //   (x) => x.id == row.profileType
                // );
                // row.profileTypeId = row.profileType;
                // row.profileType = getFirstElement(profileType, 'name')
                let profileType = profileMaster?.filter((x) => x.id == row.profileType);
                // row.channelGroup = getFirstElement(group, 'name')
                if(getFirstElement(profileType, 'name')){
                    row.profileType = getFirstElement(profileType, 'name'); // group?.length ? group[0].name : null;
                } else {
                    row.profileTypeTempId = JSON.parse(JSON.stringify(row.profileType));
                    row.profileType = null;
                }
              }
              if (row.isOEM) {
                let isOEM = businessTypes.filter((x) => x.id == row.isOEM);
                row.isOEM = getFirstElement(isOEM, 'name') // isOEM?.length ? isOEM[0].name : null;
              }
              if (row.channelGroup) {
                let group = groupMaster?.filter((x) => x.id == row.channelGroup);
                // row.channelGroup = getFirstElement(group, 'name')
                if(getFirstElement(group, 'name')){
                    row.channelGroup = getFirstElement(group, 'name'); // group?.length ? group[0].name : null;
                } else {
                    row.channelGroupTempId = JSON.parse(JSON.stringify(row.channelGroup));
                    row.channelGroup = null;
                }
              }
              if (row.country) {
                // let country = countryList?.filter((x) => x.id == row.country);
                // row.country = getFirstElement(country, 'name') // country?.length ? country[0].name : null;
                let country = countryList?.filter((x) => x.id == row.country);
                // row.channelGroup = getFirstElement(group, 'name')
                if(getFirstElement(country, 'name')){
                    row.country = getFirstElement(country, 'name'); // group?.length ? group[0].name : null;
                } else {
                    row.countryTempId = JSON.parse(JSON.stringify(row.country));
                    row.country = null;
                }
            }
              if (row.webSiteUrl)
                row.webSiteUrl = row.websiteUrl =
                  row.webSiteUrl.substring(0, 2) == "//"
                    ? row.webSiteUrl.substring(2, row.webSiteUrl?.length)
                    : row.webSiteUrl;

              return {...row} ;
            });

            setTotalRecord(totalRecord+res.data.length);
            // console.log("logcounts:",count)

            prevList =  data
            // setChannel(prevList);
            setPrevChannel(prevList);
            setLoading(false);
            return prevList;

        }).catch(err => {
            console.log("cherr:", err);
            setLoading(false);
        });
    }

    const saveChannelGroup = async () => {

        let group = groupMaster.find(x => x.id == groupId.id);
        // console.log("groups:", group, groupId)

        if (Object.keys(groupId).length) {
            setLoading(true);
            await axios.post('/channelAdmin/channelgroup', { id: group.id, group: group.name, isNew: group.isNew ? true : false }).then(res => {
                setChannel((channel) => {
                    return channel.map(x => {
                        if (x.channelGroup === groupId.name) {
                            x.channelGroup = group.name;
                        }
                        return x;
                    })
                });
                setLoading(false);
            }).catch(err => {
                setLoading(false);
            });
        }

        if (deletedGroupIds.length > 0) {
            axios.post(`/channelAdmin/deleteGroupMaster`, deletedGroupIds).then(res => {
            setDeletedGroupIds([]);
            groupMasters();
            console.log(res, "yes Deleted")
            });
        }

        if (deleteGruopInfo.length > 0) {
            axios.post(`/channelAdmin/updateGroupMasterId`, deleteGruopInfo).then(res => {
            
                ToastsStore.success('Saved Successfully!')
                setDeleteGruopInfo([]);
                groupMasters();
            }).catch(err => {
                setLoading(false);
                ToastsStore.success('Something Went Wrong!')
            });
        }
       
        setGrouping(false);
    }

    const brandMasters = async () => {
        await axios.get('/channelAdmin/getBrandMaster/').then(res => {

            let brand = _.uniqBy(res.data);
            setBrandMaster(brand);

        });
    }

    const specialityMasters = async () => {
        await axios.get('/channelAdmin/getSpecialityMaster/').then(res => {
            setSpecialityMaster(res.data);

        });
    }

    const productMaster = async () => {
        await axios.get('/channelAdmin/getProductMaster/').then(res => {
            // let cat = _.sortBy(res.data, (e) => {
            //     return e.group
            // });

            let cat = res.data.sort((a, b) => (a.group.localeCompare(b.group) || a.groupId - b.groupId || a.id - b.id));

            setCategoryMaster(cat);

        });
    }

    const groupMasters = async () => {
        setLoading(true)
        await axios.get(`/channelAdmin/getGroupMaster?isDeleted=${isDeleted}`).then(res => {
            groupMaster = res.data;
            setGroupMaster([{id:'0', name:"null"},...groupMaster]);
            setGroupFilter(groupMaster.filter(x => x.count!=0))      
            setRefreshTime(new Date().getTime())
        });
        setLoading(false)
    }

    useEffect(() => {
        if(refreshTime > 0) {
            const channels=channel.map((row) => {
                if (row.channelGroupTempId) {
                    let group = groupMaster?.filter(
                    (x) => x.id == row.channelGroupTempId 
                    );
                    row.channelGroup = ((group||[]).length >0) ? group[0].name : null;
                }
                if (row.countryTempId) {
                    let country = countryList?.filter(
                    (x) => x.id == row.countryTempId 
                    );
                    row.country = ((country ||[]).length >0) ? country[0].name : null;
                }
                if (row.profileTypeTempId) {
                    let profile = profileMaster?.filter(
                    (x) => x.id == row.profileTypeTempId 
                    );
                    row.profileType = ((profile ||[]).length >0) ? profile[0].name : null;
                }
                return {...row} ;
            });
            setChannel([...channels]);
        }
    }, [refreshTime])

    const CountryList = async () => {
        let refreshRequired = true;
        try {
            countryList = JSON.parse(localStorage.getItem("COUNTRY_MASTER"));
            if(countryList)
            {
            setCountryList(countryList);
            setSellingList(countryList);
            setTargetList(countryList);
            refreshRequired = false;
            }
        } catch(err) {
            
        }

        await axios.get('/channelAdmin/getCountryList/').then(res => {
            countryList = res.data;
            countryList = _.sortBy(countryList, (e) => {
                return e.name.toLowerCase()
            });
            localStorage.setItem("COUNTRY_MASTER", JSON.stringify(countryList))
            if(refreshRequired) { 
                setCountryList(countryList);

                setSellingList(countryList);
                setTargetList(countryList);
                setRefreshTime(new Date().getTime())
            }
        });
    }

    const getProduct = async (id) => {
        let ch = channel.find(x => x.channelId == id);

        if (ch.channelProduct?.length)
            setCategorySelectionModel(ch.channelProduct.map(String))
        else
            await axios.get('/channelAdmin/getChannelProducts/' + id).then(res => {
                setCategorySelectionModel(res.data.productGroup.map(c => c.id));
            });
    }

    const getSpeciality = async (id) => {

        let ch = channel.find(x => x.channelId == id);

        if (ch.channelSpeciality.length)
            setSpecialitySelectionModel(ch.channelSpeciality.map(String))
        else {
            await axios.get('/channelAdmin/getChannelSpeciality/' + id).then(res => {
                setSpecialitySelectionModel(res.data.map(c => c.id));
            });
        }
    }

    const getBrand = async (id) => {

        // let ch = channel.find(x => x.channelId == id);

        // if (ch.channelBrand.length)
        //     setBrandSelectionModel(ch.channelBrand)
        // else
            await axios.get('/channelAdmin/getChannelBrands/' + id).then(res => {
                // console.log("brand:", res.data['brand']);
                // setBrandSelectionModel(res.data['brand']);
                setChannelDeletedBrand(res.data['deletedBrand']);
            });
    }

    const getResellerRetailer=async()=>{
        await axios.get('channelAdmin/getProfileList').then(res => {
            // console.log('resdata',res.data)
             setRetailerList(res.data.filter(x=>x.groupName==="Retailer"));
             setResellerList(res.data.filter(x=>x.groupName==="Reseller"));
        })
    }

    const getLocation = async (id, typeId) => {

        let ch = channel.find(x => x.channelId == id);

        await axios.get('/channelAdmin/getChannelLocations/' + id + '/' + typeId).then(res => {
            // console.log("chloc:", ch.channelLocation);
            if (ch.channelLocation['sellingLocation'].length)
                setSellingSelectionModel(ch.channelLocation['sellingLocation'])
            else
                setSellingSelectionModel(res.data['sellingArray'].map(c => c.childId));
            if (ch.channelLocation['targetLocation'].length)
                setTargetSelectionModel(ch.channelLocation['targetLocation'])
            else
                setTargetSelectionModel(res.data['targetArray'].map(c => c.childId));
        });
    }

    const getRevenue = async (id) => {

        let ch = channel.find(x => x.channelId == id);


        // console.log(ch,"channel revenue")

        if (ch.revenue?.length) {
            setRevenue(ch.revenue);
            setAnnualTurn(true)
        }
        else{
            await axios.get('/channelAdmin/getChannelRevenue/' + id).then(res => {
                setRevenue(res.data);
                setAnnualTurn(true)
                
            });

        }
        

        console.log(revenue, "ch2")
    }

    const profileMasters = async () => {
        
        let refreshRequired = true;
        let profileMaster
        try {
            profileMaster = JSON.parse(localStorage.getItem("PROFILE_MASTER"));
            if(profileMaster){
            setProfileMaster(profileMaster);
            refreshRequired = false;
            }
        } catch(err) {
            
        }

        await axios.get('/channelAdmin/getProfileMaster/').then(res => {
            profileMaster = res.data;
            localStorage.setItem("PROFILE_MASTER", JSON.stringify(res.data));
            if ( refreshRequired ) { 
                setProfileMaster(profileMaster);
               setRefreshTime(new Date().getTime())
            }
        });
    }

    const handleBulkEditSelection = (input, value) => {

        if (value.id == 1) {
            setCategorySelectionModel([]);
            setChannelProduct([]);
            setProduct(true);
        }
        else if (value.id == 2) {
            setBrandSelectionModel([]);
            setChannelBrand([]);
            setBrand(true)
        }
        else if (value.id == 3) {
            setSelectGrouping(true)
        }
        else if (value.id == 4) {
            setOpenLogo(true);
            getSelectedRows();
        }
        // setEditItem(value);
    }

    const saveBulkGroup = async () => {

        let channels = channel.filter(x => channelSelectionModel.includes(x.id))
        
        await axios.post('/channelAdmin/bulkChannelGroup', { channel: channels.map(x => +x.channelId), groupId: bulkGroupId }).then(res => {
            setSelectGrouping(false);
            setLoading(true);
            setBulkEdit(false);
            
           if(res.data){
            setChannelSelectionModel([])
            setTimeout(updateChannelList,2000)
           }
        }).catch(err => {
            setLoading(false);
        });
    }

    const updateChannelList = async () => {
        setLoading(true)
        let response = await getChannel({
            filterData: {
              pageSize: 10000,
              pageNumber: page,
              channelType: channelTypeId,
              sortData: {
                sortOrder:"DESC"
              },
            },
          },);
        setChannel(response)
        setLoading(false)
    }


    const handleTypeFilter = (input, value) => {
        filterValue.channelType = value;
        setFilterValues({ ...filterValue, channelType: filterValue.channelType })
        setFilter(true)
        applyFilters()
    }
    
    const handleDeletedFilter=(input,value)=>{

        filterValue.deletedAt = value;
        setFilterValues({ ...filterValue, deletedAt: filterValue.deletedAt })
        setFilter(true)
        applyFilters()
    }
  
    const handleBusinessFilter = (input, value) => {

        filterValue.isOEM = value;
        setFilterValues({ ...filterValue, isOEM: filterValue.isOEM })
        setFilter(true)
        applyFilters()
    }

    const handleActiveFilter = (e) => {

        // if (e.target.name === 'active') {
        //     filter.isActive = e.target.checked;
        //     setFilterData({ ...filter, isActive: filter.isActive });
        // }
        // else if (e.target.name === 'public') {
        //     filter.isPublic = e.target.checked;
        //     setFilterData({ ...filter, isPublic: filter.isPublic });
        // }
        // else if (e.target.name === 'startup') {
        //     filter.startup = e.target.checked;
        //     setFilterData({ ...filter, startup: filter.startup });
        // }

        console.log("filter:", channel, filter);

        filterChannel = channel.filter(item => {
            for (let key in filter) {
                if (item[key] === undefined || item[key] != filter[key])
                    return false;
            }
            return true;
        });

        // if (filterChannel.length == 0 && !filterFlag) {
        //     filterChannel = channel.filter(ch => ch.isActive == e.target.checked);
        // }
        setFilterChannel(filterChannel)
        setFilter(true);
    }

    const clearFilter = () => {
        setFilterData({
            // channelType: null,
            // isOEM: null,
            // isActive: false,
            // isPublic: false,
            // startup: false
        });
        setFilterValues({
            channelType: null,
            profile:[],
            isOEM: null,
            country: [],
            brand: [],
            product: [],
            speciality: [],
            revenue: null,
            deletedAt:null,
           
        })
        setFilterChannel([]);
        setFilter(false);
    }

    const handleChange = (input, value) => {

        filterValue.product = value;
        setFilterValues({ ...filterValue, product: filterValue.product })
        setFilter(true);
        applyFilters()
    }

    const handleCountry = (input, value) => {
        filterValue.country = value;
        setFilterValues({ ...filterValue, country: filterValue.country })
        setFilter(true);
        applyFilters()

    }

    const handleProfileFilter = (input, value) => {

        filterValue.profile = value;
        setFilterValues({ ...filterValue, profile: filterValue.profile })
        setFilter(true);
        applyFilters();
    }


    const applyFilters = () => {
        var toSet = channel.filter(item =>{
            
            if((filterValue.brand || []).length>0) { // brand filter
                let bIds = filterValue.brand.map(x => +x.id);
                let brand = item.channelBrand.filter(x => bIds.includes(x))
                if(!((brand || []).length > 0)) return false;
            } 

            if((filterValue.country || []).length > 0){ // country filter
                let cIds = filterValue.country.map(x => +x.id);
                if(!cIds.includes(+item.regAddress?.countryId)) return false;
            }
            if(filterValue.channelType && filterValue.channelType.name){ // ChannelType filter
                if(item.channelType != filterValue.channelType.name) return false;
            }

            if((filterValue.speciality || []).length>0) { // speciality filter
                let sIds = filterValue.speciality.map(x => +x.id);
                if(!sIds.includes(+item?.channelSpeciality)) return false;
            } 

            if(filterValue.grouping && filterValue.grouping.name){ // Group filter
                if(item.channelGroup != filterValue.grouping.name) return false;
            }

            if((filterValue.profile || []).length>0) { // Profile filter
                let pIds = filterValue.profile.map(x => +x.id);
                if(!pIds.includes(+item?.profileTypeId)) return false;
            } 

            if(filterValue.deletedAt && filterValue.deletedAt.name){ // DeletedAt filter
                if(item?.deletedAt.toString() !== filterValue.deletedAt.name) return false;
            }
            
            if(filterValue.isOEM && filterValue.isOEM.name){ // BusinessType filter
                if(item.isOEM !== filterValue.isOEM.name) return false;
            }

            if((filterValue.product || []).length>0) { // Product filter
                let pdIds = filterValue.product.map(x => +x.id);
                if(!pdIds.includes(+item?.channelProduct)) return false;
            } 

            return true;
        });

        if(toSet && toSet.length > 0) {
            setFilterChannel((toSet || []))
        } else {
            setFilterChannel((previous) => {
                return []
            });
        }
    }

    const handleBrand = (input, value) => {
        filterValue.brand = value;
        setFilterValues({ ...filterValue, brand: filterValue.brand })
        setFilter(true);
        applyFilters();
    }

    const handleSpeciality = (input, value) => {

        filterValue.speciality = value;
        setFilterValues({ ...filterValue, speciality: filterValue.speciality })
        setFilter(true);
        applyFilters();

        
    }

    const handleGroupFilter = (input, value) => {
        filterValue.grouping = value;
        setFilterValues({ ...filterValue, grouping: filterValue.grouping })
        setFilter(true);
        applyFilters();
    }

    const handleRevenueFilter = (input, value) => {
        filterValue.revenue = value;
        setFilterValues({ ...filterValue, revenue: filterValue.revenue[0] })
        console.log("reve:", filterValue);

        filter.deletedAt = value.name;
        setFilterData({ ...filter, deletedAt: filter.deletedAt });

        if (filterFlag && filterParent !== 'deletedAt') {
            filterChannel = filterBase.filter(item => {
                for (let key in filter) {
                    if (item[key] === undefined || item[key] != filter[key])
                        return false;
                }
                return true;
            });
        } else {
            filterChannel = channel.filter(item => {
                for (let key in filter) {
                    if (item[key] === undefined || item[key] != filter[key])
                        return false;
                }
                return true;
            });
        }

        setFilterChannel(filterChannel)
        if (!filterFlag) {
            setFilter(true);
            setFilterParent('deletedAt');
            setFilterBase(filterChannel);
        }

    }
// console.log('filtervalue',filterValue)
    const saveTurnOver = async (value) => {
        

        let revenueOption = [{ id: 1, value: '<1M€' }, { id: 2, value: '1-10M€' }, { id: 3, value: '10-100M€' }, { id: 4, value: '>100M€' }]


        value.map(x => {
            x.annualYear = new Date().getFullYear() - (+x.annualYear - 1);
            let revOpt = revenueOption.filter(rev => rev.id == x.AnnualTurnOver);
            if (revOpt.length)
                x.AnnualTurnOver = revOpt[0].value;
        })

        let reven = _.sortBy(value, (e) => {
            return e.annualYear
        });


        setChannel((channel) => {
            return channel.map(x => {
                if (x.id === channelId) {
                    let lastReven = reven[reven.length - 1];
                    x.revenue = value;
                    x.channelRevenue = lastReven?.AnnualTurnOver ?? lastReven?.annualTurn ?? '';

                     
                }
                
                return x;
            })

        })
        if(filterFlag){
            setFilterChannel((filterChannel)=>{
                
                return filterChannel.map(x => {
                    if (x.id === channelId) {
                        let lastReven = reven[reven.length - 1];
                        x.revenue = value;
                        x.channelRevenue = lastReven?.AnnualTurnOver ?? lastReven?.annualTurn ?? '';
                    }
    
                    return x;
                })
            })
        }
         
        console.log("saverev:", channel);
        setAnnualTurn(false);
    };

    const onFilterChange = (e) => {

        console.log("delfilter:", e)


        setFilterModel({ ...e, quickFilterLogicOperator: "and" })
        if (e.items.length && e.items[0].value) {

            setColFilter(true);
        }
        else
            setColFilter(false);
    }

    const closeAddBrandPopup = () => {
        setAddBrand(false);
    }

    const handleAddBrand = async (input, value) => {
        // console.log("addbrand:", input, value);
        if (input == 'brands') {
            console.log('value', value);
            setLoading(true);
            if (value?.isNew === '1') {
                let data = {
                    brandName: value.name,
                    isActive: '1',
                    brandImage: value.documentpath
                }
                const editBrand = await axios.post('master/brand', data);
                if (editBrand.data) {
                    console.log("editBrand:", editBrand);

                    setBrandMaster((oldRows) => [{ id: editBrand.data['id'], brandName: editBrand.data['brandName'], document: { documentUrl: value.documentpath } }, ...oldRows]);
                    setLoading(false);
                    setAddBrand(false);

                    // ToastsStore.success('Saved Successfully!');
                    // search = null;
                    // setSearch(search);
                    // sort = 'desc';
                    // setSort(sort);
                    // callPendingBrand();
                } else {
                    setLoading(false);
                    setAddBrand(false)
                    // ToastsStore.error('Something Went Wrong!');
                }
            }
            // else {

            // setBrandMaster((brandMaster) => {
            //     return brandMaster.map(x => {
            //         if (x.id == value.id) {
            //             x.brandName = value.name;
            //             x.document.documentUrl = value.documentUrl
            //         }
            //         return x;
            //     })
            // })

            // }
            setLoading(false);
        }

    }

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}



    return (
        
      <div>
        <ToastsContainer
          position="top_right"
          autoClose={5000}
          store={ToastsStore}
        />
         {/* <ConfirmDialog open={validatePop} value={validateData} handleSave={closeValidateError} handleClose={closeValidateError} /> */}
         <Modal
          className="modal-dialog-centered"
          isOpen={validatePop}
          toggle={closeValidateError}
        >           
          <ModalHeader toggle={closeValidateError}>Add Channel Validation </ModalHeader>
          <ModalBody>
           <div>
                {  validateData.message != null && validateData.message.split('\n').map((subStr) => {
                   let vErr = (subStr).split("*");
                   if(vErr.length == 1){
                        return <><p><strong>{subStr}</strong></p></>
                   } else {
                        return <><p> <span style={{color:"red"}}>*</span> {vErr[1]}</p></>
                   }                      
                }) }</div>   
          </ModalBody>
          <ModalFooter>
           
            <Button color="primary" type="button" onClick={closeValidateError}>
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          className="modal-dialog-centered"
          isOpen={isOpen}
          toggle={toggleModal}
        >           
          <ModalHeader toggle={toggleModal}>Delete Confirmation</ModalHeader>
          <ModalBody>Are you sure you want to delete this Channel?</ModalBody>
          <ModalFooter>
            <Button color="primary" type="button" onClick={handleDelete}>
              Delete
            </Button>
            <Button color="primary" type="button" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className="modal-dialog-centered"
          isOpen={isOpenDeleteCompanyModal}
          toggle={toggleModalIsCompanyRelate}
        >
          <ModalHeader toggle={toggleModalIsCompanyRelate}>Delete Confirmation</ModalHeader>
          <ModalBody>
            <div>
                {/* <Checkbox {label}/> */}
                <FormControlLabel
                    control={<Radio checked={deleteCompany.clearAllCompoanies} onChange={allCompanyDelete}/>}
                    label="Clear the field for all relevant companies"
                />
                   <div style={{textAlign:'center'}}> OR </div>
                <FormControlLabel
                    control={<Radio checked={deleteCompany.clearSelectedCompanies} onChange={selectedCompanyDelete}/>}
                    label={`Replace ${selectedCompanyName} with any other group`}
                /> 
                {deleteCompany.clearSelectedCompanies === true ? (
                    <div>
                <Select
                    open={openDropDown}
                    onClose={() => setOpenDropDown(false)} // Close the dropdown when clicking outside
                    onOpen={() => setOpenDropDown(true)} // Open the dropdown when clicking the checkbox
                    value={selectedValue}
                    onChange={handleDropdownChange}
                >
                    {
                        groupMaster.map((i)=><MenuItem key={i.name} value={i.name}>{i.name}</MenuItem>)
                    }
                   
                </Select>
                </div>
                ):(<></>)}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="button" onClick={updateButton}>
              Update
            </Button>
            <Button color="primary" type="button" onClick={toggleModalIsCompanyRelate}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        {openAddBrand && (
          <AddBrand
            aws_folderName="brandlogo"
            opneadd={openAddBrand}
            closeModalPopup={closeAddBrandPopup}
            value={editBrand}
            isEdit={isBrandEdit}
            brandName={null}
            handleChangeSelect={handleAddBrand}
          />
        )}

        {selectGroup && SelectGroup()}
        {openAddress && Address()}
        {openBrand && Brand()}
        {openProduct && Product()}
        {openLogo && ChannelLogo()}
        {openSpeciality && Speciality()}
        {openDesc && Description()}
        {grouping && ChannelGroup()}
        {openYourChannel && YourChannels()}

        {bulkEdit && BulkEdit()}

        {location && Location()}
        {openSocial && SocialProfile()}
        {openContactModal && contactDetail()}
        {openUpdateContactModal && updateContactModal()}

        {isAnnualTurn && (
          <AnnualTurnOver
            open={isAnnualTurn}
            currentValue={revenue}
            handleClose={handleClose}
            handleSave={saveTurnOver}
          />
        )}

        {loading ? (
          <Spinner />
        ) : (
          <>
            <Box
              sx={{
                height: "80vh",
                width: "100%",
                "& .actions": {
                  color: "text.secondary",
                },
                "& .textPrimary": {
                  color: "text.primary",
                },
              }}
            >
              <br />
              <div className="row">
                {/* <div className="col-lg-1 thm"> */}
                {/* <div className="form-group">
                  <Autocomplete
                    sx={{ width: 100 }}
                    size="small"
                    disableClearable={true}
                    id="channelType"
                    input="channelType"
                    options={[
                      { id: "1", name: "Vendor" },
                      { id: "2", name: "Distributor" },
                      { id: "3", name: "Retailer" },
                      { id: "4", name: "Reseller" },
                    ]}
                    getOptionLabel={(option) => option.name}
                    value={filterValue.channelType}
                    onChange={handleTypeFilter}
                    renderInput={(params) => (
                      <TextField {...params} label="Type" />
                    )}
                  />
                </div> */}
                {/* </div> */}

                {/* <div className="col-lg-1 thm"> */}
                {/* <div className="form-group">
                  <Autocomplete
                    sx={{ width: 100 }}
                    size="small"
                    disableClearable={true}
                    id="businessType"
                    input="businessType"
                    options={businessTypes}
                    getOptionLabel={(option) => option.name}
                    value={filterValue.isOEM}
                    onChange={handleBusinessFilter}
                    renderInput={(params) => (
                      <TextField {...params} label="B2C/B2B" />
                    )}
                  />
                </div> */}
                {/* </div> */}

                {/* <div className="col-lg-2 thm"> */}
                {/* <div className="form-group">
                  <Autocomplete
                    sx={{ width: 250 }}
                    size="small"
                    multiple
                    id="product"
                    input="product"
                    limitTags={2}
                    options={categoryMaster}
                    getOptionLabel={(option) => option.category}
                    value={filterValue.product}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Product" />
                    )}
                  />
                </div> */}
                {/* </div> */}

                    <br />
                    <div className="row channel-master">
                        {/* <div className="col-lg-1 thm"> */}
                        <div className="form-group">
                            <Autocomplete sx={{ width: 100 }}
                                size="small"
                                id="channelType"
                                input='channelType'
                                options={channelTypes || []}
                                getOptionLabel={(option) => option.name}
                                value={filterValue.channelType}
                                onChange={handleTypeFilter}
                                renderInput={(params) => <TextField {...params} label="Type" />}
                            />
                        </div>
                        <div className="form-group">
                            <Autocomplete sx={{ width: 100 }}
                                size="small"
                                id="deletedAt"
                                input='deletedAt'
                                options={[
                                    { id: "1", name: 'true' },
                                    { id: "2", name: 'false' },
                                  ]}

                                getOptionLabel={(option) => option.name}
                                value={filterValue.deletedAt}
                                onChange={handleDeletedFilter}
                                renderInput={(params) => <TextField {...params} label="Deleted" />}
                            />
                        </div>
                        {/* </div> */}

                        {/* <div className="col-lg-1 thm"> */}
                        <div className="form-group">
                            <Autocomplete sx={{ width: 110 }}
                                size="small"
                                id="businessType"
                                input='businessType' 
                                options={businessTypes || []}
                                getOptionLabel={(option) => option.name}
                                value={filterValue.isOEM}
                                onChange={handleBusinessFilter}
                                renderInput={(params) => <TextField {...params} label="B2C/B2B" />}
                            />
                        </div>
                        {/* </div> */}

                        {/* <div className="col-lg-2 thm"> */}
                        <div className="form-group">

                            <Autocomplete sx={{ width: 230 }}
                                size="small"
                                multiple
                                id="product"
                                input='product'
                                limitTags={2}
                                options={categoryMaster || []}
                                getOptionLabel={(option) => option.category}
                                value={filterValue.product}
                                onChange={handleChange}
                                renderInput={(params) => <TextField {...params} label="Product" />}
                            />

                        </div>
                        {/* </div> */}

                        {/* <div className="col-lg-2 thm"> */}
                        <div className="form-group">

                            <Autocomplete sx={{ width: 250 }}
                                size="small"
                                multiple
                                id="country"
                                input='country'
                                limitTags={2}
                                options={countryList || []}
                                getOptionLabel={(option) => option.name}
                                value={filterValue.country}
                                onChange={handleCountry}
                                renderInput={(params) => <TextField {...params} label="Country" />}
                            />

                        </div>
                        {/* </div> */}

                        {/* <div className="col-lg-2 thm"> */}
                        <div className="form-group">
                            <Autocomplete sx={{ width: 250 }}
                                size="small"
                                multiple={true}
                                id="brand"
                                input='brand'
                                limitTags={2}
                                options={brandMaster || []}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.brandName}
                                value={filterValue.brand}
                                onChange={handleBrand}
                                renderInput={(params) => <TextField {...params} label="Brand" />}
                            />
                        </div>
                        {/* </div> */}

                        {/* <div className="col-lg-2 thm"> */}
                        <div className="form-group">
                            <Autocomplete sx={{ width: 250 }}
                                size="small"
                                multiple={true}
                                id="speciality"
                                input='speciality'
                                limitTags={2}
                                options={specialityMaster || []}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                value={filterValue.speciality}
                                onChange={handleSpeciality}
                                renderInput={(params) => <TextField {...params} label="Speciality" />}
                            />
                        </div>

                        <div className="form-group">
                            <Autocomplete sx={{ width: 250 }}
                                size="small"
                                multiple={true}
                                id="profile"
                                input='profile'
                                limitTags={2}
                                options={profileMaster || []}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                value={filterValue.profile}
                                onChange={handleProfileFilter}
                                renderInput={(params) => <TextField {...params} label="Profiles" />}
                            />
                        </div>

                        {/* </div> */}

                        {/* <div className="col-lg-2 thm"> */}
                        <div className="form-group">
                            <Autocomplete sx={{ width: 150 }}
                                size="small"
                                id="group"
                                key={groupMaster.map((_,i)=>i)}
                                input='group'
                                options={groupFilter || []}
                                getOptionLabel={(option) => option.name}
                                value={filterValue.grouping}
                                onChange={handleGroupFilter}
                                renderInput={(params) => <TextField {...params} label="Channel Group" />}
                            />
                        </div>
                        {/* </div> */}

                        {/* <div className="form-group">
                            <Autocomplete sx={{ width: 150 }}
                                size="small"
                                disableClearable={true}
                                id="revenue"
                                input='revenue'
                                options={revenueMaster}

                                getOptionLabel={(option) => option.name}
                                value={filterValue.revenue}
                                onChange={handleRevenueFilter}
                                renderInput={(params) => <TextField {...params} label="Revenue" />}
                            />
                        </div> */}

                        <Button className='mx-1' sx={{ height: 38, widht: 50 }} variant="contained" onClick={clearFilter} type="button" >Reset</Button>
                    </div>
                    <Card style={{ height: '100vh', width: '100%' }}>             
                    <DataGrid
                        rows={filterFlag ? filterChannel : channel}
                        columns={companyCol}
                        onFilterModelChange={onFilterChange}
                        checkboxSelection
                        selectionModel={channelSelectionModel}
                        onSelectionModelChange={setChannelSelectionModel}
                        disableSelectionOnClick
                        // autoHeight
                        editMode="cell"
                        rowModesModel={rowModesModel}
                        onRowEditStart={handleRowEditStart}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}

                        onCellClick={handleEdit}

                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }

                        filterModel={filterModel}
                        initialState={{
                            filter: {
                                filterModel: filterModel
                                //    {
                                //     items: [],
                                //     quickFilterLogicOperator: GridLinkOperator.Or,
                                //   },
                            },
                        }}
                        // onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}

                //onCellEditCommit={handleChannelCommit}

                components={{
                  Toolbar: EditToolbar,
                }}
                componentsProps={{
                  toolbar: {
                    isLoaded,
                    setDeleted,
                    isDeleted,
                    isTestChannel,
                    setTestChannel,
                    setChannel,
                    setRowModesModel,
                    channel,
                    handleSave,
                    handleSaveToDB,
                    setFilter,
                    setGrouping,
                    setBulkEdit,
                    channelSelectionModel,
                    filterChannel,
                    colFilter,
                    setIsOpen,
                    setButtons
                  },
                }}
                experimentalFeatures={{ newEditingApi: true }}
                // pagination
                // paginationMode="server"
                // onPageSizeChange={(event) => {
                //   setPageSize(event);
                // }}
                // onPageChange={(event) => {
                //   setPage(event + 1);
                //   if (page > currentPage) setCurrentPage(page);
                // }}
                // setPage={page}
                // rowCount={totalRecord}
                // onSortModelChange={(event) =>
                //   setSort((prev) => ({
                //     ...prev,
                //     channelName: event[0].sort.toUpperCase(),
                //   }))
                // }
              />
              </Card>
              </div>
            </Box>
          </>
        )}
      </div>
    );
}
