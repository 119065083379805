import React, {useState,useEffect} from 'react';
import {Card,CardBody,Row,Col} from "reactstrap";
import {InputLabel,Select,TextField,FormControl} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { useForm} from "react-hook-form";
import {FormAutocomplete, FormInput} from "_components/FormElements/FormInput";
import { commonService } from '_services/common.service';
import { Spinner} from 'views/Hoc/Spinner';
import { history } from '_helpers/history';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { couponFormSchema } from '../profile/validation';
import { ToastsContainer, ToastsStore } from 'react-toasts';

const Couponform = (props) => {

  const [state, setState] = useState({
      
    planOptions : [{id:1,name:"Free"},{id:7,name:"LITE"},{id:2,name:"STARTER"},{id:5,name:"PRO"},{id:4,name:"ADVANCED"},{id:3,name:"TRIAL"}],
    planValue : [],
    typeOptions : [{id:2,name:"VENDOR"},{id:3,name:"DISTRIBUTOR"},{id:4,name:"RETAILER"}],
    typeValue : [],
    subTypeOptions : [{id:1,name:"New"},{id:2,name:"Renewal"},{id:3,name:"UpGrade"}],
    subTypeValue : [],
    statusOptions : [{id:"1",name:"Active"},{id:'0',name:"InActive"}],
    statusValue : [],
    periodOptions : [{id:1,name:"Month"},{id:2,name:"Year"}],
    periodValue : [],
    discountOptions : [{id:2,name:"Percentage",  id:1,name:"Amount"}],
    discountValue : [],                
    couponDesc:null,
    couponCode:null,
    couponOfferValue:null,
    userThreshold:null,
    usageThreshold:null,
    fromDate:new Date('2014-08-18T21:11:54'),        
    dateto:new Date('2014-08-18T21:11:54'),        
  });

  const { register, handleSubmit, control, errors, watch, formState } = useForm({      
    mode:'all',  
    resolver: yupResolver(couponFormSchema),
    //defaultValues: { couponDesc: 'aaaa', discountValue: {id:1, name:'Amount'}, genericType: '', type: 0 }
  });

  console.log('errors ===== ', errors)
  const watchAll = watch();
  const {couponId,getCouponListing} = props;

  const [loading,setLoading] = useState(false);
  
   

      
    //const [fromDate, setFromDate] = React.useState(new Date('2014-08-18T21:11:54'));
    //const [toDate, setToDate] = React.useState(new Date('2014-08-18T21:11:54'));

    useEffect(() => {
      getSubscriptionPlans();
      if(couponId!=0)
      {
        (async () => {
        //setLoading(true);      
        console.log('couponId =: ', couponId);       
        let coupons = await commonService.getServices('channelAdmin/channelCoupons/'+couponId);  
        console.log("coupons:",coupons.data[0]); 
        state.couponDesc = coupons.data[0].couponDesc
        state.couponCode = coupons.data[0].couponCode
        setState({...state});
        setState({...state,
          planValue:[{id:coupons.data[0].subscriptionPlan.subscriptionPlanId,name:coupons.data[0].subscriptionPlan.subscriptionPlanType}],
          subTypeValue:{id:coupons.data[0].subscriptionType.subscriptionTypeId,name:coupons.data[0].subscriptionType.subscriptionType},
          typeValue:{id:coupons.data[0].channelType.channelTypeId,name:coupons.data[0].channelType.channelType},
          periodValue:[{id:coupons.data[0].subscriptionPeriodId,name:coupons.data[0].subscriptionPeriodId==1?'Month':'Year'}],
          statusValue:{id:coupons.data[0].isActive,name:coupons.data[0].isActive==1?'Active':'InActive'},
          discountValue:{id:+coupons.data[0].discountUnit,name:coupons.data[0].discountUnit==2?'Percentage':'Amount'},
          
          
          couponOfferValue:coupons.data[0].couponOfferValue,
          userThreshold:coupons.data[0].userThreshold,
          usageThreshold:coupons.data[0].usageThreshold,
          fromDate:coupons.data[0].fromDate,
          dateto:coupons.data[0].dateto,
        
        });             
       
        })();
        
      }
      else
      {
        setState({...state,planOptions : [{id:1,name:"Free"},{id:7,name:"LITE"},{id:2,name:"STARTER"},{id:5,name:"PRO"},{id:4,name:"ADVANCED"},{id:3,name:"TRIAL"}],
        planValue : [],
        typeOptions : [{id:2,name:"VENDOR"},{id:3,name:"DISTRIBUTOR"},{id:4,name:"RETAILER"}],
        typeValue : [],
        subTypeOptions : [{id:1,name:"New"},{id:2,name:"Renewal"},{id:3,name:"UpGrade"}],
        subTypeValue : [],
        statusOptions : [{id:"1",name:"Active"},{id:'0',name:"InActive"}],
        statusValue : [],
        periodOptions : [{id:1,name:"Month"},{id:2,name:"Year"}],
        periodValue : [],
        discountOptions : [{id:2,name:"Percentage", id:1,name:"Amount"}],
        discountValue : [],                
        couponDesc:null,
        couponCode:null,
        couponOfferValue:null,
        userThreshold:null,
        usageThreshold:null,
        fromDate:new Date('2014-08-18T21:11:54'),        
        dateto:new Date('2014-08-18T21:11:54')});        
      }
    }, [couponId]); 
  
    const  getSubscriptionPlans = async() => {
      await commonService.getServices('/channelAdmin/getSubscriptionPlan').then(res=>{
          setState({...state,planOptions : res.data});
        });
    }
    const handleChange = input => e => {          
    setState({...state, [input] : e.target.value});         
     };

      
      const save = async() => {
                
        let data={
        isActive:state.statusValue.id,
        subscriptionTypeId:+state.subTypeValue.id,
        channelTypeId:+state.typeValue.id,                 
        discountUnit:+state.discountValue.id,
        couponOfferValue:+state.couponOfferValue,
        userThreshold:+state.userThreshold,
        usageThreshold:+state.usageThreshold,
        couponDesc:state.couponDesc,
        couponCode:state.couponCode,
        fromDate:state.fromDate,
        toDate:state.dateto,
        }
console.log('data listing == ', data);
        if(couponId==0)
        {
          Object.assign(data,{subscriptionPlans:state.planValue.map(x => x.id),
            subscriptionPeriods:state.periodValue.map(x => x.id)});
        }
        else
        {
          Object.assign(data,{ subscriptionPlanId:+state.planValue[0].id,
            subscriptionPeriodId:+state.periodValue[0].id})
        }
        
        setLoading(true);   
        if(couponId==0){
          try{
            let resp = await commonService.postService(data,'channelAdmin/addCoupon').then(res => {
              console.log('res =: ', res)
            });  
                
            closeDrawer();     
            getCouponListing();
          } catch(error) {
            if (error.response && error.response.status === 422) {
            //  console.log('Unprocessable Entity:', error.response.data[0].errors.property +' : '+error.response.data[0].errors[0].message);
              ToastsStore.error(error.response.data[0].property +' : ' + error.response.data[0].errors[0].message);
              setLoading(false);       
            } else {
                console.log('Error:', error.message);
            }
          }
        } else {
          await commonService.putService('channelAdmin/channelCoupons/'+couponId,data); 
          setLoading(false);        
          closeDrawer();   
          getCouponListing();
        } 
   
       
      }    

      
      const handleFromDate = (date) => {        
        state.fromDate=date;
        setState({...state,fromDate:state.fromDate})
        //setFromDate(date);
      };

      const handleToDate = (date) => {        
        state.dateto=date;
        setState({...state,dateto:state.dateto})
        //setToDate(date);
      };

      const handleChangeSelect = (input,value) => {       
          setState({...state, [input]:value});  
      }

      const [showDrawer,setshowDrawer]=useState(false);
      console.log("text  ssds",showDrawer);
      const closeDrawer =()=>{
        props.onClose();
        setshowDrawer(false);
      }

    return (
      
        <div>    
        <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />   
        {loading? <Spinner />: (          
            <Card id='Coupon'>
                <CardBody>
                <form className="coupon-form" noValidate autoComplete="off" onSubmit={handleSubmit(save)}>
                    <Row>
                        <Col lg="6" className="mt-4">
                        <div className="form-group">
                        <FormInput
                          id="couponDesc"
                          type="text"
                          name="couponDesc"
                        //  label="First Name *"
                          register={register}
                          defaultValue={state.couponDesc}
                          error={errors.couponDesc}
                          value={state.couponDesc}
                          onChange={handleChange('couponDesc')}
                        />

                        
                        </div>
                        </Col>
                        <Col lg="6" className="mt-4">
                        

                          <FormInput
                          id="couponCode"
                          type="text"
                          name="couponCode"
                        //  label="First Name *"
                          register={register}
                          defaultValue={state.couponCode}
                          error={errors.couponCode}
                          value={state.couponCode}
                          onChange={handleChange('couponCode')}
                        />

                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" className="mt-4">
                        
                        <FormInput
                          id="couponOfferValue"
                          type="text"
                          name="couponOfferValue"
                          register={register}
                          defaultValue={state.couponOfferValue}
                          error={errors.couponOfferValue}
                          value={state.couponOfferValue}
                          onChange={handleChange('couponOfferValue')}
                        />

                        </Col>

                        <Col lg="6" className="mt-4">
                        <FormControl variant="outlined">
                        <FormAutocomplete
                        id="discountValue"
                        name="discountValue"
                        //label="Coupon Discount Unit"
                        options={state.discountOptions}
                        placeholder="Coupon Discount Unit"
                        input='discountValue'
                       errMessage={'Please Enter Coupon Discount Unit'}
                       // error={errors.discountValue}
                        //error={!errors.subplan || state.subplan.length ?false:true}
                        value={state.discountValue}
                        defaultValue={state.discountValue}
                        handleChangeSelect={handleChangeSelect}
                        control={control}  
                      //  register={register}               
                        //multiple
                        disableCloseOnSelect
                        blurOnSelect
                        getOptionSelected={(options, value) => options.name === value.name}
                        />
                        
                          </FormControl>
                        </Col>
                    </Row>



                    <Row>
                        <Col lg="6" className="mt-4">
                        <span>Coupon From Date</span>
                        <div className="awd-date">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="datefrom"
                                name="datefrom"
                                error={errors.datefrom}
                                //label="Coupon From Date"
                                value={state.fromDate}
                                onChange={handleFromDate}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                        </MuiPickersUtilsProvider>
                        </div>
                        </Col>
                        <Col lg="6" className="mt-4">
                        <span>Coupon To Date</span>
                        <div className="awd-date">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="dateto"
                                name="dateto"
                                error={errors.dateto}
                                // label="Coupon To Date"
                                value={state.dateto}
                                defaultValue={state.dateto}
                                onChange={handleToDate}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                        </MuiPickersUtilsProvider>
                        </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="6" className="mt-4">
                        <FormControl variant="outlined">
                        <FormAutocomplete
                        id="statusValue"
                        name="statusValue"
                        //label="Coupon Active/Inactive"
                        options={state.statusOptions}
                        placeholder="Coupon Active/Inactive"
                        input='statusValue'
                        errMessage={'Please Select Coupon Active/Inactive'}
                        //error={!errors.subplan || state.subplan.length ?false:true}
                        value={state.statusValue}
                        //error={errors.statusValue}
                        defaultValue={state.statusValue}
                        handleChangeSelect={handleChangeSelect}
                        control={control}     
                      //  register={register}                 
                        //multiple
                        disableCloseOnSelect
                        blurOnSelect
                        getOptionSelected={(options, value) => options.name === value.name}
                        />
                        
                            </FormControl>
                        </Col>
                        <Col lg="6" className="mt-4">
                        <FormControl variant="outlined">
                        <FormAutocomplete
                        id="subTypeValue"
                        name="subTypeValue"
                        //label="Coupon subscription Type"
                        options={state.subTypeOptions}
                        placeholder="Coupon subscription Type"
                        input='subTypeValue'
                        errMessage={'Please Select Coupon subscription Type'}
                        //error={errors.subTypeValue}
                        //error={!errors.subplan || state.subplan.length ?false:true}
                        value={state.subTypeValue}
                        defaultValue={state.subTypeValue}
                        handleChangeSelect={handleChangeSelect}
                        control={control}  
                      //  register={register}                    
                        //multiple
                        disableCloseOnSelect
                        blurOnSelect
                        getOptionSelected={(options, value) => options.name === value.name}
                        />                        
                        </FormControl>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="6" className="mt-4">
                        <FormControl variant="outlined">
                        <FormAutocomplete
                        id="typeValue"
                        name="typeValue"
                        label="Coupon Channel Type"
                        options={state.typeOptions}
                        placeholder="Coupon Channel Type"
                        input='typeValue'
                        errMessage={'Please Select Coupon Channel Type'}
                        //error={!errors.subplan || state.subplan.length ?false:true}
                        value={state.typeValue}
                       // error={errors.typeValue}
                        defaultValue={state.typeValue}
                        handleChangeSelect={handleChangeSelect}
                        control={control}  
                       // register={register}                    
                        //multiple
                        disableCloseOnSelect
                        blurOnSelect
                        getOptionSelected={(options, value) => options.name === value.name}
                        />                        
                        </FormControl>
                        </Col>
                        <Col lg="6" className="mt-4">

                        <FormControl variant="outlined">

                        <FormAutocomplete
                        id="planValue"
                        name="planValue"
                        label="Select Subscription Plan"
                        options={state.planOptions}
                        placeholder="Select Subscription Plan"
                        input='planValue'
                        errMessage={'Please Select Subscription Plan'}
                        //error={!errors.subplan || state.subplan.length ?false:true}
                        value={state.planValue}
                        //error={errors.planValue}
                        defaultValue={state.planValue}
                        handleChangeSelect={handleChangeSelect}
                        control={control}   
                      //  register={register}                   
                        multiple
                        disableCloseOnSelect
                        blurOnSelect
                        getOptionSelected={(options, value) => options.name === value.name}
                        />
                        
                            </FormControl>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="6" className="mt-4">
                        <FormInput
                          id="userThreshold"
                          type="text"
                          name="userThreshold" 
                          register={register}                         
                          error={errors.userThreshold}
                          value={state.userThreshold}
                          defaultValue={state.userThreshold}
                          onChange={handleChange('userThreshold')}
                        />                       

                        </Col>
                        <Col lg="6" className="mt-4">
                       
                        <FormInput
                          id="usageThreshold"
                          type="text"
                          name="usageThreshold"
                          register={register}    
                          error={errors.usageThreshold}
                        //  errMessage={'Please Enter Usage Threshold'}
                          value={state.usageThreshold}
                          defaultValue={state.usageThreshold}
                          onChange={handleChange('usageThreshold')}
                        />

                        </Col>
                    </Row>

                    <Row>
                    <Col lg="12" className="mt-4">
                        
                        <FormAutocomplete
                        id="SubPeriod"
                        name="SubPeriod"
                        label="Coupon Subscription Period"
                        options={state.periodOptions}
                        placeholder="Coupon Subscription Period"
                        input='periodValue'
                        errMessage={'Please Select Coupon Subscription Period'}
                        //error={!errors.subplan || state.subplan.length ?false:true}
                        value={state.periodValue}
                        defaultValue={state.periodValue}
                        handleChangeSelect={handleChangeSelect}
                        control={control}                 
                        multiple
                        disableCloseOnSelect
                        blurOnSelect
                        getOptionSelected={(options, value) => options.name === value.name}
                        />

                        {/* <FormControl variant="outlined">
                        <FormAutocomplete
                        id="periodValue"
                        name="periodValue"
                        label="Coupon Subscription Period"
                        options={state.periodOptions}
                        placeholder="Coupon Subscription Period"
                        input='periodValue'
                        error={errors.periodValue}  
                      //  register={register}     
                     //   errMessage={'Please Select Coupon Subscription Period'}
                        //error={!errors.subplan || state.subplan.length ?false:true}
                        value={state.periodValue}
                        defaultValue={state.periodValue}
                        handleChangeSelect={handleChangeSelect}
                        control={control}                 
                        multiple
                        disableCloseOnSelect
                        blurOnSelect
                        getOptionSelected={(options, value) => options.name === value.name}
                        />
                        
                            </FormControl> */}
                        </Col>
                    </Row>
                    <div className="text-center mt-4">
            <button type="submit"  className="btn btn-primary" >Save</button>
            <button onClick={e => {e.preventDefault(); closeDrawer()}}  className="btn btn-warning" >Cancel</button>
          </div> 
                </form>
                </CardBody>
            </Card>
            )};
        </div>
                
    );
};

export default Couponform;