import React, { useEffect, useState } from "react";
import { FormInput, FormFileInput, FormSelect, FormButton, FormMultiSelect, FormTextArea, FormCheckBox, FormAutocomplete, FormRadio, NewFieldAutocomplete, } from "_components/FormElements/FormInput";
import {
  Col, Row, Card, CardBody, CardHeader, Container, FormGroup, Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup, ListGroupItem, ListGroup
} from "reactstrap";
import FormHelperText from '@material-ui/core/FormHelperText';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';


import 'date-fns';
import {

  InputAdornment,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';

import { ProfileUploader, BannerUploader, MailBannerUploader } from 'views/Hoc/ProfileUpload';
import { commonService } from '_services/common.service';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker, KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import axios from 'axios';

import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import ConfirmDialog from 'views/Hoc/Dialog/GlobalDialog';
import { EventSchema } from './validation';
import EventPackage from "./EventPackage";
import Packages from "./Packages";
import PackageAddon from "./PackageAddon";
import { Spinner } from "views/Hoc/Spinner";
import { history } from "_helpers";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import CopyPackages from "./CopyPackage";
import EventsHostel from "./Events_Hotel"
import Tabs_Controller from "./Tabs_Controller";
import { SelectAutocomplete } from "_components/FormElements/FormInput";
import SelectHotel from "./SelectHotel";
import HotelBookingUser from "./EventHotelBooking";

var _ = require('lodash');

const Events = (props) => {
  const { register, handleSubmit, control, errors, watch, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(EventSchema),
  });


  let adminUser = JSON.parse(localStorage.getItem('CHuser'));

  let seller = [{ id: 2, name: 'VENDOR', status: false, withPackage: false }, { id: 3, name: 'DISTRIBUTOR', status: false, withPackage: false }, { id: 4, name: 'RETAILER', status: false, withPackage: false }, { id: 5, name: 'RESELLER', status: false, withPackage: false }];
  let buyer = [{ id: 2, name: 'VENDOR', status: false, withPackage: false }, { id: 3, name: 'DISTRIBUTOR', status: false, withPackage: false }, { id: 4, name: 'RETAILER', status: false, withPackage: false }, { id: 5, name: 'RESELLER', status: false, withPackage: false }];

  let other = [{ id: 2, name: 'OTHER', status: false, withPackage: false }];

  const [packDeleteAlert, setPackDeleteAlert] = useState(false);
  const [packageUsedCount, setPackageUsedCount] = useState(null);
  const [countryForSelectHotel, setCountryForSelectHotel] = useState(null);

  const [state, setState] = useState({
    eventId: null,
    eventName: '',
    eventShortName: '',
    eventDescription: '',
    eventShortDescription:'',
    fromDate: null,
    toDate: null,
    fromTime: null,
    toTime: null,
    timeZone: '',
    address: '',
    country: [],
    city: '',
    zipCode: '',
    regTemplate: '',

    approveNewUserTemplate: '',
    approveExistingUserTemplate: '',
    dismissUserTemplate: '',
    associateUserTemplate: '',
    associateChannelTemplate: '',

    sellerEventRegisterURL: '',
    buyerEventRegisterURL: '',
    eventSiteUrl: '',
    eventMapUrl: '',
    venuetext: '',


    type: '1',
    eventLogo: '',
    eventLogoId: '',
    eventBanner: '',
    eventMailBanner: '',
    eventListingImage:'',
    eventListingImageId:'',
    eventSocialImage:'',
    eventSocialImageId:'',
    eventShareImage:'',
    eventShareImageId:'',
    eventBannerId: '',
    eventMailBannerId: '',
    eventSignupBanner: '',
    eventSignupBannerId: '',
    eventCatalystId: null,
    sellerOption: seller,
    buyerOption: buyer,
    otherOption: other,

    vendorStatus: '1',
    buyerStatus: '1',

    vendorSecondaryToRegister: '1',
    buyerSecondaryToRegister: '1',
    multiMeet: '1',

    countryOptions: [],
    buyerPackages: [],
    sellerPackages: [],
    otherPackages: [],
    deletedPackages: [],

    vendorListDate: null,
    buyerListDate: null,
    vendorMeetingDate: null,
    buyerMeetingDate: null,
    vendorListMsg: null,
    buyerListMsg: null,
    vendorSchedulerMsg: null,
    buyerSchedulerMsg: null
  });

  const [packageData, setPackageData] = useState({});

  const [open, setOpen] = useState(false);
  const [copy, setCopy] = useState(false);
  const [addonOpen, setAddonOpen] = useState(false);

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState('');
  const [imageBan, setBanner] = useState('');
  const [imageEmailBan, setEmailBanner] = useState('');
  const [listingImage, setListingImage] = useState('');
  const [socialImage, setSocialImage] = useState('');
  const [shareImage, setShareImage] = useState('');

  const [imageSrc, setImageSrc] = useState('');
  const [imageSrcBanner, setImageBanner] = useState('');
  const [imageSrcEmailBanner, setMailImageBanner] = useState('');
  const [eventListingImage, setEventListingImage] = useState('');
  const [eventSocialImage, setEventSocialImage] = useState('');
  const [eventShareImage, setEventShareImage] = useState('');

  const [bannerId, setBannerId] = useState('');

  let [list, setList] = useState([]);
  let [listData, setListData] = useState([]);
  let [eventNameList, setEventNameList] = useState([]);
  let [eventError, setEventError] = useState(0);

  const [PastTabs, setPastTabs] = useState([]);
  const [PreTabs, setPreTabs] = useState([]);

  const [fieldError, setFieldError] = useState(false)
  const [sellerError, setSellerError] = useState(false)
  const [buyerError, setBuyerError] = useState(false)
  const [otherError, setOtherError] = useState(false)
  const [sellerBuyerErrLength, setSellerBuyerErrLength] = useState(0)
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  
  useEffect(() => {
    setEventError(0);
    getOtherChannelType()
  }, [state.eventName])

  const getOtherChannelType = () => {
    commonService.postService({isOther:'1'}, 'common/getChannelTypeWithParam').then(res => {
      if (res.data?.error) {
        setLoading(false);
        ToastsStore.warning('Something went wrong');
      } else {
        let otherChannelTypeList = []
        res.data.map(x => {
            otherChannelTypeList.push({ id: x.channelTypeId, name: x.channelType.toUpperCase(), status: true, withPackage: true })
        });
        state.otherOption = otherChannelTypeList;
        setState({ ...state });
      }
    }).catch(err => {
      setLoading(false);
      console.log('err', err)
    })
  }

  const handleTabFlag = (value, name) => {

    //console.log("selectedTab:", name);
    let id = name.split(":")[0];
    let type = name.split(":")[1];
    let item = name.split(":")[2];
    if (type === "PastEvent")
      setPastTabs((tab) => {
        return tab.map(x => {
          if (x.id === id && type === x.tabType) {
            switch (item) {
              case "checked": {
                x.checked = !x.checked;
                break;
              }
              case "namechange": {
                console.log("flgs:", id, item);
                x.customName = value != "" ? value : null;
                break;
              }
              case "url": {
                x.url = value != "" ? value : null;
                break;
              }
              case "hideorsoon": {
                x.hideorsoon = value;
                break;
              }
              case "template": {
                x.template = value;
                break;
              }
            }
          }
          return x;
        })
      })
    else if (type === "PreEvent")
      setPreTabs((tab) => {
        return tab.map(x => {
          if (x.id === id && type === x.tabType) {
            switch (item) {
              case "checked": {
                x.checked = !x.checked;
                break;
              }
              case "namechange": {
                console.log("flgs:", id, item);
                x.customName = value != "" ? value : null;
                break;
              }
              case "url": {
                x.url = value != "" ? value : null;
                break;
              }
              case "hideorsoon": {
                x.hideorsoon = value;
                break;
              }
              case "template": {
                x.template = value;
                break;
              }
            }
          }
          return x;
        })
      })
  }

  const eventAuth = () => {
    setLoading(true);
    // eventRegister();
    if (!localStorage.getItem('eventcatalyst_token')) {
      //   let body = new URLSearchParams();
      //   body.set('grant_type', process.env.REACT_APP_EVENTCAT_GRANT_TYPE);
      //   body.set('username', process.env.REACT_APP_EVENTCAT_USERNAME);
      //   body.set('password', process.env.REACT_APP_EVENTCAT_PASSWORD);
      //   const requestOptions = {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
      //     body: body.toString()
      // };
      // console.log('body',process.env);
      let req = {
        url: process.env.REACT_APP_EVENTCATALYST_API + 'oauth/token'
      }
      commonService.postService(req, 'account/eventcatalystToken').then(res => {
        if (res.data?.error) {
          setLoading(false);
          ToastsStore.warning('Something went wrong');
        } else {
          localStorage.setItem('eventcatalyst_token', JSON.stringify(res.data));
          getEventList();
        }
      }).catch(err => {
        setLoading(false);
        console.log('err', err)
      })
    } else {
      getEventList();
    }
  }
  const getEventList = () => {
    const url = 'getEventRegister';
    let data = {
      token: JSON.parse(localStorage.getItem('eventcatalyst_token')),
      url: process.env.REACT_APP_EVENTCATALYST_API + 'api/registration/' + userData.contactId
    }
    commonService.postService(data, 'account/getEventRegister').then(res => {
      setLoading(false);
      list = listData = _.orderBy(res.data.registrations, ['date_start'], ['desc']);
      setListData(listData);
      setList(list);
      console.log('list', list)
      eventNameList = list.map(x => ({ id: x.eventId, name: x.event_name }));
      setEventNameList(eventNameList);
      // resultCount= list?list.length:0;
    }, err => {
      setLoading(false);
      console.log('err', err)
    });
  }
  var userData = JSON.parse(localStorage.getItem('CHuser'));


  const handleClose = () => {
    setOpen(false);
    setAddonOpen(false);
  }

  const handleCopyClose = () => {
    setCopy(false);
  }


  const watchAll = watch();

  let eventId = props.location?.state?.eventId ? props.location?.state?.eventId : 0;

  console.log('eventId', props.location.state)

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (eventId != 0) {
        await getEventDetails();
        await getEventTabsFlag();
      }

      await eventAuth();
      await getCountryList();
      
      await getOtherChannelType();
      setLoading(false);
    })();
  }, []);


  const editPackage = (item) => {
    if (item.belongsTo) {
      setAddonOpen(true);
      setPackageData({ ...item, isAddon: false, eventId: eventId })
    } else {
      setOpen(true);
      setPackageData({ ...item, eventId: eventId });
    }

  }

  const copyPackage = (item) => {
    setCopy(true)
    setPackageData({ ...item, eventId: eventId });
  }

  const getCountryList = async () => {
    await axios.post('/channelAdmin/getFilterCountry', {
      userId: adminUser.userId
    }).then(res => {
      setState({ ...state, countryOptions: res.data });
    })
  }

  const getEventTabsFlag = async () => {
    await axios.get('/channelAdmin/EventTabsFlag/' + eventId + '/all').then(res => {


      if (res.data["result"].filter(tab => tab.tabType === "PastEvent").length > 0)
        setPastTabs(res.data["result"].filter(tab => tab.tabType === "PastEvent"));
      else {
        setPastTabs((tab) => {
          return res.data["master"].filter(tab => tab.tabType === "PastEvent").map(x => {
            x.checked = true;
            x.customName = null;
            x.hideorsoon = null;
            x.url = null;
            x.template = null;
            return x;
          })
        })
      }

      if (res.data["result"].filter(tab => tab.tabType === "PreEvent").length > 0)
        setPreTabs(res.data["result"].filter(tab => tab.tabType === "PreEvent"));
      else {
        setPreTabs((tab) => {
          return res.data["master"].filter(tab => tab.tabType === "PreEvent").sort((a, b) => a.id - b.id).map(x => {
            x.checked = true;
            x.customName = null;
            x.hideorsoon = null;
            x.url = null;
            return x;
          })
        })
      }

    });
  }

  const getEventDetails = async () => {

    await axios.get('/channelAdmin/getEventById/' + eventId).then(res => {
      if (res.data.length) {
        console.log(res.data);
        let event = res.data[0];
        state.eventId = event.id;
        setCountryForSelectHotel(event.country ? event.country.country : null)
        state.eventBanner = event.banner?.documentPath;
        state.eventMailBanner = event.mailbanner?.documentPath;
        state.eventListingImage = event.eventImageList?.documentPath;
        state.eventSocialImage = event.eventSocialImageList?.documentPath;
        state.eventShareImage = event.eventShareImageList?.documentPath;
        setImageBanner(state.eventBanner);
        setMailImageBanner(state.eventMailBanner);
        setEventListingImage(state.eventListingImage);
        setEventSocialImage(state.eventSocialImage);
        setEventShareImage(state.eventShareImage);
        state.eventBannerId = event.eventBannerId;
        state.eventMailBannerId = event.eventMailBannerId;
        state.eventListingImageId = event.eventListingImageId;
        state.eventSocialImageId = event.eventSocialImageId;
        state.eventShareImageId = event.eventShareImageId;


        // state.eventSignupBanner = event.signupbanner?.documentPath;
        // setImageBanner(state.eventSignupBanner);
        // state.eventSignupBannerId = event.eventSignupBannerId;


        state.eventLogo = event.logo?.documentPath;
        setImageSrc(state.eventLogo);
        state.eventLogoId = event.eventLogoId;
        state.eventName = { id: event.eventCatalystId ? event.eventCatalystId : '0', name: event.name };
        state.eventShortName = event.shortname;
        state.eventDescription = event.description;
        state.eventShortDescription = event.shortDescription;
        state.country = { name: event.country?.country, id: event.countryId };
        state.address = event.address;
        state.city = event.city;
        state.zipCode = event.zipcode;
        state.regTemplate = event.regTemplate;

        state.approveNewUserTemplate = event.newUserTemplate;
        state.approveExistingUserTemplate = event.existingUserTemplate;
        state.dismissUserTemplate = event.dismissUserTemplate;
        state.associateUserTemplate = event.associateUserTemplate;
        state.associateChannelTemplate = event.associateChannelTemplate;

        state.sellerEventRegisterURL = event.sellerEventRegisterURL;
        state.buyerEventRegisterURL = event.buyerEventRegisterURL;
        state.eventSiteUrl = event.eventSiteUrl;
        state.eventMapUrl = event.eventMapUrl;
        state.venuetext = event.venuetext;

        state.vendorListDate = event.vendorListDate;
        state.buyerListDate = event.buyerListDate;
        state.vendorMeetingDate = event.vendorMeetingDate;
        state.buyerMeetingDate = event.buyerMeetingDate;

        state.vendorListMsg = event.vendorListMsg;
        state.buyerListMsg = event.buyerListMsg;
        state.vendorSchedulerMsg = event.vendorSchedulerMsg;
        state.buyerSchedulerMsg = event.buyerSchedulerMsg;


        state.fromDate = event.fromDate;
        state.toDate = event.toDate;
        state.fromTime = event.fromTime;
        state.toTime = event.toTime;
        state.timeZone = event.timezone;
        state.type = event.type + '';
        state.vendorStatus = event.vendorStatus + '';
        state.buyerStatus = event.buyerStatus + '';

        state.vendorSecondaryToRegister = event.vendorSecondaryToRegister != '0' ? '1' : '2';
        state.buyerSecondaryToRegister = event.buyerSecondaryToRegister != '0' ? '1' : '2';

        state.multiMeet = event.multiMeet != '0' ? '1' : '2';
        state.eventCatalystId = event.eventCatalystId;


        state.buyerPackages = event.packages.length ? event.packages.filter(x => x.isBuyer == '1') : [];
        state.sellerPackages = event.packages.length ? event.packages.filter(x => x.isSeller == '1') : [];
        state.otherPackages = event.packages.length ? event.packages.filter(x => x.isOther == '1') : [];
        

        state.buyerPackages.map(x => {
          x.packageType = x.packageType ? packageTypeOption.find(p => p.id == x.packageType) : '';
          x.packageUser = x.packageUser ? packageUserOption.find(p => p.id == x.packageUser) : '';
          //   x.meetingsAllowed = x.no_of_meeting_allowed;
          //   x.accessBadgeAllowed = x.no_of_access_badge;
          //   x.privateWebinar = x.no_of_private_webinar;
          //   x.firesideChat = x.no_of_fireside_chat;
          //   x.subscriptionToCH = x.subs_to_chPlatform;
          //   x.emailMarketing = x.emailMarketing;

          //   if (x.packageOptions?.length) {
          //     x.Options_for_buyers = x.packageOptions.map(y => {
          //       return { id: y.optionId, optionId: y.optionId, optname: y.text, optvalue: y.value }
          //     });
          //   } else {
          //     x.Options_for_buyers = [{ id: Math.random(), optvalue: '', optname: '' }]
          //   }

        })

        state.sellerPackages.map(x => {
          x.packageType = x.packageType ? packageTypeOption.find(p => p.id == x.packageType) : '';
          x.packageUser = x.packageUser ? packageUserOption.find(p => p.id == x.packageUser) : '';
          //   x.meetingsAllowed = x.no_of_meeting_allowed;
          //   x.accessBadgeAllowed = x.no_of_access_badge;
          //   x.privateWebinar = x.no_of_private_webinar;
          //   x.firesideChat = x.no_of_fireside_chat;
          //   x.subscriptionToCH = x.subs_to_chPlatform;
          //   x.emailMarketing = x.emailMarketing;

          //   if (x.packageOptions?.length) {
          //     x.Options_for_buyers = x.packageOptions.map(y => {
          //       return { id: y.optionId, optionId: y.optionId, optname: y.text, optvalue: y.value }
          //     });
          //   } else {
          //     x.Options_for_buyers = [{ id: Math.random(), optvalue: '', optname: '' }]
          //   }

          //   if (x.marketingOptions?.length) {
          //     x.marketing_options = x.marketingOptions.map(y => {
          //       return { id: y.optionId, optionId: y.optionId, optname: y.text, optvalue: y.value }
          //     });
          //   } else {
          //     x.marketing_options = [{ id: Math.random(), optvalue: '', optname: '' }]
          //   }

        })

        state.otherPackages.map(x => {
          x.packageType = x.packageType ? packageTypeOption.find(p => p.id == x.packageType) : '';
          x.packageUser = x.packageUser ? packageUserOption.find(p => p.id == x.packageUser) : '';
        })

        // state.buyerOption = state.buyerOption.map(x => {
        //   if (state.buyerPackages.filter(y => y.channelType == x.id).length)
        //     x.status = true;
        //   return x;
        // })

        // state.sellerOption = state.sellerOption.map(x => {
        //   if (state.sellerPackages.filter(y => y.channelType == x.id).length)
        //     x.status = true;
        //   return x;
        // })       

        state.buyerOption = state.buyerOption.map(x => {
          if (event.buyers.split(',').map(x => Number(x)).includes(x.id))
            x.status = true;
          if (state.buyerPackages.map(y => y.channelType).includes(x.id))
            x.withPackage = true;
          return x;
        })

        state.sellerOption = state.sellerOption.map(x => {
          if (event.sellers.split(',').map(x => Number(x)).includes(x.id))
            x.status = true;
          if (state.sellerPackages.map(y => y.channelType).includes(x.id))
            x.withPackage = true;
          return x;
        })

        state.otherOption = state.otherOption.map(x => {
          // if (event?.other.split(',').map(x => Number(x)).includes(x.id))
          //   x.status = true;
          if (state.otherPackages.map(y => y.channelType).includes(x.id))
            x.withPackage = true;
          return x;
        })

        setState({ ...state });

      }
    })

  }
  const handleChangeEventForm = input => e => {
    //console.log(input,'testingdata', e);
    setState({ ...state, [input]: e.target.value });
  }
 
  const handleChange = input => e => {
    let checktype = 0;
    if (input == "VENDOR" || input == "DISTRIBUTOR" || input == "RETAILER" || input == "RESELLER")
      checktype = e.target.getAttribute('data-type');

    if (checktype == '1' && (input == "VENDOR" || input == "DISTRIBUTOR" || input == "RETAILER" || input == "RESELLER")) {
      state.sellerOption.map(x => {
        if (x.name == input && x.status == false)
          x.status = true;
        else if (x.name == input && x.status == true) {
          x.status = false;
          x.withPackage = false;
        }
      });
      setSellerError(false);
      setState({ ...state });
    }

    else if (checktype == '2' && (input == "VENDOR" || input == "DISTRIBUTOR" || input == "RETAILER" || input == "RESELLER")) {
      state.buyerOption.map(x => {
        if (x.name == input && x.status == false)
          x.status = true;
        else if (x.name == input && x.status == true) {
          x.status = false;
          x.withPackage = false;
        }
      });
      setBuyerError(false)
      setState({ ...state });
    }

    else if (checktype == '3' && (input == "OTHER")) {
      state.otherOption.map(x => {
        if (x.name == input && x.status == false)
          x.status = true;
        else if (x.name == input && x.status == true) {
          x.status = false;
          x.withPackage = false;
        }
      });
      setOtherError(false)
      setState({ ...state });
    }

    else if (input == 'eventDescription') {
      setState({ ...state, [input]: e.target.value.slice(0, 5000) });
    }else if (input == 'eventShortDescription') {
      setState({ ...state, [input]: e.target.value.slice(0, 1000) });
    }
    else {
      setState({ ...state, [input]: e.target.value });
    }

  }

  const handlePackage = (id, input) => e => {

    if (id == '1' && (input == "VENDOR" || input == "DISTRIBUTOR" || input == "RETAILER" || input == "RESELLER")) {
      state.sellerOption.map(x => {
        if (x.name == input && e.target.value == 1)
          x.withPackage = true;
        else if (x.name == input && e.target.value == 2)
          x.withPackage = false;
      });
      setState({ ...state });
    }
    else if (id == '2' && (input == "VENDOR" || input == "DISTRIBUTOR" || input == "RETAILER" || input == "RESELLER")) {
      state.buyerOption.map(x => {
        if (x.name == input && e.target.value == 1)
          x.withPackage = true;
        else if (x.name == input && e.target.value == 2)
          x.withPackage = false;
      });
      setState({ ...state });
    }
    else if (id == '3' && (input == "Other")) {
      state.otherOption.map(x => {
        if (x.name == input && e.target.value == 1)
          x.withPackage = true;
        else if (x.name == input && e.target.value == 2)
          x.withPackage = false;
      });
      setState({ ...state });
    }

    console.log("seller:", state.sellerOption, state.buyerOption, state.otherOption)
  }

  const handleBuyerMeetingDateChange = (date) => {
    //errors.fromDate = false;
    setState({ ...state, buyerMeetingDate: date });
  };

  const handleVendorMeetingDateChange = (date) => {
    //errors.fromDate = false;
    setState({ ...state, vendorMeetingDate: date });
  };

  const handleBuyerListDateChange = (date) => {
    //errors.fromDate = false;
    setState({ ...state, buyerListDate: date });
  };

  const handleVendorListDateChange = (date) => {
    // errors.fromDate = false;
    setState({ ...state, vendorListDate: date });
  };
  const handleDateChange = (date) => {
    //errors.fromDate = false;
    setState({ ...state, fromDate: date });
  };

  const handleDateChange1 = (date) => {
    errors.toDate = false;
    setState({ ...state, toDate: date });
  };

  const handleTimeChange = (time) => {
    errors.fromTime = false;
    setState({ ...state, fromTime: time });
  }

  const handleTimeChange1 = (time) => {
    errors.toTime = false;
    setState({ ...state, toTime: time });
  }

  const handleTimeZone = (time) => {
    errors.timeZone = false;
    setState({ ...state, timeZone: time });
  }

  const handleChangeSelect = async (input, value) => {
    if (input == 'eventName' && value == null) return

    if (input == 'eventName') {
      setLoading(true);
      if (!value) value = { id: '0', name: '' }

      let available = true;
      if (value?.id != '0') {
        available = await axios.get('/channelAdmin/eventAvailabilityCheck/' + value.id + '/' + state.eventId).then(res => res.data);
      }
      setTimeout(() => setLoading(false), 1000);
      if (available) {
        if (listData.filter(x => x.eventId == value.id).length) {
          listData.map(x => {
            if (x.eventId == value.id) {
              state.eventName = value;
              state.eventDescription = x.description;
              state.eventCatalystId = x.eventId;
              state.fromDate = x.date_start;
              state.toDate = x.date_end;
              state.city = x.location;

              state.type = x.type ? x.type : '3';
              setState({ ...state });
            }
          })
          // setLoading(false);
        }
        else {
          state.eventCatalystId = null;

          setState({ ...state, [input]: value });
        }
      } else {
        state.eventCatalystId = null;
        setEventError(1);
        setState({ ...state });
      }

    }
    else {
      setState({ ...state, [input]: value });
    }

  }

  const saveAddon = (isNew, pack) => {

    if (pack.isSeller == '1') {
      if (isNew) {
        setState({ ...state, sellerPackages: [...state.sellerPackages, pack] })
      }
      else {
        state.sellerPackages = state.sellerPackages.map(x => {
          if (x.packageId == pack.packageId) {
            x = pack;
          }
          return x;
        })
        setState({ ...state });
      }
    }
    else if (pack.isBuyer == '1') {
      if (isNew) {
        setState({ ...state, buyerPackages: [...state.buyerPackages, pack] })
      }
      else {
        state.buyerPackages = state.buyerPackages.map(x => {
          if (x.packageId == pack.packageId) {
            x = pack;
          }
          return x;
        })
        setState({ ...state });
      }
    }
    else if (pack.isOther == '1') {
      if (isNew) {
        setState({ ...state, otherPackages: [...state.otherPackages, pack] })
      }
      else {
        state.otherPackages = state.otherPackages.map(x => {
          if (x.packageId == pack.packageId) {
            x = pack;
          }
          return x;
        })
        setState({ ...state });
      }
    }

  }

  const savePackage = (data) => {



    if (data.isBuyer == '1') {
      // if(state.buyerPackages.filter(x => x.channelType==data.channelType).length){
      //   let temp = state.buyerPackages.map(x => {
      //     if(x.channelType == data.channelType)
      //       x=data;
      //     return x;
      //   })
      //   setState({...state, buyerPackages: temp});
      // }else{
      //   let temp = state.buyerPackages;
      //   temp.push(data);
      //   setState({...state, buyerPackages: temp})
      // }

      if (data.packageId) {
        state.buyerPackages = state.buyerPackages.map(x => {
          if (x.packageId == data.packageId) {
            x = data;
          }
          return x;
        })
        console.log("state.buyerPackages:", state.buyerPackages)
        setState({ ...state });
      } else if (data.id) {

        if (state.buyerPackages.filter(x => x.id == data.id).length) {
          state.buyerPackages = state.buyerPackages.map(x => {
            if (x.id == data.id) {
              x = data;
            }
            return x;
          })

          setState({ ...state });

        } else {
          let temp = state.buyerPackages;
          temp.push(data);
          console.log('temp', temp, data)
          setState({ ...state, buyerPackages: temp })
        }


      }

    }
    if (data.isSeller == '1') {
      // if(state.sellerPackages.filter(x => x.channelType==data.channelType).length){
      //   let temp = state.sellerPackages.map(x => {
      //     if(x.channelType == data.channelType)
      //       return data;
      //     return x;
      //   })
      //   setState({...state, sellerPackages: temp});
      // }else{
      //   let temp = state.sellerPackages;
      //   temp.push(data);
      //   console.log('temp', temp, data)
      //   setState({...state, sellerPackages: temp})
      // }
      if (data.packageId) {
        state.sellerPackages = state.sellerPackages.map(x => {
          if (x.packageId == data.packageId) {
            x = data;
          }
          return x;
        })
        setState({ ...state });
      } else if (data.id) {

        if (state.sellerPackages.filter(x => x.id == data.id).length) {
          state.sellerPackages = state.sellerPackages.map(x => {
            if (x.id == data.id) {
              x = data;
            }
            return x;
          })

          setState({ ...state });

        } else {
          let temp = state.sellerPackages;
          temp.push(data);
          console.log('temp', temp, data)
          setState({ ...state, sellerPackages: temp })
        }


      }

    }

    if (data.isOther == '1') {
      if (data.packageId) {
        state.otherPackages = state.otherPackages.map(x => {
          if (x.packageId == data.packageId) {
            x = data;
          }
          return x;
        })
        setState({ ...state });
      } else if (data.id) {

        if (state.otherPackages.filter(x => x.id == data.id).length) {
          state.otherPackages = state.otherPackages.map(x => {
            if (x.id == data.id) {
              x = data;
            }
            return x;
          })

          setState({ ...state });

        } else {
          let temp = state.otherPackages;
          temp.push(data);
          console.log('temp', temp, data)
          setState({ ...state, otherPackages: temp })
        }


      }

    }

    ToastsStore.warning('Click SaveEvent to update Package for event')

  };

  //deleting packages
  const deletePackage = async (data) => {

    let packageId = data.packageId ? data.packageId : data.id;

    let packageUsedCount = await axios.get('/channelAdmin/packUsedCount/' + packageId).then(res => res.data);

    if (packageUsedCount) {
      setPackageUsedCount(packageUsedCount);
      setPackDeleteAlert(true);
    } else {

      if (data.packageId) {
        if (data.isSeller == '1')
          state.sellerPackages = state.sellerPackages.filter(x => x.packageId != data.packageId);
        if (data.isBuyer == '1')
          state.buyerPackages = state.buyerPackages.filter(x => x.packageId != data.packageId);

        let deletedList = state.deletedPackages;
        deletedList.push(data.packageId);
        setState({ ...state, deletedPackages: deletedList });
      }
      if (data.id) {
        if (data.isSeller == '1')
          state.sellerPackages = state.sellerPackages.filter(x => x.id != data.id);
        if (data.isBuyer == '1')
          state.buyerPackages = state.buyerPackages.filter(x => x.id != data.id);
        setState({ ...state });
      }

    }

  }

  //images
  const selectImg = async (data) => {
    if (data) {
      setImage('');
      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + props.aws_folderName);
      console.log('resImagePath', resImagePath);
      if (resImagePath) {
        setImageSrc(resImagePath.data.url);
        setState({ ...state, eventLogo: resImagePath.data.url, eventLogoId: '' });
        setLoading(false);
      }

    }

  }

  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const handleChangeImage = event => {
    const data = event.target.files[0];
    console.log('files', data)
    setError('');
    if (data && data['type'].split('/')[0] === 'image') {
      console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log('file', file)
        console.log('reader.result', reader.result)
        setImage(
          reader.result
        );
        console.log('image', image)
        //setOpen(false);          
      };

    } else {
      setError('Jpg | Jpeg | PNG Image Formats Only Accepted');
    }

  };

  const handleCropClose = () => {
    setImage('');
  }

  //banner

  const handleChangeBanner = event => {
    const data = event.target.files[0];
    console.log('files', data)

    if (data && data['type'].split('/')[0] === 'image') {
      console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log('file', file)
        console.log('reader.result', reader.result)
        setBanner(
          reader.result
        );
        console.log('image', image)
        //setOpen(false);          
      };

    }
  };

  const selectBannerImg = async (data) => {
    if (data) {
      setBanner('');
      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + 'banner');
      console.log('resImagePath', resImagePath);
      if (resImagePath) {
        setImageBanner(resImagePath.data.url);
        setState({ ...state, eventBanner: resImagePath.data.url, eventBannerId: '' });
        setLoading(false);
      }

    }

  }

  const handleChangeEmailBanner = event => {
    const data = event.target.files[0];
    console.log('files', data)

    if (data && data['type'].split('/')[0] === 'image') {
      console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log('file', file)
        console.log('reader.result', reader.result)
        setEmailBanner(
          reader.result
        );
        console.log('image', image)
        //setOpen(false);          
      };

    }

  };

  const handleChangeEventListingImage = event => {
    const data = event.target.files[0]; 
    if (data && data['type'].split('/')[0] === 'image') {
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => { 
        setListingImage(
          reader.result
        );        
      };

    }

  };

  const handleChangeSocialImage = event => {
    const data = event.target.files[0]; 
    if (data && data['type'].split('/')[0] === 'image') {
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => { 
        setSocialImage(
          reader.result
        );        
      };

    }

  };


  const selectSocialImage = async (data) => {
    if (data) {
      setSocialImage('');
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + 'banner');
      if (resImagePath) {
        setEventSocialImage(resImagePath.data.url);
        setState({ ...state, eventSocialImage: resImagePath.data.url, eventSocialImageId: '' });
        setLoading(false);
      }

    }

  }

  const handleChangeShareImage = event => {
    const data = event.target.files[0]; 
    if (data && data['type'].split('/')[0] === 'image') {
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => { 
        setShareImage(
          reader.result
        );        
      };

    }

  };


  const selectShareImage = async (data) => {
    if (data) {
      setShareImage('');
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + 'banner');
      if (resImagePath) {
        setEventShareImage(resImagePath.data.url);
        setState({ ...state, eventShareImage: resImagePath.data.url, eventShareImageId: '' });
        setLoading(false);
      }

    }

  }

   
  const selectEmailBannerImg = async (data) => {
    if (data) {
      setEmailBanner('');
      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + 'banner');
      console.log('resImagePath', resImagePath);
      if (resImagePath) {
        setMailImageBanner(resImagePath.data.url);
        setState({ ...state, eventMailBanner: resImagePath.data.url, eventMailBannerId: '' });
        setLoading(false);
      }

    }

  }


  const selectEventListingImage = async (data) => {
    if (data) {
      setListingImage('');
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + 'banner');
      console.log('resImagePath', resImagePath);
      if (resImagePath) {
        setEventListingImage(resImagePath.data.url);
        setState({ ...state, eventListingImage: resImagePath.data.url, eventListingImageId: '' });
        setLoading(false);
      }

    }

  }

  // const selecteventSignupBannerImg = async (data) => {
  //   if (data) {
  //     setBanner('');
  //     //converting base64 string to file format.
  //     var random = Math.random();
  //     var fileName = random + '.png';
  //     var file = dataURLtoFile(data, fileName);
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     setLoading(true);
  //     const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + 'banner');
  //     console.log('resImagePath', resImagePath);
  //     if (resImagePath) {
  //       setImageBanner(resImagePath.data.url);
  //       setState({ ...state, eventSignupBanner: resImagePath.data.url, eventSignupBannerId: '' });
  //       setLoading(false);
  //     }

  //   }

  // }


  const save = async () => {

    //console.log('stateevent', state, errors);
    setLoading(true);
    let available = true;

    if (state.eventCatalystId)
      available = await axios.get('/channelAdmin/eventAvailabilityCheck/' + state.eventCatalystId + '/' + state.eventId).then(res => res.data);

    if (!available) {
      setLoading(false);
    } else {

      let sellerList = state.sellerOption.filter(x => x.status === true);
      let buyerList = state.buyerOption.filter(x => x.status === true);
      let otherList = state.otherOption.filter(x => x.status === true);


      let packages = [];
      let packageOptions = [];
      let channelOptions = [];
      let marketingOptions = [];

      state.sellerPackages.map(x => {
        let EventPackageSeller = {
          packageName: x.packageName,
          price: +x.price,
          packageType: x.packageType,
          packageUser: x.packageUser,
          description: x.description,
          discount: +x.discount,
          template: x.template,
          delegateTemplate: x.delegateTemplate,
          // cordTemplate: x.cordTemplate,
          partialRefuseTemplate: x.partialRefuseTemplate,
          approvalTemplate: x.approvalTemplate,
          dismissTemplate: x.dismissTemplate,
          receiptTemplate: x.receiptTemplate,
          offerIds: x.packageOffer,
          isPublic: x.isPublic,
          no_of_meeting_allowed: x.no_of_meeting_allowed,
          no_of_meeting_request: x.no_of_meeting_request,
          no_of_access_badge: x.no_of_access_badge,
          // boothSize: +x.boothSize,
          // no_of_meeting_allowed: +x.meetingsAllowed,
          // no_of_access_badge: +x.accessBadgeAllowed,
          // no_of_private_webinar: +x.privateWebinar,
          // no_of_fireside_chat: +x.firesideChat,
          // membersAllowed: +x.membersAllowed,
          // additionalMembersCosting: +x.additionalMembersCosting,
          // description: x.packageDescription,
          // emailMarketing: +x.emailMarketing,
          // subs_to_chPlatform: +x.subscriptionToCH,
          // hotel_room: x.hotel_room,
          channelOptions: x.channelIds?.length ? x.channelIds : [],
          channelType: x.channelType,
          isBuyer: '0',
          isSeller: '1',
        }




        if (x.packageId)
          EventPackageSeller = Object.assign(EventPackageSeller, { packageId: x.packageId });



        // if (x.Options_for_buyers?.length) {
        //   x.Options_for_buyers.map(y => {
        //     let eventPackageOptions = {
        //       type: '1',
        //       packageId: x.packageId,
        //       text: y.optname,
        //       value: y.optvalue,
        //     }

        //     if (y.optionId)
        //       eventPackageOptions = Object.assign(eventPackageOptions, { optionId: y.optionId });

        //     if (y.optvalue && y.optname)
        //       packageOptions.push(eventPackageOptions);
        //   });
        //   EventPackageSeller = Object.assign(EventPackageSeller, { packageOptions: packageOptions });
        // }

        // if (x.marketing_options?.length) {
        //   x.marketing_options.map(y => {
        //     let eventMarketingOptions = {
        //       type: '2',
        //       packageId: x.packageId,
        //       text: y.optname,
        //       value: y.optvalue,
        //     }

        //     if (y.optionId)
        //       eventMarketingOptions = Object.assign(eventMarketingOptions, { optionId: y.optionId });

        //     if (y.optvalue && y.optname)
        //       marketingOptions.push(eventMarketingOptions);
        //   });
        //   EventPackageSeller = Object.assign(EventPackageSeller, { marketingOptions: marketingOptions });
        // }

        packages.push(EventPackageSeller);
        packageOptions = [];
        marketingOptions = [];
      })

      state.buyerPackages.map(x => {
        let EventPackageBuyer = {
          packageName: x.packageName,
          price: +x.price,
          packageType: x.packageType,
          packageUser: x.packageUser,
          description: x.description,
          discount: +x.discount,
          template: x.template,
          delegateTemplate: x.delegateTemplate,
          // cordTemplate: x.cordTemplate,
          partialRefuseTemplate: x.partialRefuseTemplate,
          approvalTemplate: x.approvalTemplate,
          dismissTemplate: x.dismissTemplate,
          receiptTemplate: x.receiptTemplate,
          isPublic: x.isPublic,
          no_of_meeting_allowed: x.no_of_meeting_allowed,
          no_of_meeting_request: x.no_of_meeting_request,
          no_of_access_badge: x.no_of_access_badge,
          offerIds: x.packageOffer,
          // boothSize: +x.boothSize,
          // no_of_meeting_allowed: +x.meetingsAllowed,
          // no_of_access_badge: +x.accessBadgeAllowed,
          // no_of_private_webinar: +x.privateWebinar,
          // no_of_fireside_chat: +x.firesideChat,
          // membersAllowed: +x.membersAllowed,
          // additionalMembersCosting: +x.additionalMembersCosting,
          // description: x.packageDescription,
          // emailMarketing: x.emailMarketing ? '1' : '0',
          // subs_to_chPlatform: x.subs_to_chPlatform ? '1' : '0',
          // hotel_room: x.hotel_room,
          channelOptions: x.channelIds?.length ? x.channelIds : [],
          channelType: x.channelType,
          isBuyer: '1',
          isSeller: '0',
        }



        if (x.packageId)
          EventPackageBuyer = Object.assign(EventPackageBuyer, { packageId: x.packageId });


        // if (x.Options_for_buyers?.length) {
        //   x.Options_for_buyers.map(y => {
        //     let eventPackageOptions = {
        //       type: '1',
        //       packageId: x.packageId,
        //       text: y.optname,
        //       value: y.optvalue,
        //     }

        //     if (y.optionId)
        //       eventPackageOptions = Object.assign(eventPackageOptions, { optionId: y.optionId });

        //     if (y.optvalue && y.optname)
        //       packageOptions.push(eventPackageOptions);
        //   });

        //   EventPackageBuyer = Object.assign(EventPackageBuyer, { packageOptions: packageOptions });
        // }


        packages.push(EventPackageBuyer);
        packageOptions = [];
      })


      state.otherPackages.map(x => {
        let EventPackageOther = {
          packageName: x.packageName,
          price: +x.price,
          packageType: x.packageType,
          packageUser: x.packageUser,
          description: x.description,
          discount: +x.discount,
          template: x.template,
          delegateTemplate: x.delegateTemplate,
          partialRefuseTemplate: x.partialRefuseTemplate,
          approvalTemplate: x.approvalTemplate,
          dismissTemplate: x.dismissTemplate,
          receiptTemplate: x.receiptTemplate,
          isPublic: x.isPublic,
          no_of_meeting_allowed: x.no_of_meeting_allowed,
          no_of_meeting_request: x.no_of_meeting_request,
          no_of_access_badge: x.no_of_access_badge,
          offerIds: x.packageOffer,
          channelOptions: x.channelIds?.length ? x.channelIds : [],
          channelType: x.channelType,
          isBuyer: '0',
          isSeller: '0',
          isOther: '1',
        }

        if (x.packageId)
          EventPackageOther = Object.assign(EventPackageOther, { packageId: x.packageId });

        packages.push(EventPackageOther);
        packageOptions = [];
      })

      if (state.eventName == null || state.eventName == "" || state.eventDescription == null || state.eventDescription == ""
        || state.timeZone == null || state.timeZone == "" || state.zipCode == "" || state.city == null || state.city == ""
        || state.address == null || state.address == "" || state.eventLogo == null || state.eventLogo == "" || state.eventBanner == null
        || state.eventBanner == "" || state.vendorListMsg == null || state.vendorListMsg == "" || state.eventMailBanner == null
        || state.eventMailBanner == "" || sellerError === true || buyerError === true || state.buyerListMsg == null || state.fromDate == null
        || state.fromDate == "" || state.toDate == null || state.toDate == "" || state.buyerListMsg == "" || state.vendorSchedulerMsg == null
        || state.vendorSchedulerMsg == "" || state.buyerSchedulerMsg == null || state.buyerSchedulerMsg == "" || state.country == null ||
        (Array.isArray(state.country) && state.country.length === 0)
      ) {
        setLoading(false)
        if (sellerBuyerErrLength == 0) {
          setSellerError(true)
          setBuyerError(true)
          setSellerBuyerErrLength(1);
        }
        setFieldError(true)
        return
      }

      let event = {
        eventId: state.eventId,
        name: state.eventName?.name,
        shortname: state.eventShortName,
        description: state.eventDescription,
        shortDescription: state.eventShortDescription,
        type: +state.type,
        fromDate: state.fromDate,
        vendorListDate: state.vendorListDate,
        buyerListDate: state.buyerListDate,
        vendorMeetingDate: state.vendorMeetingDate,
        buyerMeetingDate: state.buyerMeetingDate,

        vendorListMsg: state.vendorListMsg,
        buyerListMsg: state.buyerListMsg,
        vendorSchedulerMsg: state.vendorSchedulerMsg,
        buyerSchedulerMsg: state.buyerSchedulerMsg,

        toDate: state.toDate,
        fromTime: state.fromTime,
        toTime: state.toTime,
        timezone: state.timeZone,
        address: state.address,
        city: state.city,
        countryId: +state.country.id,
        zipcode: state.zipCode,
        regTemplate: state.regTemplate,

        newUserTemplate: state.approveNewUserTemplate,
        existingUserTemplate: state.approveExistingUserTemplate,
        dismissUserTemplate: state.dismissUserTemplate,
        associateUserTemplate: state.associateUserTemplate,
        associateChannelTemplate: state.associateChannelTemplate,

        sellerEventRegisterURL: state.sellerEventRegisterURL,
        buyerEventRegisterURL: state.buyerEventRegisterURL,
        eventSiteUrl: state.eventSiteUrl,
        eventMapUrl: state.eventMapUrl,
        venuetext: state.venuetext,


        eventLogoId: state.eventLogoId ? state.eventLogoId : null,
        eventLogo: state.eventLogo,
        eventBannerId: state.eventBannerId ? state.eventBannerId : null,
        eventListingImageId:state.eventListingImageId ? state.eventListingImageId : null,
        eventSocialImageId:state.eventSocialImageId ? state.eventSocialImageId : null,
        eventShareImageId:state.eventShareImageId ? state.eventShareImageId : null,

        eventBanner: state.eventBanner,
        eventMailBannerId: state.eventMailBannerId ? state.eventMailBannerId : null,
        eventMailBanner: state.eventMailBanner,
        eventListingImage: state.eventListingImage,
        eventSocialImage: state.eventSocialImage,
        eventShareImage: state.eventShareImage,

        eventSignupBannerId: state.eventSignupBannerId ? state.eventSignupBannerId : null,
        eventSignupBanner: state.eventSignupBanner,
        eventCatalystId: state.eventCatalystId,
        sellers: sellerList.map(x => x.id).toString(),
        buyers: buyerList.map(x => x.id).toString(),
        vendorStatus: +state.vendorStatus,
        buyerStatus: +state.buyerStatus,
        vendorSecondaryToRegister: state.vendorSecondaryToRegister == '1' ? '1' : '0',
        buyerSecondaryToRegister: state.buyerSecondaryToRegister == '1' ? '1' : '0',

        multiMeet: state.multiMeet == '1' ? '1' : '0',
        hasPackage: packages.length ? '1' : '0'
      }

      let eventTabFlags = [];

      PastTabs.map(tab => {
        let tabData = {
          eventId: state.eventId,
          tabType: 'PastEvent',
          tabId: +tab.id,
          tabName: tab.label,
          tabStatus: tab.checked,
          hideorsoon: tab.hideorsoon === undefined ? null : tab.hideorsoon,
          customName: tab.customName === undefined ? null : tab.customName,
          template: tab.template === undefined ? null : tab.template,
          url: tab.url === undefined ? null : tab.url
        }
        eventTabFlags.push(tabData);
      })

      PreTabs.map(tab => {
        let tabData = {
          eventId: state.eventId,
          tabType: 'PreEvent',
          tabId: +tab.id,
          tabName: tab.label,
          tabStatus: tab.checked,
          hideorsoon: tab.hideorsoon === undefined ? null : tab.hideorsoon,
          customName: tab.customName === undefined ? null : tab.customName,
          template: tab.template === undefined ? null : tab.template,
          url: tab.url === undefined ? null : tab.url
        }
        eventTabFlags.push(tabData);
      })


      let finalData = {
        event: event,
        packages: packages,
        tabFlags: eventTabFlags
        // packageOptions: packageOptions,
        //channelOptions:channelOptions
      }

      // console.log("finalData:", finalData);
      // setLoading(false);
      await axios.post('channelAdmin/boEventCreate', finalData).then(
        res => {

          history.push('/admin/eventListing');
          setLoading(false);
          ToastsStore.success('Events are updated successfully');
        }
      ).catch(err => {
        console.log('error', err);
        setLoading(false);
      })
    }


  };



  return (
    <>
      {
        loading && <Spinner />
      }
      <div className="main-content" style={{ display: loading === true ? "none" : "block" }}>
        <Nav tabs className="event-tabs" style={{ margin: "0.1% 0 0 14.2%" }}>
          <NavItem>
            <NavLink
              className={activeTab === '1' ? 'active' : ''}
              onClick={() => { toggle('1'); }}
            >
              Event Info
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '2' ? 'active' : ''}
              onClick={() => { toggle('2'); }}
            >
              Hotels
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '3' ? 'active' : ''}
              onClick={() => { toggle('3'); }}
            >
              Hotel Booking
            </NavLink>
          </NavItem>
        </Nav>


        <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
        {open && <Packages visible={open} handleClose={handleClose} packageData={packageData} savePackage={savePackage} />}

        <ConfirmDialog open={packDeleteAlert} value={{ title: 'Package Removal', message: `package cannot be deleted, as it is allocated to ${packageUsedCount} companies`, second_message: 'Please contact DB administrator' }} handleSave={() => setPackDeleteAlert(false)} handleClose={() => setPackDeleteAlert(false)} />

        {copy && <CopyPackages visible={copy} handleClose={handleCopyClose} packageData={packageData} savePackage={savePackage} />}

        {addonOpen && <PackageAddon visible={addonOpen} handleClose={handleClose} packageData={packageData} savePackage={saveAddon} />}

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Container className="col-lg-9 col-md-9 col-sm-12  " fluid>
              <Card>
                <CardHeader>
                  <h2 style={{ textAlign: "center", color: "#2ca5cd" }}>Event module</h2>
                </CardHeader>
                <CardBody>
                  <form noValidate onSubmit={handleSubmit(save)} >
                    <div className="row">
                      <div className="col-lg-12 col-md-6 col-sm-12 ">
                        <h4 >Event Name *</h4>

                        <NewFieldAutocomplete
                          id="eventName"
                          type="text"
                          label="Event Name"
                          // value={props.signupData.companyname}
                          name="eventName"
                          options={eventNameList}
                          placeholder="Event Name"
                          // register={register}
                          // error={errors.postalcode}
                          defaultValue={state.eventName}
                          disableCloseOnSelect
                          limitTags={2}
                          // defaultValue={productCategories}
                          control={control}
                          input='eventName'
                          value={state.eventName}
                          register={register}
                          errMessage={eventError ? 'Event Already Registered' : ''}
                          error={errors.eventName ? errors.eventName : (fieldError && state.eventName?.name == "") ? "" : eventError}
                          handleChangeSelect={handleChangeSelect}
                        />

                      </div>

                      <div className="col-lg-12 col-md-6 col-sm-12 ">

                        <FormInput
                          className="comp-name mt-4"
                          id="eventShortName"
                          name="eventShortName"
                          placeholder=""
                          label="Event Short Name"
                          type="text"
                          variant="outlined"
                          value={watchAll.eventShortName}
                          defaultValue={state.eventShortName}
                          register={register}
                          //error={errors.eventShortName}
                          onChange={handleChange('eventShortName')}
                        />

                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 mt-2">

                        <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="eventDescription" name="eventDescription" ref={register} value={state.eventDescription} />
                        {!errors.eventDescription || (state.eventDescription.length > 5 && state.eventDescription.length < 5000) ? <h4 >Event Description * (5000 Characters Max)
                          {state.eventDescription?.length > 0 && <span style={{ color: state.eventDescription?.length > 5000 ? "red" : "#9ea2a5" }}> remaining characters  {5000 - state.eventDescription.length}</span>}
                        </h4> :
                          <h4 >Event Description * (5000 Characters Max) <span style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{
                            state.eventDescription.length <= 1 ? 'Event Description can\'t be empty.' : state.eventDescription.length <= 5 ? 'Event Description must have min 5 characters' : state.eventDescription.length > 5000 ? 'Event Description must have max 5000 characters' : ''
                          }</span> </h4>}
                        {/* <h4>Event Description *</h4> */}

                        <div className="form-group mb-0">
                          <FormTextArea
                            className="buytext2 w-100 h-auto"
                            name="note"
                            id="note"
                            rows="3"
                            type="textarea"
                            // label="Event Description *"
                            defaultValue={state.eventDescription}
                            placeholder="Tell us about the Event -max 5000 characters *"
                            value={state.eventDescription}
                            onChange={handleChange('eventDescription')}
                          />

                        </div>

                        <h4 >Event Short Description * (1000 Characters Max) </h4>
                        <div className="form-group mb-0">
                          <FormTextArea
                            className="buytext2 w-100 h-auto"
                            name="note"
                            id="note"
                            rows="3"
                            type="textarea"
                            defaultValue={state.eventShortDescription}
                            placeholder="Tell us about the Event in short description -max 1000 characters *"
                            value={state.eventShortDescription}
                            onChange={handleChange('eventShortDescription')}
                          />

                        </div>
                      </div>
                    </div>

                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="date" name="fromDate" ref={register} value={state.fromDate} />
                    <Row>
                      <div className="col-lg-6 col-sm-12 mt-2">
                        <h4>Starting on</h4>
                        <div className="awd-date">
                          <FormControl>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date1"
                                name="date1"
                                defaultValue={state.fromDate}
                                // ref={register}
                                control={control}
                                value={state.fromDate}
                                onChange={handleDateChange}
                              // KeyboardButtonProps={{
                              //   'aria-label': 'change date',
                              // }}
                              />
                            </MuiPickersUtilsProvider>
                          </FormControl>
                          <FormHelperText style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }} >{(fieldError && (state.fromDate === null || state.fromDate === "")) ? errors.fromDate?.message : ""}</FormHelperText>

                        </div>
                      </div>

                      <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="date" name="toDate" ref={register} value={state.toDate} />
                      <div className="col-lg-6 col-sm-12 mt-2">
                        <h4>Ending on</h4>
                        <div className="awd-date">
                          <FormControl>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date2"
                                name="date2"
                                defaultValue={state.toDate}
                                // ref={register}
                                control={control}
                                value={state.toDate}
                                onChange={handleDateChange1}
                                minDate={state.fromDate}
                              // KeyboardButtonProps={{
                              //   'aria-label': 'change date',
                              // }}
                              />
                            </MuiPickersUtilsProvider>
                          </FormControl>
                          <FormHelperText style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }} >{(fieldError && (state.toDate === null || state.toDate === "")) ? errors.toDate?.message : ""}</FormHelperText>

                        </div>
                      </div>
                    </Row>

                    {/* Time */}

                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="time" name="fromTime" ref={register} value={state.fromTime} />
                    <Row>
                      <div className="col-lg-6 col-sm-12 mt-2">
                        <h4>Starting Time</h4>
                        <div className="awd-date">
                          <FormControl>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                              <KeyboardTimePicker
                                disableToolbar
                                variant="inline"

                                margin="normal"
                                id="time1"
                                name="time1"
                                defaultValue={state.fromTime}
                                // ref={register}
                                control={control}
                                value={state.fromTime}
                                onChange={handleTimeChange}
                                ampm={false}
                              // KeyboardButtonProps={{
                              //   'aria-label': 'change date',
                              // }}
                              />
                            </MuiPickersUtilsProvider>
                            {/* <FormHelperText style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }} >{errors.fromTime?.message}</FormHelperText> */}
                          </FormControl>
                        </div>
                      </div>

                      <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="time" name="toTime" ref={register} value={state.toTime} />
                      <div className="col-lg-6 col-sm-12 mt-2">
                        <h4>Ending Time</h4>
                        <div className="awd-date">
                          <FormControl>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                              <KeyboardTimePicker
                                disableToolbar
                                variant="inline"
                                margin="normal"
                                id="time2"
                                name="time2"
                                defaultValue={state.toTime}
                                // ref={register}
                                control={control}
                                value={state.toTime}
                                onChange={handleTimeChange1}
                                ampm={false}
                              // KeyboardButtonProps={{
                              //   'aria-label': 'change date',
                              // }}
                              />
                            </MuiPickersUtilsProvider>
                            {/* <FormHelperText style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }} >{errors.toTime?.message}</FormHelperText> */}
                          </FormControl>
                        </div>
                      </div>
                    </Row>

                    <Row>
                      <div className="col-lg-6 col-sm-12 mt-3">
                        <FormControl>
                          <h4>TimeZone * <p style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{errors.timeZone?.message}</p></h4>
                          <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="timeZone" name="timeZone" ref={register} value={state.timeZone} />

                          <TimezonePicker
                            absolute={false}
                            // defaultValue  = {state.timeZone}
                            value={state.timeZone}
                            placeholder="Select timezone..."
                            onChange={handleTimeZone}
                          />
                        </FormControl>

                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="form-group">
                          <FormInput
                            className="comp-name mt-4"
                            id="venuetext"
                            name="venuetext"
                            placeholder=""
                            label="Event Venue Text for Signup"
                            type="text"
                            variant="outlined"
                            value={state.venuetext}
                            defaultValue={state.venuetext}
                          //  register={register}
                            error={errors.venuetext}
                            onChange={handleChange('venuetext')}
                          />
                        </div>
                      </div>

                    </Row>


                    <div className="row">
                      <div className="col-lg-6 mt-3">

                        <FormTextArea
                          className="buytext2 w-100 h-auto"
                          name="address"
                          id="address"
                          rows="4"
                          type="textarea"
                          label="Venue Address *"
                          defaultValue={state.address}
                          placeholder="Venue Address"
                          value={watchAll.address}
                          register={register}
                          onChange={handleChange("address")}
                          error={errors.address}
                        />
                        <div className="row">
                          <FormInput
                            className="comp-name mt-4"
                            id="eventmap"
                            name="eventMapUrl"
                            placeholder=""
                            label="Event Location Map URL"
                            type="text"
                            variant="outlined"
                            value={watchAll.eventMapUrl}
                            defaultValue={state.eventMapUrl}
                            register={register}
                            error={errors.eventMapUrl}
                            onChange={handleChange('eventMapUrl')}
                          />
                        </div>

                      </div>
                      <div className="col-lg-6 mt-4 ">
                        <div className="row">
                          <FormControl variant="outlined" style={{ width: "100%" }}>
                            <FormAutocomplete
                              id="country"
                              name="country"
                              label="Country *"
                              options={state.countryOptions}
                              placeholder="Select Country"
                              disableCloseOnSelect
                              blurOnSelect
                              limitTags={1}
                              control={control}
                              input="country"
                              getOptionSelected={(options, value) => options.name === value.name}
                              value={state.country}
                              handleChangeSelect={handleChangeSelect}
                              errMessage={"Please Select Country"}
                              defaultValue={state.country}
                            />
                          </FormControl>
                          <FormHelperText style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }} >{(fieldError && (!state.country || (Array.isArray(state.country) && state.country.length === 0))) ? "Please Select Country" : ""}</FormHelperText>
                        </div>


                        <div className="row">
                          <FormInput
                            className="comp-name mt-4"
                            id="outlined-search"
                            name="zipCode"
                            placeholder=""
                            label="Zip/Pincode *"
                            type="text"
                            variant="outlined"
                            value={state.zipCode}
                            defaultValue={state.zipCode}
                          //  register={register}
                            error={errors.zipCode}
                            onChange={handleChangeEventForm('zipCode')}
                          />
                        </div>

                        <div className="row">
                          <FormInput
                            className="comp-name mt-4"
                            id="outlined-search1"
                            name="city"
                            label="City *"
                            type="text"
                            variant="outlined"
                            value={state.city}
                            defaultValue={state.city}
                            error={errors.city}
                          //  register={register}
                            onChange={handleChangeEventForm('city')}
                          />
                        </div>
                      </div>
                    </div>

                    <Row>

                      <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="eventLogo" name="eventLogo" ref={register} value={state.eventLogo} />
                      {/* <h4>Event Logo *{!errors.eventLogo || state.eventLogo ? '' : <span style={{color: '#f44336', fontSize:'0.75rem'}}>   Please Select Event Logo</span>}</h4> */}

                      <div className="col-xl-2 col-lg-3  col-md-4 col-sm-12 mt-3">
                        <h4>Event Logo *{!errors.eventLogo || state.eventLogo ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select Event Logo</span>}</h4>

                        <ProfileUploader img={state.eventLogoId ? process.env.REACT_APP_CDN_URL + imageSrc : imageSrc ? process.env.REACT_APP_CDN_URL_TEMP + imageSrc : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
                          handleChange={handleChangeImage} selectImg={selectImg} image={image} handleCropClose={handleCropClose}
                        />
                      </div>

                      <div className="mob-para col-lg-5 col-md-7 col-sm-12 mt-5 pl-4">
                        <p className="image-hint">Accepts Jpeg / Jpg / Png Only</p>
                        <p className="image-hint">(Max size allowed 200 Kb)</p>
                      </div>
                    </Row>

                    {/* <div className="col-lg-12 ">

                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="eventSignupBanner" name="eventSignupBanner" ref={register} value={state.eventSignupBanner} />

                  <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Signup Background Banner Image

                    {!errors.eventSignupBanner || state.eventSignupBanner ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select Event Banner</span>}
                  </h4>
                  <h5 style={{ float: 'right' }}>(Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 2 mb)</h5>


                  <div className="form-group mt-3" style={{ height: 'auto' }}>
                    <BannerUploader img={state.eventSignupBannerId ? process.env.REACT_APP_CDN_URL + imageSrcBanner : imageSrcBanner ? process.env.REACT_APP_CDN_URL_TEMP + imageSrcBanner : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
                      handleChange={handleChangeBanner} selectImg={selectBannerImg} image={imageBan} handleCropClose={e => setImageBanner('')}
                    />
                    {loading && <div style={{
                      position: 'relative', display: 'block',
                      top: '50%',
                      left: '50%',
                      zIndex: '1000',
                    }}><Spinner /></div>}
                  </div>
                 
                  <div className="text-right"><span><i>* Image resolution should be 1800 * 450 </i></span></div>

                </div> */}
                    <div className="col-lg-12 " style={{marginTop:"2.5rem"}}>

                      <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="eventBanner" name="eventBanner" ref={register} value={state.eventBanner} />

                      <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Event Banner Image

                        {!errors.eventBanner || state.eventBanner ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select Event Banner</span>}
                      </h4>
                      <h5 style={{ float: 'right' }}>(Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 5 mb)</h5>


                      <div className="form-group mt-3" style={{ height: 'auto' }}>
                        {/* <MultiUpload multiple={true} limit={1} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="bannerImages"/> */}
                        <BannerUploader img={state.eventBannerId ? process.env.REACT_APP_CDN_URL + imageSrcBanner : imageSrcBanner ? process.env.REACT_APP_CDN_URL_TEMP + imageSrcBanner : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
                          handleChange={handleChangeBanner} selectImg={selectBannerImg} image={imageBan} handleCropClose={e => setImageBanner('')}
                        />
                        {loading && <div style={{
                          position: 'relative', display: 'block',
                          top: '50%',
                          left: '50%',
                          zIndex: '1000',
                        }}><Spinner /></div>}
                      </div>
                      {/* <div className="ban-preview mt-3">
   <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={bannerId ? process.env.REACT_APP_CDN_URL+imageSrc : imageSrc ? process.env.REACT_APP_CDN_URL_TEMP+imageSrc : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}/>
  </div>       */}
                      <div className="text-right"><span><i>* 2048 X 400 (Use a resolution of 5:1) </i></span></div>

                    </div>
                    <div className="col-lg-12" style={{marginTop:"2.5rem"}}>

                      <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="eventBanner" name="eventBanner" ref={register} value={state.eventMailBanner} />

                      <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Event Mailing Banner Image

                        {!errors.eventBanner || state.eventMailBanner ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select Event Mailing Banner Image</span>}
                      </h4>
                      <h5 style={{ float: 'right' }}>(Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 5 mb)</h5>


                      <div className="form-group mt-3" style={{ height: 'auto' }}>
                        <MailBannerUploader img={state.eventMailBannerId ? process.env.REACT_APP_CDN_URL + imageSrcEmailBanner : imageSrcEmailBanner ? process.env.REACT_APP_CDN_URL_TEMP + imageSrcEmailBanner : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
                          handleChange={handleChangeEmailBanner} selectImg={selectEmailBannerImg} image={imageEmailBan} handleCropClose={e => setEmailBanner('')}
                        />
                        {loading && <div style={{
                          position: 'relative', display: 'block',
                          top: '50%',
                          left: '50%',
                          zIndex: '1000',
                        }}><Spinner /></div>}
                      </div>
                      <div className="text-right"><span><i>* 2048 X 400 (Use a resolution of 5:1) </i></span></div>
                    </div>

                    <div className="col-lg-12" style={{marginTop:"2.5rem"}}>

<input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="eventBanner" name="eventBanner" ref={register} value={state.eventMailBanner} />

<h4 style={{ textAlign: 'left', display: 'inline-block' }}>Event Listing Image </h4>
<h5 style={{ float: 'right' }}>(Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 5 mb)</h5>
<div className="form-group mt-3" style={{ height: 'auto' }}>
  <MailBannerUploader img={state.eventListingImageId ? process.env.REACT_APP_CDN_URL + state.eventListingImage : eventListingImage ? process.env.REACT_APP_CDN_URL_TEMP + eventListingImage : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
    handleChange={handleChangeEventListingImage} selectImg={selectEventListingImage} image={listingImage} handleCropClose={e => setListingImage('')}
  />
  {loading && <div style={{
    position: 'relative', display: 'block',
    top: '50%',
    left: '50%',
    zIndex: '1000',
  }}><Spinner /></div>}
</div>
<div className="text-right"><span><i>* 2048 X 400 (Use a resolution of 5:1) </i></span></div>
</div>



<div className="col-lg-12" style={{marginTop:"2.5rem"}}>

<input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="eventBanner" name="eventBanner" ref={register} value={state.eventMailBanner} />

<h4 style={{ textAlign: 'left', display: 'inline-block' }}>Event Share Image </h4>
<h5 style={{ float: 'right' }}>(Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 5 mb)</h5>
<div className="form-group mt-3" style={{ height: 'auto' }}>
  <MailBannerUploader img={state.eventShareImageId ? process.env.REACT_APP_CDN_URL + state.eventShareImage : eventShareImage ? process.env.REACT_APP_CDN_URL_TEMP + eventShareImage : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
    handleChange={handleChangeShareImage} selectImg={selectShareImage} image={shareImage} handleCropClose={e => setShareImage('')}
  />
  {loading && <div style={{
    position: 'relative', display: 'block',
    top: '50%',
    left: '50%',
    zIndex: '1000',
  }}><Spinner /></div>}
</div>
<div className="text-right"><span><i>* 546 X 546 (Use a resolution of 1:1) </i></span></div>
</div>


<div className="col-lg-12" style={{marginTop:"2.5rem"}}>

<input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="eventBanner" name="eventBanner" ref={register} value={state.eventMailBanner} />

<h4 style={{ textAlign: 'left', display: 'inline-block' }}>Event Social Image </h4>
<h5 style={{ float: 'right' }}>(Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 5 mb)</h5>
<div className="form-group mt-3" style={{ height: 'auto' }}>
  <MailBannerUploader img={state.eventSocialImageId ? process.env.REACT_APP_CDN_URL + state.eventSocialImage : eventSocialImage ? process.env.REACT_APP_CDN_URL_TEMP + eventSocialImage : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
    handleChange={handleChangeSocialImage} selectImg={selectSocialImage} image={socialImage} handleCropClose={e => setSocialImage('')}
  />
  {loading && <div style={{
    position: 'relative', display: 'block',
    top: '50%',
    left: '50%',
    zIndex: '1000',
  }}><Spinner /></div>}
</div>
<div className="text-right"><span><i>* 546 X 546 (Use a resolution of 1:1) </i></span></div>
</div>

                    <div className="row">
                      <div className="col-lg-6 mt-3">
                        <div className="form-group">
                          <FormRadio
                            name="Type"
                            className="text-sm d-inline mb-0"
                            label="Type"
                            options={radioGroupOptions}
                            control={control}

                            register={register}
                            // row
                            onChange={handleChange('type')}
                            value={state.type}
                            defaultValue={state.type}
                            error={errors.type}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mt-3">
                        <div className="form-group">
                          <FormInput
                            className="comp-name mt-4"
                            id="eventsite"
                            name="eventSiteUrl"
                            placeholder=""
                            label="Event Site URL"
                            type="text"
                            variant="outlined"
                            value={watchAll.eventSiteUrl}
                            defaultValue={state.eventSiteUrl}
                            register={register}
                            error={errors.eventSiteUrl}
                            onChange={handleChange('eventSiteUrl')}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="row">
                  <div className="col-lg-6 mt-3">
                    <FormInput
                      className="comp-name mt-4"
                      id="outlined-search"
                      name="regTemplate"
                      placeholder=""
                      label="Register Mail Template Id *"
                      type="text"
                      variant="outlined"
                      value={watchAll.regTemplate}
                      defaultValue={state.regTemplate}
                      register={register}
                      error={errors.regTemplate}
                      onChange={handleChange('regTemplate')}
                    />
                  </div>

                  <div className="col-lg-6 mt-3">
                    <FormInput
                      className="comp-name mt-4"
                      id="outlined-search"
                      name="approvingNewUser"
                      placeholder=""
                      label="Approving New User Mail Template Id *"
                      type="text"
                      variant="outlined"
                      value={watchAll.approveNewUserTemplate}
                      defaultValue={state.approveNewUserTemplate}
                      register={register}
                      error={errors.approveNewUserTemplate}
                      onChange={handleChange('approveNewUserTemplate')}
                    />
                  </div>

                </div> */}

                    {/* <div className="row">
                  <div className="col-lg-6 mt-3">
                    <FormInput
                      className="comp-name mt-4"
                      id="outlined-search"
                      name="approvingExistingUser"
                      placeholder=""
                      label="Approving Existing User Mail Template Id *"
                      type="text"
                      variant="outlined"
                      value={watchAll.approveExistingUserTemplate}
                      defaultValue={state.approveExistingUserTemplate}
                      register={register}
                      error={errors.approveExistingUserTemplate}
                      onChange={handleChange('approveExistingUserTemplate')}
                    />
                  </div>

                  <div className="col-lg-6 mt-3">
                    <FormInput
                      className="comp-name mt-4"
                      id="outlined-search"
                      name="dismissUserTemplate"
                      placeholder=""
                      label="Dismiss User Mail Template Id *"
                      type="text"
                      variant="outlined"
                      value={watchAll.dismissUserTemplate}
                      defaultValue={state.dismissUserTemplate}
                      register={register}
                      error={errors.dismissUserTemplate}
                      onChange={handleChange('dismissUserTemplate')}
                    />
                  </div>

                </div> */}

                    {/* <div className="row">
                  <div className="col-lg-6 mt-3">
                    <FormInput
                      className="comp-name mt-4"
                      id="outlined-search"
                      name="associateUserTemplate"
                      placeholder=""
                      label="Associate User Partial Refusal Mail Template Id *"
                      type="text"
                      variant="outlined"
                      value={watchAll.associateUserTemplate}
                      defaultValue={state.associateUserTemplate}
                      register={register}
                      error={errors.associateUserTemplate}
                      onChange={handleChange('associateUserTemplate')}
                    />
                  </div>

                  <div className="col-lg-6 mt-3">
                    <FormInput
                      className="comp-name mt-4"
                      id="outlined-search"
                      name="associateChannelTemplate"
                      placeholder=""
                      label="Associate Channel Partial Refusal Mail Template Id *"
                      type="text"
                      variant="outlined"
                      value={watchAll.associateChannelTemplate}
                      defaultValue={state.associateChannelTemplate}
                      register={register}
                      error={errors.associateChannelTemplate}
                      onChange={handleChange('associateChannelTemplate')}
                    />
                  </div>

                </div> */}

                    <Row>

                      <div className="col-lg-6 col-sm-12 mt-4">
                        <h4>Allowed user type *</h4>
                        <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="allowedUserType1" name="allowedUserType1" ref={register} value={state.sellerOption.filter(x => x.status).length ? 'set' : ''} />
                        <h5>Seller <p style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{sellerError == true ? errors.allowedUserType1?.message : ""}</p></h5>


                        {state.sellerOption.map(x => (
                          <Card>
                            <div className="chck">
                              <FormCheckBox
                                id={x.name}
                                type="checkbox"
                                label={x.name}
                                checked={x.status}
                                label1={{ textAlign: "justify" }}
                                onClick={handleChange(x.name)}
                                data-type="1"
                              //register={register}
                              />
                              {(x.status && state.type != 2) &&
                                <FormRadio
                                  name="package"
                                  options={[{ value: "1", label: "With Package" }, { value: "2", label: "Without Package" }]}
                                  control={control}
                                  register={register}
                                  value={x.withPackage ? '1' : '2'}
                                  onClick={handlePackage(1, x.name)}

                                />}
                            </div>
                          </Card>
                        ))}



                      </div>




                      <div className="col-lg-6 col-sm-12 mt-4">
                        <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="allowedUserType2" name="allowedUserType2" ref={register} value={state.buyerOption.filter(x => x.status).length ? 'set' : ''} />
                        <h5 className="mt-4">Buyer <p style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{buyerError == true ? errors.allowedUserType2?.message : ""}</p></h5>

                        {state.buyerOption.map((x, i) => (
                          <Card>
                            <div className="chck">
                              <FormCheckBox
                                id={x.name + i}
                                type="checkbox"
                                label={x.name}
                                checked={x.status}
                                label1={{ textAlign: "justify" }}
                                onClick={handleChange(x.name)}
                                data-type="2"
                              //register={register}
                              />

                              {(x.status && state.type != 2) &&
                                <FormRadio
                                  name="package"
                                  options={[{ value: "1", label: "With Package" }, { value: "2", label: "Without Package" }]}
                                  control={control}
                                  register={register}
                                  value={x.withPackage ? '1' : '2'}
                                  onClick={handlePackage(2, x.name)}
                                  data-type="2"
                                />}
                            </div>

                          </Card>
                        ))}
                      </div>
                    </Row>

                    {/* code added @16/8 */}
                    <div className="row">

                      <div className="col-lg-6 mt-3">
                        <div className="form-group">
                          <FormRadio
                            name="vendorStatus"
                            className="text-sm d-inline mb-0"
                            label="Seller Event Status"
                            options={sellerStatus}
                            control={control}

                            register={register}
                            // row
                            onChange={handleChange('vendorStatus')}
                            value={state.vendorStatus}
                            defaultValue={state.vendorStatus}
                            error={errors.vendorStatus}
                          />
                        </div>
                      </div>

                      {+state.vendorStatus === 4 &&
                        <div className="col-lg-6 mt-3">
                          <div className="form-group">
                            <FormInput
                              className="comp-name mt-4"
                              id="register-url"
                              name="sellerEventRegisterURL"
                              placeholder=""
                              label="Seller Event Register URL"
                              type="text"
                              variant="outlined"
                              value={watchAll.sellerEventRegisterURL}
                              defaultValue={state.sellerEventRegisterURL}
                              register={register}
                              error={errors.sellerEventRegisterURL}
                              onChange={handleChange('sellerEventRegisterURL')}
                            />
                          </div>
                        </div>}
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mt-3">
                        <div className="form-group">
                          <FormRadio
                            name="vendorSecondaryToRegister"
                            className="text-sm d-inline mb-0"
                            label="Allow Seller Secondary User Registration"
                            options={allowUserRegistration}
                            control={control}

                            register={register}
                            // row
                            onChange={handleChange('vendorSecondaryToRegister')}
                            value={state.vendorSecondaryToRegister}
                            defaultValue={state.vendorSecondaryToRegister}
                            error={errors.vendorSecondaryToRegister}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mt-3">
                        <div className="form-group">
                          <FormRadio
                            name="buyerStatus"
                            className="text-sm d-inline mb-0"
                            label="Buyer Event Status"
                            options={buyerStatus}
                            control={control}

                            register={register}
                            // row
                            onChange={handleChange('buyerStatus')}
                            value={state.buyerStatus}
                            defaultValue={state.buyerStatus}
                            error={errors.buyerStatus}
                          />
                        </div>
                      </div>
                      {+state.buyerStatus === 4 &&
                        <div className="col-lg-6 mt-3">
                          <div className="form-group">
                            <FormInput
                              className="comp-name mt-4"
                              id="register-url"
                              name="buyerEventRegisterURL"
                              placeholder=""
                              label="Buyer Event Register URL"
                              type="text"
                              variant="outlined"
                              value={watchAll.buyerEventRegisterURL}
                              defaultValue={state.buyerEventRegisterURL}
                              register={register}
                              error={errors.buyerEventRegisterURL}
                              onChange={handleChange('buyerEventRegisterURL')}
                            />
                          </div>
                        </div>}
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mt-3">
                        <div className="form-group">
                          <FormRadio
                            name="buyerSecondaryToRegister"
                            className="text-sm d-inline mb-0"
                            label="Allow Buyer Secondary User Registration"
                            options={allowUserRegistration}
                            control={control}

                            register={register}
                            // row
                            onChange={handleChange('buyerSecondaryToRegister')}
                            value={state.buyerSecondaryToRegister}
                            defaultValue={state.buyerSecondaryToRegister}
                            error={errors.buyerSecondaryToRegister}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mt-3">
                        <div className="form-group">
                          <FormRadio
                            name="multiMeet"
                            className="text-sm d-inline mb-0"
                            label="Allow or not multiple meetings between 2 companies / different peoples"
                            options={allowMultiMeeting}
                            control={control}

                            register={register}
                            // row
                            onChange={handleChange('multiMeet')}
                            value={state.multiMeet}
                            defaultValue={state.multiMeet}
                            error={errors.buyerSecondaryToRegister}
                          />
                        </div>
                      </div>
                    </div>


                    {state.type != 2 && <>
                      <div className="mt-4">
                        <Accordion >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography className="">Seller Packages</Typography>
                          </AccordionSummary>
                          {/* <AccordionDetails>
          <Typography>
           Vendor 
        <i  class="fa fa-plus-circle faplus11 d-inline" ></i>
        </Typography>
        </AccordionDetails>
        <AccordionDetails>
          <Typography>
           Distributor <i  class="fa fa-plus-circle faplus11 d-inline" ></i>
          </Typography>
        </AccordionDetails> */}

                          {
                            state.sellerOption.map((x, i) => (
                              <>
                                {x.withPackage && <AccordionDetails>
                                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="sellerPackages" name={`sellerPackages[${i}].value`} ref={register} value={state.sellerPackages.filter(y => y.channelType == x.id).length ? 'set' : ''} />
                                  <Typography>
                                    {x.name}
                                    {/* <i  class="fa fa-plus-circle faplus11 d-inline" onClick={() => {setOpen(true); setPackageData({channelType: x.id, isBuyer: '0', isSeller: '1'})}}> */}
                                    <p style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{errors.sellerPackages ? errors.sellerPackages[i] ? errors.sellerPackages[i].value?.message : '' : ''}</p>
                                    {/* </i> */}

                                    {/* <div className="listbill">
                                  <Row>
                                    <Col lg="11" className="pad-less">
                                      <Row>
                                        <Col><h3 className="text-center">Package Name</h3></Col>
                                        <Col><h3 className="text-center">Price</h3></Col>
                                        <Col><h3 className="text-center">Package Type</h3></Col>
                                        <Col><h3 className="text-center">Package User</h3></Col>
                                        <Col><h3 className="text-center">Edit</h3></Col>
                                        <Col><h3 className="text-center">Addon</h3></Col>
                                        <Col><h3 className="text-center">Delete</h3></Col>
                                      </Row>
                                    </Col>
                                    <Col lg="1"  ><i class="fas fa-plus-circle faplus1 mt-3" onClick={() => { setOpen(true); setPackageData({ channelType: x.id, isBuyer: '0', isSeller: '1' }) }}></i></Col>
                                  </Row>
                                </div> */}
                                    <Card className="w-100 mt-3 listbill1">
                                      <CardBody>
                                        <ListGroup className="list my--3" flush>
                                          <ListGroupItem className="px-0">

                                            {/* {state.sellerPackages?.filter(y => y.channelType == x.id).map((item, i) => (
                                          <Row key={i}>
                                            <Col lg="12" className="pad-less">
                                              <Row>

                                                <Col className="pad-less">
                                                  <h4 className="text-center">{item.packageName}</h4></Col>
                                                <Col ><h4 className="text-center">{item.price}</h4></Col>
                                                <Col ><h4 className="text-center">{item.packageType?.name}</h4></Col>
                                                <Col ><h4 className="text-center">{item.packageUser?.name}</h4></Col>
                                                <Col ><h4 className="text-center"><i class="fas fa-edit d-inline" onClick={() => editPackage(item)}></i></h4></Col>
                                                {item.packageType?.id == 1 ?
                                                  <Col ><h4 className="text-center"><i class="fas fa-plus-circle d-inline" onClick={() => { setAddonOpen(true); setPackageData({ ...item, isAddon: true, eventId: eventId }) }}></i></h4></Col> :
                                                  <Col ><h4 className="text-center"></h4></Col>}
                                                <Col ><h4 className="text-center"><i class="fas fa-trash d-inline" onClick={() => { deletePackage(item) }}></i></h4></Col>


                                              </Row>
                                            </Col>
                                          </Row>
                                        ))} */}

                                            <div className="table-responsive">
                                              <table className="table">
                                                <thead className="listbill">
                                                  <tr>
                                                    <th width="35%">Package <br />Name</th>
                                                    <th>Price</th>
                                                    <th>Package <br /> Type</th>
                                                    <th>Package <br />User</th>
                                                    <th>Edit</th>
                                                    <th>Copy</th>
                                                    {/* <th>Add On</th> */}
                                                    <th>Delete</th>
                                                    <th><i class="fas fa-plus-circle faplus1 mt-3" onClick={() => { setOpen(true); setPackageData({ channelType: x.id, isBuyer: '0', isSeller: '1', isOther: '0'}) }}></i></th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {state.sellerPackages?.filter(y => y.channelType == x.id).map((item, i) => (
                                                    <tr key={i}>
                                                      <td>{item.packageName}</td>
                                                      <td>{item.price}</td>
                                                      <td>{item.packageType?.name}</td>
                                                      <td>{item.packageUser?.name}</td>
                                                      <td>
                                                        <i className="fas fa-edit d-inline" onClick={() => editPackage(item)}></i>
                                                      </td>
                                                      <td>
                                                        <i className="far fa-clone" onClick={() => copyPackage(item)}></i>
                                                      </td>
                                                      {/* <td>
            {item.packageType?.id === 1 ? (
              <i className="fas fa-plus-circle d-inline" onClick={() => { setAddonOpen(true); setPackageData({ ...item, isAddon: true, eventId: eventId }) }}></i>
            ) : (
              ''
            )}
          </td> */}
                                                      <td>
                                                        <i className="fas fa-trash d-inline" onClick={() => { deletePackage(item) }}></i>
                                                      </td>
                                                      <td></td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </table>
                                            </div>


                                          </ListGroupItem>
                                        </ListGroup>
                                      </CardBody>
                                    </Card>




                                  </Typography>


                                </AccordionDetails>}
                              </>
                            ))
                          }

                        </Accordion>
                      </div>

                      <div className="mt-4 ">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography className="">Buyer Packages</Typography>
                          </AccordionSummary>
                          {/* <AccordionDetails>
          <Typography>
           Vendor 
        <i  class="fa fa-plus-circle faplus11 d-inline" ></i>
        </Typography>
        </AccordionDetails>
        <AccordionDetails>
          <Typography>
           Distributor <i  class="fa fa-plus-circle faplus11 d-inline" ></i>
          </Typography>
        </AccordionDetails> */}

                          {
                            state.buyerOption.map((x, i) => (
                              <>
                                {x.withPackage && <AccordionDetails className="eve_list">
                                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="buyerPackages" name={`buyerPackages[${i}].value`} ref={register} value={state.buyerPackages.filter(y => y.channelType == x.id).length ? 'set' : ''} />
                                  <Typography>
                                    {x.name}
                                    {/* <i  class="fa fa-plus-circle faplus11 d-inline" onClick={() => {setOpen(true); setPackageData({channelType: x.id, isBuyer: '1', isSeller: '0'})}}> */}
                                    <p style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{errors.buyerPackages ? errors.buyerPackages[i] ? errors.buyerPackages[i].value?.message : '' : ''}</p>
                                    {/* </i> */}


                                    <div className="listbill">
                                      {/* <Row>
                                    <Col lg="11" className="pad-less">
                                      <Row>
                                        <Col><h3 className="text-center">Package Name</h3></Col>
                                        <Col><h3 className="text-center">Price</h3></Col>
                                        <Col><h3 className="text-center">Package Type</h3></Col>
                                        <Col><h3 className="text-center">Package User</h3></Col>
                                        <Col><h3 className="text-center">Edit</h3></Col>
                                        <Col><h3 className="text-center">Addon</h3></Col>
                                        <Col><h3 className="text-center">Delete</h3></Col>
                                      </Row>
                                    </Col>
                                    <Col lg="1"  ><i class="fas fa-plus-circle faplus1 mt-3" onClick={() => { setOpen(true); setPackageData({ channelType: x.id, isBuyer: '1', isSeller: '0' }) }}></i></Col>
                                  </Row> */}
                                    </div>
                                    <Card className="w-100 mt-3 listbill1">
                                      <CardBody>
                                        <ListGroup className="list my--3" flush>
                                          <ListGroupItem className="px-0">

                                            <div className="table-responsive">
                                              <table className="table">
                                                <thead className="listbill">
                                                  <tr>
                                                    <th width="35%">Package <br />Name</th>
                                                    <th>Price</th>
                                                    <th>Package <br /> Type</th>
                                                    <th>Package <br />User</th>
                                                    <th>Edit</th>
                                                    <th>Copy</th>
                                                    {/* <th>Add On</th> */}
                                                    <th>Delete</th>
                                                    <th><i class="fas fa-plus-circle faplus1 mt-3" onClick={() => { setOpen(true); setPackageData({ channelType: x.id, isBuyer: '1', isSeller: '0',isOther: '0' }) }}></i></th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {state.buyerPackages?.filter(y => y.channelType == x.id).map((item, i) => (
                                                    <tr key={i}>
                                                      <td class="wrapText">{item.packageName}</td>
                                                      <td>{item.price}</td>
                                                      <td>{item.packageType?.name}</td>
                                                      <td>{item.packageUser?.name}</td>
                                                      <td>
                                                        <i className="fas fa-edit d-inline" onClick={() => editPackage(item)}></i>
                                                      </td>
                                                      <td>
                                                        <i className="far fa-clone " onClick={() => copyPackage(item)}></i>
                                                      </td>
                                                      {/* <td>
          {item.packageType?.id === 1 ? (
            <i className="fas fa-plus-circle d-inline" onClick={() => { setAddonOpen(true); setPackageData({ ...item, isAddon: true, eventId: eventId }) }}></i>
          ) : (
            ''
          )}
        </td> */}
                                                      <td>
                                                        <i className="fas fa-trash d-inline" onClick={() => { deletePackage(item) }}></i>
                                                      </td>
                                                      <td></td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </table>
                                            </div>




                                          </ListGroupItem>
                                        </ListGroup>
                                      </CardBody>
                                    </Card>

                                  </Typography>



                                </AccordionDetails>}
                              </>
                            ))
                          }

                        </Accordion>
                      </div>

                      <div className="mt-4 ">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                          <Typography className="">Other's Packages</Typography>
                          </AccordionSummary>
                          {
                            state.otherOption.map((x, i) => (
                              <>
                                {x.withPackage && <AccordionDetails className="eve_list">
                                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="Packages" name={`otherPackages[${i}].value`} ref={register} value={state.otherPackages.filter(y => y.channelType == x.id).length ? 'set' : ''} />
                                  <Typography>
                                    {x.name}
                                    {/* <i  class="fa fa-plus-circle faplus11 d-inline" onClick={() => {setOpen(true); setPackageData({channelType: x.id, isBuyer: '1', isSeller: '0'})}}> */}
                                    <p style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{errors.otherPackages ? errors.otherPackages[i] ? errors.otherPackages[i].value?.message : '' : ''}</p>
                                    {/* </i> */}


                                    <div className="listbill">
                                      
                                    </div>
                                    <Card className="w-100 mt-3 listbill1">
                                      <CardBody>
                                        <ListGroup className="list my--3" flush>
                                          <ListGroupItem className="px-0">

                                            <div className="table-responsive">
                                              <table className="table">
                                                <thead className="listbill">
                                                  <tr>
                                                    <th width="35%">Package <br />Name</th>
                                                    <th>Price</th>
                                                    <th>Package <br /> Type</th>
                                                    <th>Package <br />User</th>
                                                    <th>Edit</th>
                                                    <th>Copy</th>
                                                    {/* <th>Add On</th> */}
                                                    <th>Delete</th>
                                                    <th><i class="fas fa-plus-circle faplus1 mt-3" onClick={() => { setOpen(true); setPackageData({ channelType: x.id, isBuyer: '0', isSeller: '0',  isOther: '1' }) }}></i></th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {state.otherPackages?.filter(y => y.channelType == x.id).map((item, i) => (
                                                    <tr key={i}>
                                                      <td class="wrapText">{item.packageName}</td>
                                                      <td>{item.price}</td>
                                                      <td>{item.packageType?.name}</td>
                                                      <td>{item.packageUser?.name}</td>
                                                      <td>
                                                        <i className="fas fa-edit d-inline" onClick={() => editPackage(item)}></i>
                                                      </td>
                                                      <td>
                                                        <i className="far fa-clone " onClick={() => copyPackage(item)}></i>
                                                      </td>
                                                      <td>
                                                        <i className="fas fa-trash d-inline" onClick={() => { deletePackage(item) }}></i>
                                                      </td>
                                                      <td></td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </table>
                                            </div>
                                          </ListGroupItem>
                                        </ListGroup>
                                      </CardBody>
                                    </Card>
                                  </Typography>
                                </AccordionDetails>}
                              </>
                            ))
                          }
                        </Accordion>
                      </div>

                      {/* <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="date" name="fromDate" ref={register} value={state.fromDate} /> */}
                      <Row>
                        <div className="col-lg-6 col-sm-12 mt-2">
                          <h4>Buyer List for Vendor </h4>
                          <div className="awd-date">
                            <FormControl>
                              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                <KeyboardDatePicker
                                  disableToolbar
                                  variant="inline"
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  id="vendorListDate"
                                  name="vendorListDate"
                                  defaultValue={state.vendorListDate}
                                  // ref={register}
                                  control={control}
                                  value={state.vendorListDate}
                                  onChange={handleVendorListDateChange}
                                // KeyboardButtonProps={{
                                //   'aria-label': 'change date',
                                // }}
                                />
                              </MuiPickersUtilsProvider>
                              {/* <FormHelperText style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }} >{(fieldError && (state.vendorListDate === null || state.vendorListDate === "")) ? errors.fromDate?.message : ""}</FormHelperText> */}
                            </FormControl>
                          </div>
                        </div>

                        <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="date" name="toDate" ref={register} value={state.toDate} />
                        <div className="col-lg-6 col-sm-12 mt-2">

                          <FormTextArea
                            className="buytext2 w-100 h-auto"
                            name="vendorListMsg"
                            id="vendorListMsg"
                            rows="2"
                            type="textarea"
                            label="Vendor Listing Message *"
                            defaultValue={state.vendorListMsg}
                            style={{ borderColor: fieldError === true && (state.vendorListMsg === null || state.vendorListMsg === "") ? '#f44336' : '' }}
                            //placeholder="Tell us about the Event -max 5000 characters *"
                            value={state.vendorListMsg}
                            onChange={handleChange('vendorListMsg')}
                          />
                          <FormHelperText style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{(fieldError && (state.vendorListMsg === null || state.vendorListMsg === "")) ? "Please Enter Vendor Listing Message" : ""}</FormHelperText>

                        </div>

                      </Row>

                      {/* <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="date" name="fromDate" ref={register} value={state.fromDate} /> */}
                      <Row>
                        <div className="col-lg-6 col-sm-12 mt-2">
                          <h4>Vendor List for Buyer </h4>
                          <div className="awd-date">
                            <FormControl>
                              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                <KeyboardDatePicker
                                  disableToolbar
                                  variant="inline"
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  id="buyerListDate"
                                  name="buyerListDate"
                                  defaultValue={state.buyerListDate}
                                  // ref={register}
                                  control={control}
                                  value={state.buyerListDate}
                                  onChange={handleBuyerListDateChange}
                                // KeyboardButtonProps={{
                                //   'aria-label': 'change date',
                                // }}
                                />
                              </MuiPickersUtilsProvider>
                              {/* <FormHelperText style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{(fieldError && (state.buyerListDate === null || state.buyerListDate === "")) ? errors.fromDate?.message : ""}</FormHelperText> */}

                            </FormControl>
                          </div>
                        </div>

                        {/* <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="date" name="toDate" ref={register} value={state.toDate} /> */}
                        <div className="col-lg-6 col-sm-12 mt-2">


                          <FormTextArea
                            className="buytext2 w-100 h-auto"
                            name="buyerListMsg"
                            id="buyerListMsg"
                            rows="2"
                            type="textarea"
                            label="Buyer Listing Message *"
                            defaultValue={state.buyerListMsg}
                            style={{ borderColor: fieldError === true && (state.buyerListMsg === null || state.buyerListMsg === "") ? '#f44336' : '' }}
                            //placeholder="Tell us about the Event -max 5000 characters *"
                            value={state.buyerListMsg}
                            onChange={handleChange('buyerListMsg')}
                          />
                          <FormHelperText style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{(fieldError && (state.buyerListMsg === null || state.buyerListMsg === "")) ? "Please Enter Buyer Listing Message" : ""}</FormHelperText>
                        </div>

                      </Row>

                      {/* <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="date" name="fromDate" ref={register} value={state.fromDate} /> */}
                      <Row>
                        <div className="col-lg-6 col-sm-12 mt-2">
                          <h4>Vendor Meeting Scheduler </h4>
                          <div className="awd-date">
                            <FormControl>
                              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                <KeyboardDatePicker
                                  disableToolbar
                                  variant="inline"
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  id="vendorMeetingDate"
                                  name="vendorMeetingDate"
                                  defaultValue={state.vendorMeetingDate}
                                  // ref={register}
                                  control={control}
                                  value={state.vendorMeetingDate}
                                  onChange={handleVendorMeetingDateChange}
                                // KeyboardButtonProps={{
                                //   'aria-label': 'change date',
                                // }}
                                />
                              </MuiPickersUtilsProvider>
                              {/* <FormHelperText style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }} >{(fieldError && (state.vendorMeetingDate === null || state.vendorMeetingDate === "")) ? errors.fromDate?.message : ""}</FormHelperText> */}
                            </FormControl>
                          </div>
                        </div>

                        {/* <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="date" name="toDate" ref={register} value={state.toDate} /> */}
                        <div className="col-lg-6 col-sm-12 mt-2">


                          <FormTextArea
                            className="buytext2 w-100 h-auto"
                            name="vendorSchedulerMsg"
                            id="vendorSchedulerMsg"
                            rows="2"
                            type="textarea"
                            label="Vendor Sheduler Message *"
                            defaultValue={state.vendorSchedulerMsg}
                            style={{ borderColor: fieldError === true && (state.vendorSchedulerMsg === null || state.vendorSchedulerMsg === "") ? '#f44336' : '' }}
                            // placeholder="Tell us about the Event -max 5000 characters *"
                            value={state.vendorSchedulerMsg}
                            onChange={handleChange('vendorSchedulerMsg')}
                          />
                          <FormHelperText style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{(fieldError && (state.vendorSchedulerMsg === null || state.vendorSchedulerMsg === "")) ? "Please Enter Vendor Sheduler Message" : ""}</FormHelperText>
                        </div>

                      </Row>

                      {/* <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="date" name="fromDate" ref={register} value={state.fromDate} /> */}
                      <Row>
                        <div className="col-lg-6 col-sm-12 mt-2">
                          <h4>Buyer Meeting Scheduler </h4>
                          <div className="awd-date">
                            <FormControl>
                              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                <KeyboardDatePicker
                                  disableToolbar
                                  variant="inline"
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  id="buyerMeetingDate"
                                  name="buyerMeetingDate"
                                  defaultValue={state.buyerMeetingDate}
                                  // ref={register}
                                  control={control}
                                  value={state.buyerMeetingDate}
                                  onChange={handleBuyerMeetingDateChange}
                                // KeyboardButtonProps={{
                                //   'aria-label': 'change date',
                                // }}
                                />
                              </MuiPickersUtilsProvider>
                              {/* <FormHelperText style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }} >{(fieldError && (state.buyerMeetingDate === null || state.buyerMeetingDate === "")) ? errors.fromDate?.message : ""}</FormHelperText> */}
                            </FormControl>
                          </div>
                        </div>

                        {/* <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="date" name="toDate" ref={register} value={state.toDate} /> */}
                        <div className="col-lg-6 col-sm-12 mt-2">


                          <FormTextArea
                            className="buytext2 w-100 h-auto"
                            name="buyerSchedulerMsg"
                            id="buyerSchedulerMsg"
                            rows="2"
                            type="textarea"
                            label="Buyer Sheduler Message *"
                            defaultValue={state.buyerSchedulerMsg}
                            style={{ borderColor: fieldError === true && (state.buyerSchedulerMsg === null || state.buyerSchedulerMsg === "") ? '#f44336' : '' }}
                            //placeholder="Tell us about the Event -max 5000 characters *"
                            value={state.buyerSchedulerMsg}
                            onChange={handleChange('buyerSchedulerMsg')}
                          />
                          <FormHelperText style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{(fieldError && (state.buyerSchedulerMsg === null || state.buyerSchedulerMsg === "")) ? "Please Enter Buyer Sheduler Message" : ""}</FormHelperText>
                        </div>

                      </Row>

                      <Tabs_Controller eventId={state.eventId} handleChange={handleTabFlag} PastTabs={PastTabs} PreTabs={PreTabs} />




                    </>}


                    <div className="text-center mt-4">
                      <button type="submit" className="btn btn-primary" onClick={save}>Save Event</button>
                      {/*onClick={save}*/}
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Container>
          </TabPane>

          <TabPane tabId="2">
            {
              state.eventId != null && state.fromDate != null && state.toDate != null &&
              <SelectHotel eventId={state.eventId} country={countryForSelectHotel} eventFromDate={state.fromDate} eventToDate={state.toDate} />

            }

          </TabPane>

          <TabPane tabId="3">
            { state.eventId != null &&
              <HotelBookingUser eventId={state.eventId} />
            }

          </TabPane>

        </TabContent>
      </div>
    </>
  );
}


const Past_Event_Tabs = [{ id: '1', label: "Participants", checked: true, customName: null, hideorsoon: null, url: null },
{ id: '2', label: "My Meeting Contacts", checked: true, customName: null, hideorsoon: null, url: null },
{ id: '3', label: "Keynote Presentation", checked: true, customName: null, hideorsoon: null, url: null },
{ id: '4', label: "Best Product Winner", checked: true, customName: null, hideorsoon: null, url: null },
{ id: '5', label: "Pictures", checked: true, customName: null, hideorsoon: null, url: null }];



const packageTypeOption = [{ id: 1, name: 'Package' }, { id: 2, name: 'Addon' }]

const packageUserOption = [{ id: 1, name: 'Primary' }, { id: 2, name: 'Secondary' }]

const radioGroupOptions = [
  { value: "1", label: "Physical" },
  { value: "2", label: "Digital" },
  { value: "3", label: "Hybrid " },
];

const sellerStatus = [
  { value: "1", label: "Register in CH" },
  { value: "2", label: "Sold Out" },
  { value: "3", label: "Completed" },
  { value: "4", label: "Other Register" },
];

const allowUserRegistration = [
  { value: "1", label: "Yes" },
  { value: "2", label: "No" },
];

const allowMultiMeeting = [
  { value: "1", label: "Yes" },
  { value: "2", label: "No" },
];

const buyerStatus = [
  { value: "1", label: "Register in CH" },
  { value: "2", label: "Sold Out" },
  { value: "3", label: "Completed" },
  { value: "4", label: "Other Register" },
];




// export default function LabTabs() {


// }
export default Events;