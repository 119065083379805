import React, { useEffect, useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { SelectAutocomplete } from '_components/FormElements/FormInput'
import { Card, CardBody, Container, Row, Col, Media, CardHeader, UncontrolledTooltip } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import UserFilter from "components/Filter-Right/UserFilter.js";
import pagination from "views/Hoc/pagination.js";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import axios from 'axios';
import { Spinner } from '../../Hoc/Spinner'
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/PaginationActions';
import Drawer from 'views/Hoc/Dialog/Globaldrawer'
import { commonService } from '_services/common.service';
import { history } from '_helpers';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import EventsHostel from './Events_Hotel';

// toggles collapse between mini sidenav and normal
const toggleSidenav = e => {

  if (document.getElementById('filter').classList.contains("g-sidenav-pinned")) {
    document.getElementById('filter').classList.remove("g-sidenav-pinned");
    document.getElementById('filter').classList.add("g-sidenav-hidden");
  } else {
    document.getElementById('filter').classList.add("g-sidenav-pinned");
    document.getElementById('filter').classList.remove("g-sidenav-hidden");
  }
};

const company_type_Option = [
  { name: "Vendor", id: 2 },
  { name: "Distributor", id: 3 },
  { name: "Retailer", id: 4 },
];

export const EventsListing = (props) => {

  const { control } = useForm({ mode: 'onBlur' });
  // const ExampleTabs = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  let [loading, setLoading] = useState(true)
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  const [showDrawer, setshowDrawer] = useState(false);

  const [eventId, setEventId] = useState();

  const [state, setState] = useState({ dataTable: [], total: 0 });
  const [searchState, setSearch] = useState({ search: [], searchSelect: '', dropdownList: null, filterEventsSelect: [{ id: 3, name: "All Current Events" }], filterEventsSelectId: 3, filterEvents: [{ id: 1, name: "All Events" }, { id: 2, name: "All Past Events" }, { id: 3, name: "All Current Events" }] });
  const [filterValueState, setFilterValue] = useState({ Company_type: null, country: [] });
  const [filterOptionState, setFilterOption] = useState({ countryOption: [], company_type_Option: company_type_Option });


  // useEffect(() => {  

  //   (async () => {
  //     console.log("coupon");  
  //   let coupons = await commonService.postService({},'channelAdmin/channelCoupons');  
  //   state.dataTable = coupons.data.rows;
  //   state.total = coupons.data.count;   
  //   setState({ ...state });  
  //   setLoading(false);
  //   })();
  // }, []);

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getCouponListing();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    setPage(0);
    getCouponListing();
  };

  useEffect(() => {
    const previousPath = localStorage.getItem('previousPath');
    if (previousPath != null && previousPath == 'admin/hotel') {
      setActiveTab('2')
      localStorage.removeItem('previousPath');
    }

    getCouponListing();
    //getCountryList();     
    //getSearchList()
  }, []);

  const getCountryList = () => {
    axios.post('/admin/userCountry', {
    }).then(res => {
      let ctyFilter = res.data.map(x => x.countryid);
      filterOptionState.countryOption = ctyFilter;
      setFilterOption({ ...filterOptionState, countryOption: filterOptionState.countryOption });
    })
  }

  const getFilterData = () => {
    let filter = [];
    if (filterValueState.country && filterValueState.country.length > 0) {
      filter.countryId = filterValueState.country.map(x => parseInt(x.id));
    }
    if (filterValueState.Company_type) {
      filter.channelTypeId = filterValueState.Company_type.id;
    }
    return filter;
  }

  const getCouponListing = async () => {
    loading = true;
    setLoading(loading);
    // var filter = await getFilterData();
    console.log("page:", page);
    let data = {
      // search: searchState.search?searchState.search[0]?searchState.search[0].id:null:null,      
      // filter : {
      //   countryId:filter.countryId?filter.countryId:null,
      //   channelTypeId:filter.channelTypeId?filter.channelTypeId:null,        
      // },
      page: page * rowsPerPage,
      limit: rowsPerPage,
      dateId: searchState.filterEventsSelectId,
      search: searchState.searchSelect
    }
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.post('/channelAdmin/getEventListing', data
    ).then(res => {
      //console.log("userlisting:",res.data);   
      state.dataTable = res.data.rows;
      searchState.dropdownList = res.data.eventsName
      state.total = res.data.count;
      loading = false;
      setLoading(loading);
      setState({ ...state });
    }).catch((err) => {
      state.dataTable = [];
      state.total = 0;
      loading = false;
      setLoading(loading);
      setState({ ...state });
    })
  }

  const getSearchList = () => {
    axios.get('/admin/searchList').then(res => {
      let data = [];
      res.data.map(x => {
        data.push({ name: x.fName.trim() + ' ' + x.lName.trim(), id: x.id });
      })

      //data.push(...contact);
      searchState.dropdownList = data
      setSearch({ ...state, dropdownList: searchState.dropdownList });
    })
  }

  const handleChangeSelect = (input, value) => {
    if (input == 'search') {
      searchState.search = [value]
      searchState.searchSelect = value.name;

      setSearch({ ...searchState })
    }
    if (input == 'eventfilter') {
      searchState.filterEventsSelect = [value]
      searchState.filterEventsSelectId = value?.id
      setSearch({ ...searchState })
    }
    if (input == 'Company_type') {
      filterValueState.Company_type = value
      setFilterValue({ ...filterValueState })
    }
    if (input == 'country') {
      filterValueState.country = value
      setFilterValue({ ...filterValueState })
    }
    getCouponListing();
  }

  const resetFilterValue = () => {
    filterValueState.Company_type = null;
    filterValueState.country = [];
    setFilterValue({ ...filterValueState });
    getCouponListing();
  }

  const copyEvent = async (id) => {
    setLoading(true);
    axios.post('/channelAdmin/copyEvent', { eventId: id }
    ).then(res => {
      setTimeout(() => {
        ToastsStore.success('Event Copied successfully')
        // setLoading(false);
        // history.push('/admin/eventListing')
        getCouponListing();
      }, 1000)
    })

  }

  const changeStatus = (id, status) => {
    let newStatus = status == null ? '0' : '1';
    axios.patch('channelAdmin/updateEventStatus/' + id + '/' + newStatus);
    state.dataTable.map(x => {
      if (x.id == id) {
        x.deletedAt = newStatus == '0' ? new Date() : null;
      }
    })
    setState({ ...state });
    //console.log("newStatus:", newStatus);

  }

  const edit = (e) => {
    e.preventDefault();
    console.log('e', e);
  }

  console.log("text", showDrawer);
  const closeDrawer = () => {
    setshowDrawer(false);
  }
  const [sidenavOpen, setOpen] = useState(false);
  return (
    <div id="usermanage">


      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />

      {/* <div className={classes.root}> */}
      <UserFilter
        Filter={true}
        filterOption={filterOptionState}
        filterValue={filterValueState}
        resetFilterValue={resetFilterValue}
        handleChangeSelect={handleChangeSelect}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen} />

      <SimpleHeader name="admin-users" parentName="Dashboard" />

      <Container style={{ marginTop: "20px" }} fluid>

        <Card>
          <div>
            <Nav tabs className="event-tabs">
              <NavItem>
                <NavLink
                  className={activeTab === '1' ? 'active' : ''}
                  onClick={() => { toggle('1'); }}
                >
                  Events
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === '2' ? 'active' : ''}
                  onClick={() => { toggle('2'); }}
                >
                  Hotel
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={activeTab === '3' ? 'active' : ''}
                // onClick={() => { toggle('3'); }}
                >
                  Tabs
                </NavLink>
              </NavItem>

            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <h3 className="event-head">Manage Events </h3>
                {loading ? <Spinner /> : <ToolkitProvider
                  data={state.dataTable}
                  keyField="Id"
                  columns={[
                    {
                      dataField: 'Id',
                      text: " Id",
                      sort: true,
                      formatter: (rowContent, row, rowIndex, index) => {
                        return (
                          <>
                            {rowIndex + 1 + (page * rowsPerPage)}
                          </>

                        )
                      }
                    },
                    {
                      dataField: "name",
                      text: "Event Name",
                      sort: true
                    },
                    {
                      dataField: "description",
                      text: " DESCRIPTION",
                      sort: true,
                      formatter: (cell, row) => {
                        return <div className="Evedes">{cell}</div>;
                      },
                    },
                    {
                      dataField: "country.country",
                      text: "Country",
                      sort: true
                    },
                    {
                      dataField: "fromDate",
                      text: "From Date",
                      sort: true
                    },
                    {
                      dataField: "toDate",
                      text: "To Date",
                      sort: true,
                    },
                    {
                      dataField: "isActive",
                      text: "Status",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <label className="custom-toggle custom-toggle-success">
                              <input defaultChecked={row.deletedAt == null ? true : false} type="checkbox" name="status" onClick={() => { changeStatus(row.id, row.deletedAt) }} />
                              <span className="custom-toggle-slider rounded-circle" data-label-off="Inactive" data-label-on="Active"></span>
                            </label>
                          </>
                        )
                      }
                    },
                    {
                      dataField: "eventId",
                      text: "Action",
                      formatter: (rowContent, row, index) => {
                        console.log('row', rowContent)
                        return (
                          <>
                            <i id={'Edit' + index} onClick={() => { history.push({ pathname: '/admin/event', state: { eventId: row.id } }) }} class="fas fa-edit pointer">
                              <UncontrolledTooltip target={'Edit' + index} placement='bottom'> Edit </UncontrolledTooltip></i>

                            <i id={'CopyEvent' + index} onClick={() => { copyEvent(row.id) }} className="fas fa-copy pointer ml-2">
                              <UncontrolledTooltip target={'CopyEvent' + index} placement='bottom'> Copy Event </UncontrolledTooltip></i>

                            <i id={'ManageEvent' + index} onClick={() => { history.push({ pathname: '/admin/manageEvent', state: { eventId: row.id } }) }} className="fas fa-tasks pointer ml-2">
                              <UncontrolledTooltip target={'ManageEvent' + index} placement='bottom'> ManageEvent </UncontrolledTooltip></i>

                            <i id={'EventConnection' + index} onClick={() => { history.push({ pathname: '/admin/eventConnection', state: { eventId: row.id } }) }} className="fas fa-tasks pointer ml-2">
                              <UncontrolledTooltip target={'EventConnection' + index} placement='bottom'> EventConnection </UncontrolledTooltip></i>


                          </>
                        )
                      }
                    }


                  ]}
                  search
                >
                  {props => (
                    <div className="py-2 table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 mb-2"
                      >
                        <div className='row'>
                          <div className='col-sm-6'>
                            <SelectAutocomplete
                              id="search"
                              type="text"
                              label="search"
                              name="search"
                              className="d-inline-block"
                              style={{ width: '48%' }}
                              options={searchState.dropdownList}
                              placeholder="search..."
                              disableCloseOnSelect
                              // defaultValue={searchState.search?searchState.search[0]:null}
                              control={control}
                              input='search'
                              value={searchState.search ? searchState.search[0] : null}
                              handleChangeSelect={handleChangeSelect}
                            />

                            <SelectAutocomplete
                              id="eventfilter"
                              type="text"
                              label="filter"
                              name="eventfilter"
                              className="d-inline-block"
                              style={{ width: '48%', marginLeft: '20px' }}
                              options={searchState.filterEvents}
                              placeholder="filter events"
                              disableCloseOnSelect
                              // defaultValue={searchState.search?searchState.search[0]:null}
                              control={control}
                              input='eventfilter'
                              value={searchState.filterEventsSelect ? searchState.filterEventsSelect[0] : null}
                              handleChangeSelect={handleChangeSelect}
                            />
                          </div>
                          <div className='col-sm-6'>
                            <button onClick={() => history.push({ pathname: '/admin/event', state: { eventId: 0 } })} className="btn btn-primary float-right">Create Event</button>
                            <button onClick={() => history.push({ pathname: '/admin/eventmeet', state: { eventId: 0 } })} className="btn btn-primary float-right" style={{ marginRight: '10px' }}>Settings</button>
                          </div>
                        </div>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        // pagination={pagination}
                        bordered={false}
                        hover={true}
                        rowClasses='pointer'
                      />

                      <TablePagination
                        component="div"
                        count={state.total}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </div>
                  )}
                </ToolkitProvider>}

              </TabPane>
              <TabPane tabId="2">
                <EventsHostel />
              </TabPane>

            </TabContent>
          </div>



        </Card>

      </Container>
    </div>
  );
};
export default EventsListing;
