import React, { useEffect, useState, Fragment } from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { IconButton, Autocomplete } from '@mui/material';
import pdf_template from '../../../assets/img/eventtemplate/download_pdf.png';
import video_template from '../../../assets/img/eventtemplate/video.png';
import Drawer from 'views/Hoc/Dialog/Globaldrawer'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import {
    Col, Row,
} from "reactstrap";
import { Button } from "antd";


export default function Tabs_Controller({ handleChange, PastTabs, PreTabs, eventId }) {
   
    const templateChange = (value) => {
        console.log("tempval:", value)
        handleChange(null, value);
    }
    const [tabvalue, setTabValue] = React.useState('pre_event'); 
    const [showPreTabs, setShowPreTabs] = React.useState(true);
    const [showPastTabs, setShowPastTabs] = React.useState(false);

    const handleTabChange = (event, newValue) => {
       // console.log('newValue => handleTabChange', newValue)
        setTabValue(newValue);
        if(newValue == 'pre_event'){
            setShowPreTabs(true);
            setShowPastTabs(false);
        } else {
            setShowPreTabs(false);
            setShowPastTabs(true);
        }
        // const [showPreTabs, setShowPreTabs] = React.useState(true);
        // const [showPastTabs, setShowPastTabs] = React.useState(false);
    };
  
    const [template, setTemplate] = useState('');
    const [showDrawer, setshowDrawer] = useState(false);

    const closeDrawer = () => {
        //props.onClose();
        setshowDrawer(false);
    }

    return (
        <div className="hoteroom-tabe">

            {template == "Pdf Template" &&
                <Drawer title="Pdf Template" eventId={eventId} visible={showDrawer} closeDrawer={closeDrawer} />}
            {template == "Video Template" &&
                <Drawer title="Video Template" eventId={eventId} visible={showDrawer} closeDrawer={closeDrawer} />}

            <Row>
                <div className="col-lg-6 col-sm-12 mt-2">
                    <h4>Tabs Control (Hide/Show) </h4>
                </div>

            </Row>

            <Box sx={{ width: '100%' }}>
      <Tabs
        value={tabvalue}
        onChange={handleTabChange}     
      >       
        <Tab value="pre_event" label="Pre Event" />
        <Tab value="post_event" label="Post Event" />
      </Tabs>
    </Box>

            {showPreTabs && PreTabs?.map(tab =>
                <Row>

                    <div className="col-lg-6 col-sm-12 mt-2">
                        <FormControlLabel
                            control={
                                <Checkbox style={{ color: '#2c9db7' }} onChange={(e) => handleChange(e.target.value, e.target.name)} checked={tab.checked} name={tab.id + ":" + tab.tabType + ":checked"} />
                            }
                            label={tab.label}
                        />
                    </div>

                    <div className="col-lg-6 col-sm-12 mt-2">
                        {tab.checked ?
                            <>
                                <TextField
                                    style={{ width: 350,paddingBottom:"7px"  }}
                                    label="URL"
                                    id={tab.id + ":" + tab.tabType + ":url"}
                                    value={tab.url ? tab.url : null}
                                    defaultValue={tab.url ? tab.url : null}
                                    size="small"
                                    name={tab.id + ":" + tab.tabType + ":url"}
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                />

                                <TextField
                                    style={{ width: 350,paddingBottom:"7px"  }}
                                    label="Custom Name"
                                    id={tab.id + ":" + tab.tabType + ":namechange"}
                                    value={tab.customName ? tab.customName : null}
                                    defaultValue={tab.customName ? tab.customName : null}
                                    size="small"
                                    name={tab.id + ":" + tab.tabType + ":namechange"}
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                />

                            </>
                            :
                            <>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value="0" control={<Radio checked={tab.hideorsoon == '0' ? true : false} name={tab.id + ":" + tab.tabType + ":hideorsoon"} style={{ color: '#2c9db7' }} onChange={(e) => handleChange(e.target.value, e.target.name)} />} label="Hide" />
                                    <FormControlLabel value="1" control={<Radio checked={tab.hideorsoon == '1' ? true : false} name={tab.id + ":" + tab.tabType + ":hideorsoon"} style={{ color: '#2c9db7' }} onChange={(e) => handleChange(e.target.value, e.target.name)} />} label="Coming Soon" />

                                </RadioGroup>
                            </>}
                    </div>

                </Row>
            )
            }

        

            {showPastTabs && PastTabs?.map(tab =>
                <Row>

                    <div className="col-lg-6 col-sm-12 mt-2">
                        <FormControlLabel
                            control={
                                <Checkbox style={{ color: '#2c9db7' }} onChange={(e) => handleChange(e.target.value, e.target.name)} checked={tab.checked} name={tab.id + ":" + tab.tabType + ":checked"} />
                            }
                            label={tab.label}
                        />
                    </div>

                    <div className="col-lg-6 col-sm-12 mt-2">
                        {tab.checked ?
                            <>
                                <TextField
                                    style={{ width: 450,paddingBottom:"10px"  }}
                                    label="URL"
                                    id={tab.id + ":" + tab.tabType + ":url"}
                                    value={tab.url ? tab.url : null}
                                    defaultValue={tab.url ? tab.url : null}
                                    size="small"
                                    name={tab.id + ":" + tab.tabType + ":url"}
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                />

                                <TextField
                                    style={{ width: 450,paddingBottom:"10px"  }}
                                    label="Custom Name"
                                    id={tab.id + ":" + tab.tabType + ":namechange"}
                                    value={tab.customName ? tab.customName : null}
                                    defaultValue={tab.customName ? tab.customName : null}
                                    size="small"
                                    name={tab.id + ":" + tab.tabType + ":namechange"}
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                />

                                <br />
                                <div class="row">
                                    <div style={{ width: 520,paddingBottom:"10px" }}>
                                        <Autocomplete
                                            // disableClearable={true}
                                            // style={{ width: 550 }}
                                            size='small'
                                            id={tab.id + ":" + tab.tabType + ":template"}
                                            input={tab.id + ":" + tab.tabType + ":template"}
                                            options={top100Films}

                                            onChange={(e, v) => templateChange(tab.id + ":" + tab.tabType + ":template")}
                                            getOptionLabel={(option) => option.name ? option.name : "Select Template"}
                                            value={top100Films.find(x => x.name == tab.template) ? top100Films.find(x => x.name == tab.template) : {}}

                                            renderOption={(option, { selected }) => (
                                                <li onClick={(e) => handleChange(option.key, tab.id + ":" + tab.tabType + ":template")}>
                                                    <React.Fragment>
                                                        <span>
                                                            {/* <Button color="primary"> */}
                                                            <img style={{ height: 80, width: 250 }} src={option.key === 'Pdf Template' ? pdf_template : video_template} />{option.key}
                                                            {/* </Button> */}
                                                        </span>
                                                    </React.Fragment>
                                                </li>
                                            )}
                                            renderInput={(params) => <TextField size='small' {...params} label="" />}
                                        />
                                    </div>
                                    {tab.template &&
                                        <div style={{ width: 30 }}>
                                            <div style={{ fontSize: 30, color: "#2c9db7" }}>
                                                <i onClick={() => { setTemplate(tab.template); setshowDrawer(true) }} class="fa fa-plus-circle"></i>
                                            </div>
                                        </div>}
                                </div>


                            </>
                            :
                            <>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value="0" control={<Radio checked={tab.hideorsoon == '0' ? true : false} name={tab.id + ":" + tab.tabType + ":hideorsoon"} style={{ color: '#2c9db7' }} onChange={(e) => handleChange(e.target.value, e.target.name)} />} label="Hide" />
                                    <FormControlLabel value="1" control={<Radio checked={tab.hideorsoon == '1' ? true : false} name={tab.id + ":" + tab.tabType + ":hideorsoon"} style={{ color: '#2c9db7' }} onChange={(e) => handleChange(e.target.value, e.target.name)} />} label="Coming Soon" />

                                </RadioGroup>
                            </>}
                    </div>

                </Row>
            )
            }


        </div >
    );
}

const visibleOption = [
    { value: "1", label: "Hide" },
    { value: "2", label: "Coming Soon" },
];

const top100Films = [
    { name: 'Pdf Template', id: 1, template: "download" },
    { name: 'Video Template', id: 2, template: "video" },
];
