import React ,{useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import {  FormCheckBox } from "_components/FormElements/FormInput";
import Checkbox from '@mui/material/Checkbox';
import { Button, Modal } from "reactstrap";
import { ProfileUploader } from '../ProfileUpload';
import { commonService } from '_services/common.service';
import {Spinner} from 'views/Hoc/Spinner';
import {ToastsContainer, ToastsStore} from 'react-toasts';
//import { Col } from 'antd';
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

export const AddChannelType=(props)=> {
const { opneadd, closeModalPopup, channelType,isEdit } = props;

const [checked, setChecked] = React.useState(true);
const [channelTypeName, setChannelTypeName] = React.useState(channelType);
const [loading, setLoading] = React.useState(false);
const [error, setError] = React.useState('');
const [primaryChannel, setPrimaryChannel] = React.useState([]);
const [visibility, setVisibility] = React.useState([]);
useEffect(() => {
  console.log('props',props);
  getPrimaryChannelType();
  if(props?.isEdit && opneadd){
    setLoading(true);
    setValues();    
  }
}, [opneadd]);

const setValues=()=>{
  let value = props?.value;
  setChannelTypeName(value?.channelType);
  setVisibility(JSON.parse(value?.visibility))
  setChecked(value?.mode)
  setLoading(false);
}

const handleChange = (e) => {
  console.log('e',e);
  setChecked(!checked);
};

const handleVisibilityChange = (e) => {
  console.log(e.target.value,'before if ',e.target.id)
 
   if(visibility.indexOf(e.target.value) > -1)  { 
    console.log('visibility' )
    let temArr = [];
    temArr = visibility.filter(function(item) {
     
      return item != e.target.value
    })
    console.log('temArr',temArr);
     setVisibility(temArr);
   } else { 
    console.log('push' )
     visibility.push(e.target.value) 
     setVisibility(visibility.filter((item, index) => visibility.indexOf(item) === index));
     console.log('e',visibility);
   }

  
  
};



const getPrimaryChannelType = async() => {
  let res = await commonService.getServiceWithParams({    
    visibility: '1',
  }, '/common/getChannelType');
  if (res) {
    console.log('res = ',res.data.list );
    setPrimaryChannel(res.data.list);
  }
}

const addChannelType = async() => {
  if(channelTypeName){
      setLoading(true);
      const isAvailable = await commonService.postService({
            channelType: channelTypeName,
            channelTypeId: props?.value?.channelTypeId,
            mode: checked,
            action: (props?.isEdit)?'edit':'add',
            priority: '0',
            visibility:visibility
          }, 'common/addChannelType');  
      if(isAvailable == 'exist'){
        ToastsStore.error('Channel Type already exist.');
      } else {
        ToastsStore.success('Channel Type added successfully.');
      }  
      setLoading(false);
    }   
}

const handleChannelType = e => {
  setChannelTypeName(e.target.value);
}

return (
<div>
<Modal className="modal-dialog-centered" isOpen={opneadd}>
<ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
<div className="modal-header">
<h3 className="text-center">{isEdit?'Update ChannelType':'Add New ChannelType'}</h3>
<button
aria-label="Close"
className="close"
data-dismiss="modal"
type="button"
onClick={closeModalPopup}
>
<span aria-hidden={true}>×</span>
</button>
</div>
<div className="modal-body">

{loading ? <div style={{ float: 'center' }}><Spinner /></div> : <>
    <Card>
        <CardBody>
          <Row>
            {/* <Col lg="3"> Channel Type </Col> */}
            <Col lg="12"> 
                <TextField id="outlined-basic" style= {{ width:"327px"}} label="Channel Type Name" variant="outlined" onChange={handleChannelType} value={channelTypeName}/>
            </Col>
          </Row>
          <Row style= {{ padding:"21px 0 0 0"}}>
          <Col lg="3"> Visibility </Col>
            <Col lg="9"> 
                { primaryChannel.map(x =>
                    <FormCheckBox
                    id={x.channelType}
                    style= {{ padding:"5px"}}
                    value={x.channelType}
                    type="checkbox"
                    label={x.channelType}
                    checked={(visibility.indexOf(x.channelType) > -1)?true:false}
                      // register={register}
                      //    error={errors.postalcode}
                      onClick={handleVisibilityChange}
                    />
                  )}
            </Col>
          </Row>
          <Row>
            <Col lg="12"> 
                By defalt Show in Signup screen 
                  <Checkbox
                  id="checked"
                  type="checkbox"
                  label1={{textAlign:"justify"}}
                  checked={checked}
                  // register={register}
                  value={'checked'}
                  label="Show in SignUp Form"
                  onClick={(e)=>handleChange(e)}
                  />   
            </Col>
          </Row>
        </CardBody>
    </Card>
                             
</>
}

<span style={{color: '#f44336', fontSize:'0.75rem'}}>{error}</span>
<div className="modal-footer">
<Button
color="secondary"
data-dismiss="modal"
type="button"
onClick={closeModalPopup}
>
Close
</Button>
<Button
className='btn btn-primary'
data-dismiss="modal"
type="button"
onClick={addChannelType}
>
{isEdit?'Edit':'Add'}
</Button>
</div>
</div>


</Modal>
</div>
);
}
export default AddChannelType;