import React, { useEffect, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { FormTextArea, FormInput, FormAutocomplete, SingleAutocompleteGrouping, FormRadio, FormCheckBox, FormAutocompleteGrouping } from "_components/FormElements/FormInput";
import { useForm } from "react-hook-form";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import SelectCurrency from ''
import { commonService } from '_services/common.service';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker, KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Select from '@mui/material/Select';
import Table from 'react-bootstrap/Table';


import {
    FormControl,
    InputAdornment,
} from "@material-ui/core";
import {
    Button, Modal
} from "reactstrap";
import Chip from '@mui/material/Chip';

const payOption = [{ id: 1, name: "Full Amount", checked: true }, { id: 2, name: "Split", checked: false }]

const payPeriod = [{ id: 1, name: "Immediate", value: 0 }, { id: 2, name: "45 Days", value: 45 }, { id: 3, name: "90 Days", value: 90 }]

const currCode = [{ id: 1, name: "EURO", value: 'EUR' }, { id: 2, name: "Dollar", value: 'USD' }]

export const PaymentSplit = (props) => {

    const { regType, pack, packageList, paymentSplit, eventId, eventChannel, contactList, channel, contact, popupId , eventChannelId} = props;
        
    var today = Moment();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [paymentOption, setPaymentOption] = useState(payOption);
    const [periodList, setPeriodList] = useState([]);//{ id: 1, period: payPeriod, percent: null, selectedPeriod: 1 }, { id: 2, period: payPeriod, percent: null, selectedPeriod: null }]);
    const [totalSplit, setSplit] = useState(2);

    const [discount, setDiscount] = useState(0);

    //const [badge, setBadge] = useState(0);

    const [currency, setCurrency] = useState('EUR');

    const [finalAmount, setFinalAmount] = useState(0);

    const [totalAmount, setTotalAmount] = useState(0);

    const [customAmtFlag, setCustomAmtFlag] = useState(false);

    const [addons, setAddon] = useState([]);

    const [pennyId,setPennyId] = useState(null);

    const [selectedAddons, setSelectedAddon] = useState([]);

    const [lastSplitErr, setLastSplitErr] = useState(false);
    const [splitErr, setSplitErr] = useState(false);

    const [paidFlag, setPaidFlag] = useState(false);
    const qty_options = [{ id: 1, name: "1", value: '1' }, { id: 2, name: "2", value: '2' },
        , { id: 3, name: "3", value: '3' }, { id: 4, name: "4", value: '4' }, { id: 5, name: "5", value: '5' }, { id: 6, name: "6", value: '6' }, { id: 7, name: "7", value: '7' }, { id: 8, name: "8", value: '8' }, { id: 9, name: "9", value: '9' }, { id: 10, name: "10", value: '10' }]

    useEffect(() => {

        // if (addons.length) {

        //     const sum = addons.reduce((accumulator, object) => {
        //         return accumulator + object.price;
        //     }, 0);

        //     const famt = finalAmount ? (finalAmount + sum) : (pack.price + sum);
        //     setTotalAmount(famt)
        // } else {
        setTotalAmount(0);
        setFinalAmount(0);
        // }

        setDiscount(0);
        setPeriodList([]);

    }, [pack])

    useEffect(() => {

        
        if (packageList?.filter(x => x.packageUser == 3).length) {

            
            if (regType === "Seller") {
                const addon = packageList.filter(x => x.packageUser == 3 && x.isSeller=='1');
                setAddon(addon);
            }             
            // else if(regType === "Buyer"){
            //     const addon = packageList.filter(x => x.packageUser == 3 && x.isBuyer=='1');
            //     setAddon(addon);
            // }
            else{
                const addon = packageList.filter(x => x.channelType == channel.channelTypeId && x.packageUser == 3);
                setAddon(addon);
            }

            // console.log("adds:",regType,addons)
            // const sum = addon.reduce((accumulator, object) => {
            //     return accumulator + object.price;
            // }, 0);

            // const famt = finalAmount ? (finalAmount + sum) : (pack.price + sum);

            // setTotalAmount(famt);
        }
        const famt = pack.price;
        setTotalAmount(famt);
        setFinalAmount(famt);

        handleSplit(2);


    }, [])


    const handleDiscount = (discount) => {
        if ((pack.price - discount) > 0) {
            setDiscount(discount);
            setFinalAmount(pack.price - discount);
            const sum = addons?.filter(x => x.finalAmount > 0 && x.isSelected)?.reduce((acc, obj) => acc + obj.finalAmount, 0)
            const tamt = sum + (pack.price - discount);
            setTotalAmount(tamt);
        } else {
            setDiscount(discount);
            setFinalAmount(pack.price - discount);
        }

    }

    const handleCustom = (amount) => {
        if(amount!=pack.price){
            setTotalAmount(amount);
            setFinalAmount(amount);
            setCustomAmtFlag(true);
        }else{
            setCustomAmtFlag(false);
        }
       
    }

    const setAddonDiscount = (name, value) => {

        setAddon((addon) => {

            return addon.map(x => {
                if (x.id == name) {
                    x["discount"] = value;
                    handleAddonDiscount(x.id);
                }
                return x;
            })
        })
    }

    const setQtyOption = (name, value) => {


        setAddon((addon) => {
            //console.log(addon);
            return addon.map(x => {
                if (x.id == name) {
                    x["qty"] = value;
                    handleAddonDiscount(x.id);
                }
                return x;
            })
        });

    }

    const selectAddon = (name, value) => {

        setAddon((addon) => {
            return addon.map(x => {
                if (x.id == name && value) {
                    x["finalAmount"] = x.price;
                    x["isSelected"] = true;
                    x["qty"] = 1;
                } else if (x.id == name && !value) {
                    x["finalAmount"] = x.price;
                    x["discount"] = 0;
                    x["isSelected"] = false;
                    x["qty"] = 1;
                }
                return x;
            })
        })

        const sum = addons?.filter(x => x.finalAmount > 0 && x.isSelected)?.reduce((acc, obj) => acc + obj.finalAmount, 0)

        //console.log("sadd:", )
        const famt = finalAmount + sum;
        setTotalAmount(famt)

    }

    const handleAddonDiscount = async (addonId) => {

        await setAddon((addon) => {
            return addon.map(x => {
                if (x.id == addonId) {
                    let camt = x.price - x.discount;
                    if (x.qty > 1) {
                        camt = camt * x.qty;
                    }
                    // if (x.discount > 0 && x.discount <= camt) {
                    //     camt = camt - x.discount
                    // }

                    x["finalAmount"] = camt;
                }
                return x;
            })
        })

        const sum = addons?.filter(x => x.finalAmount > 0 && x.isSelected)?.reduce((acc, obj) => acc + obj.finalAmount, 0)

        //console.log("tamt:", addons,sum, finalAmount, pack.price);
        const famt = finalAmount + sum;
        setTotalAmount(famt)

    }

    const handleSplit = (value) => {

        setSplitErr(false);
        let list = [];

        paymentSplit(true, []);

        if (value == "") {
            setPeriodList(list);
            setSplit(0);
        }
        if (value == 1 || value == 7) {
            setPeriodList(list);
            setSplit(value);

        } else {
            for (var i = 0; i < value && value <= 6; i++) {
                list.push({ id: i + 1, period: payPeriod, percent: null, selectedPeriod: i == 0 ? 1 : null, paidFlag: false });

            }
            if (value <= 6) {
                setPeriodList(list);
                setSplit(value);
            }
        }

    }

    const changePaymentOption = (selected, value) => {
        if (selected == 1 && value) {
            setPaymentOption([{ id: 1, name: "Full Amount", checked: value }, { id: 2, name: "Split", checked: false }]);
            setPeriodList([{ id: 1, period: payPeriod, percent: 100, selectedPeriod: 1, paidFlag: false }]);
            setSplitErr(false);
            setLastSplitErr(false);
        } else if (selected == 2 && !value) {
            setPaymentOption([{ id: 1, name: "Full Amount", checked: true }, { id: 2, name: "Split", checked: value }]);
            setPeriodList([{ id: 1, period: payPeriod, percent: 100, selectedPeriod: 1, paidFlag: false }]);
            setSplitErr(false);
            setLastSplitErr(false);
        } else if (selected == 2 && value) {
            setPaymentOption([{ id: 1, name: "Full Amount", checked: false }, { id: 2, name: "Split", checked: value }]);
            handleSplit(2);
        }

        paymentSplit(true, []);
    }

    const confirmPayment = async () => {
        // console.log("periodList:", finalAmount,pack);
        let errFlag = false;
        let list = [];
        let splits = [];

        let vatPercentage; let isVatCompany;


        if (popupId == 3) {

            const channels = eventChannel ? eventChannel?.find(x => x.eventId == eventId) : channel;

            const participant = contactList ? contactList?.filter(x => x.eventParticipants?.filter(p => p.eventId == eventId && (p.status == 0 || p.status == null)).length > 0) : contact;

            await commonService.postService({ eventId:eventId,offers: [],channelTypeId:channel?.channelTypeId,regType:regType, channelId: channels?.channelId, contactId: participant?.length ? participant[0].contactId : participant.contactId }, '/channelAdmin/packageOffers').then(res => {
                // packOffers = res.data.offers;
                vatPercentage = res.data.hasVat;
                // vatNumber = res.data.vatNumber;
                isVatCompany = res.data.isVatCompany;
                // offerAmount = res.data.offers.filter(x => x.offerType == 1).length ? res.data.offers.filter(x => x.offerType == 1).reduce((a, curr) => parseInt(a) + parseInt(curr.couponOfferValue), 0) : 0;
            })
        }



        if (popupId == 3 && paidFlag) {

            const channels = eventChannel ? eventChannel?.find(x => x.eventId == eventId) : channel;

            const participant = contactList ? contactList?.filter(x => x.eventParticipants?.filter(p => p.eventId == eventId && (p.status == 0 || p.status == null)).length > 0) : contact;

            let taxAmt  = 0
            if(isVatCompany){
             taxAmt  = (((totalAmount || customAmtFlag) ? totalAmount : pack.price) * (vatPercentage / 100)).toFixed(2);
            }

            let obj = {
                channelId: channels?.channelId,
                contactId: participant?.length ? participant[0].contactId : participant.contactId,
                eventId: eventId,
                packageId: pack.id,//channel.packageId,
                splitId: 1,
                paymentRemainderCount: 0,
                paymentMethod: 1,
                paymentStatus: '1',
                wireTransfer: '1',
                percentage: 100,
                name: pack.name,
                eventId: pack.eventId,
                currCode: currency,
                description: pack.description.replace(/<[^>]+>/g, ''),
                splitAmount: (totalAmount || customAmtFlag) ? totalAmount : pack.price,
                discount: discount,
                paidFlag: paidFlag,
                actualAmount: customAmtFlag?finalAmount:pack.price,
                packagePrice: (finalAmount||customAmtFlag) ? finalAmount : pack.price,
                totalAmount: (totalAmount || customAmtFlag) ? totalAmount : pack.price,//channel.package['price']
                vatPercentage: vatPercentage,
                isVatCompany: isVatCompany,
                pennyInvoiceId:pennyId,
                taxAmount:(isVatCompany)?taxAmt:0

            }

            splits.push(obj);
        } else {
            //console.log('popupId = ', popupId);
            //console.log('paidFlag = ', paidFlag);
            let paymentOptionId = paymentOption.find(x => x.checked == true).id;
         
            if (paymentOption.find(x => x.checked == true).id == 2) {
                const empty = periodList.filter(p => p.percent == null || !p.paymentDate);
                //console.log('periodList = ', periodList);
                if (totalSplit == 0 || lastSplitErr) {
                    errFlag = true;
                }
                else if (empty.length) {
                    setSplitErr(true);
                    paymentSplit(true, []);
                    errFlag = true;
                    return;
                } else {
                    setSplitErr(false);
                    list = periodList;
                    errFlag = false;
                }
            }

            if (paymentOption.find(x => x.checked == true).id == 1) {
                list = periodList.filter(p => p.selectedPeriod == 1);
            }

//console.log('payment split : ', list);
            const channels = eventChannel ? eventChannel?.find(x => x.eventId == eventId) : channel;

            const participant = contactList ? contactList?.filter(x => x.eventParticipants?.filter(p => p.eventId == eventId && (p.status == 0 || p.status == null)).length > 0) : contact;


            var today = Moment();
                     
            await commonService.postService({ eventId:eventId,offers: [],channelTypeId:pack.channelType,regType:regType, channelId: channels?.channelId, contactId: participant?.length ? participant[0].contactId : participant.contactId }, '/channelAdmin/packageOffers').then(res => {
                // packOffers = res.data.offers;
                vatPercentage = res.data.hasVat;
                // vatNumber = res.data.vatNumber;
                isVatCompany = res.data.isVatCompany;
                // offerAmount = res.data.offers.filter(x => x.offerType == 1).length ? res.data.offers.filter(x => x.offerType == 1).reduce((a, curr) => parseInt(a) + parseInt(curr.couponOfferValue), 0) : 0;

                let taxAmt  = 0
                if(isVatCompany){
                taxAmt  = (((totalAmount || customAmtFlag) ? totalAmount : pack.price) * (vatPercentage / 100)).toFixed(2);
                }


                list.map(p => {
                    // console.log("paymentSplitss:", p);
                    let ch_paidFlag = (paymentOptionId == 1)?paidFlag:p.paidFlag;
                    let obj = {
                        channelId: channels?.channelId,
                        contactId: participant?.length ? participant[0].contactId : participant.contactId,
                        eventId: eventId,
                        packageId: pack.id,//channel.packageId,
                        splitId: p.id,
                        paymentRemainderCount: 0,
                        paymentMethod: paymentOption.find(x => x.checked == true).id,
                        paymentStatus: ch_paidFlag ? '1' : '0',
                        wireTransfer: ch_paidFlag ? '1' : '0',
                        paymentDate: p.paymentDate,
                        percentage: p.percent,
                        name: pack.name,
                        currCode: currency,
                        eventId: pack.eventId,
                        description: pack.description.replace(/<[^>]+>/g, ''),
                        splitAmount: ((p.percent / 100) * ((totalAmount || customAmtFlag) ? totalAmount : pack.price)),
                        packagePrice: (finalAmount || customAmtFlag) ? finalAmount : pack.price,
                        totalAmount: (totalAmount || customAmtFlag) ? totalAmount : pack.price,//channel.package['price']
                        discount: discount,
                        actualAmount: customAmtFlag?finalAmount:pack.price,
                        paidFlag: ch_paidFlag,
                        vatPercentage: vatPercentage,
                        isVatCompany: isVatCompany,
                        pennyInvoiceId:pennyId,
                        eventChannelId:+eventChannelId,
                        taxAmount:taxAmt
    
                    }
    
                    if (paymentOption.find(x => x.checked == true).id == 2) {
                        Object.assign(obj, { percentage: p.percent });// paymentDate: p.selectedPeriod == 1 ? today.format('MM/DD/YYYY') : Moment(today).add(payPeriod.find(x => x.id == p.selectedPeriod).value, 'days').format('MM/DD/YYYY') })
                    } else {
                        Object.assign(obj, { splitAmount: (totalAmount || customAmtFlag) ? totalAmount : pack.price, percentage: 100 });// paymentDate: today.format('MM/DD/YYYY') })
                    }
    
                    splits.push(obj);
    
                })

            })

            

        }

        let selectedaddons = [];
        if (popupId == 3 && addons.filter(x => x.isSelected).length) {
            const add = addons.filter(x => x.isSelected);
            const channels = eventChannel ? eventChannel?.find(x => x.eventId == eventId) : channel;

            const participant = contactList ? contactList?.filter(x => x.eventParticipants?.filter(p => p.eventId == eventId && (p.status == 0 || p.status == null)).length > 0) : contact;

            add.map(x => {

                let formattedFinalAmt = x.discount ? (x.price - x.discount) * x.qty : (x.price) * x.qty;

                let formattedDiscount = x.discount ? (x.discount * x.qty) : 0;

                let hasDiscount = x.discount ? true : false;

                if (currency == 'USD') {

                    formattedFinalAmt = new Intl.NumberFormat('fr-US', {
                        style: 'currency',
                        currency: 'USD',
                        currencyDisplay: 'narrowSymbol'
                    })
                        .format(formattedFinalAmt)
                        .trim();

                    formattedDiscount = new Intl.NumberFormat('fr-US', {
                        style: 'currency',
                        currency: 'USD',
                        currencyDisplay: 'narrowSymbol'
                    })
                        .format(formattedDiscount)
                        .trim();

                } else {

                    formattedFinalAmt = new Intl.NumberFormat('fr-DE', {
                        style: 'currency',
                        currency: 'EUR',
                        currencyDisplay: 'narrowSymbol'
                    })
                        .format(formattedFinalAmt)
                        .trim();

                    formattedDiscount = new Intl.NumberFormat('fr-DE', {
                        style: 'currency',
                        currency: 'EUR',
                        currencyDisplay: 'narrowSymbol'
                    })
                        .format(formattedDiscount)
                        .trim();

                }

                let obj = {
                    channelId: channels?.channelId,
                    contactId: participant?.length ? participant[0].contactId : participant.contactId,
                    packageId: x.id,
                    name: x.name,
                    description: x.description,
                    eventId: eventId,
                    actualAmount: x.price,
                    quantity: x.qty,
                    discount: x.discount ? (x.discount * x.qty) : 0,
                    finalAmount: x.discount ? (x.price - x.discount) * x.qty : (x.price) * x.qty,
                    formattedFinalAmount: formattedFinalAmt,
                    hasAddonDisc: hasDiscount,
                    formattedDiscount: formattedDiscount
                }
                selectedaddons.push(obj);
            })
        }



        paymentSplit(errFlag, splits, selectedaddons);
    }


    const handlePercent = (input, value) => {
        //console.log("per:",input,value,totalSplit);
        const peri = periodList.map(x => {
            if (x.id == input && x.id <= totalSplit - 1) {
                x.percent = value;
            }
            return x;
        })

        // if (input == totalSplit - 1) {
        const last = periodList.find(x => x.id == totalSplit);
        const peris = peri.filter(x => x.id != totalSplit);
        const sum = peris?.reduce((acc, obj) => acc + +obj.percent, 0)
        last.percent = 100 - sum;
        if (last.percent == 0) {
            setLastSplitErr(true);
            paymentSplit(true, []);

        } else {
            setLastSplitErr(false);
        }
        setPeriodList([...peris, last]);
        // } else {
        // setPeriodList(peri);
        // }

    }

    const onSelectedCurrency = currencyAbbrev => {
        //console.log(`Selected: ${currencyAbbrev}`)
    }

    const handlePaidFlag = (input, value) => {
       
        let flg;
        const peri = periodList.map(x => {
            //console.log(value, ' value ',x.id ,'handlePaidFlag',totalSplit);
            if (x.id == input && x.id <= totalSplit) {
                 flg = (value == 'on') ? true : false;
                 //console.log(x,"paidflag:", flg);
                 x.paidFlag = flg;
            }
            
            return x;
        })


        setPeriodList(peri);

    }




    const setPeriod = (period, selected) => {
        // console.log("period:", periodList,period, Moment(selected).format('MM/DD/YYYY'));
        const peri = periodList.map(x => {
            if (x.id == period) {
                // x.selectedPeriod = selected;
                x.paymentDate = Moment(selected).format('MM/DD/YYYY');
            }
            return x;
        })

        setPeriodList(peri);

    }

    const setPaidFlagSatus= () => {
        //console.log('full amount : ', paidFlag);
     (paidFlag == true )?setPaidFlag(false):setPaidFlag(true)
    }

    return (
        <>
            <Box
                sx={{
                    border: '2px dashed #2c9db7',
                    // height: 320,

                    marginLeft: '25px',

                    width: 780,
                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },
                }}
            >
                <div className="container" style={{ marginTop: 5, padding: '10px' }}>
                    <ul className='payment-box'>
                        <li>
                            <b>Package</b>
                            <Chip label={`${currency == 'EUR' ? '€ ' : '$ '}${pack.price}`} style={{ backgroundColor: '#4caf50', color: '#fff' }} variant="filled" size="large" />
                        </li>
                        {popupId == 3 && <>
                            <li>

                                <TextField style={{ width: '90px' }}
                                    InputLabelProps={{ shrink: true }}
                                    id="discount"
                                    label="Discount"
                                    type="text"
                                    name="discount"
                                    value={discount}
                                    size="small"
                                    onChange={(e) => handleDiscount(+e.target.value)}
                                />
                            </li>
                            <li>

                                <b>Currency </b>

                                {/* <FormControl className='period-spit'  > */}
                                {/* <InputLabel id="demo-select-small" style={{ position: 'absolute', zIndex: 1, backgroundColor: '#fff', fontSize: '10px', top: '-5px', padding: '3px 10px', left: '10px' }}>Currency</InputLabel> */}

                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small" //{period.id.toString()}
                                    value={currency}
                                    label="Period"
                                    size="small"
                                    onChange={(e) => setCurrency(e.target.value)}
                                >
                                    {
                                        currCode.map(x =>
                                            <MenuItem value={x.value}>{x.name}</MenuItem>
                                        )}

                                </Select>
                                {/* </FormControl> */}
                            </li>

                            {/* <div className="col-lg-2">
                                <Button style={{ margin: 'auto' }} color="primary" type="button" onClick={() => handleDiscount()}>Apply</Button>
                            </div> */}
                            <li>

                                <b>Final Amount </b>
                                <Chip label={`${currency == 'EUR' ? '€ ' : '$ '} ${finalAmount ? finalAmount : finalAmount == 0 ? 0 : pack.price}`} style={{ backgroundColor: finalAmount < 0 ? '#ff0133' : '#4caf50', color: '#fff' }} variant="filled" size="large" />
                            </li>

                            <li>
                                <b>Custom Amount </b>
                                <TextField style={{ width: '90px' }}
                                    InputLabelProps={{ shrink: true }}
                                    id="customPackageAmount" //{period.id}
                                    label="Amount"
                                    type="text"
                                    // disabled={period.id == totalSplit ? true : false}
                                    name={"customPackageAmount"}
                                    value={finalAmount}
                                    onChange={(e) => handleCustom(+e.target.value)}
                                />
                            </li>

                            <li>

                                <TextField style={{ width: '90px' }}
                                    InputLabelProps={{ shrink: true }}
                                    id="pennyId" //{period.id}
                                    label="pennyId"
                                    type="text"
                                    // disabled={period.id == totalSplit ? true : false}
                                    name={"pennyId"}
                                    // value={period.percent}
                                    onChange={(e) => setPennyId(e.target.value)}
                                />

                            </li>





                        </>}                

                        <li>
                            <b>Status </b>

                            <label className="custom-toggle custom-toggle-success">
                                <input defaultChecked={paidFlag ? true : false} type="checkbox" name="status" onClick={() => {setPaidFlagSatus(paidFlag)}} />
                                <span className="custom-toggle-slider rounded-circle" data-label-off="Not Paid" data-label-on="Paid">

                                </span>
                            </label>
                        </li>
                    </ul>

                    {(popupId == 3 && addons.length > 0) && <>

                        <br />

                        <div className="row">
                            <b>Benefits</b>
                        </div>

                        <hr className="m-0 ml-3 ordersumsplit" style={{ width: "100%" }} />
                        <Table striped bordered hover style={{ width: '100%' }} className='benefit-tabe'>
                            <thead>
                                <tr>
                                    <th>Addon</th>
                                    <th>Amount</th>
                                    <th>Discount</th>
                                    <th>Quantity</th>
                                    <th>Final Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {addons.map(addon => <>

                                    <tr>
                                        <td><Checkbox
                                            size="small"
                                            //checked={paymentOption.find(x => x.id == 1).checked}
                                            // color="warning"
                                            onChange={(e) => { selectAddon(addon.id, e.target.checked ? true : false) }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        /> {addon.name}</td>
                                        <td>
                                            <Chip label={`${currency == 'EUR' ? '€ ' : '$ '} ${addon.price}`} style={{ backgroundColor: '#4caf50', color: '#fff' }} variant="filled" size="large" />

                                        </td>
                                        <td>
                                            <TextField style={{ width: '90px' }}
                                                InputLabelProps={{ shrink: true }}
                                                id="discount"
                                                label="Discount"
                                                type="text"
                                                disabled={addons.filter(x => x.isSelected && x.id == addon.id).length ? false : true}
                                                name={addon.id}
                                                value={addon.discount ? addon.discount : 0}
                                                size="small"
                                                onChange={(e) => setAddonDiscount(e.target.name, +e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <InputLabel id="demo-select-small" style={{ position: 'absolute', zIndex: 1, backgroundColor: '#fff', fontSize: '10px', top: '-5px', padding: '3px 10px', left: '10px' }}>Qty</InputLabel>
                                            <Select
                                                label="Qty"
                                                id="qty" //{period.id.toString()}
                                                name={addon.id}
                                                value={addon.qty ? addon.qty : 1}
                                                size="small"
                                                onChange={(e) => setQtyOption(e.target.name, +e.target.value)}
                                            >
                                                {
                                                    qty_options.map(x =>
                                                        <MenuItem value={x.value}>{x.name}</MenuItem>
                                                    )}
                                            </Select>

                                        </td>
                                        <td><Chip label={`${currency == 'EUR' ? '€ ' : '$ '} ${addon.finalAmount ? addon.finalAmount : addon.finalAmount == 0 ? 0 : addon.price}`} style={{ backgroundColor: addon.finalAmount < 0 ? '#ff0133' : '#4caf50', color: '#fff' }} variant="filled" size="large" />
                                        </td>
                                    </tr>
                                </>)}
                            </tbody>
                        </Table>

                        {/* <hr className="m-0 ml-3 ordersumsplit" style={{ width: "100%" }} /> */}

                        <div className="row">
                            <div className="col-lg-10">
                                <b>Total Amount</b>
                            </div>
                            <div className="col-lg-2">
                                <Chip label={`${currency == 'EUR' ? '€ ' : '$ '} ${totalAmount ? totalAmount : totalAmount == 0 ? 0 : pack.price}`} style={{ backgroundColor: '#0a7ff3', color: '#fff', marginLeft: '14px' }} variant="filled" size="large" />
                            </div>
                        </div>

                        <br />
                    </>}

                    {/* {popupId == 3 &&
                        <div className="row">

                            <div className="col-lg-2">
                                <label className="custom-toggle custom-toggle-success">
                                    <input defaultChecked={paidFlag ? true : false} type="checkbox" name="status" onClick={() => { setPaidFlag(!paidFlag) }} />
                                    <span className="custom-toggle-slider rounded-circle" data-label-off="Not Paid" data-label-on="Paid"></span>
                                </label>
                            </div>

                            <div className="col-lg-3">
                                <FormControl className='period-spit'  >
                                    <InputLabel id="demo-select-small" style={{ position: 'absolute', zIndex: 1, backgroundColor: '#fff', fontSize: '10px', top: '-5px', padding: '3px 10px', left: '10px' }}>Currency</InputLabel>

                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small" //{period.id.toString()}
                                        value={currency}
                                        label="Period"
                                        size="small"
                                        onChange={(e) => setCurrency(e.target.value)}
                                    >
                                        {
                                            currCode.map(x =>
                                                <MenuItem value={x.value}>{x.name}</MenuItem>
                                            )}

                                    </Select>
                                </FormControl>

                            </div>

                        </div>} */}

                    {((popupId == 3 && !paidFlag) || popupId != 3) && <>
                        <hr className="m-0 ml-3 ordersumsplit" style={{ width: "100%" }} />

                        <div className="row">
                            <div className="col-lg-3">
                                <Checkbox
                                    checked={paymentOption.find(x => x.id == 1).checked}
                                    // color="warning"
                                    onChange={(e) => { changePaymentOption(1, e.target.checked ? true : false) }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                /> Full Amount
                            </div>

                            <div className="col-lg-6">
                                <Checkbox
                                    checked={paymentOption.find(x => x.id == 2).checked}
                                    // color="warning"
                                    onChange={(e) => { changePaymentOption(2, e.target.checked ? true : false) }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                /> Split
                            </div>


                        </div>


                        {paymentOption.find(x => x.id == 2).checked &&
                            <div className="row">
                                <div className="col-lg-3">
                                    <b>Set Amount Split</b>
                                </div>

                                <div className="col-lg-6" style={{ marginBottom: '20px' }}>
                                    <FormInput
                                        className=""
                                        id="splitCount"
                                        label="No of Split"
                                        type="number"
                                        name='splitCount'
                                        // register={register}
                                        // error={errors.template}
                                        defaultValue={totalSplit}
                                        value={totalSplit}
                                        onChange={(e) => handleSplit(e.target.value)}

                                        // autoComplete="current-password"
                                        variant="outlined"
                                    />

                                </div>
                            </div>}


                        {(periodList.length > 0 && paymentOption.find(x => x.id == 2).checked) &&
                            <Table striped bordered hover style={{ width: '100%' }} className='spit-tabe'>
                                <thead>
                                    <tr>
                                        <th>Split</th>
                                        <th>Percentage</th>
                                        {/* <th>PennyId</th> */}
                                        <th>Payment Date</th>
                                        <th style={{ width: '135px' }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {periodList.map(period => {
                                        return <>

                                            <tr>
                                                <td><InputLabel id={period.id.toString()}>{`Split ${period.id}`}</InputLabel>

                                                </td>
                                                <td> <TextField style={{ width: '90px' }}
                                                    InputLabelProps={{ shrink: true }}
                                                    id={period.id}
                                                    label="Percentage"
                                                    type="text"
                                                    disabled={period.id == totalSplit ? true : false}
                                                    name={period.id}
                                                    value={period.percent}
                                                    onChange={(e) => handlePercent(e.target.name, e.target.value)}
                                                /></td>
                                                {/* <td><TextField style={{ width: '90px' }}
                                                    InputLabelProps={{ shrink: true }}
                                                    id={period.id}
                                                    label="pennyId"
                                                    type="text"
                                                    // disabled={period.id == totalSplit ? true : false}
                                                    name={period.id}
                                                    // value={period.percent}
                                                    onChange={(e) => handlePercent(e.target.name, e.target.value)}
                                                /></td> */}
                                                <td>
                                                    <div className="awd-date">

                                                        <FormControl className='period-spit'>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                                                <KeyboardDatePicker
                                                                    disableToolbar
                                                                    variant="inline"
                                                                    format="MM/dd/yyyy"
                                                                    margin="normal"
                                                                    id="buyerMeetingDate"
                                                                    name="buyerMeetingDate"
                                                                    defaultValue={!period.paymentDate ? null : periodList.find(x => x.id == period.id)?.paymentDate}
                                                                    // ref={register}
                                                                    //   control={control}
                                                                    value={!period.paymentDate ? null : periodList.find(x => x.id == period.id)?.paymentDate}
                                                                    //   value={state.buyerMeetingDate}
                                                                    onChange={(date) => setPeriod(period.id, date)}
                                                                // KeyboardButtonProps={{
                                                                //   'aria-label': 'change date',
                                                                // }}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                            {/* <FormHelperText style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }} >{errors.fromDate?.message}</FormHelperText> */}
                                                        </FormControl>
                                                    </div>
                                                </td>
                                                <td>
                                                    <label className="custom-toggle custom-toggle-success">
                                                        <input defaultChecked={period.paidFlag} type="checkbox" name="status" onClick={(e) => handlePaidFlag(period.id, e.target.value)} />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="Not Paid" data-label-on="Paid"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                        </>
                                    })}
                                </tbody>
                            </Table>
                        }




                    </>}
                </div>
            </Box>
            <div style={{ paddingLeft: '25px' }}>
                {(totalSplit == 1 && paymentOption.find(x => x.id == 2).checked) && <div className="row"><p style={{ color: "red" }}>Please go with Full Amount</p></div>}

                {(totalSplit == 7 && paymentOption.find(x => x.id == 2).checked) && <div className="row"><p style={{ color: "red" }}>Maximum 6 splits are allowed</p></div>}

                {(totalSplit == 0 && paymentOption.find(x => x.id == 2).checked) && <div className="row"><p style={{ color: "red" }}>Atleast minimum 2 splits are must</p></div>}

                {splitErr && <><br /> <div className="row"><p style={{ color: "red" }}>Please make it proper split with all data</p></div></>}

                {lastSplitErr && <><br /> <div className="row"><p style={{ color: "red" }}>Last Split Percentage Should be atleast 1</p></div></>}
            </div>
            <div className="modal-footer" style={{ textAlign: 'center', paddingBottom: '0' }}>
                <Button style={{ margin: 'auto' }} color="primary" type="button" onClick={() => confirmPayment()}>ConfirmPayment</Button>
            </div>
        </>
    );
}