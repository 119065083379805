import React, { useState, useEffect } from 'react';
import { Card, Container, CardHeader, UncontrolledTooltip } from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import BootstrapTable from "react-bootstrap-table-next";
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/PaginationActions';
import AddChannelType from "views/Hoc/Dialog/AddChannelType";
import classnames from "classnames";
import { Spinner } from '../../Hoc/Spinner';
import { SelectAutocompleteWithList, FormAutocomplete } from '_components/FormElements/FormInput';
import axios from 'axios';
import { commonService } from '_services/common.service';
import { useForm } from "react-hook-form";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
import { styled } from '@mui/material/styles';
import { Form, Row, Col } from "reactstrap";

export const ChannelTypeMaster = () => {
  const [state, setState] = useState({ dataTable: [], total: 0 });
  let [searchList, setSearchList] = useState([]);
  let [search, setSearch] = useState(null);
  let [sort_by, setSortby] = useState([]);
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [loading, setLoading] = useState(false);
  const [openadd, openAddnew] = useState(false);
  const [isEdit, setEdit] = useState(false);
  let [editChannelType, setEditChannelType] = useState(null);
  let [sort, setSort] = useState('desc');


  const { control, register } = useForm({ mode: 'onBlur' });
  let [isModalPopup, setModalPopup] = useState(false);

  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });
  useEffect(() => {
    getChannelType();
    //getSearchDropdownList();
  }, []);
  const getChannelType = async () => {
    setLoading(true);
    let sortby = [];
    if (sort_by.length) {
      sort_by.map(x => {
        sortby.push(x.id);
      })
    } else {

      sortby = ['createdAt','channelTypeId'];
    }
    let res = await commonService.getServiceWithParams({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      search: search ? search.id : null,
      orderBy: sort,
      order: sortby
    }, '/common/getChannelType');
    if (res) {
     
       state.dataTable = res.data.list;
        state.total = res.data.total;
      setState({ ...state });
      setLoading(false);
    } else {
      ToastsStore.error('Something Went Wrong!');
      state.dataTable = [];
      state.total = 0;
      setLoading(false);
      setState({ ...state });
    }
  }
 

  const callChannelTypeList = () => {
    page = 0;
    setPage(page);
    getChannelType();
  }

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getChannelType();
  }; 

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    callChannelTypeList();
  };
  const handleChange = (input) => (e) => {
    search = e.target.value;
    setSearch(search);
    callChannelTypeList();
  }

  


  const handleChangeSelect = async (input, value) => {
    
    if (input == 'search') {
      search = value
      setSearch(search);
      callChannelTypeList();
    }
    if (input == 'sort_by') {
      sort_by = value
      setSortby(sort_by);
      callChannelTypeList();
    }
  }

  const openDialag = (value) => {
    // console.log('value', value);
    editChannelType = value;
    setEditChannelType(editChannelType);
    setEdit(true);
    openAddnew(true);
  }

  const addChannelType = () => {
    setEdit(false);
    openAddnew(true);
  }

  const closeModalPopup = (value) => {
    openAddnew(false);
    callChannelTypeList();
  }
  const deletePopup = (channelType) => {
    modalPopValue.message = 'Do you want to delete this Channel Type?';
    modalPopValue.title = 'Delete Channel Type';
    modalPopValue.value = channelType;
    modalPopValue.popUpId = 1;
    modalPopValue.active = false;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }
  
  const closePopup = () => {
    setModalPopup(false);
  };

  const confirmPopup = (res) => {
    setModalPopup(false);
    if (res.active) {
      
    } else {
      deleteChannelType(res.value)
    }
  }
  const deleteChannelType = async (data) => {
    await axios.delete('common/deleteChannelType/' + data.channelTypeId).then(res => {
		  if(res.data == 'ChannelExist') {
        ToastsStore.warning('Channel Exist for this ChannelType, Unable to delete');
      }  else {      
        ToastsStore.success('Channel Removed successfully!');
        if (searchList.length) {
          removeCTFromList(data.id)
        }
        getChannelType();
      }
    }).catch(err => {
      ToastsStore.error('Something went wrong!');
    })
  }

 
  const changeStatus = (id, status) => {
    console.log("id:", id);
    console.log("status:", status);
    let newStatus = status == '1' ? '0' : '1';
    axios.patch('common/updateChannelType/' + id + '/' + newStatus);
    state.dataTable.map(x => {
      if (x.id == id) {
        x.isActive = newStatus;
      }
    })
    setState({ ...state });
    //console.log("newStatus:", newStatus);

  }
  const removeCTFromList = (id) => {
    searchList = searchList.filter(x => x.id != id);
    setSearchList(searchList);
  }
  return (
    <div id="channelList">
      <SimpleHeader name="Channel Type" parentName="Master" />
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
      <AddChannelType  opneadd={openadd} closeModalPopup={closeModalPopup} value={editChannelType} isEdit={isEdit}  handleChangeSelect={handleChangeSelect} />
      <Container className="mt--3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Channel Type</h3>
                {/* <p className="text-sm mb-0">
                        This is an exmaple of data table using the well known
                        react-bootstrap-table2 plugin. This is a minimal setup in
                        order to get started fast.
                      </p> */}
              </CardHeader>
              {loading ? <Spinner /> : <ToolkitProvider
                ClassName="align-items-center table-flush" responsive
                data={state.dataTable}
                keyField="id"
                columns={[
                  {
                    dataField: "channelTypeId",
                    text: "Channel Type Id",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {row.channelTypeId                          }
                        </>
                      )
                    }
                  },
                  {
                    dataField: "channelType",
                    text: "Channel Type Name",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {row.channelType}
                        </>
                      )
                    }
                  },
                  {
                    dataField: "mode",
                    text:  "Type",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {row.mode == '1'?'Show in SignUp':'Hide in Signup'}
                        </>
                      )
                    }
                  },   
                  {
                    dataField: "mode",
                    text:  "Type",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {row.mode == '1'?'Primary':'Secondary'}
                        </>
                      )
                    }
                  },   
                  {
                    dataField: "createdAt",
                    text: "Created on",
                    sort: true,
                    
                  },
                  {
                    dataField: "isActive",
                    text: "Status",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          <label className="custom-toggle custom-toggle-success">
                            <input defaultChecked={row.isActive == '1' ? true : false} type="checkbox" name="status" onClick={() => { changeStatus(row.channelTypeId, row.isActive) }} />
                            <span className="custom-toggle-slider rounded-circle" data-label-off="Inactive" data-label-on="Active"></span>
                          </label>
                        </>
                      )
                    }
                  },
                  {
                    dataField: "action",
                    text: "Action",
                    formatter: (rowContent, row, index) => {
                      return (
                        <>
                          <div className="res-i">

                            <a onClick={() => { openDialag(row) }} ><i id={'Edit' + index} class="fas fa-pencil-alt ml-4" style={{ color: "#46b88f" }}>
                              <UncontrolledTooltip target={'Edit' + index} placement='bottom'> Edit </UncontrolledTooltip></i></a>                            
                              
                            <a onClick={() => deletePopup(row)}><i id={'Del' + index} class="fas fa-trash-alt ml-4" style={{ color: "#fb6340" }}>
                              <UncontrolledTooltip target={'Del' + index} placement='bottom'> Delete </UncontrolledTooltip></i></a>                            

                          </div>
                        </>
                      )
                    }
                  }
                ]}
              >
                {props => (
                  <div className="py-2 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 mb-2"
                    >
                      <Row>
                       
                        <Col lg='4' className='p-0 text-center'>
                          {/* <AdminNavbar/> */}
                          <Form
                            className={classnames(
                              "navbar-search form-inline mr-sm-3",
                            )}
                          >                           
                          </Form>
                        </Col>

                        <Col className='text-right pt-2  auto'>
                          <button class="btn btn-primary float-right" type="button" onClick={() => addChannelType()}>Add Channel Type</button>
                        </Col>


                      </Row>

                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      // pagination={pagination}
                      bordered={false}
                      hover={true}

                      rowClasses='pointer'
                    />

                    <TablePagination
                      component="div"
                      count={state.total}
                      page={page}
                      onChangePage={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </div>
                )}
              </ToolkitProvider>}
            </Card>
          </div>
        </Row>
      </Container>
      
    </div>
  );
}
export default ChannelTypeMaster;
