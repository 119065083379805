import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Card, Container, Row, CardHeader, UncontrolledTooltip, ListGroupItem, Modal,
  ListGroup, Col, Badge, Button, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Filter from "components/Filter-Right/Filter.js";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useForm } from "react-hook-form";
import { SelectAutocomplete } from '_components/FormElements/FormInput';
import axios from 'axios';
import { Spinner } from '../../Hoc/Spinner'
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/PaginationActions';
import Dialog from 'views/Hoc/Dialog/GlobalDialog';
import ChangeCoordinator from 'views/Hoc/Dialog/ChangeCordDialog';
import ConfirmDialog from 'views/Hoc/Dialog/GlobalDialog';
import NotificationAlert from "react-notification-alert";
import UserView from 'views/Hoc/Dialog/UserView';
import Drawer from 'views/Hoc/Dialog/Globaldrawer';
import Connect from 'views/Hoc/Dialog/Connect';
import { history } from '_helpers/history';
import CompanyPublicSettings from 'views/Hoc/Dialog/CompanyPublicSettings';
import ChannelExportFilter from 'views/Hoc/Dialog/ChannelExportFilter';
import { commonService } from '_services/common.service';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import EventsPop from 'views/Hoc/Eventspop';
import EventPackDetails from 'views/Hoc/Dialog/EventPackDetails';
import ChannelType from './ChannelType';
import HubspotEdit from './HubspotEdit';
import Box from '@mui/material/Box';
import { FormAutocomplete, FormInput } from "_components/FormElements/FormInput";

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Contactless, LocalConvenienceStoreOutlined } from '@material-ui/icons';
import { getInitialGridSortingState } from '@material-ui/data-grid';
import { he } from 'date-fns/locale';
import MergeCompanyData from 'views/Hoc/Dialog/MergeCompanyData';

const toggleSidenav = e => {
  console.log(e)

  if (document.getElementById('filter').classList.contains("g-sidenav-pinned")) {
    document.getElementById('filter').classList.remove("g-sidenav-pinned");
    document.getElementById('filter').classList.add("g-sidenav-hidden");
  } else {
    document.getElementById('filter').classList.add("g-sidenav-pinned");
    document.getElementById('filter').classList.remove("g-sidenav-hidden");
  }
  // function handleOrangeClick({
  //  sidenavOpen: setOpen(!sidenavOpen)
  // });
};

export const CompanyListing = (props) => {

  let [token, settoken] = useState(null);

  const notificationAlert = useRef(null);
  const { control } = useForm({ mode: 'onBlur' });
  const [showDrawer, setshowDrawer] = useState(false);
  const [connect, setshowconnect] = useState(false);

  const [badgeAlert, setBadgeAlert] = useState(false);

  const [cordAlert, setCordAlert] = useState(false);

  let [headerSorting, setHeaderSorting] = useState({ column: null, order: 'asc' });

  const [eventUser, setEventUser] = useState(false);



  const [contactid, setContact] = useState();

  const [user, setUser] = useState({ firstName: '', lastName: '', jobTitle: '', country: '' });

  const [state, setState] = useState({ dataTable: [], total: 0, duplicateCheck: [], eventInfo: [] });
  const [searchState, setSearch] = useState({ search: null, dropdownList: [] });
  const [filterValueState, setFilterValue] = useState({ Company_type: [], is_OEM: null, country: [], plan: null, product_Categories: [], tag: null, product_families: [], brand: [], dist_profile: [], reseller_profile: [], retailer_profile: [], pending_profile: null, event_attd: null, event_manage: [] });
  const [filterOptionState, setFilterOption] = useState({ countryOption: [], product_cat_Option: [], plan_Option: plan_Option, tag_Option: tag_Option, company_type_Option: [], is_OEM_Option: is_OEM_Option, product_family_option: [], brand_Option: [], distProfile_Option: [], reseller_Option: [], retailer_Option: [], profile_Option: profile_Option, event_option: event_option, event_manage_options: [] });
  const [priviledge, setPriviledge] = useState({ View: true, Write: true, Delete: true, Approve: true, Dismiss: true });

  let [loading, setLoading] = useState(true);
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [open, setModalOpen] = useState(false);
  let [isEventOpen, setEventOpen] = useState(false);
  let [isEventCord, setEventCord] = useState(false);
  let [channelId, setChannelId] = useState(null);
  let [contactId, setContactId] = useState(null);
  let [contactList, setContactList] = useState(null);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, fromEvent: null });
  let [cordValue, setChangeCordValue] = useState({ eventId: null, users: null, channelId: null });

  let adminUser = JSON.parse(localStorage.getItem('CHuser'));
  const [drawerInput, setDrawerInput] = useState(null);
  let [sort, setSort] = useState('');
  let [isSort, setisSort] = useState(false);

  const [mailFlag, setMailFlag] = useState(false);
  const [template, setMailTemplate] = useState({ id: null, templateId: null, label: null });
  const [mailContact, setMailContact] = useState({});
  const [showFilterHeader, setShowFilterHeader] = useState(false);
  const [activeType, setActiveType] = useState({ id: 'live', label: 'Other' });
  const [channelTypeList, setChannelTypeList] = useState([]);
  const[product_families_selected, setProduct_families_selected] = useState(false);
 
  useEffect(() => {

    //clearing local storage data about selected user
    let existing = JSON.parse(localStorage.getItem('CHuser'));
    existing['userData'] = {};
    localStorage.setItem('CHuser', JSON.stringify(existing));
    getChannelType();
    getPendingChannel();
    getPendingChannelsList();
    getCountryList();
    // getProductFamily();
    getAllProductFamilies();
    // getProductCat();
    getOldBrandOption();
    getOldProfileOption();
    // getEventOptions();
    getUserPriviledge();
    getEventManageOptions()
    // getPlanList();
   
  }, [props]);

  const getChannelType = async() => {
    await axios.get('/common/getChannelAllType').then(res => {
      let channelTypeArr = {};
      
     // console.log(' ====== channelTypeArr ====== ',channelTypeArr);
      //return temp;
      setChannelTypeList(res.data);
      let company_type_Option = res.data;
      if (adminUser.accessId == 3 || adminUser.accessId == 4)
        filterOptionState.company_type_Option = company_type_Option.filter(i => [3, 4, 5].includes(i.id));
      else
        filterOptionState.company_type_Option = company_type_Option;
  
      setFilterOption({ ...filterOptionState, company_type_Option: company_type_Option });
      
    }).catch(err => {
      console.log('Error while getting channel Type')
    }); 

  }     
  

  const getEventManageOptions = () => {
    let data = {
      page: 0,
      limit: 10
    }
    axios.get('/channelAdmin/getAllEventsFilterOptions', data).then(res => {
      filterOptionState.event_manage_options = res.data.map(it => {
        let newIt = {}
        newIt.name = it.name
        newIt.id = it.id

        return newIt
      })
      setFilterOption({ ...filterOptionState })
    })
  }

  const getUserPriviledge = () => {
    axios.get('/admin/getUserPriviledge/' + adminUser.userId).then(res => {

      if (res.data.length > 0) {
        let data = res.data.map(x => +x.notificationId);
        priviledge.View = data.includes(22) ? true : false;
        priviledge.Write = data.includes(33) ? true : false;
        priviledge.Delete = data.includes(44) ? true : false;
        priviledge.Approve = data.includes(55) ? true : false;
        priviledge.Dismiss = data.includes(66) ? true : false;
        setPriviledge(priviledge);
      }
    })
  }

  const getCountryList = () => {
    axios.post('/channelAdmin/getFilterCountry', {
      signupstatusid: [14, 15],
      userId: adminUser.userId
    }).then(res => {
      //console.log("page-->customer-->api-->channelAdmin/getFilterCountry ", new Date())
      filterOptionState.countryOption = res.data;
      setFilterOption({ ...filterOptionState, countryOption: filterOptionState.countryOption });
    }).catch(err => {
      console.log("page-->customer-->api-->channelAdmin/getFilterCountry error ", err, " ", new Date())
    })
  }

  // const getProductFamily = () => {
  //   axios.post('/channelAdmin/getProductFamily', {
  //     signupstatusid: [14, 15],
  //     userId: adminUser.userId,
  //     accessId: +adminUser.accessId
  //   }).then(res => {
  //     //console.log("page-->customer-->api-->channelAdmin/getProductFamily ", new Date())
  //     filterOptionState.product_family_option = res.data;
  //     setFilterOption({ ...filterOptionState, product_family_Option: filterOptionState.product_family_option });
  //   }).catch(err => {
  //     console.log("page-->customer-->api-->channelAdmin/getProductFamily error ", err, new Date())
  //   })
  // }

  const getAllProductFamilies = () => {
    axios.get(`/channelAdmin/getAllProductFamilies`).then(res => {
      filterOptionState.product_family_option = res.data;
      setFilterOption({ ...filterOptionState, product_family_Option: filterOptionState.product_family_option });
    }).catch(err => {
      console.log("page-->customer-->api-->channelAdmin/buisnessType_Id error ", err, new Date())
    })
  }
 
  // const getProductCat = () => {
  //   axios.post('/channelAdmin/getProductCat', {
  //     signupstatusid: [14, 15],
  //     userId: adminUser.userId,
  //     accessId: +adminUser.accessId
  //   }).then(res => {
  //     //console.log("page-->customer-->api-->channelAdmin/getProductCat ", new Date())
  //     filterOptionState.product_cat_Option = res.data;
  //     setFilterOption({ ...filterOptionState, product_cat_Option: filterOptionState.product_cat_Option });
  //   }).catch(err => {
  //     console.log("page-->customer-->api-->channelAdmin/getProductCat error ", err, new Date())
  //   })
  // }

  
  const getProductCategories = (productGroup) => {
    axios.post('/channelAdmin/getProductCategories', {
      productGroup
    }).then(res => {
      filterOptionState.product_cat_Option = res.data;
      setFilterOption({ ...filterOptionState, product_cat_Option: filterOptionState.product_cat_Option });
    }).catch(err => {
      console.log("page-->customer-->api-->channelAdmin/getProductCategories error ", err, new Date())
    })
  }

  const getOldBrandOption = () => {
    axios.post('/channelAdmin/getChannelBrandOptions', {
      signupstatusid: [14, 15],
      userId: adminUser.userId,
      accessId: +adminUser.accessId
    }).then(res => {
      //console.log("page-->customer-->api-->channelAdmin/getChannelBrandOptions ", new Date())
      filterOptionState.brand_Option = res.data;
      setFilterOption({ ...filterOptionState, brand_Option: filterOptionState.brand_Option });
    }).catch(err => {
      console.log("page-->customer-->api-->channelAdmin/getChannelBrandOptions error ", err, new Date())
    })
  }

  const getOldProfileOption = () => {
    axios.post('/channelAdmin/getChannelProfileOptions', {
      signupstatusid: [14, 15],
      userId: adminUser.userId,
      accessId: +adminUser.accessId
    }).then(res => {

      //console.log("page-->customer-->api-->channelAdmin/getChannelProfileOptions ", new Date())

      let key = ['Distributor'];
      filterOptionState.distProfile_Option = res.data.profileList?.filter(x => key.includes(x.groupname));
      key = ['Retailer'];
      filterOptionState.retailer_Option = res.data.profileList?.filter(x => key.includes(x.groupname));
      key = ['Reseller'];
      filterOptionState.reseller_Option = res.data.profileList?.filter(x => key.includes(x.groupname));
      //console.log('filter option state', filterOptionState)
      setFilterOption({ ...filterOptionState });
    }).catch(err => {
      console.log("page-->customer-->api-->channelAdmin/getChannelProfileOptions error ", err, new Date())
    });
  }
  const getPlanList = () => {
    axios.get('/channelAdmin/getSubscriptionPlan').then(res => {
      //console.log("page-->customer-->api-->channelAdmin/getSubscriptionPlan ", new Date())
      filterOptionState.plan_Option = res.data;
      setFilterOption({ ...filterOptionState, plan_Option: filterOptionState.plan_Option });
    }).catch(err => {
      console.log("page-->customer-->api-->channelAdmin/getSubscriptionPlan error ", err, new Date())
    });
  }

  const getEventOptions = async () => {
    await axios.get('/channelAdmin/getEventOptions').then(res => {
      //console.log("page-->customer-->api-->channelAdmin/getEventOptions ", new Date())
      event_option.push(...res.data.events)
    }).catch(err => {
      console.log("page-->customer-->api-->channelAdmin/getEventOptions error ", err, new Date())
      return ToastsStore.error('something went wrong')
    });
  }

  const getFilterData = () => {
    let filter = [];
    if (filterValueState.country && filterValueState.country.length > 0) {
      filter.countryId = filterValueState.country.map(x => parseInt(x.id));
    }
    if (filterValueState.plan) { //&& filterValueState.plan.length > 0) {
      filter.planId = filterValueState.plan.id; //map(x => parseInt(x.id));
    }

    if (filterValueState.Company_type && filterValueState.Company_type.length > 0) {
      // filter.channelTypeId = filterValueState.Company_type.id;
      filter.channelTypeId = filterValueState.Company_type.map(x => parseInt(x.id));
    }

    if (filterValueState.tag) {
      filter.tag = filterValueState.tag.id;
    }

    if (filterValueState.pending_profile) {
      filter.pending_profile = filterValueState.pending_profile.id;
    }


    if (filterValueState.is_OEM) {
      filter.is_OEM = filterValueState.is_OEM.id;
    }
    if (filterValueState.product_families && filterValueState.product_families.length > 0) {
      filter.product_families = filterValueState.product_families.map(x => parseInt(x.id));
    }
    if (filterValueState.product_Categories && filterValueState.product_Categories.length > 0) {
      filter.product_Categories = filterValueState.product_Categories.map(x => parseInt(x.id));
    }
    if (filterValueState.brand && filterValueState.brand.length > 0) {
      filter.brand = filterValueState.brand.map(x => parseInt(x.id));
    }
    //profile 
    if (filterValueState.dist_profile && filterValueState.dist_profile.length > 0) {
      filter.retailerprofileId = filterValueState.dist_profile.map(x => parseInt(x.id));
    }
    if (filterValueState.reseller_profile && filterValueState.reseller_profile.length > 0) {
      filter.retailerprofileId = filter.retailerprofileId?.length ? filter.retailerprofileId.push(...filterValueState.reseller_profile.map(x => parseInt(x.id))) : filterValueState.reseller_profile.map(x => parseInt(x.id));
    }
    if (filterValueState.retailer_profile && filterValueState.retailer_profile.length > 0) {
      filter.retailerprofileId = filter.retailerprofileId?.length ? filter.retailerprofileId.push(...filterValueState.retailer_profile.map(x => parseInt(x.id))) : filterValueState.retailer_profile.map(x => parseInt(x.id));
    }
    if (filterValueState.event_attd) {
      filter.eventId = filterValueState.event_attd.id;
    }

    if (filterValueState.event_manage && filterValueState.event_manage.length > 0) {
      filter.event_manage = filterValueState.event_manage.map(x => parseInt(x.id));
    }

    return filter;
  }

  const closeFilter = () => {
    if (document.getElementById("filter").classList.contains("g-sidenav-pinned")) {
      document.getElementById("filter").classList.remove("g-sidenav-pinned");
      document.getElementById("filter").classList.add("g-sidenav-hidden");
      document.getElementById("filter-hide").classList.add("filter-hide-width");
    }
  };

  const getPendingChannel = async () => {

    //props.location.approval
    loading = true;
    setLoading(loading);
    var filter = await getFilterData();
    let data = {
      search: searchState.search ? searchState.search[0] ? searchState.search[0].id : null : null,
      signupstatusid: [14],
      userId: adminUser.userId,
      filter: {
        countryId: filter.countryId ? filter.countryId : null,
        planId: filter.planId ? filter.planId : null,
        channelTypeId: filter.channelTypeId && filter.channelTypeId.length > 0 ? filter.channelTypeId : null,
        is_OEM: filter.is_OEM ? filter.is_OEM : null,
        tag: filter.tag ? filter.tag : null,
        productCategoryId: filter.product_Categories ? filter.product_Categories : null,
        productFamilyId: filter.product_families ? filter.product_families : null,
        brandId: filter.brand ? filter.brand : null,
        retailerprofileId: filter.retailerprofileId ? filter.retailerprofileId : null,
        specialityId: filter.specialityId ? filter.specialityId : null,
        pending_profile: filter.pending_profile ? filter.pending_profile : null,
        product_Categories: filter.product_Categories ? filter.product_Categories : null,
        eventId: filter.eventId ? filter.eventId : null,
        events: filter.event_manage && filter.event_manage.length > 0 ? filter.event_manage : null,
        approval_filter: props?.location?.approval ? props.location.approval : null,
        active_type: activeType.id
      },
      page: page * rowsPerPage,
      order: isSort ? sort : false,
      headerSorting: headerSorting ? headerSorting : null,
      limit: rowsPerPage
    }
    let duplicateCheckArr = []; let duplicateCheck1 = [];
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    let eventCoordinate = [];
    axios.post('/channelAdmin/pendingApproval',
      data).then(res => {
        //console.log("page-->customer-->api-->channelAdmin/pendingApproval ", new Date())
        res.data.rows.map(async x => {
          if (x.hubspotCompanyId) {
            duplicateCheck1[x.channelId] = '';
            duplicateCheck1[x.channelId] = await axios.get('/channelAdmin/checkDuplicateHSIds/' + x.hubspotCompanyId + '/' + x.channelId).then(result => {
              //  console.log('result ===> ', result.data.count);

              // console.log("page-->customer-->api-->channelAdmin/checkDuplicateHSIds ", new Date())
              closeFilter();
              return result.data.count

            }).catch(err => {
              console.log("page-->customer-->api-->channelAdmin/checkDuplicateHSIds error ", err, new Date())
            });
          } else {
            duplicateCheck1[x.channelId] = 0
          }
        });
        state.duplicateCheck = duplicateCheck1;
        state.dataTable = res.data.rows;
        state.total = res.data.count;// res.data.record_total;
        state.eventInfo = res.data.eventInfo;
        loading = false;
        setLoading(loading);
        setState({ ...state });
      }).catch((err) => {
        state.dataTable = [];
        state.total = 0;
        loading = false;
        setLoading(loading);
        setState({ ...state });
      });
    if (activeType.id == 'other') {
      getPendingChannelsList();
    }
  }

  const getPendingChannelsList = () => {
    axios.get('/channelAdmin/channelAccountsName/' + adminUser.userId + '/' + activeType.id).then(res => {

      //console.log("page-->customer-->api-->channelAdmin/pendingChannelsList/", new Date())
      let data = [];
      data.push(...res.data)
      data = data.map(x => {
        return { name: x.name.trim(), id: x.id }
      });
      let contact = [];
      res.data.map(x => {

        x.contacts.map(c => {
          contact.push({ name: c.firstName + ' ' + c.lastName, id: x.id, companyName: x.name.trim() });
        })
      });

      // if(fromEvent == null){
      //   console.log('gfdgdfgdfgdfgd')
      //   duplicateCheck =  axios.get('/hubspot/getMatchedHS/',  ch_Id).then(res => {
      //     let data = [];
      //     console.log(res);
      //     console.log(res.data);
      //   });
      // }

      data.push(...contact);
      searchState.dropdownList = data
      setSearch({ dropdownList: searchState.dropdownList });
    }).catch(err => {
      console.log("page-->customer-->api-->channelAdmin/pendingChannelsList/  error->", err, "  ", new Date())
    });
  }

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getPendingChannel();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    callPendingChannel();
  };

  const handleChangeSelectFilter = (input, value) => {
    handleChangeSelect(input, value, true);
  }

  const handleOnInputChange = (input, value) => {
    if (activeType.id == 'other') {
      let resutDataOption = [];
      if (value.length > 3) {

        searchState.dropdownList.filter(function (item) {

          let result = item.name.match(new RegExp(value, 'ig'));
          if (result) {
            resutDataOption.push(item);
          }
        });
        if (resutDataOption.length > 0) {
          searchState.dropdownList = resutDataOption;
          setSearch({ dropdownList: searchState.dropdownList });
        }
      }
    }
  }

  const handleChangeSelectSearch = (input, value) => {
    handleChangeSelect(input, value, false);
  }

  const handleChangeSelect = (input, value, reload) => {
    if (input == 'search') {
      filterValueState.Company_type = [];
      filterValueState.is_OEM = null;
      filterValueState.country = [];
      filterValueState.plan = [];
      filterValueState.tag = null;
      filterValueState.pending_profile = null;
      filterValueState.product_Categories = [];
      filterValueState.product_families = [];
      filterValueState.brand = [];
      filterValueState.retailer_profile = [];
      filterValueState.reseller_profile = [];
      filterValueState.dist_profile = [];
      filterValueState.event_attd = [];
      filterValueState.event_manage = []

      setFilterValue({ ...filterValueState });
      searchState.search = [value]
      setSearch({ ...searchState })
    }
    if (input == 'Company_type') {
      filterValueState.Company_type = value
      setFilterValue({ ...filterValueState })
    }

    // if (input == 'is_OEM') {
    //   filterValueState.is_OEM = value  
    //   setFilterValue({ ...filterValueState })
    // }

    if (input == 'country') {
      filterValueState.country = value
      setFilterValue({ ...filterValueState })
    }
    if (input == 'plan') {
      filterValueState.plan = value
      setFilterValue({ ...filterValueState })
    }
    if (input == 'tag') {
      filterValueState.tag = value
      setFilterValue({ ...filterValueState })
    }
    if (input == 'pending_profile') {
      filterValueState.pending_profile = value
      setFilterValue({ ...filterValueState })
    }

    if (input == 'product_Categories') {
      filterValueState.product_Categories = value
      setFilterValue({ ...filterValueState });
    }
    if (input == 'product_family') {
      filterValueState.product_families = value;
      setFilterValue({ ...filterValueState });
      if(value?.length > 0){
        setProduct_families_selected(true);
        getProductCategories(value);
      }else{
        setProduct_families_selected(false);
        filterValueState.product_Categories = [];
      }
      setFilterValue({ ...filterValueState });
    }

    if (input == 'brand') {
      filterValueState.brand = value;
      setFilterValue({ ...filterValueState });
    }
    if (input == 'distProfile') {
      filterValueState.dist_profile = value;
      setFilterValue({ ...filterValueState });
    }
    if (input == 'retailerProfile') {
      filterValueState.retailer_profile = value;
      setFilterValue({ ...filterValueState });
    }
    if (input == 'resellerProfile') {
      filterValueState.reseller_profile = value;
      setFilterValue({ ...filterValueState });
    }
    if (input == 'event_attd') {
      filterValueState.event_attd = value;
      setFilterValue({ ...filterValueState });
    }

    if (input == 'event_manage') {
      filterValueState.event_manage = value;
      setFilterValue({ ...filterValueState })
    }

    if (reload) {
      callPendingChannel();
    } else {
      handleFilterHeader(filterValueState);
    }

  }

  const handleFilterHeader = (filterStates) => {

    let toShowHeader = false
    let filterKeys = Object.keys(filterStates)

    filterKeys.forEach((key) => {

      if (key == 'event_attd' || key == 'is_OEM' || key == 'plan' || key == 'tag' || key == 'pending_profile') {
        if (filterStates[key]) {
          toShowHeader = true
        }
      }
      else {
        if (filterStates[key] && filterStates[key].length > 0) {
          toShowHeader = true
        }

      }

    })

    setShowFilterHeader(toShowHeader)

  }

  const handleFilter = () => {
    callPendingChannel();
  }

  const callPendingChannel = () => {
    closeDrawer();
    page = 0;
    setPage(page);
    getPendingChannel();
  }
  const setSorting = (data) => {
    isSort = true;
    setisSort(isSort);
    if (data != sort) {
      sort = data;
      setSort(sort);
      callPendingChannel();
    }
  }

  const clearSorting = () => {
    isSort = false;
    setisSort(isSort);
    callPendingChannel();
  }
  const resetFilterValue = () => {
    filterValueState.Company_type = [];
    filterValueState.is_OEM = null;
    filterValueState.country = [];
    filterValueState.plan = null;
    filterValueState.tag = null;
    filterValueState.product_Categories = [];
    filterValueState.product_families = [];
    filterValueState.brand = [];
    filterValueState.retailer_profile = [];
    filterValueState.reseller_profile = [];
    filterValueState.dist_profile = [];
    filterValueState.event_attd = null;
    filterValueState.event_manage = []
    setFilterValue({ ...filterValueState });
    setShowFilterHeader(false)
    callPendingChannel();
  }

  const updateStatus = (selectedProfile, data, id, contact, fromEvent, eventId, packageId, eventType, multiEventApproval, packagePrice, paymentSplit) => {

    //console.log("packprice:", packagePrice)
    loading = true;
    let letApprove;
    if (multiEventApproval) {
      letApprove = contact.find(x => x.eventId == eventId);
      packageId = letApprove?.packageId;
      contact = letApprove?.contactId;
    }
    // console.log('associate user:', data, id, contact, fromEvent, eventId, packageId, multiEventApproval);
    setLoading(loading);
    axios.put('/channelAdmin/channelApproval', {
      channelId: data,
      contact: contact,
      signUpStatusId: id,
      fromEvent: fromEvent,
      eventId: eventId,
      eventType: eventType,
      packageId: packageId,
      packagePrice: +packagePrice,
      paymentSplit: paymentSplit,
      selectedProfile: selectedProfile ? selectedProfile : null

    }).then(res => {
      // console.log("page-->customer-->api-->channelAdmin/channelApproval", new Date())
      if (res) {
        if (id == 0 && fromEvent == '0')
          ToastsStore.success('Channel Removed successfully')
        else if (data == fromEvent) {
          let eventdata = {
            contactId: typeof contact === 'object' ? contact.contactId : contact,
            channelId: data,
            profileurl: process.env.REACT_APP_LOCALSITE + 'login',
            eventId: eventId,
            packageId: packageId
          }

          if (id == 15 || id == 501) {

            if (id == 501) {
              let maildata = {
                contactId: contact,
                packageId: packageId,
                profileurl: process.env.REACT_APP_LOCALSITE + 'login',
              }
              if (!eventId)
                axios.post('mailerservice/Channel_Approval_Mail', maildata).then(res => {
                  // console.log("page-->customer-->api-->mailerservice/Channel_Approval_Mail", new Date())
                }).catch(err => {
                  console.log("page-->customer-->api-->mailerservice/Channel_Approval_Mail error->", err, "  ", new Date())
                });
              else {
                let eventdata = {
                  channelId: data,
                  approvalStatus: 1,
                  eventId: eventId,
                  packageId: packageId,
                  //profileurl: process.env.REACT_APP_LOCALSITE + 'login',
                  //event redirect
                  //profileurl: 'http://localhost:4200/eventLoginRedirect/'+eventId+'/'+data,
                  profileurl: process.env.REACT_APP_LOCALSITE + 'eventLoginRedirect/' + eventId + '/' + data,
                  isAssociateUser: true,
                  contactId: typeof (contact) == "object" ? contact.contactId : contact
                }
                axios.post('mailerservice/eventsApprovalRelatedMail', Object.assign(eventdata, { isNew: '1' })).then(res => {
                  //  console.log("page-->customer-->api-->mailerservice/eventsApprovalRelatedMail", new Date())
                }).catch(err => {
                  console.log("page-->customer-->api-->mailerservice/eventsApprovalRelatedMail error->", err, "  ", new Date())
                });
              }
            }


            if (id == 15 && eventId)
              axios.post('mailerservice/eventsApprovalRelatedMail', Object.assign(eventdata, { profileurl: process.env.REACT_APP_LOCALSITE + 'eventLoginRedirect/' + eventId + '/' + data, packageId: packageId, approvalStatus: 1, isNew: '0' })).then(res => {
                //  console.log("page-->customer-->api-->mailerservice/eventsApprovalRelatedMail", new Date())
              }).catch(err => {
                console.log("page-->customer-->api-->mailerservice/eventsApprovalRelatedMail error->", err, "  ", new Date())
              });;;
            // axios.post('mailerservice/eventsDeligateApproval', eventdata);

            ToastsStore.success('Event Approved successfully');

          }//partial refusal associate user (secondary delegates)
          else if (id == 502) {
            let eventdata = {
              channelId: data,
              approvalStatus: 3,
              eventId: eventId,
              packageId: packageId,
              profileurl: process.env.REACT_APP_LOCALSITE + 'login',
              isAssociateUser: true,
              contactId: typeof (contact) == "object" ? contact.contactId : contact
            }
            axios.post('mailerservice/eventsApprovalRelatedMail', Object.assign(eventdata, { isNew: '1' })).then(res => {
              //  console.log("page-->customer-->api-->mailerservice/eventsApprovalRelatedMail", new Date())
            }).catch(err => {
              console.log("page-->customer-->api-->mailerservice/eventsApprovalRelatedMail error->", err, "  ", new Date())
            });
          }
          else if (id == 33 && eventId) {
            axios.post('mailerservice/eventsApprovalRelatedMail', Object.assign(eventdata, { approvalStatus: 2 })).then(res => {
              //  console.log("page-->customer-->api-->mailerservice/eventsApprovalRelatedMail", new Date())
            }).catch(err => {
              console.log("page-->customer-->api-->mailerservice/eventsApprovalRelatedMail error->", err, "  ", new Date())
            });
            ToastsStore.warning('Event Dismissed successfully');
          } else {
            ToastsStore.warning('Dismissed successfully');
          }

        }
        else if (id == 0 && fromEvent == '1') {
          //event mail
          let eventdata = {
            channelId: data,
            approvalStatus: 4,
            eventId: eventId,
            contactId: typeof (contact) == "object" ? contact.contactId : contact
          }
          axios.post('mailerservice/eventsApprovalRelatedMail', eventdata).then(res => {
            //  console.log("page-->customer-->api-->mailerservice/eventsApprovalRelatedMail", new Date())
          }).catch(err => {
            console.log("page-->customer-->api-->mailerservice/eventsApprovalRelatedMail error->", err, "  ", new Date())
          });
          ToastsStore.success('Request Send successfully')
        }
        else {
          id == 15 ? ToastsStore.success(eventId ? 'Event Approved successfully' : 'Channel Approved successfully') : ToastsStore.warning('Channel Dismiss successfully');
          if (id == 15) {
            let maildata = {
              to: contact.email,
              contactId: contact.contactId,
              packageId: packageId,
              profileurl: process.env.REACT_APP_LOCALSITE + 'login'
            }
            if (eventId == null && fromEvent == null)
              axios.post('mailerservice/Channel_Approval_Mail', maildata).then(res => {
                //  console.log("page-->customer-->api-->mailerservice/Channel_Approval_Mail", new Date())
              }).catch(err => {
                console.log("page-->customer-->api-->mailerservice/Channel_Approval_Mail error->", err, "  ", new Date())
              });
            //event mail
            if (eventId && fromEvent == 15) {
              let eventdata = {
                channelId: data,
                approvalStatus: (fromEvent == '2') ? 3 : 1,
                eventId: eventId,
                packageId: packageId,
                profileurl: process.env.REACT_APP_LOCALSITE + 'login',
                contactId: typeof (contact) == "object" ? contact.contactId : contact
              }
              axios.post('mailerservice/eventsApprovalRelatedMail', Object.assign(eventdata, { isNew: '0' })).then(res => {
                //  console.log("page-->customer-->api-->mailerservice/eventsApprovalRelatedMail", new Date())
              }).catch(err => {
                console.log("page-->customer-->api-->mailerservice/eventsApprovalRelatedMail error->", err, "  ", new Date())
              });
            }

            if (fromEvent == '1' || fromEvent == '2' || fromEvent == '3') {
              let eventdata = {
                channelId: data,
                approvalStatus: (fromEvent == '2' || fromEvent == '3') ? 3 : 1,
                eventId: eventId,
                packageId: packageId,
                profileurl: process.env.REACT_APP_LOCALSITE + 'login',
                isAssociateUser: fromEvent == '3' ? true : false,
                contactId: typeof (contact) == "object" ? contact.contactId : contact
              }
              axios.post('mailerservice/eventsApprovalRelatedMail', Object.assign(eventdata, { isNew: '1' })).then(res => {
                // console.log("page-->customer-->api-->mailerservice/eventsApprovalRelatedMail", new Date())
              }).catch(err => {
                console.log("page-->customer-->api-->mailerservice/eventsApprovalRelatedMail error->", err, "  ", new Date())
              });
            }
          }
          else if (id == 16) {
            let maildata = {
              to: contact.email,
              contactId: contact.contactId,
              eventId: eventId,
              //profileurl: process.env.REACT_APP_LOCALSITE + 'login'
            }
            if (data != fromEvent && fromEvent != 15)
              axios.post('mailerservice/Channel_Dismiss_Mail', maildata).then(res => {
                //  console.log("page-->customer-->api-->mailerservice/Channel_Dismiss_Mail", new Date())
              }).catch(err => {
                console.log("page-->customer-->api-->mailerservice/Channel_Dismiss_Mail5 error->", err, "  ", new Date())
              });

            //event mail
            if (fromEvent || eventId) {
              // let eventdata = {
              //   channelId: data,
              //   approvalStatus: 2,
              //   eventId: eventId,
              //   eventId: packageId,
              //   contactId: typeof (contact) == "object" ? contact.contactId : contact
              // }
              // if(packageId){
              //   axios.post('mailerservice/eventsApprovalRelatedMail', eventdata).then(res => {
              //     //  console.log("page-->customer-->api-->mailerservice/eventsApprovalRelatedMail", new Date())
              //   }).catch(err => {
              //     console.log("page-->customer-->api-->mailerservice/eventsApprovalRelatedMail error->", err, "  ", new Date())
              //   });
              // }
            }
          }

        }

        setModalPopValue({ message: null, title: null, value: null, popUpId: null, fromEvent: null });
        callPendingChannel();
        searchState.search = null
        setSearch({ ...searchState, search: searchState.search })
      }
    }).catch((err) => {
      console.log('err', err)
      loading = false;
      setLoading(loading);
      ToastsStore.error('Soming went wrong');
    })
  }

  const openContact = (data) => {
    setDrawerInput(data);
    setshowDrawer(true);
    //console.log('datauser', data);
  }

  const [showPasswordDrawer, setPasswordDrawer] = useState(false);

  const openPassword = (data) => {
    setDrawerInput(data);
    setPasswordDrawer(true);
    // console.log('datauser', data);
  }

  const openModalPopUp = (channelType, ch_Id, id, contact, fromEvent, eventId, eventChannel, packageId, eventParticipants, contactList) => {

    //  console.log('eventId', eventId, "fromEvent", fromEvent, id, eventChannel, packageId, eventParticipants)
    //  console.log('ch_Id', ch_Id, "contact", contact, id, "contactList", contactList)
    let packageName;
    let multiEventApproval = false;

    packageName = (state.eventInfo && eventId)?state.eventInfo?.find(e => e.id == eventId).hasPackage.find(p => p.id == packageId)?.name:'';

    if (id == 15 || id == 33) {
      multiEventApproval = eventChannel?.filter(ech => ech.approvalStatus == 0 || ech.approvalStatus == null).length > 1 ? true : false
    }
    else if (id == 25 || id == 43) {
      id = (id == 25) ? 15 : (id == 43) ? 33 : id;
      multiEventApproval = eventParticipants?.filter(x => x.status == 0 || x.status == null).length > 1 ? true : false;
    }


    if (eventParticipants?.filter(x => x.status == 0 || x.status == null).length && !multiEventApproval) {
      contact = +eventParticipants.find(x => x.status == 0 || x.status == null).contactId;
      eventId = +eventParticipants.find(x => x.status == 0 || x.status == null).eventId;
      packageId = +eventParticipants.find(x => x.status == 0 || x.status == null).packageId;

      packageName = state.eventInfo.find(e => e.id == eventId).hasPackage.find(p => p.id == packageId).name;
    }
   

    // if (Array.isArray(eventChannel) && eventChannel.length==1){
    //   eventId=eventChannel[0].eventId;
    //   packageName = eventChannel.find(ec => ec.eventId == eventId).package?.packageName;
    //   // packageName = eventChannel[0].package?.packageName;
    // }
    // else if(multiEventApproval){
    //   packageName = eventChannel.find(ec => ec.eventId == eventId).package?.packageName;
    // }
    // else
    //   packageName = eventChannel;

    let eventListFlag = false;


    let packageNameList = {
      201: 'Hosted Buyer',
      202: 'Invited Buyer',
      203: 'Visiting Delegate',
      204: 'Extra Delegate Shared Room',
      205: 'Extra Delegate Single Room'
    };

    if (ch_Id == fromEvent) {

      //15 - approve button on channel list &  25 - approval button on contact
      if (id == 15 || id == 25) {

        //code change package before approval for event
        //console.log("ppp:", eventChannel, eventParticipants)
        if (eventParticipants.length) {
          modalPopValue.channelType = eventChannel[0].package ? eventChannel[0].package['channelType'] : null;
          modalPopValue.isEventSignup = true;
          modalPopValue.packageUser = eventChannel.filter(ch => ch.approvalStatus == 1 && ch.eventId == eventId).length ? 2 : 1;
        }

        // eventListFlag = true;
        // multiEventApproval = eventParticipants.filter(x => x.status == 0||x.status == null).length > 1 ? true : false;
        // console.log('eventinfo', state.eventInfo)
        modalPopValue.message = (packageId == 204 || packageId == 205) ? 'You want to Approve this second user for Event' : 'You want to Approve this user for Event';
        modalPopValue.title = 'Approve User For Event';
        modalPopValue.second_message = packageId != 0 ? "Selected Package - " + packageName : ''; //(packageId == 204) ? "Selected Package - Extra Delegate Shared Room" : "Selected Package - Extra Delegate Single Room";
      }
      else if (id == 501) {
        // eventListFlag = eventId ? true : false;
        modalPopValue.message = 'You want to Associate this user ' + (eventId ? 'With event' : '');
        modalPopValue.title = 'Approve Associate User ' + (eventId ? 'with event' : '');
        modalPopValue.second_message = eventId ? "Selected Package - " + packageName : ''; // (packageId == 204) ? "Selected Package - Extra Delegate Shared Room" : "Selected Package - Extra Delegate Single Room";
      }
      else if (id == 502) {
        modalPopValue.message = 'You want to Associate this user ' + (eventId ? 'Without approving event' : '');
        modalPopValue.title = 'Approve Associate User ' + (eventId ? 'without event' : '');
        // modalPopValue.second_message = eventId ? "Selected Package - " + packageName : ''; // (packageId == 204) ? "Selected Package - Extra Delegate Shared Room" : "Selected Package - Extra Delegate Single Room";
      }
      else if (id == 503) {
        modalPopValue.message = 'You want to Dismiss this Associate user';
        modalPopValue.title = 'Dismiss Associate Event';
        modalPopValue.second_message = eventId ? "Selected Package - " + packageName : '' // (packageId == 204) ? "Selected Package - Extra Delegate Shared Room" : "Selected Package - Extra Delegate Single Room";
      }
      else {
        //multiEventApproval = eventParticipants.filter(x => x.status == 0||x.status == null).length > 1 ? true : false;
        modalPopValue.message = (packageId == 204 || packageId == 205) ? 'You want to Dismiss this second user for Event' : 'You want to Dismiss this user for Event';
        modalPopValue.title = 'Dismiss User For Event';
        modalPopValue.second_message = packageId != 0 ? "Selected Packages - " + packageName : ''; //(packageId == 204) ? "Selected Package - Extra Delegate Shared Room" : "Selected Package - Extra Delegate Single Room";
      }

      let eventChannelId = (eventParticipants)?(eventParticipants.length > 0)?eventParticipants[0].eventChannelId:null : null;
      modalPopValue.value = { contactList: contactList, eventChannel: eventChannel, multiEventApproval: multiEventApproval, channelId: ch_Id, id: id, contact: multiEventApproval ? eventParticipants : contact, fromEvent: fromEvent, eventId: eventId, packageId: packageId, eventInfo: state.eventInfo, registeredEventId: [], eventListFlag: eventListFlag,eventChannelId:eventChannelId };
    }
    else if (id === 15) {
      // if (fromEvent == 1 || fromEvent == 15)
      // eventListFlag = true;

      //code change package before approval for event
      let eventChannelId;
      if (contact.eventParticipants.length) {
        modalPopValue.isEventSignup = true;
        modalPopValue.packageUser = contact.eventParticipants.length > 1 ? 2 : 1;
        eventChannelId = contact?.eventParticipants.filter(x => x.contactId == eventChannel.find(ch => ch.approvalStatus != 1)?.registeredBy)[0]?.eventChannelId;
      }

      let duplicateCheck = (state.duplicateCheck[ch_Id] == 0) ? false : true;
      modalPopValue.message = (fromEvent == 1 && contact.eventParticipants.length > 0) ? 'You want to Approve this Channel for both Event and CH' : fromEvent == 2 ? 'You want Approve this Channel for CH Only' : fromEvent == 15 ? `You want to Approve this Channel & User - ${contact?.firstName + ' ' + contact?.lastName} for Event` : 'You want to Approve';
      modalPopValue.title = "Approve Channel";
      modalPopValue.profileType = channelType;


      if (contact?.isActive != '1' && fromEvent == 15) {
        checkActiveContact();
        return;
      }
      if (fromEvent == 1 && contact.eventParticipants.length > 0)
        modalPopValue.second_message = packageName ? "Selected Package - " + packageName : duplicateCheck ? 'Please check duplicate values' : '';
      modalPopValue.value = { eventChannel: eventChannel, channelId: ch_Id, id: id, contact: contact, fromEvent: fromEvent, eventId: eventId, packageId: packageId, eventInfo: state.eventInfo, registeredEventId: [], eventListFlag: eventListFlag , eventChannelId:eventChannelId};
    } else if (id == 0) {
      modalPopValue.message = fromEvent == '1' ? 'You want to send Interview Request' : 'You want to Delete Channel';
      modalPopValue.title = fromEvent == '1' ? 'Interview Request' : "Delete Channel";
      modalPopValue.second_message = packageName ? "Selected Package - " + packageName : '';
      modalPopValue.value = { channelId: ch_Id, id: id, contact: contact, fromEvent: fromEvent, eventId: eventId, packageId: packageId, eventInfo: state.eventInfo, registeredEventId: [], eventListFlag: eventListFlag };
    }
    else {
      modalPopValue.message = 'You want to Dismiss';
      modalPopValue.title = eventId ? "Dismiss Event" : "Dismiss Channel";
      modalPopValue.second_message = packageName ? "Selected Package - " + packageName : '';
      modalPopValue.value = { channelId: ch_Id, id: id, contact: contact, fromEvent: fromEvent, eventId: eventId, packageId: packageId, eventInfo: state.eventInfo, registeredEventId: [], eventListFlag: eventListFlag };
    }
    modalPopValue.popUpId = 1;
    setModalPopValue({ ...modalPopValue })
    open = true;
    setModalOpen(open);
  };

  const openCoordinator = (list, contactId, eventId, channelId) => {
    let contacts = []; let contactsfull = [];
    list.filter(x => x.contactId != contactId).map(x => {
      contacts.push({ id: x.contactId, name: x.firstName + ' ' + x.lastName })
      contactsfull.push(x)
    })

    cordValue.eventId = +eventId;
    cordValue.channelId = +channelId;
    cordValue.users = contacts;
    cordValue.oldMainDelegate = +contactId;
    cordValue.newMainDelegate = null;
    cordValue.comments = null;
    cordValue.overallUser = contactsfull;
    cordValue.changeregister = null;
    cordValue.removeparticipant = null;

    setChangeCordValue(cordValue)
    setEventCord(true);
  }

  const changeCoordinator = async (value) => {

    await axios.post('channelAdmin/changeEventCord/', {
      newMainDelegate: +value.newMainDelegate,
      oldMainDelegate: +value.oldMainDelegate,
      eventId: +value.eventId,
      channelId: +value.channelId,
      comments: value.comments,
      changeregister: value.changeregister,
      removeparticipant: value.removeparticipant
    }).then(res => {
      if (res.data == 'InvalidPackage') {
        ToastsStore.warning('Unable to update. Invalid Event Details');
        setEventCord(false);
      } else {
        ToastsStore.success('Event Coordinator Changed Successfully');
        setEventCord(false);
        history.push('/admin/CompanyListing');
      }

    }).catch(err => {
      ToastsStore.error('Error in Event Coordinator Change');
      setEventCord(false);
    });

    setEventCord(false);
  }


  const handleClose = () => {
    setModalOpen(false);
    setEventOpen(false);
    setEventCord(false);

  };
  // const handleSave = (value) => {
  //   open = false;
  //   setModalOpen(open);
  //   if (value.popUpId == 3) {
  //     if (value.value.contact.eventParticipants[0]?.status == '1') {

  //     } else {
  //       vendorEventRegister(value.value.channel, value.value.contact);
  //     }

  //   } else {
  //     updateStatus(value.value.channelId, value.value.id, value.value.contact, value.value.fromEvent, value.value.eventId, value.value.packageId);
  //   }
  // };
  const handleSave = (value) => {
    open = false;

    let eid = (value?.value?.multiEventApproval == true) ? value.eventToRegister : value.value.eventId;
    let packagePrice = value.value['eventInfo'].find(x => x.id == eid)?.hasPackage.find(x => x.id == value.packageId)?.price;

    if (!packagePrice) {
      packagePrice = value.value['eventInfo'].find(x => x.id == eid)?.hasPackage.find(x => x.id == value.value.packageId)?.price;
    }
    //console.log("packagePrice:", value, packagePrice);
    setModalOpen(open);
    if (value.popUpId == 3) {
      if (value.value.NO_SEATS_FLAG || (value.value.contact.eventParticipants[0]?.status == '1' && value.value.registeredEventId.includes(value.eventToRegister))) {
        // already registered user so no need to call
      }
      else {
        packagePrice = value.value['eventInfo'].find(x => x.id == value.eventToRegister)?.hasPackage.find(x => x.eventId == value.eventToRegister && x.id == value.packageId)?.price;

        let pakageId;
        let totalBadge;
        if (state.eventInfo.length > 1 && value?.hasPackage) {
          pakageId = (value.eventType != 2 && value.hasPackage) ? value.packageId : 0;
          totalBadge = value.hasPackage.find(p => p.id == pakageId).no_of_access_badge;
        }
        else if (value.hasPackage) {
          pakageId = (state.eventInfo[0].type != 2 && state.eventInfo[0].hasPackage) ? value.packageId : 0;
          totalBadge = value.hasPackage.find(p => p.id == pakageId).no_of_access_badge;
        }

        vendorEventRegister(value.eventCord, value.value.packageUser, +totalBadge, value.value.channel, value.value.contact, pakageId, value.eventToRegister ? value.eventToRegister : state.eventInfo[0].id, packagePrice, value.paymentSplit, value.addons);

      }

    } else if (value.popUpId == 4) {
      setModalOpen(false);
    }
    else if (value.isEventSignup) {
      updateStatus(value.selectedProfile ? value.selectedProfile : null, value.value.channelId, value.value.id, value.value.contact, value.value.fromEvent, value?.value.multiEventApproval ? value.eventToRegister : value.value.eventId, value.packageId ? value.packageId : value.value.packageId, value.value.eventInfo?.find(x => x.id == value.value.eventId)?.type, value?.value.multiEventApproval, packagePrice, value.paymentSplit);
    }
    else {
      updateStatus(value.selectedProfile ? value.selectedProfile : null, value.value.channelId, value.value.id, value.value.contact, value.value.fromEvent, value?.value.multiEventApproval ? value.eventToRegister : value.value.eventId, value.value.packageId, value.value.eventInfo?.find(x => x.id == value.value.eventId)?.type, value?.value.multiEventApproval, packagePrice, value.paymentSplit);
    }
  };

  const [userview, setuserview] = useState(false)
  const login = (contactId, firstName, lastName, jobTitle, country, channelId, channelTypeId, documentPath) => {
    setContact(contactId);
    setUser({ firstName: firstName, lastName: lastName, jobTitle: jobTitle, country: country, channelId: channelId, channelTypeId: channelTypeId, documentPath: documentPath });
    setuserview(true);

  }
  const closelogin = () => {
    setuserview(false);
  }

  const closeDrawer = () => {
    setshowDrawer(false);
    setPasswordDrawer(false);
  }

  const closeConnect = () => {
    setshowconnect(false);
  }
  const openConnect = () => {
    setshowconnect(true);
  }
  const opneUser = () => {
    closeConnect();
    setshowDrawer(true);
  }

  // style={{width:'46%',float:'right'}}

  //copy email
  const copyEmail = (x) => {
    navigator.clipboard.writeText(x.email);
    if (document.getElementById("tooltipemail" + x.contactId)) {
      document.getElementById("tooltipemail" + x.contactId).innerHTML = "copied : " + x.email;
    }
  }
  const openEvent = (contactData) => {
    setEventOpen(true);
    setChannelId(contactData.channelId);
    setContactId(contactData.contactId);
    contactList = contactData;
    setContactList(contactList);
  }
  const handleSaveEvnt = (channelId, contactId, pageUrl, eventId) => {
    setEventOpen(false);
    setLoading(true);
    state.dataTable.map(ch => {
      if (ch.channelId == channelId) {
        ch.contacts.map(con => {
          if (con.contactId == contactId) {
            con.isEvent = '1'
          }
        })
      }
    })
    setState({ ...state })
    setLoading(false);
    window.open(pageUrl, '_blank');
  };

  // const eventRegisterPop = (channel, contact) => {
  //   modalPopValue.message = 'You want to Register this Contact for Event';

  //   if (contact.eventParticipants[0]?.status == '1')
  //     modalPopValue.message = 'Already registered this Contact for Event';

  //   modalPopValue.title = 'Event Registration';
  //   modalPopValue.second_message = `User: ${contact.firstName + ' ' + contact.lastName} for Channel : ${channel.companyName}`;
  //   modalPopValue.value = { channel: channel, contact: contact };
  //   modalPopValue.popUpId = 3;
  //   setModalPopValue({ ...modalPopValue })
  //   open = true;
  //   setModalOpen(open);
  // }
  const eventRegisterPop = (channel, contact) => {

    //console.log("cords:",channel.eventChannel[0])
    // if(channel.eventChannel.length && state.eventInfo.length > 0 && channel?.contacts.filter(x => x.eventParticipants.filter(a => a.eventId == channel.eventChannel[0].eventId).length != 0).length==0 && channel.eventChannel[0].package.packageType==1 && channel.eventChannel[0].package.packageUser==1 && channel.eventChannel[0].package.price>0 && (channel.eventChannel[0].paymentstatus==0 || channel.eventChannel[0].paymentstatus==null))  
    // {
    //   setCordAlert(true);
    // }else 
    if (channel.eventChannel.length && state.eventInfo.length > 0 && channel.eventChannel[0].package.packageType == 1 && channel.eventChannel[0].package.packageUser == 1 && channel.eventChannel[0].package.price > 0 && (channel.eventChannel[0].paymentstatus == 0 || channel.eventChannel[0].paymentstatus == null)) {
      setCordAlert(true);
    } else {

      let packageId; let publicPack = []; let NO_SEATS_FLAG = false;

      if (channel?.eventChannel?.length && state.eventInfo.length == 1) {

        let packList = state.eventInfo.find(x => x.id == channel.eventChannel[0].eventId).hasPackage?.filter(p => p.channelType == channel.channelTypeId)


        for (let user = 1; user <= 2 && !packageId; user++) {
          for (let pack = 1; pack <= 2 && !packageId; pack++) {
            let currentPack = packList.filter(x => x.packageUser == user && x.packageType == pack);


            if (currentPack.length) {
              let registeredCount = channel?.contacts.filter(x => x.eventParticipants.filter(e => e.eventId == channel.eventChannel[0].eventId && e.packageId == currentPack[0].id).length > 0).length;
              //console.log("regs:", currentPack, registeredCount)
              if (registeredCount == 0) {

                NO_SEATS_FLAG = false;
                packageId = currentPack[0].id;
                //publicPack = [...currentPack[0]];
                //move to subscription page with package selection
              }
              else if (registeredCount > 0 && registeredCount < currentPack[0].no_of_access_badge) {
                NO_SEATS_FLAG = false;
                packageId = currentPack[0].id;
                //publicPack = [...currentPack[0]];
                //register user as a delegate 

              }
              // if(currentPack[0].accessBadge==currentPack[0].registeredCount){
              //   completedPackage.push(currentPack[0].packageId);
              // }
            }

            //move to next package of first user and repeat the same process
          }
          //move to second user and repeat the same process    
        }
      } else if (channel?.eventChannel?.length == 0 && state.eventInfo.length == 1)
        NO_SEATS_FLAG = false;



      let eventListFlag = false;


      let packageUser = channel.contacts.filter(x => x.eventParticipants?.length > 0).length ? 2 : 1;

      //  console.log("channelss:", packageId);

      // if (NO_SEATS_FLAG) {
      //   modalPopValue.message = "Maximum limit of participants reached for all packages.";
      // } else {
      if (state.eventInfo.length > 1) {
        modalPopValue.message = `You want to Register this Contact for Event: `;
        eventListFlag = true
      }

      if (state.eventInfo.length == 1)
        modalPopValue.message = `You want to Register this Contact for Event: ${state.eventInfo[0] ? state.eventInfo[0].name : ''}`;


      // }

      if (contact.eventParticipants[0]?.status == '1' && state.eventInfo.length > 0)
        modalPopValue.message = 'Already registered this Contact for Event';

      modalPopValue.title = 'Event Registration';
      modalPopValue.eventCord = false;
      modalPopValue.second_message = `User: ${contact.firstName + ' ' + contact.lastName} for Channel : ${channel.companyName}`;
      modalPopValue.value = {
        packageId: packageId,
        channel: channel, contact: contact, isEventRegister: contact.eventParticipants[0]?.status == '1' ? false : true,
        isMainDelegate: channel.eventChannel?.length ? false : true, eventInfo: state.eventInfo, registeredEventId: contact.eventParticipants.map(x => +x.eventId), eventListFlag: eventListFlag, packageUser: packageUser, NO_SEATS_FLAG: NO_SEATS_FLAG
      };
      modalPopValue.popUpId = 3;
      setModalPopValue({ ...modalPopValue })
      open = true;
      setModalOpen(open);
    }





  }
  const vendorEventRegister = async (eventCord, packageUser, totalBadge, channel, contact, packageId, eventToRegister, packagePrice, paymentSplit, addons) => {

    setLoading(true);

    // let additionalMembers = +channel.eventChannel?.find(ch => ch.eventId == eventToRegister)?.additionalMembers;

    let additionalMembers = +channel.contacts?.filter(x => x.eventParticipants.filter(e => e.eventId == eventToRegister && e.packageId == packageId).length).length;

    // console.log("badge:",totalBadge,additionalMembers,eventRegisterPayment?eventRegisterPayment:'1');


    if (additionalMembers == totalBadge && channel.channelTypeId == 2) {
      setLoading(false);
      setBadgeAlert(true);
    }
    else {
      let data = {
        channelId: channel.channelId,
        contactId: contact.contactId,
        eventId: eventToRegister,//state.eventInfo[0].id,
        packageId: packageId ? packageId : null,
        channelTypeId: channel.channelTypeId,
        approvalStatus: 1,
        // paymentstatus: eventRegisterPayment ? eventRegisterPayment : '1',
        paymentstatus: (packagePrice && parseInt(packagePrice) > 0 && additionalMembers == 0) ? '0' : '1',
        isNew: '0',
        profileurl: process.env.REACT_APP_LOCALSITE + 'login',
        isEventCoordinator: eventCord,
        eventType: state.eventInfo.find(x => x.id == eventToRegister).type,
        additionalMembers: additionalMembers ? additionalMembers + 1 : 1,
        paymentSplit: paymentSplit,
        addons: addons,
        isBoEventRegister: true,

      }

      //service for event registering in CH

      //if (channel.channelTypeId == 2 || packageId == 201 || packageId == 202 || (state.eventInfo.length > 0 && state.eventInfo.find(x => x.id == eventToRegister).type == 2) || (state.eventInfo.length > 0 && state.eventInfo.find(x => x.id == eventToRegister).type == 1) || (state.eventInfo.length > 0 && state.eventInfo.find(x => x.id == eventToRegister).type == 3))
        await axios.post('channelAdmin/vendorEventRegister', data).then(async res => {
          // if (channel.channelTypeId == 2 && eventCord && state.eventInfo.find(x => x.id == eventToRegister).type == 1){
          //   axios.post('mailerservice/eventsCoordinatorMail', data);           
          // }else if(channel.channelTypeId == 2 && !eventCord && state.eventInfo.find(x => x.id == eventToRegister).type == 1){
          //   if(!additionalMembers)
          //   axios.post('mailerservice/eventsCoordinatorMail', data); 
          //   axios.post('mailerservice/eventsDelegateMail', data);
          // }
          // else
          axios.post('mailerservice/eventsApprovalRelatedMail', data);
          //send to event catalyst
          if (channel.channelTypeId == 2 && !eventCord) {
            await axios.post('channelAdmin/boToEventCatalyst', [{ contactId: +data.contactId, eventId: +data.eventId }]).then(res => {
              //hide catalyst register
              //eventCatalystRegister({ channelId: channel.channelId, channelTypeId: channel.channelTypeId }, res.data, data.eventId);
              //ToastsStore.success('Successfully registered to Event Catalyst');
            }).catch(err => ToastsStore.warning('something went wrong'));
          }

          ToastsStore.success('Contact Registered for event in CH');
        }).catch(err => ToastsStore.error('Something went wrong'))
      // else if (packageId != 201 && packageId != 202)
      //   await axios.post('channelAdmin/extraEventRegister', data).then(res => ToastsStore.success('Contact Registered for event in CH')).catch(err => ToastsStore.error('Something went wrong'));

      setModalPopValue({ message: null, title: null, value: null, popUpId: null, fromEvent: null });

      callPendingChannel();
    }
  }

  const eventCatalystRegister = async (userData, eventCatalystData, eventId) => {

    if (eventCatalystData.length) {
      await getPublicToken(userData.channelId);
      let req = {
        url: process.env.REACT_APP_EVENTCATALYST_API + 'oauth/token'
      }

      let catalystToken = await commonService.postService(req, 'account/eventcatalystToken').then(res => {
        if (res.data?.error) {
          ToastsStore.warning('Something went wrong');
        } else {
          return res.data;
        }
      }).catch(err => {
        console.log('err', err)
      })

      //sending first user to event catalyst
      const x = eventCatalystData[0];

      let pack = x.package.filter(p => p.contactId == x.contactId);

      let dec = x.detailDesc ? x.detailDesc : null
      //console.log('dec', dec);
      if ((dec === null) || (dec === '')) {
        dec = null;
      } else {
        dec = dec.toString();
        dec = dec.replace(/(<([^>]+)>)/ig, '').trim();
      }

      let data = {
        "participantId": parseInt(x.contactId),
        "firstName": x.firstName,
        "lastName": x.lastName,
        "email": x.email,
        "companyName": x.companyName,
        "companyId": parseInt(userData.channelId),
        //static need to replace in future
        "eventId": '053ac317-8db8-40c0-991e-bd52d861d6a0',
        "chEventId": eventId,
        "status": "Confirmed",
        "type": userData.channelTypeId === '2' ? "Vendor" : "Buyer",
        "typeId": parseInt(userData.channelTypeId),
        "timezone": "",
        "matchingScore": [],
        "country": x.isoCode,
        "profileUrl": process.env.REACT_APP_LOCALSITE + 'channelView/' + userData.channelId + '?token=' + token,
        "photoUrl": x.profileimage,
        "mobile": x.mobileNo,
        "jobTitle": x.jobTitle,
        "category": userData.channelTypeId === '2' ? 'Vendor' : userData.channelTypeId === '3' ? 'Distributor' : userData.channelTypeId === '4' ? 'Retailer' : 'Reseller',
        "logoUrl": x.companylogo,
        "existingCategory": x.ExistingCategory ? x.ExistingCategory.replace(/,/g, "|") : null,
        "interestedCategory": x.IntrestedCategory ? x.IntrestedCategory.replace(/,/g, "|") : null,
        "profileType": x.ProfileType ? x.ProfileType : null,
        "retailCustomerProfile": x.Retail_Customer_Profile ? x.Retail_Customer_Profile.replace(/,/g, "|").replace(/,/g, "|") : null,
        "detailDesc": dec,
        "password": x.password ? x.password : null,
        "package": pack.length > 0 ? pack[0] : {},
      }

      // // Status: Invited, Shortlist, Confirmed, Rejected, Cancelled, WaitingList, Declined
      let body = {
        token: catalystToken, //JSON.parse(localStorage.getItem('eventcatalyst_token')),
        url: process.env.REACT_APP_EVENTCATALYST_API + 'api/registration',
        body: data
      }


      await commonService.postService(body, 'account/postEventRegister').then(async res => {
        setLoading(false);
        await setEventContact(+x.contactId)
        await setEventParticipantStatus(eventId, +userData.channelId, +x.contactId);
      });
      //End sending first user to event catalyst


      // if (eventCatalystData.length > 1) {
      //   setTimeout(async () => {
      //     eventCatalystData.slice(1).map(async x => {

      //       let pack = x.package.filter(p => p.contactId == x.contactId);

      //       let dec = x.detailDesc ? x.detailDesc : null
      //       console.log('dec', dec);
      //       if ((dec === null) || (dec === '')) {
      //         dec = null;
      //       } else {
      //         dec = dec.toString();
      //         dec = dec.replace(/(<([^>]+)>)/ig, '').trim();
      //       }

      //       let data = {
      //         "participantId": parseInt(x.contactId),
      //         "firstName": x.firstName,
      //         "lastName": x.lastName,
      //         "email": x.email,
      //         "companyName": x.companyName,
      //         "companyId": parseInt(userData.channelId),
      //         "eventId": state.eventCatalystId,
      //         "chEventId": state.eventId,
      //         "status": "Confirmed",
      //         "type": userData.channelTypeId === '2' ? "Vendor" : "Buyer",
      //         "typeId": parseInt(userData.channelTypeId),
      //         "timezone": "",
      //         "matchingScore": [],
      //         "country": x.isoCode,
      //         "profileUrl": process.env.REACT_APP_LOCALSITE + 'channelView/' + userData.channelId + '?token=' + token,
      //         "photoUrl": x.profileimage,
      //         "mobile": x.mobileNo,
      //         "jobTitle": x.jobTitle,
      //         "category": userData.channelTypeId === '2' ? 'Vendor' : userData.channelTypeId === '3' ? 'Distributor' : userData.channelTypeId === '4' ? 'Retailer' : 'Reseller',
      //         "logoUrl": x.companylogo,
      //         "existingCategory": x.ExistingCategory ? x.ExistingCategory.replace(/,/g, "|") : null,
      //         "interestedCategory": x.IntrestedCategory ? x.IntrestedCategory.replace(/,/g, "|") : null,
      //         "profileType": x.ProfileType ? x.ProfileType : null,
      //         "retailCustomerProfile": x.Retail_Customer_Profile ? x.Retail_Customer_Profile.replace(/,/g, "|").replace(/,/g, "|") : null,
      //         "detailDesc": dec,
      //         "password": x.password ? x.password : null,
      //         "package": pack.length > 0 ? pack[0] : {},
      //       }

      //       // // Status: Invited, Shortlist, Confirmed, Rejected, Cancelled, WaitingList, Declined
      //       let body = {
      //         token: catalystToken, //JSON.parse(localStorage.getItem('eventcatalyst_token')),
      //         url: process.env.REACT_APP_EVENTCATALYST_API + 'api/registration',
      //         body: data
      //       }

      //       await commonService.postService(body, 'account/postEventRegister').then(async res => {
      //         setLoading(false);
      //         await setEventContact(+x.contactId)
      //         await setEventParticipantStatus(eventId, +userData.channelId, +x.contactId);
      //       });

      //     })
      //   }, 5000);
      // }
    }
  }

  const setEventContact = async (contactId) => {
    await commonService.getServices('channelAdmin/setEventContact/' + contactId).then(res => {

    }, err => {
      console.log('err', err)
    });
  }

  const setEventParticipantStatus = async (eventId, channelId, contactId) => {
    let data = {
      eventId: eventId,
      channelId: channelId,
      contactId: contactId
    }
    await commonService.postService(data, 'channelAdmin/setEventParticipantStatus/').catch(err => ToastsStore.warning('something went wrong'));
  }

  const getPublicToken = async (channelId) => {

    let tokenData = await commonService.getServices('account/getPublicToken/' + channelId);
    if (tokenData) {

      token = tokenData.data.token;
      settoken(token);

    }
  }

  const checkActiveContact = () => {
    modalPopValue.message = 'The Main Delegate is not active. Associate the user to the channel and then approve for event.';
    modalPopValue.title = 'Main Delegate is not active a active contact.';
    modalPopValue.popUpId = 4;
    setModalPopValue({ ...modalPopValue })
    open = true;
    setModalOpen(open);
  }

  //resend user mail @2/9/22
  const sendMail = async (template) => {
    // console.log("tempId:", mailContact);



    let maildata = {
      contactId: +mailContact.contactId,
      //packageId: packageId,
      profileurl: process.env.REACT_APP_LOCALSITE + 'login',
    }


    if (template.label === 'paymentmail') {

      let data = {
        eventId: template.id,
        channelId: mailContact.eventParticipants[0].channelId,
        packageId: mailContact.eventParticipants[0].packageId,
        contactId: mailContact.contactId
      }
      await commonService.postService(data, 'channelAdmin/getEventSubscription/').then(res => {

        let subscription = res.data.subscription;
        // console.log("bill:",subscription[0].subcription[0]);
        let payment = subscription[0].subcription.length ? subscription[0].subcription[0] : null;

        let offers = res.data.offers;

        if (payment) {
          let eventData = {
            contactId: payment.contactId,
            channelId: payment.channelId,
            packageId: payment?.packageId,
            eventId: payment?.eventId,
            //paymentTransactionId: payment?.paymentTransactionId,
            profileurl: process.env.REACT_APP_LOCALSITE,
            finalAmount: payment?.finalAmount,
            subTotal: payment.finalAmount - payment.taxAmount,
            taxAmount: payment?.taxAmount,
            vatNumber: payment?.vatNumber,
            actualAmount: payment?.actualAmount,
            offer: offers?.filter(o => o.offerType == 2 && parseFloat(payment.couponAmount) > 0).length ? offers : offers?.filter(o => o.offerType == 1),
          };

          axios.post('mailerservice/eventPaymentMail', eventData).then(res => {
            // console.log("page-->customer-->api-->channelAdmin/eventPaymentMail", new Date())

          }).catch(err => {
            console.log("page-->customer-->api-->mailerservice/eventPaymentMail error->", err, "  ", new Date())
          });
        }
      }).
        catch(err => ToastsStore.warning('something went wrong:', err));


    } else if (template.label === 'approvalmail') {
      axios.post('mailerservice/Channel_Approval_Mail', maildata).then(res => {
        //  console.log("page-->customer-->api-->channelAdmin/Channel_Approval_Mail", new Date())

      }).catch(err => {
        console.log("page-->customer-->api-->mailerservice/Channel_Approval_Mail error->", err, "  ", new Date())
      });
    } else if (template.label === 'eventapprovalmail') {
      //  console.log("eventapprovalmail:", template);
      let maildata = {
        contactId: template.contactId,
        approvalStatus: 1,
        eventId: template.eventId,
        isNew: 1,
        contactId: template.contactId,
        channelId: template.channelId,
        packageId: template.packageId,
        profileurl: process.env.REACT_APP_LOCALSITE + 'eventLoginRedirect/' + template.eventId + '/' + template.channelId
      }

      axios.post('mailerservice/eventsApprovalRelatedMail', maildata).then(res => {
        //  console.log("page-->customer-->api-->channelAdmin/eventsApprovalRelatedMail", new Date())

      }).catch(err => {
        console.log("page-->customer-->api-->mailerservice/eventsApprovalRelatedMail error->", err, "  ", new Date())
      });
    }


    setMailFlag(false);
  }

  const resendMail = (eventChannel) => {

    //  console.log("resendmail:",eventChannel);

    eventChannel.map(ch => {
      // if(mailContact.contactId==ch.registeredBy){
      // let obj={
      //   id:ch.eventId,
      //   templateId:'',
      //   name:"EventPayment Mail ("+ch.event.shortname+")",
      //   label:"paymentmail"
      // }
      // if(!resendmail_option.map(x => x.id).includes(ch.eventId))
      // resendmail_option.push(obj);

      let obj = {
        id: ch.eventId,
        eventId: ch.eventId,
        templateId: ch.package.template,
        name: "Event Approval Mail (" + ch.event.shortname + ")",
        label: "eventapprovalmail",
        approvalStatus: 1,
        isNew: 1,
        packageId: ch.package.packageId,
        contactId: ch.registeredBy,
        channelId: ch.channelId,

      }
      if (!resendmail_option.map(x => x.id).includes(ch.eventId))
        resendmail_option.push(obj);


      // }
    })



    return (
      <div>
        <Modal className="modal-dialog-centered" fade={false} isOpen={mailFlag} size="xg" style={{ minWidth: 900, width: '30%' }}>
          <div className="modal-header">
            <strong>Select mail to resend</strong>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => setMailFlag(false)}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">

            <Box
              sx={{
                height: 600,
                width: '100%',
                '& .actions': {
                  color: 'text.secondary',
                },
                '& .textPrimary': {
                  color: 'text.primary',
                },
              }}
            >

              <Autocomplete
                disableClearable={true}
                id="channelType"
                input='channelType'
                options={resendmail_option}
                getOptionLabel={(option) => option.name}
                // value={filterValue.channelType}
                onChange={(input, value) => setMailTemplate({ ...template, templateId: value.templateId, id: value.id, label: value.label, eventId: value.eventId, channelId: value.channelId, contactId: value.contactId, packageId: value.packageId })}
                renderInput={(params) => <TextField {...params} label="Mail" />}
              />


            </Box>

          </div>
          <div className="modal-footer">
            <Button color="primary" type="button" onClick={() => sendMail(template)}>Send Mail</Button>
            <Button color="secondary" data-dismiss="modal" type="button" onClick={() => setMailFlag(false)}>Close</Button>
          </div>
        </Modal>
      </div>
    )
  }

  const openMailPop = (contact) => {
    setMailFlag(true);
    //console.log("conta:", contact);
    setMailContact(contact);
  }
  //end resend user mail @2/9/22

  const User = {
    renderer: (row, rowIndex, index) => {
      //console.log('rowuser', row, rowIndex);
      return (
        <div style={{ width: '100%' }}>

          {/* resend user mail */}
          {mailFlag &&
            resendMail(row.eventChannel)}

          {row.contacts.length > 0 ? row.contacts.map((x, i) => {

            let eventCooardinator = null;
            eventCooardinator = (x.eventCoordinate.filter(ec => ec.contactId == x.contactId).length > 0) ? true : false;
//console.log('hai Meena', x);
            let eventsList = null; let eventId = null;
            if (x.eventParticipants.length) {
              eventId = x.eventParticipants.find(p => p.status == null || p.status == 0)?.eventId;
              eventsList = row.eventChannel.filter(ch => x?.eventParticipants.filter(p => p.packageId == ch.packageId && (p.status == null || p.status == 0)).map(s => s.eventId)?.includes(ch.eventId))?.map(ch => ch['event'].shortname)?.join(',');
            }
            return (
              <ListGroup className="list my--2" flush key={'table' + x.contactId}>
                <ListGroupItem className="px-0">
                  <Row className="align-items-center">
                    <Col className="col-1">
                      <a
                        className="avatar rounded-circle"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }}
                          alt="..."
                          src={x.document?.documentPath ? x.document.documentPath.includes(process.env.REACT_APP_CDN_URL) ? x.document.documentPath : process.env.REACT_APP_CDN_URL + x.document.documentPath : "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"}//{require("assets/img/theme/team-1.jpg")}
                        />
                      </a>
                    </Col>
                    <div className="col-lg-2 ml--2">
                      <p className="mb-0" style={{ fontSize: '0.7rem' }}>

                        {x.firstName + ' ' + x.lastName}

                      </p>
                      <small>{x.email} <i style={{ cursor: "pointer", color: "green", fontSize: "0.7rem" }} id={"email" + x.contactId} onClick={() => copyEmail(x)} className="far fa-copy"></i>  </small>

                      {/* tooltip for copied email */}
                      <UncontrolledTooltip delay={0} placement="bottom" id={"tooltipemail" + x.contactId} target={"email" + x.contactId}>copy email</UncontrolledTooltip>

                      {/* onClick={() => login(x.contactId, x.firstName, x.lastName, x.jobTitle, x.country?.country, row.channelId, row.channelTypeId, x.document?.documentPath)} style={{ padding: "10px 10px 0 0 " }} */}
                      {priviledge.Write &&
                        // <div><a id={'Login' + index} target="_blank" href={"http://localhost:4200/directlogin/" + x.contactId}>
                        <div><a id={'Login' + index} target="_blank" href={process.env.REACT_APP_DIRECTLOGIN + x.contactId}>
                          <i class="fas fa-sign-in-alt pointer"></i> </a>                          <UncontrolledTooltip target={'Login' + index} placement='bottom'> Login </UncontrolledTooltip>
                          <i id={'Uedit' + index} onClick={() => { openContact(Object.assign(x, { channelTypeId: row.channelTypeId, channelId: row.channelId, userId: row.user[0].userId })) }} style={{ padding: "10px 10px 0 10px" }} class="fas fa-edit pointer"></i>
                          <UncontrolledTooltip target={'Uedit' + index} placement='bottom'> UserEdit </UncontrolledTooltip>
                          <i id={'Rpwd' + index} onClick={() => { openPassword(Object.assign(x, { channelTypeId: row.channelTypeId, channelId: row.channelId, contactId: x.contactId, userId: x.user.userId })) }} style={{ padding: "10px 10px 0 10px" }} class="fas fa-key pointer"></i>
                          <UncontrolledTooltip target={'Rpwd' + index} placement='bottom'> ResetPassword </UncontrolledTooltip>
                          <i id={'Inter' + index} onClick={() => { openConnect() }} style={{ padding: "10px 10px 0 10px" }} class="fas fa-exchange-alt pointer"></i>
                          <UncontrolledTooltip target={'Inter' + index} placement='bottom'> Connect </UncontrolledTooltip>
                          {state.eventInfo.length > 0 && +row.channelTypeId != 6 && <>
                            <i id={'Eve' + index} onClick={() => { eventRegisterPop(row, x) }} style={{ padding: "10px 10px 0 10px" }} class="far fa-calendar-alt pointer"></i>
                            {/* <i id={'Eve' + index} onClick={() => { row.channelTypeId == 2 ? eventRegisterPop(row, x) : openEvent(Object.assign(x, { channelTypeId: row.channelTypeId, channelId: row.channelId, contactId: x.contactId, userId: x.user.userId })) }} style={{ padding: "10px 10px 0 10px" }} class="far fa-calendar-alt pointer"></i> */}
                            <UncontrolledTooltip target={'Eve' + index} placement='bottom'> Event </UncontrolledTooltip>
                          </>}
                          <i id={'Mail' + index} onClick={() => { openMailPop(x) }} style={{ padding: "10px 10px 0 10px" }} class="fas fa-envelope pointer"></i>
                          <UncontrolledTooltip target={'Mail' + index} placement='bottom'> Mail </UncontrolledTooltip>
                        </div>}
                    </div>

                    <div className="col-lg-1 ml--2">
                      <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                        Last Login
                      </p>
                      <small>{new Date(x.user.lastLogin).toLocaleDateString()}</small>
                    </div>

                    <div className="col-lg-1 ml--2">
                      <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                        Hubspot Id
                      </p>
                      <small>{x.hubspotContactId}</small>
                    </div>

                    <div className="col-lg-1 ml--2">
                      <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                        {x.jobTitle}
                      </p>
                    </div>

                    <div className="col-lg-1 ml--2">
                      <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                        Contact Id
                      </p>
                      <small>{x.user.contactId}</small>
                    </div>

                    {/* <div className="col-lg-1 ml--2">
                            <p className="mb-0" style={{fontSize:'0.7rem'}}>
                            {x.hubspotContactId ? x.hubspotContactId : '-'}
                            </p>
                          </div> */}
                    {/* <div className="col-lg-2 ml--2">
                    <p className="mb-0" style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '0.7rem' }} id={'producttip' + x.contactId}>
                      {
                        x.productGroup?.length ? x.productGroup.map((y, i) => {
                          if (x.productGroup.length - 1 != i)
                            return (y.productGroup.productGroup + ', ');
                          return (y.productGroup.productGroup);
                        }) : <>-</>
                      }
                    </p>
                    <UncontrolledTooltip delay={0} target={'producttip' + x.contactId}>
                      {
                        x.productGroup?.length ? x.productGroup.map((y, i) => {
                          if (x.productGroup.length - 1 != i)
                            return (y.productGroup.productGroup + ', ');
                          return (y.productGroup.productGroup);
                        }) : <></>
                      }
                    </UncontrolledTooltip>
                  </div> */}
                    <div className="col-lg-1 ml--2">
                      <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                        {x.user?.role?.roleId == '1' ? 'Account Owner' : x.user?.role?.roleId == '2' ? 'Admin' : 'Primary'}
                      </p>
                    </div>
                    <div className="col-lg-1 ml--2">
                      <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                        {x.isShared == '0' ? 'Confidential' : 'Shared'}
                      </p>
                    </div>
                    {/* <div className="col-lg-1 ml--2">
                    <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                      {x.country?.country}
                    </p>

                  </div> */}
                    <div className="col-lg-1 ml-2">

                      <Badge className='outer_badge' style={{ width: "100%", height: "2rem", padding: "0.6rem", border: "1px solid #46b88f", color: "#46b88f" }}>
                        {x.isActive == '1' ? 'Active' : 'Inactive'}

                      </Badge>

                    </div>

                    {/* <Col className="col-lg-1">
                            <Button className="float-right" color="primary" size="sm" type="button">
                            <i class="fas fa-sign-in-alt pointer"></i>   Login
                            </Button>
                          </Col>
                          <Col className="col-lg-1">
                            <Button className="float-right" color="primary" size="sm" type="button">
                            <i class="fas fa-sign-in-alt pointer"></i>   Edit
                            </Button>
                          </Col>
                          <Col className="col-lg-1">
                            <Button className="float-right" color="primary" size="sm" type="button">
                            <i class="fas fa-sign-in-alt pointer"></i>   Replace
                            </Button>
                          </Col> */}



                    {row.eventChannel.map(eventChannel =>
                      <>

                        {
                        //state.eventInfo.length > 0 && x.eventCoordinate.filter(a => a.eventId == eventChannel.eventId).length == 0 && x.eventCoordinate.contactId == x.contactId &&

                        state.eventInfo.length > 0 && x.eventParticipants.length == 0 && (x.eventCoordinate.length > 0 && x.eventCoordinate[0]?.contactId == x.contactId) &&
                          <div class='ribbon-wrapper-5'>
                            <div class='ribbon-5 bg-orange'>Event Manager{`(${eventChannel.event['shortname'].toString()})`}</div>
                            <button onClick={() => openCoordinator(row.contacts, x.contactId, row.eventChannel[0].eventId, row.eventChannel[0].channelId, x)} className="btn btn-icon btn-default btn-sm" variant="contained" color="default" >
                              change
                            </button>
                          </div>}


                        {state.eventInfo.length > 0 && x?.eventParticipants.length > 0 && ((row?.eventChannel.length == 1 && x?.eventParticipants.filter(a => a.eventId == eventChannel?.eventId && a.status == 1)) || (row?.eventChannel.length > 1 && x?.eventParticipants.filter(a => a.eventId == eventChannel?.eventId && a.status == 1 && eventChannel?.packageId == a?.packageId).length > 0)) && //eventChannel.package.packageType==1 && eventChannel.package.packageUser==1 &&
                          <div class='ribbon-wrapper-5'>
                            <div class={`ribbon-5 ${((x?.eventParticipants.filter(a => a.eventId == eventChannel.eventId && a.status == 1).length > 0 && row.eventPayment.filter(p => p.eventId == eventChannel.eventId).length == 0 && eventChannel.approvalStatus == 1) || ((eventChannel.approvalStatus == 1 && eventChannel.registeredBy == x.contactId && row.eventPayment?.filter(ep => ep.eventId == eventChannel.eventId && ep.paymentStatus == '0').length == 0) || (row.eventPayment?.filter(ep => ep.contactId == x.contactId && ep.eventId == eventChannel.eventId).length != 0 && row?.eventPayment?.filter(ep => ep.contactId == x.contactId && ep.paymentStatus == '0' && ep.eventId == eventChannel.eventId).length == 0))) ? 'bg-green' : 'bg-blue'}`}>{`${eventChannel?.registeredBy == x.contactId && eventChannel?.package?.packageType == 1 && eventChannel.package?.packageUser == 1 ? 'Event Manager' : 'Event'}`} {`(${eventChannel.event['shortname'].toString()})`}</div>
                            {eventCooardinator && <button onClick={() => openCoordinator(row.contacts, x.contactId, row.eventChannel[0].eventId, row.eventChannel[0].channelId)} className="btn btn-icon btn-default btn-sm" variant="contained" color="default" >
                              change
                            </button>}
                          </div>}


                      </>

                    )}

                    {(row.signUpStatusId == 15 && eventsList && x.contactStatusId != 14) ? (
                      <>

                        <button disabled={priviledge.Approve ? false : true} id={'Approve' + x.contactId} onClick={() => openModalPopUp(row.channelTypeId, row.channelId, 25, x.contactId, row.channelId, eventId, row.eventChannel, x.eventParticipants[0].packageId, x.eventParticipants, row.contacts)} className="btn btn-icon btn-default btn-sm" variant="contained" color="default" >
                          <span className="btn-inner--icon"><i className="ni ni-check-bold"></i></span>
                          <UncontrolledTooltip target={'Approve' + x.contactId} placement='bottom'> {`Event Approve (${eventsList}) `} </UncontrolledTooltip>
                        </button>


                        <button disabled={priviledge.Dismiss ? false : true} id={'Dismiss' + x.contactId} onClick={() => openModalPopUp(row.channelTypeId, row.channelId, 43, x.contactId, row.channelId, eventId, row.eventChannel, x.eventParticipants[0].packageId, x.eventParticipants, row.contacts)} className="btn btn-icon btn-warning btn-sm" variant="contained" color="warning" >
                          <span className="btn-inner--icon"><i className="ni ni-fat-remove"></i></span>
                          <UncontrolledTooltip target={'Dismiss' + x.contactId} placement='bottom'> {`Event Dismiss (${eventsList}) `}</UncontrolledTooltip>
                        </button>

                      </>
                    ) : ''
                    }

                    {
                      (x.contactStatusId == 14) && <>
                        <button disabled={priviledge.Approve ? false : true} id={'Approve1' + index} onClick={() => openModalPopUp(row.channelTypeId, row.channelId, 501, x.contactId, row.channelId, eventId, row.eventChannel, x.eventParticipants[0]?.packageId, x.eventParticipants, row.contacts)} className={`btn btn-icon btn-sm ${eventId ? "btn-default" : "btn-success"}`} variant="contained" color="success" >
                          <span className="btn-inner--icon"><i className="ni ni-check-bold"></i></span>
                          <UncontrolledTooltip target={'Approve1' + index} placement='bottom'> Associate Contact {eventId ? 'With event ' : ''} </UncontrolledTooltip>
                        </button>

                        {eventId && <button id={'Partial1' + index} onClick={() => openModalPopUp(row.channelTypeId, row.channelId, 502, x.contactId, row.channelId, eventId, row.eventChannel[0]?.package?.packageName, x.eventParticipants[0]?.packageId)} className={`btn btn-icon btn-sm ${eventId ? "btn-default" : "btn-success"}`} variant="contained" color="success" >
                          <span className="btn-inner--icon"><i className="ni ni-ungroup"></i></span>
                          <UncontrolledTooltip target={'Partial1' + index} placement='bottom'> Partial Refusal </UncontrolledTooltip>
                        </button>}

                        {/* <button id={'Partial' + index} onClick={() => openModalPopUp(row.channelTypeId,row.channelId, 502, x.contactId, row.channelId, x.eventParticipants[0].eventId, row.eventChannel[0]?.package?.packageName, x.eventParticipants[0].packageId)} className="btn btn-icon btn-success btn-sm" variant="contained" color="success" >
                              <span className="btn-inner--icon"><i className="ni ni-ungroup"></i></span>
                              <UncontrolledTooltip target={'Partial' + index} placement='bottom'> Partial Refusal </UncontrolledTooltip>
                            </button> */}


                        <button disabled={priviledge.Dismiss ? false : true} id={'Dismiss1' + index} onClick={() => openModalPopUp(row?.channelTypeId, row?.channelId, 503, x.contactId, row.channelId, eventId, row?.eventChannel[0]?.package?.packageName, x?.eventParticipants[0]?.packageId, x?.eventParticipants, row.contacts)} className="btn btn-icon btn-warning btn-sm" variant="contained" color="warning" >
                          <span className="btn-inner--icon"><i className="ni ni-fat-remove"></i></span>
                          <UncontrolledTooltip target={'Dismiss1' + index} placement='bottom'> Dismiss Associate Contact </UncontrolledTooltip>
                        </button>

                      </>
                    }
                  </Row>
                </ListGroupItem>

              </ListGroup>)
          })
            : <><p>No Active Users. Create user for channel before proceed to further...</p> <button className="btn btn-icon btn-success btn-sm" variant="contained" color="success" onClick={() => { history.push({ pathname: '/admin/addUser', state: { type: 3, addOrEdit: 'ADD', channelData: row } }) }} >
              Create Users
            </button></>}
        </div >
      )
    },
    className: 'bgrow'
  };
  //const [openSide,setOpen] = React.useState(1);
  const [sidenavOpen, setOpen] = useState(false);
  const [exportFlag, setExportFilterFlag] = useState(false);
  const [pubFlag, setPublicFlag] = useState(false);
  const [publicFlagData, setPublicFlagData] = useState({});
  const [mergeCompanyData, setMergeCompanyData] = useState({});
  const [mergeCompany, setMergeCompany] = useState(false);
  const [eventPackOpen, setEventPackOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [hubspotCompanyOpen, setHubspotCompanyOpen] = useState(false);

  const [Type, setType] = useState({});
  const [HSCId, setHSC] = useState({});


  const editProfileType = (data) => {
    setProfileOpen(true);
    //console.log(profileOpen, 'isOpen')
    setType(data);
  }

  const editHubspot = (data) => {
    setHubspotCompanyOpen(true);
    setHSC(data);
  }
  const editHubspotClose = () => {
    setHubspotCompanyOpen(false);
  }


  const editProfileClose = () => {
    setProfileOpen(false);
  }

  const editHSSave = () => {
    setHubspotCompanyOpen(false);
  }

  const openMergeCompanyDialog = (item) => {
    let data = {
      channelId: item.channelId,
      channelTypeId: item.channelTypeId,
      companyName: item.companyName
    };
    setMergeCompanyData(data);
    setMergeCompany(true);
  }

  const closeMergeCompanyDialogDialog = () => {
    setMergeCompanyData({});
    setMergeCompany(false);
    getPendingChannel();
  }

  const editProfileSave = (type) => {
    //api
    setProfileOpen(false);
    setLoading(true);
    axios.post('/channelAdmin/updateProfile',
      { channelId: Type.channelId, typeId: type }).then(res => {
        state.dataTable.map(ch => {
          if (ch.channelId == Type.channelId) {
            ch.channelTypeId = type;
          }
        })
        setState({ ...state })
        setLoading(false);
      });
    //console.log(type, 'Type');
  }

  const closeExportFilter = () => {
    setExportFilterFlag(false);
  }
  const exportFilter = () => {
    setExportFilterFlag(true);
  }



  const showChannelAccount = () => {

    if (activeType.id == 'live') {
      activeType.id = 'other';
      activeType.label = 'Live';
      setActiveType(activeType);
      getPendingChannelsList();
      getPendingChannel();
    } else {
      activeType.id = 'live';
      activeType.label = 'Other';
      setActiveType(activeType);
      getPendingChannelsList();
      getPendingChannel();
    }
  }



  const openPublicFlag = (item) => {
    let data = {
      channelId: item.channelId,
      channelTypeId: item.channelTypeId,
      isFeatured: item.isFeatured == '1' ? true : false,
    };
    setPublicFlagData(data);
    setPublicFlag(true);
  }

  const closePublicFlag = () => {
    setPublicFlagData({});
    setPublicFlag(false);
  }

  const edit = async (contactid, channelId, channelTypeId) => {
    setuserview(false);
    let contactData = await commonService.getServices('/channelAdmin/getContact/' + contactid);
    if (contactData) {
      let data = Object.assign(contactData.data, { channelTypeId: channelTypeId, channelId: channelId, userId: null });
      setDrawerInput(data);
      setshowDrawer(true);
      //console.log('datauser', data);
    }
  }

  const setUserData = (userData) => {
    let existing = JSON.parse(localStorage.getItem('CHuser'));
    existing['userData'] = userData;
    localStorage.setItem('CHuser', JSON.stringify(existing));
    // history.push('/admin/CompanyListing/companysettings');

    const win = window.open(window.location.origin + '/admin/CompanyListing/companysettings', "_blank");
  }
  
  const setOtherUserData = (userData) => {
    let existing = JSON.parse(localStorage.getItem('CHuser'));
    existing['userData'] = userData;
    localStorage.setItem('CHuser', JSON.stringify(existing));
    const win = window.open(window.location.origin + '/admin/manageOtherUser', "_blank");
  }

  

  const getSorting = (column, target) => {

    setHeaderSorting({ ...headerSorting, column: null })

    let order = (headerSorting.order === 'desc') ? 'asc' : 'desc';

    headerSorting.order = order;
    headerSorting.column = column.dataField;

    setHeaderSorting(headerSorting);

    if (headerSorting.column)
      callPendingChannel();
  }

  const getRegisteredEventContact = async (channelId) => {
    setChannelId(channelId);
    setEventPackOpen(true);
  }

  return (
    <div id="channelList">
      <ChannelType isOpen={profileOpen} type={Type.type} handleClose={editProfileClose} handleSave={editProfileSave} />
      <HubspotEdit isOpen={hubspotCompanyOpen} type={HSCId} handleClose={editHubspotClose} handleSave={editHSSave} />
      {/* {userview && */}
      <EventsPop isOpen={isEventOpen} handleSaveEvnt={handleSaveEvnt} handleClose={handleClose} channelId={channelId} contactId={contactId} contactList={contactList} />
      <EventPackDetails isOpen={eventPackOpen} handleClose={() => setEventPackOpen(false)} channelId={channelId} />
      <UserView open={userview} closelogin={closelogin} contactid={contactid} user={user} edit={edit} />
      {/* } */}
      {connect &&
        <Connect opneUser={opneUser} closeConnect={closeConnect} />
      }
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      {open &&
        <Dialog open={open} value={modalPopValue} handleSave={handleSave} handleClose={handleClose}></Dialog>}

      {isEventCord &&
        <ChangeCoordinator open={isEventCord} value={cordValue} handleSave={changeCoordinator} handleClose={handleClose}></ChangeCoordinator>}

      <ConfirmDialog open={cordAlert} value={{ title: 'Event Registration', message: 'You cannot apply yet for the event as payment is pending, please check later', second_message: '' }} handleSave={() => setCordAlert(false)} handleClose={() => setCordAlert(false)} />

      <ConfirmDialog open={badgeAlert} value={{ title: 'Event Registration', message: 'Maximum limit of participants reached', second_message: '' }} handleSave={() => setBadgeAlert(false)} handleClose={() => setBadgeAlert(false)} />
      <ChannelExportFilter closeModalPopup={closeExportFilter} opneadd={exportFlag} />
      {publicFlagData.hasOwnProperty('channelId') && <CompanyPublicSettings closeModalPopup={closePublicFlag} opneadd={pubFlag} data={publicFlagData} />}

      {mergeCompanyData.hasOwnProperty('channelId') && <MergeCompanyData closeModalPopup={closeMergeCompanyDialogDialog} opneadd={mergeCompany} data={mergeCompanyData} />}

      <NotificationAlert ref={notificationAlert} />
      {/* <div className={classes.root}> */}
      <Filter
        Filter={true}
        filterOption={filterOptionState}
        filterValue={filterValueState}
        resetFilterValue={resetFilterValue}
        handleChangeSelect={handleChangeSelectSearch}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        showFilterHeader={showFilterHeader}
        handleFilter={handleFilter}
        product_families_selected={product_families_selected}
      />

      <SimpleHeader location={props.match} name="Channel List" parentName="Dashboard" filter={true} />
      <Drawer title="Edit User" visible={showDrawer} contactData={drawerInput} closeDrawer={closeDrawer} reload={callPendingChannel} />
      {showPasswordDrawer && <Drawer title="Reset Password" visible={showPasswordDrawer} contactData={drawerInput} closeDrawer={closeDrawer} reload={callPendingChannel} />}
      <Container className="mt--3" fluid>
        <Row>
          <div className="col">
            <Card style={{ padding: '20px' }}>
              <CardHeader>
                <h3 className="mb-0">Company Listing</h3>
                {/* <p className="text-sm mb-0">
                    This is an exmaple of data table using the well known
                    react-bootstrap-table2 plugin. This is a minimal setup in
                    order to get started fast.
                  </p> */}
              </CardHeader>
              {loading ? <Spinner /> : <ToolkitProvider
                ClassName="align-items-center table-flush" responsive
                data={state.dataTable}
                keyField="contacts[0].email"
                columns={[

                  {
                    dataField: "Action",
                    text: "Action",
                    formatter: (rowContent, row, index) => {
                      let isevent = false;
                      let hasPrimary = false;

                      let isAssociate = false;
                      let isAssociateEvent = false;
                      let eventsList = null; let eventid;
                      let approvedEventId = [];

                      let contact;

                      if(row.isOtherUser == '1'){
                        return (
                          <>
                             
                            { <button disabled={priviledge.Write ? false : true} id={'Edit' + index} className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" onClick={e => { e.stopPropagation(); setOtherUserData(Object.assign(row, { userId: row.user[0].userId, contactId: row.user[0].contactId })); }}>
                              <span className="btn-inner--icon"><i className="fas fa-pencil-alt"></i></span>
                              <UncontrolledTooltip target={'Edit' + index} placement='bottom'> Edit </UncontrolledTooltip>
                            </button>
                            }

{row.signUpStatusId == 15 && <button disabled={priviledge.Delete ? false : true} id={'Del15' + index} onClick={() => openModalPopUp(row.channelTypeId, row.channelId, 0, contact, '0', row.eventId)} className="btn btn-icon btn-warning btn-sm" variant="contained" color="#fb6340">
                            <span className="btn-inner--icon"><i className="fas fa-trash-alt"></i></span>
                            <UncontrolledTooltip target={'Del15' + index} placement='bottom'> Delete </UncontrolledTooltip>
                          </button>}

                            </>
                        )
                      } else {

                      if (row.eventChannel.length)
                        row.contacts.map(y => {
                          if (row.eventChannel.find(ch => ch.approvalStatus != 1)?.registeredBy == y.contactId) {
                            contact = y;
                            return;
                          }
                        })

                      // row.eventChannel.filter(ch => ch.eventId == row.eventId).map(x => {
                      //   row.contacts.map(y => {
                      //     if (x.registeredBy == y.contactId) {
                      //       contact = y;
                      //       return;
                      //     }
                      //   })
                      // })







                      row.contacts.map(x => {

                        approvedEventId = row.eventChannel.filter(ch => x.eventParticipants.filter(p => p.status == 1).map(s => s.eventId)?.includes(ch.eventId))?.map(ch => +ch.eventId);

                        if (x.eventParticipants?.length > 0 && x.eventParticipants.filter(x => (x.status == '0' || x.status == null)).length > 0) {
                          isevent = true;
                          eventsList = row.eventChannel.filter(ch => x.eventParticipants.filter(p => p.packageId == ch.packageId && (p.status == null || p.status == 0)).map(s => s.eventId)?.includes(ch.eventId))?.map(ch => ch['event'].shortname)?.join(',');
                          eventid = row.eventChannel.find(ch => x.eventParticipants.filter(p => p.status == null || p.status == 0).map(s => s.eventId)?.includes(ch.eventId))?.eventId;
                          //console.log("approves:", eventid, isevent)
                        }

                        if (x.eventParticipants?.length > 0 && x.eventParticipants.filter(x => (x.status == '1' && x.eventId == eventid && approvedEventId.includes(+x.eventId))).length > 0) {
                          hasPrimary = true
                        }


                        if (x.contactStatusId == 14) {
                          isAssociate = true;
                        }

                        if (x.contactStatusId == 14 && x.eventParticipants.filter(x => (x.status == '0' || x.status == null)).length > 0) {
                          isAssociateEvent = true;
                        }



                      })

                      return (
                        <>

                          {/* {row.signUpStatusId == 14 ? (
                            <button className="btn btn-icon btn-warning btn-sm" variant="contained" color="warning" >
                              <span className="btn-inner--icon"><i className="ni ni-circle-08"></i></span>
                            </button>) : (
                            <button className="btn btn-icon btn-success btn-sm" variant="contained" color="success" >
                              <span className="btn-inner--icon"><i className="ni ni-circle-08"></i></span>
                            </button>
                          )} */}
                          {row.signUpStatusId == 14 && <button disabled={priviledge.Write ? false : true} id={'Edit' + index} className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" onClick={e => { e.stopPropagation(); setUserData(Object.assign(row, { userId: row.user[0].userId, contactId: row.user[0].contactId })); }}>
                            <span className="btn-inner--icon"><i className="fas fa-pencil-alt"></i></span>
                            <UncontrolledTooltip target={'Edit' + index} placement='bottom'> Edit </UncontrolledTooltip>
                          </button>
                          }
                          {row.signUpStatusId == 14 && (
                            <button disabled={priviledge.Approve ? false : true} id={'Approve' + index} onClick={() => openModalPopUp(row.channelTypeId, row.channelId, 15, row.contacts[0], row.fromEvent, row.eventId, row.eventChannel, row.eventChannel[0]?.package?.packageId)} className={`btn btn-icon btn-sm ${row.fromEvent == 1 ? 'btn-default' : 'btn-success'}`} variant="contained" >
                              <span className="btn-inner--icon"><i className="ni ni-check-bold"></i></span>
                              <UncontrolledTooltip target={'Approve' + index} placement='bottom'> Approve </UncontrolledTooltip>
                            </button>
                          )}

                          {/* For Event  */}
                          {(row.signUpStatusId == 14 && row.fromEvent == '1') && (
                            <button disabled={priviledge.Approve ? false : true} id={'Partial' + index} onClick={() => openModalPopUp(row.channelTypeId, row.channelId, 15, contact, '2', row.eventId, row.eventChannel, row.eventChannel[0]?.package?.packageId)} className="btn btn-icon btn-success btn-sm" variant="contained" color="success" >
                              <span className="btn-inner--icon"><i className="ni ni-ungroup"></i></span>
                              <UncontrolledTooltip target={'Partial' + index} placement='bottom'> Partial Refusal </UncontrolledTooltip>
                            </button>
                          )}


                          {(row.signUpStatusId == 14 && row.fromEvent == '1') && (
                            <button disabled={priviledge.Approve ? false : true} id={'Request' + index} onClick={() => openModalPopUp(row.channelTypeId, row.channelId, 0, contact, row.fromEvent, row.eventId, row.eventChannel[0]?.package?.packageName)} className="btn btn-icon btn-success btn-sm" variant="contained" color="success">
                              <span className="btn-inner--icon"><i className="ni ni-send"></i></span>
                              <UncontrolledTooltip target={'Request' + index} placement='bottom'> Send Request </UncontrolledTooltip>
                            </button>
                          )}
                          {/* End  */}

                          {row.signUpStatusId == 14 && (
                            <button disabled={priviledge.Dismiss ? false : true} id={'Del' + index} onClick={() => openModalPopUp(row.channelTypeId, row.channelId, 16, row.contacts[0], row.fromEvent, row.eventId, row.eventChannel[0]?.package?.packageName)} className="btn btn-icon btn-warning btn-sm" variant="contained" color="warning">
                              <span className="btn-inner--icon"><i className="ni ni-fat-remove"></i></span>
                              <UncontrolledTooltip target={'Del' + index} placement='bottom'> Dismiss </UncontrolledTooltip>
                            </button>
                          )}



                          {row.signUpStatusId == 15 && <button disabled={priviledge.Write ? false : true} id={'Edit15' + index} className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" onClick={e => { e.stopPropagation(); setUserData(Object.assign(row, { userId: row.user[0].userId, contactId: row.user[0].contactId })); }}>
                            <span className="btn-inner--icon"><i className="fas fa-pencil-alt"></i></span>
                            <UncontrolledTooltip target={'Edit15' + index} placement='bottom'> Edit </UncontrolledTooltip>
                          </button>}

                          {row.signUpStatusId == 15 && <button disabled={priviledge.View ? false : true} id={'View15' + index} className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary"  onClick={() => window.open('/admin/CompanyListing/channelDetail/' + row.channelId, '_blank')}>
{/* //  onClick={() => history.push('/admin/CompanyListing/channelDetail/' + row.channelId)} > */}
                            <span className="btn-inner--icon"><i className="far fa-eye"></i></span>
                            <UncontrolledTooltip target={'View15' + index} placement='bottom'> View </UncontrolledTooltip>
                          </button>}

                          {row.signUpStatusId == 15 && <button disabled={priviledge.Write ? false : true} id={'Set15' + index} className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" onClick={() => openPublicFlag(row)}>
                            <span className="btn-inner--icon"><i className="fa fa-cog"></i></span>
                            <UncontrolledTooltip target={'Set15' + index} placement='bottom'> Setting </UncontrolledTooltip>
                          </button>}

                          {/* { row.signUpStatusId == 15 &&  */}
                          <button disabled={priviledge.Write ? false : true} id={'Merge16' + index} className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" onClick={() => openMergeCompanyDialog(row)}>
                            <span className="btn-inner--icon"><i className="fas fa-plus"></i></span>
                            <UncontrolledTooltip target={'Merge16' + index} placement='bottom'> Merge Company Data </UncontrolledTooltip>
                          </button>
                          {/* } */}

                          {(row.signUpStatusId == 15 && isevent && !isAssociate && !hasPrimary) && (
                            <button disabled={priviledge.Approve ? false : true} id={'Approve' + index} onClick={() => openModalPopUp(row.channelTypeId, row.channelId, 15, contact, row.channelId, row.eventId, row.eventChannel, row.eventChannel[0]?.package?.packageId, row.contacts.filter(x => x.contactId == row.eventChannel.find(ch => ch.approvalStatus != 1)?.registeredBy)[0].eventParticipants, row.contacts)} className="btn btn-icon btn-default btn-sm" variant="contained" color="default" >
                              <span className="btn-inner--icon"><i className="ni ni-check-bold"></i></span>
                              <UncontrolledTooltip target={'Approve' + index} placement='bottom'> {`Event Approve (${row.eventChannel.filter(ch => (ch.approvalStatus == null || ch.approvalStatus == 0))?.map(ch => ch['event'].shortname)?.join(',')}) `} </UncontrolledTooltip>
                            </button>
                          )}

                          {/* For Associate Main Delegate  */}
                          {/* {(row.signUpStatusId == 15 && row.eventId && contact?.contactStatusId == 14) && (
                            <button disabled={priviledge.Approve ? false : true} id={'Partial' + index} onClick={() => openModalPopUp(row.channelTypeId,row.channelId, 15, contact, '3', row.eventId, row.eventChannel[0]?.package?.packageName)} className="btn btn-icon btn-success btn-sm" variant="contained" color="success" >
                              <span className="btn-inner--icon"><i className="ni ni-ungroup"></i></span>
                              <UncontrolledTooltip target={'Partial' + index} placement='bottom'> Partial Refusal Associate User </UncontrolledTooltip>
                            </button>
                          )} */}


                          {(row.signUpStatusId == 15 && hasPrimary && isevent) && (
                            <button id={'Approve' + index} className="btn btn-icon btn-default btn-sm" variant="contained" color="default" >
                              <span className="btn-inner--icon"><i className="ni ni-circle-08"></i></span>
                              <UncontrolledTooltip target={'Approve' + index} placement='bottom'> {`Second Event User (${eventsList}) `} </UncontrolledTooltip>
                            </button>
                          )}

                          {/* {(row.eventId == null && isevent && row.signUpStatusId == 15 && !isAssociate) && (
                            <button id={'Approve' + index} className="btn btn-icon btn-default btn-sm" variant="contained" color="default" >
                              <span className="btn-inner--icon"><i className="ni ni-circle-08"></i></span>
                              <UncontrolledTooltip target={'Approve' + index} placement='bottom'> Digital Event Approval </UncontrolledTooltip>
                            </button>
                          )} */}

                          {(row.signUpStatusId == 15 && isevent && !isAssociate && !hasPrimary) && (
                            <button disabled={priviledge.Dismiss ? false : true} id={'Del' + index} onClick={() => openModalPopUp(row.channelTypeId, row.channelId, 33, contact, row.channelId, row.eventId, row.eventChannel, row.eventChannel[0]?.package?.packageId, row.contacts.filter(x => x.contactId == row.eventChannel[0]?.registeredBy)[0].eventParticipants, row.contacts)} className="btn btn-icon btn-warning btn-sm" variant="contained" color="warning">
                              <span className="btn-inner--icon"><i className="ni ni-fat-remove"></i></span>
                              <UncontrolledTooltip target={'Del' + index} placement='bottom'> {`Event Dismiss (${row.eventChannel.filter(ch => (ch.approvalStatus == null || ch.approvalStatus == 0))?.map(ch => ch['event'].shortname)?.join(',')}) `}</UncontrolledTooltip>
                            </button>
                          )}

                          {row.signUpStatusId == 15 && <button disabled={priviledge.Delete ? false : true} id={'Del15' + index} onClick={() => openModalPopUp(row.channelTypeId, row.channelId, 0, contact, '0', row.eventId)} className="btn btn-icon btn-warning btn-sm" variant="contained" color="#fb6340">
                            <span className="btn-inner--icon"><i className="fas fa-trash-alt"></i></span>
                            <UncontrolledTooltip target={'Del15' + index} placement='bottom'> Delete </UncontrolledTooltip>
                          </button>}

                          {(isAssociate) && (
                            <button id={'Approve12' + index} className={`btn btn-icon  btn-sm ${isAssociateEvent ? "btn-default" : "btn-success"}`} variant="contained" color="primary" >
                              <span className="btn-inner--icon"><i className="far fa-handshake"></i></span>
                              <UncontrolledTooltip target={'Approve12' + index} placement='bottom'> New Associate User {isAssociateEvent && 'With Event'}</UncontrolledTooltip>
                            </button>
                          )}


                        </>

                      )
                      }
                    }
                  },
                  {
                    dataField: "channelTypeId",
                    text: "Company Type",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {/* {rowContent == 2 && "Vendor"}
                          {rowContent == 3 && "Distributor"}
                          {rowContent == 4 && "Retailer"}
                          {rowContent == 5 && "Reseller"} */}

                          {
                             channelTypeList.map(item => {
                               if(item.id == rowContent){
                                 return item.name
                               }
                             })
                          }
                          
                          {row.signUpStatusId == 14 && <i class="fas fa-pencil-alt float-right mt-1" onClick={() => editProfileType({ type: rowContent, channelId: row.channelId })}></i>}
                        </>
                      )
                    },
                    headerEvents: {
                      onClick: (e, column, columnIndex) => { getSorting(column, e.target) }
                    }
                  },
                  // {
                  //   dataField: "companyName",
                  //   text: " Company Name",
                  //   sort: true,
                  //   headerEvents: {
                  //     onClick: (e, column, columnIndex) => { getSorting(column, e.target) }
                  //   }
                  // },
                  {
                    dataField: "companyName",
                    text: "Company Name",
                    sort: true,
                    formatter: (cell, row) => (
                      <span style={{fontSize:"0.8125rem"}}
                        onClick={() => window.open('/admin/CompanyListing/channelDetail/' + row.channelId, '_blank')}
                      >
                        {cell}
                      </span>
                    )
                  },
                  
                  // {
                  //   dataField: "hubspotCompanyId",
                  //   text: "Company hubspot ID",
                  //   // sort: true,
                  // },
                  {
                    dataField: 'country.name',
                    text: "Company Country",
                    // sort: true
                  },
                  // {
                  //   dataField: 'regAddress.address',
                  //   text: "Company Address",
                  //   sort: true
                  // },
                  // {
                  //   dataField: "channelDetail.phone1",
                  //   text: "Company Phone No",
                  //   sort: true
                  // },
                  {
                    dataField: "isOEM",
                    text: "B2B/B2C",
                    // sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {rowContent == 1 && "B2C"}
                          {rowContent == 2 && "B2B"}
                          {rowContent == 3 && "Both"}
                        </>
                      )
                    }
                  },
                  {
                    dataField: "Users",
                    text: "Users",
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          <div className="avatar-group">
                            {row.contacts?.length && row.contacts.map((x, i) => <><a
                              className="avatar avatar-sm rounded-circle"
                              href="#pablo"
                              id={'easytool' + x.contactId}
                              onClick={e => e.preventDefault()}
                            >
                              <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }}
                                alt="..."
                                src={x.document?.documentPath ? x.document.documentPath.includes(process.env.REACT_APP_CDN_URL) ? x.document.documentPath : process.env.REACT_APP_CDN_URL + x.document.documentPath : 'http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png'}//{require("assets/img/theme/team-1.jpg")}
                              />
                            </a>
                              <UncontrolledTooltip delay={0} target={'easytool' + x.contactId}>
                                {x.firstName + ' ' + x.lastName}
                              </UncontrolledTooltip></>)}
                            {/* <a
                      className="avatar avatar-sm rounded-circle"
                      href="#pablo"
                      id="tooltip852299360"
                      onClick={e => e.preventDefault()}
                    >
                      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
                        alt="..."
                        src={require("assets/img/theme/team-2.jpg")}
                      />
                    </a>
                    <UncontrolledTooltip delay={0} target="tooltip852299360">
                      Romina Hadid
                    </UncontrolledTooltip>
                    <a
                      className="avatar avatar-sm rounded-circle"
                      href="#pablo"
                      id="tooltip333095755"
                      onClick={e => e.preventDefault()}
                    >
                      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
                        alt="..."
                        src={require("assets/img/theme/team-3.jpg")}
                      />
                    </a>
                    <UncontrolledTooltip delay={0} target="tooltip333095755">
                      Alexander Smith
                    </UncontrolledTooltip>
                    <a
                      className="avatar avatar-sm rounded-circle"
                      href="#pablo"
                      id="tooltip917841216"
                      onClick={e => e.preventDefault()}
                    >
                      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
                        alt="..."
                        src={require("assets/img/theme/team-4.jpg")}
                      />
                    </a>
                    <UncontrolledTooltip delay={0} target="tooltip917841216">
                      Jessica Doe
                    </UncontrolledTooltip> */}
                          </div>
                        </>
                      )
                    }
                  },
                  // {
                  //   dataField: 'channelProductGroup',
                  //   text: "Product Families",
                  //   // sort: true,
                  //   formatter: (rowContent) => {
                  //     let data = rowContent.map(x=>x.productGroup.name);
                  //     data = data.toString();
                  //     return(
                  //       <>
                  //       {data}
                  //       </>
                  //     )
                  //   }
                  // },
                  // {
                  //   dataField: "contacts[0].firstName",
                  //   text: "Contact Name",
                  //   sort: true,
                  //   formatter: (rowContent, row) => {
                  //     return(
                  //       <>
                  //       {row.contacts[0].firstName +' '+ row.contacts[0].firstName}
                  //       </>
                  //     )
                  //   }
                  // },
                  // {
                  //   dataField: 'contacts[0].jobTitle',
                  //   text: "Job Title",
                  //   sort: true
                  // },
                  // {
                  //   dataField: 'contacts[0].email',
                  //   text: "Contact Email",
                  //   sort: true
                  // },
                  // {
                  //   dataField: "contacts[0].mobileNo",
                  //   text: "Contact Mobile No",
                  //   sort: true,
                  //   formatter: (rowContent, row) => {
                  //     return(
                  //       <>
                  //       {row.contacts[0].mobileNo?.replace(/[^\w\s]/gi, '')}
                  //       </>
                  //     )
                  //   }
                  // },
                  // {
                  //   dataField: "contacts[0].hubspotContactId",
                  //   text: "Contact hubspot ID",
                  //   sort: true,
                  //   formatter: (rowContent, row) => {
                  //     return(
                  //       <>
                  //       {row.contacts[0].hubspotContactId? row.contacts[0].hubspotContactId : '-'}
                  //       </>
                  //     )
                  //   }
                  // },
                  {
                    dataField: "profileStrength",
                    text: "Profile Strength",
                    sort: true,
                    headerEvents: {
                      onClick: (e, column, columnIndex) => { getSorting(column, e.target) }
                    }

                  },
                  {
                    dataField: "createdAt",
                    text: "Registered On",
                    sort: true,
                    formatter: (rowContent, row, index) => {
                      return new Date(rowContent).toLocaleDateString()
                    },
                    headerEvents: {
                      onClick: (e, column, columnIndex) => { getSorting(column, e.target) }
                    }
                  },
                  {
                    dataField: "contacts",
                    text: "From Event",
                    // sort: true,
                    formatter: (rowContent, row, index) => {
                      let isevent = false;
                      let onlyCoordinator = false;
                      let ispaid = false;
                      // rowContent.map(x => {
                      //   if (x.isEvent == '1') {
                      //     isevent = true
                      //   }
                      // })
                      row.contacts.map(x => {
                        if (x.eventParticipants?.length > 0 && x?.eventParticipants.filter(x => (x.status == '1')).length > 0) {
                          isevent = true
                        }
                      })

                      row.eventChannel.map(x => {
                        if (x.paymentstatus == '1') {
                          ispaid = true
                        }
                        if(x?.approvalStatus == '1'){
                          isevent = true
                        }
                      })

                      return (
                        <>

                          {isevent ? <i id={'Y' + index} class="far fa-calendar-check" style={{ color: (ispaid) ? "green" : "black" }} onClick={() => getRegisteredEventContact(row.channelId)}><UncontrolledTooltip target={'Y' + index} placement='right'>{(ispaid) ? 'Event Confirmed' : 'payment pending'}</UncontrolledTooltip></i> : onlyCoordinator ? <i id={'Y' + index} class="far fa-calendar-check" style={{ color: "orange" }} onClick={() => getRegisteredEventContact(row.channelId)}><UncontrolledTooltip target={'Y' + index} placement='right'> Event Manager </UncontrolledTooltip></i> : <i id={'Y' + index} class="far fa-calendar-times" style={{ color: "red" }} onClick={() => getRegisteredEventContact(row.channelId)} ><UncontrolledTooltip target={'Y' + index} placement='right'  > No Event </UncontrolledTooltip></i>}
                        </>
                      )
                    }
                  },
                  {
                    dataField: "hubspotCompanyId",
                    text: "HubSpot Id",
                    //sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <> {rowContent}
                          {<i class="fas fa-pencil-alt float-right mt-1" onClick={() => editHubspot({ hubspotCompanyId: row.hubspotCompanyId, channelId: row.channelId, sta: 'none', upt: 'none' })}></i>}
                        </>
                      )
                    }
                  },
                  {
                    dataField: 'channelId',
                    text: "Channel Id",
                    sort: true
                  },
                  // {
                  //   dataField: "status",
                  //   text: "Status",
                  //   sort: true,
                  //   formatter: (rowContent, row) => {
                  //       return (    
                  //         <>        
                  //         {row.signUpStatusId==14?(
                  //         <button className="btn btn-icon btn-warning btn-sm" variant="contained" color="warning" >
                  //         <span>Pending</span>
                  //     </button>):(
                  //       <button className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" >
                  //         <span>Approved</span>
                  //     </button>
                  //     )}                                               
                  //       </>
                  //       )
                  //   }
                  // } ,

                  // {
                  //   dataField: "Public Settings",
                  //   text: "Public Settings",
                  //   formatter: (rowContent, row) => {
                  //     return (                          
                  //       <>                          

                  //     {row.signUpStatusId==15 && <button className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" onClick={()=>openPublicFlag(row)}>
                  //         <span>Public View</span>
                  //     </button>}

                  //     </>

                  //     )
                  //     }

                  // }
                ]}
              >
                {props => (
                  <div >
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 "
                    >
                      <div style={{ width: '20%', display: 'inline-block', marginBottom: '15px' }}>
                        <SelectAutocomplete
                          id="search"
                          type="text"
                          label="search"
                          name="search"
                          options={searchState.dropdownList}
                          placeholder="search..."
                          disableCloseOnSelect
                          // defaultValue={searchState.search?searchState.search[0]:null}
                          control={control}
                          input='search'
                          value={searchState.search ? searchState.search[0] : null}
                          handleChangeSelect={handleChangeSelectFilter}
                          onInputChange={handleOnInputChange}
                        />
                      </div>

                      <UncontrolledDropdown className="pl-3 mt-2 mb-2" group>
                        <DropdownToggle caret className="p-2" style={{ top: '7px' }}>
                          <h3
                            style={{ display: "inline-block" }}
                            className="font-weight-bold m-0"
                          >
                            Sort {isSort ? sort ? 'A - Z' : 'Z - A' : ''}
                          </h3>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem

                            onClick={() => setSorting('asc')}
                          >
                            A - Z
                          </DropdownItem>
                          <DropdownItem

                            onClick={() => setSorting('desc')}
                          >
                            Z - A
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      {isSort && <button class="btn btn-icon btn-secondary ml-2" type="button" onClick={clearSorting}>
                        <span class="btn-inner--text">clear</span>
                      </button>}

                      {priviledge.Write &&
                        <Button className="btn btn-primary float-right" type="button" onClick={exportFilter} style={{ top: '15px' }}>Export</Button>}
                      <Button className="btn btn-primary float-right" type="button" style={{ top: '15px' }} onClick={showChannelAccount}>Switch To {activeType.label}</Button> 
                      <button style={{ top: '15px' }} onClick={() => history.push({ pathname: '/admin/manageOtherUser' })} className="btn btn-primary float-right">Create Other User</button>
                      </div>

                    <div className=" table-responsive">
                      <div className='channel-table'>
                        <BootstrapTable
                          {...props.baseProps}
                          expandRow={User}
                          bootstrap4={true}
                          // pagination={pagination}
                          bordered={false}
                          hover={true}
                          headerClasses="tableHeader"
                          rowClasses='pointer'
                        />
                      </div>
                      <TablePagination
                        component="div"
                        count={state.total}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        style={{ float: 'right' }}
                      />
                    </div>
                  </div>
                )}
              </ToolkitProvider>}
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};
export default CompanyListing;

const is_OEM_Option = [
  { name: "B2C", id: '1' },
  { name: "B2B", id: '2' },
  { name: "Both", id: '3' }
]
const plan_Option = [
  { name: "Free", id: '1' },
  { name: "Premium", id: '6' },
  { name: "expired", id: '-2' }
]
const tag_Option = [
  { name: "Featured", id: '1' },
  { name: "Public", id: '2' }
]
const profile_Option = [
  { name: "After 1st Remainder", id: '1' },
  { name: "After 2nd Remainder", id: '2' },
  { name: "After 3rd Remainder", id: '3' }
]
const event_option = [
  { name: "Registered", id: '-1' },
  { name: "Not Registered", id: '-2' },
  // { name: "Completed", id: '-2' },
  // { name: "Upcoming", id: '-3' }
]

const resendmail_option = [
  { id: '1', name: 'Approval Mail', templateId: 'd-fadcddce4e6a41e9a73c027c7ca6ed95', label: 'approvalmail' },
  // { id: '2', name: 'EventPayment Mail', templateId: 'd-fadcddce4e6a41e9a73c027c7ca6ed95' }
]